import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import {getContractTerminationPdf} from "../../../services/contract.service";
import {blobToBase64, downloadFile} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
// import {FileOpener} from "@ionic-native/file-opener";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledLightBlueButton} from "../../../components/button/button.style";
import {isPlatform} from "@ionic/react";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type TerminatePreviewModalProps = {
    isOpen: boolean;
    contract?: Contract,
    onClose: () => void;
};

const TerminationPreviewModal: React.FC<TerminatePreviewModalProps> = (props: TerminatePreviewModalProps) => {

    const {t} = useTranslation();

    const [showTerminate, updateShowTerminate] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowTerminate(props.isOpen);
    };

    const openTerminationPdf = async () => {
        if (props.contract?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getContractTerminationPdf(props.contract?.id)
                    .then(response => {
                        return response.data;
                    })

                if (isPlatform('desktop') || isPlatform('mobileweb')) {
                    downloadFile(fileData, 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - ' + t("contractPage.terminationPreview.title") + '.pdf');
                } else {
                    let base64 = null;
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - ' + t("contractPage.terminationPreview.title") + '.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch (e) {

                        }
                    }
                }
            }
        }
    }

    return (
        <Modal
            isOpen={showTerminate}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleCloseRemarks()}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.terminationPreview.title")}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.termination_content || ''}}/>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledLightBlueButton onClick={() => openTerminationPdf()}>{t("contractPage.terminationPreview.download")}</StyledLightBlueButton>
                    </StyledFooter>
                </StyledModalContent>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default TerminationPreviewModal;