import React, {useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import {StyledInput} from '../../components/form/input/input.style';
import {useForm} from 'react-hook-form';
import {StyledContent} from '../reportPage/manualBarcodePage.style';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import {useTranslation} from 'react-i18next';
import {Links} from "../links";
import {Order} from "@app/reportPage/reportPage.component";
import {findContainerByNumber} from "@services/report.service";

interface RouterProps {
    orderId: string;
    specificationId: string;
}

interface ManualContainerProps extends RouteComponentProps<RouterProps> {
}

const ManualContainerFindPage: React.FC<ManualContainerProps> = ({match}) => {

    const fieldName = "containerNumber";
    const history = useHistory();
    const {t} = useTranslation();
    const [error, setError] = useState<string>();
    const [containerId, setContainerId] = useState<string>();

    const {params: {orderId, specificationId}} = match;


    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const {register, formState: {isValid}, getValues, handleSubmit} = useForm({
        mode: 'all'
    });

    const handleSave = async () => {
        await findContainerByNumber(getValues(fieldName), orderId)
            .then(response => {
                console.log(response.data);
                if (response.data.status == 'success') {
                    if (response.data.message) {
                        history.push(`${Links.report}/${orderId}/${specificationId}/0/0/${response.data.message}`);
                    }
                }
            })
            .catch(reason => {
                console.log(reason);
                let error = reason.response.data.message;
                if (error == 'CONTAINER_NOT_IN_THIS_ORDER') {
                    setError(t('reportsTab.orders.scanContainerPage.error.container_not_in_this_order'))
                } else if (error == 'CONTAINER_DONE') {
                    setError(t('reportsTab.orders.scanContainerPage.error.container_done'))
                } else {
                    setError(t('reportsTab.container.manualContainerFind.error.no_container'))
                }

                return null;
            });
    };

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('manualContainerFind.title')}
                            </div>
                        </StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <h3 className="desc">{t("manualContainerFind.number")}</h3>
                            <StyledInput name={fieldName} className={error && 'hasErrors'} inputMode="text"
                                         ref={register({
                                             required: true
                                         })}/>
                            {error && <p className="errorMessage">{error}</p>}
                            <Form.Button type="submit"
                                         disabled={!isValid}>{t("manualContainerFind.addButton")}</Form.Button>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};
export default ManualContainerFindPage;