export enum PhoneNumberPrefix {
	'PL' = 'PL',
	'DE' = 'DE',
	'SK' = 'SK',
	'CZ' = 'CZ',
	'RU' = 'RU',
	'TK' = 'TK',
	'RO' = 'RO',
	'GB' = 'GB',
	'HU' = 'HU',
	'CN' = 'CN',
	'NL' = 'NL',
	'PT' = 'PT',
	'ES' = 'ES',
	'AL' = 'AL',
	'BE' = 'BE',
	'FR' = 'FR',
	'TR' = 'TR'
}

