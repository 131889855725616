import styled from 'styled-components';

export const StyledContent = styled.div`

  width: 100%;
  padding-top: 40px;

  .info {
    margin-bottom: 32px;
  }
`;

export const StyledStatusContainer = styled.div`

width: 100%;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 140%;
color: #888A98;
padding: 16px 0;

.status-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;

  .statement {
    color: #0084FF;
  }
}

`;