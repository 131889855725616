import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {IonCol, IonItem, IonLabel, IonList} from "@ionic/react";

import {SubMenuItem} from "@models/menu";
import Pane from "@components/pane/pane.component";

type SubMenuModalProps = {
    topEdge?: number,
    subMenuItems: SubMenuItem[];
    getIcon: (item: SubMenuItem) => ReactNode;
    redirect: (item: SubMenuItem) => void;
};

const SubMenuModal: React.FC<SubMenuModalProps> = ({topEdge, subMenuItems, getIcon, redirect}: SubMenuModalProps) => {
    const {t} = useTranslation();

    const handleItemClick = (item: SubMenuItem) => {
        redirect(item);
    }

    return (
        <Pane topEdge={topEdge}>
            <IonList style={{'paddingRight': '20px', 'paddingTop': '10px'}}>
                {
                    subMenuItems.map((item) => {
                        return <IonItem key={item.id} onClick={() => handleItemClick(item)}>
                            <IonCol size='2' style={{'textAlign': 'center'}}>
                                {getIcon(item)}
                            </IonCol>
                            <IonLabel>
                                {t(item.reference)}
                            </IonLabel>
                        </IonItem>
                    })
                }
            </IonList>
        </Pane>
    );
};

export default SubMenuModal;