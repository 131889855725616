import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";
import {LogisticContainer} from "@app/reportPage/reportPage.component";

type SaveConfirmationModalProps = {
    isOpen: boolean;
    logisticContainer: LogisticContainer | null | undefined;
    onConfirm: () => void;
    onCancel: () => void;
};

const SaveConfirmationModal: React.FC<SaveConfirmationModalProps> = (props: SaveConfirmationModalProps) => {

    const {t} = useTranslation();

    const [showModal, updateShowModal] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleCancel = () => {
        updateShowModal(false);
        props.onCancel();
    };

    const handleSign = () => {
        updateShowModal(false);
        props.onConfirm();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => props.onCancel()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(handleSign)}>
                <StyledModalContent>
                    <h1 className="title">{t("reportConfirmation.title")}</h1>
                    <div className="content">
                        {props.logisticContainer &&
                            <p>{t("reportConfirmation.descriptionLogisticContainer")}</p>
                        }
                        {!props.logisticContainer &&
                            <p>{t("reportConfirmation.description")}</p>
                        }
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledWiteButton type="button" onClick={handleCancel}>{t("reportConfirmation.cancel")}</StyledWiteButton>
                        <StyledButton type="submit">{t("reportConfirmation.confirm")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default SaveConfirmationModal;