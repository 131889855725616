import styled from 'styled-components';

export const RowMeasurementContainer = styled.div`
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  padding: 0px 11px 24px 11px;
  margin: 24px 0;
  
  .title {
    margin-bottom: 0px;
  }
`;