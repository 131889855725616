import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonToast, useIonViewWillEnter } from '@ionic/react';
import { } from '@ionic/react';
import StyledTitle from '../../components/title/index';
import StyledSubTitle from '../../components/title/subTitle';
import { StyledLoginContainer } from '../login/login.styles';
import { StyledWiteButton, StyledButton } from '../../components/button/button.style';
import { useHistory } from 'react-router-dom';
import { AppLauncher } from '@capacitor/app-launcher';
import { Device } from '@capacitor/device';
import { useTranslation } from 'react-i18next';
import CircleImage, { ImageTypes } from '../../components/circleImage/circleImage.component';
import {loginStatusBarUpdate} from "../../utils/tools/statusbar";

const ForgotPasswordSuccess: React.FC = () => {

    useIonViewWillEnter(() => {
        loginStatusBarUpdate();
    });

    const history = useHistory();
    const { t } = useTranslation();

    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');

    function goToLogin(e: any) {
        e.preventDefault();
        history.push('/login');
    };

    const openMailApp = async () => {
        const info = await Device.getInfo();
        if (info.platform === 'android') {
            var retAndroid = await AppLauncher.canOpenUrl({ url: 'com.google.android.gm' });
            let openRet = await AppLauncher.openUrl({ url: 'com.google.android.gm' }).catch(() => {
                setToast(t("forgotPassword.emailAppNotFound"));
                setShowToast(true);
            });
        } else if (info.platform === 'ios') {
            var retIos = await AppLauncher.canOpenUrl({ url: 'message://' });
            let openRet = await AppLauncher.openUrl({ url: 'message://' }).catch(() => {
                setToast(t("forgotPassword.emailAppNotFound"));
                setShowToast(true);
            });
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledLoginContainer>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toast}
                        duration={6000}
                        position="top"
                        color="danger"
                    />
                    <CircleImage image={ImageTypes.LETTER}></CircleImage>
                    <StyledSubTitle>{t("forgotPassword.subTitle")}</StyledSubTitle>
                    <StyledTitle>{t("forgotPassword.successEmailCheck")}</StyledTitle>

                    <StyledButton onClick={openMailApp} >
                        {t("forgotPassword.successButtonEmailApp")}
                    </StyledButton>

                    <StyledWiteButton onClick={goToLogin}>
                        {t("forgotPassword.successButtonBackLogin")}
                    </StyledWiteButton>
                </StyledLoginContainer>
            </IonContent >
        </IonPage >
    );
};

export default ForgotPasswordSuccess;
