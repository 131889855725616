import styled from 'styled-components';

export const StyledSubTitle = styled.p`
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    line-height: 17px;
    margin-top: 60px;
    margin-bottom: -5px;
`;
