export enum AuditType {
    BHP = 'BHP',
    QUALITY = 'QUALITY',
}

export enum AuditShift {
    MORNING = 'MORNING',
    EVENING = 'EVENING',
    NIGHT = 'NIGHT',
}

export enum AuditReportPenaltyType {
    WORKER = 'WORKER',
    COORDINATOR = 'COORDINATOR',
    TEAM_LEADER = 'TEAM_LEADER',
}

export enum AuditIncompatibilityActionType {
    IMMEDIATE = 'IMMEDIATE',
    CORRECTIVE = 'CORRECTIVE',
    PERFECTING = 'PERFECTING',
}

export enum AuditIncompatibilityStatus {
    PENDING = '0',
    RESOLVED = '1',
}