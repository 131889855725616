import styled from "styled-components";


export const StyledContent = styled.div`
    width: 100%;
    padding-bottom: 10px;
  
    .progress-bar {
        padding-top: 12px;
  
        .progress-bar-background {
            height: 4px;
            background-color: #D9D9D9;
            overflow: hidden;
        }
    
        .progress-bar-progress {
            height: 4px;
            background-color: #D8001D
        }
      
        .counter {
          font-weight: bold;
          text-align: center;
        }
    }
  
    .steps {
      text-align: center;
      font-weight: bold;
      letter-spacing: 10px;
    }
`