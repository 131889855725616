import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "../../../components/modal/modal.component";
import {Notification} from "../../../models/notification";
import {StyledButton, StyledLightBlueButton, StyledWiteButton} from "../../../components/button/button.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledModalContent} from "../../../components/modal/modal.style";
import {Task, TaskStatus} from "../components/taskItem/taskItem.component";
import VectorImage from "../../../assets/images/vector.svg";
import {IonSkeletonText} from "@ionic/react";
import {StyledDataTable} from "../../../components/content/content.style";
import moment from "moment";
import {getConfiguration} from "../../../services/addEditValuePage.service";
import {StyledActionButtons} from "../components/taskItem/taskItem.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type PreviewTaskItemModalProps = {
    isOpen: boolean;
    taskItem?: Task | null;
    onClose: () => void;
	onApprove: (task: Task) => void,
	onDismiss: (task: Task) => void,
};

const PreviewTaskItemModal: React.FC<PreviewTaskItemModalProps> = (props: PreviewTaskItemModalProps) => {

    const {t} = useTranslation();

    const [showRemarksList, updateShowRemarksList] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
    	handleOpen();
    });

    const handleOpen = () => {
        updateShowRemarksList(props.isOpen);
    };

    return (
        <Modal
            isOpen={showRemarksList}
            canDismiss={disableSwipeToClose}
            maxHeight={'75%'}
            onDidDismiss={() => handleClose()}>

			{
				props.taskItem && <StyledModalContent className="scrollable">
					<h6 className="description">{t('notificationTab.timetablePane.preview.title', {coordinator: props.taskItem?.coordinator})}</h6>
					<StyledDataTable>
						<div className="data-row">
							<div className="data-title">{t('notificationTab.timetablePane.preview.place')}</div>
							<div className="data-value">{props.taskItem?.place || '--'}</div>
							<div className="data-action"></div>
						</div>
						<div className="data-row">
							<div className="data-title">{t('notificationTab.timetablePane.preview.jobPosition')}</div>
							<div className="data-value long-text">{props.taskItem?.jobPosition || '--'}</div>
							<div className="data-action"></div>
						</div>
						<div className="data-row">
							<div className="data-title">{t('notificationTab.timetablePane.preview.from')}</div>
							<div
								className="data-value">{moment(props.taskItem?.startDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')}</div>
							<div className="data-action"></div>
						</div>
						<div className="data-row">
							<div className="data-title">{t('notificationTab.timetablePane.preview.to')}</div>
							<div
								className="data-value">{moment(props.taskItem?.endDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')}</div>
							<div className="data-action"></div>
						</div>
						<div className="data-row">
							<div className="data-title">{t('notificationTab.timetablePane.preview.details')}</div>
							<div className="data-value">{props.taskItem?.description}</div>
							<div className="data-action"></div>
						</div>
					</StyledDataTable>

					<StyledFooter className="modal footer no-shadow">
						{
							props.taskItem.status === TaskStatus.PROPOSED &&
							<div style={{width: '100%'}}>
								{
									props.taskItem.acceptEnabled &&
									<StyledButton className="accept"
												  onClick={(e) => {
													  e.stopPropagation();
													  props.taskItem ? props.onApprove(props.taskItem) : e.preventDefault()
												  }}>
										{t("notificationTab.taskItem.accept")}
									</StyledButton>
								}

								<StyledWiteButton onClick={(e) => {
									e.stopPropagation();
									props.taskItem ? props.onDismiss(props.taskItem) : e.preventDefault()
								}}>
									{t("notificationTab.taskItem.dismiss")}
								</StyledWiteButton>
							</div>
						}

						{
							props.taskItem.status === TaskStatus.ACCEPTED && <div style={{width: '100%', 'textAlign': 'center'}}>
									<h6 className="description">{t("notificationTab.taskItem.accepted")}</h6>
									<StyledWiteButton onClick={(e) => {
										e.stopPropagation();
										props.taskItem ? props.onDismiss(props.taskItem) : e.preventDefault()
									}}>
										{t("notificationTab.taskItem.dismiss")}
									</StyledWiteButton>
								</div>
						}

						{
							(props.taskItem.status === TaskStatus.ABSENCE_REPORTED || props.taskItem.status === TaskStatus.ABSENCE_REPORTED_AFTER_DEADLINE || props.taskItem.status === TaskStatus.ABSENCE_UNREPORTED) &&
								<h6 className="description">{t("notificationTab.taskItem.dismissed")}</h6>
						}
					</StyledFooter>
				</StyledModalContent>
			}
        </Modal>
    );
};

export default PreviewTaskItemModal;
