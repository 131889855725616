import styled from 'styled-components';

export const StyledAvailabilityOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #B5B7C6;
  margin-bottom: 5px;

    div {
      display: inline-block;

      &.selected {
        font-weight: 600;
        color: #000000;
      }
    }
  }
`;

export const StyledDetailViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #F3F3F6;
    border-radius: 12px;
    padding: 9px 7px 9px 18px;
    min-height: 42px;
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: #000000;

    &.filled {
      background: #FFFFFF;
      border: 1px solid #EDEDF3;
      box-sizing: border-box;
      box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
      border-radius: 12px;

      .details {
        font-weight: normal;
      }
    }
  }
`;

export const StyledNormalViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.24px;

  color: #888A98;

  .column {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100px;

    img {
      width: 16px;
      height: 16px;
      margin-top: 21px;
    }

    .hours-interval {
      margin: 14px 0;
      border: 1px solid #EDEDF3;
      box-sizing: border-box;
      box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
      border-radius: 26px;
      width: 100%;
      padding: 4px 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .hour {
      border: 1px solid #E6E5EB;
      box-sizing: border-box;
      width: 100%;
      border-bottom: none;
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.last {
        border-bottom: 1px solid #E6E5EB;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.first {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &.selected {
        background: #0085FF;
        border-color: rgba(255, 255, 255, 0.25);
        color: #fff;
      }
    }
  }
`;
