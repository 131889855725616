import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../../components/modal/modal.component";
import Form from '../../../components/form';
import {useForm} from "react-hook-form";
import {StyledModalContent} from "../../../components/modal/modal.style";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {IonItem} from "@ionic/react";
import {StyledFooter} from "../../../components/pane/pane.style";
import LinkLabel from "../../../components/form/input/label.component";
import {ApprovalsContext} from "../../mainPage/tabs/settings/modals/appApprovalsModal.component";


type JobOfferContactApprovalModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
};

const JobOfferContactApprovalModal: React.FC<JobOfferContactApprovalModalProps> = (props: JobOfferContactApprovalModalProps) => {

    const {t} = useTranslation();

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [jobOfferContactChecked, setJobOfferContactCheckedChecked] = useState<any>(null);
    const [jobOfferContactShowDesc, setJobOfferContactShowDesc] = useState(false);

    const approvals = useContext(ApprovalsContext);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowApprovals(props.isOpen);
    };

    const onSubmit = async () => {
        approvals.setJobOfferContactChecked(true);

        if (props.onSubmit != undefined) {
            props.onSubmit();
        }

        props.onClose();
    };

    return (
        <Modal
            isOpen={showApprovals}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <h1 className="title">{t("settingsTab.approvalsModal.title")}</h1>

                    <div className="content">

                        <IonItem lines="none">
                            <StyledIonLabel>{t("createAccount.acceptJobOfferContact")}</StyledIonLabel>
                            <StyledIonCheckbox slot="start" checked={jobOfferContactChecked}
                                               onIonChange={e => {
                                                   setJobOfferContactCheckedChecked(e.detail.checked)
                                               }}/>
                        </IonItem>

                        <>
                            { !jobOfferContactShowDesc &&
                            <a className="showLessMore" onClick={ e => setJobOfferContactShowDesc(true)}>{t("createAccount.showMore")}</a>
                            }

                            { jobOfferContactShowDesc &&
                            <LinkLabel urlString={t("createAccount.acceptJobOfferContactDescURL")}
                                       url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                <p className="termsDesc">{t("createAccount.acceptJobOfferContactDesc1")}</p>
                                <p className="termsDesc">{t("createAccount.acceptJobOfferContactDesc2")}</p>
                            </LinkLabel>
                            }

                            { jobOfferContactShowDesc &&
                            <a className="showLessMore" onClick={ e => setJobOfferContactShowDesc(false)}>{t("createAccount.showLess")}</a>
                            }
                        </>
                    </div>

                    <StyledFooter className="modal footer no-shadow">
                        <Form.Button type="submit" disabled={!jobOfferContactChecked}>{t("settingsTab.approvalsModal.acceptButton")}</Form.Button>
                    </StyledFooter>
                </StyledModalContent>

            </Form.Container>
        </Modal>
    );
}

export default JobOfferContactApprovalModal;