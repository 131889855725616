import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {FORM_FIELDS, FormFieldsType} from './forgotPasswordForm.types';
import Form from '../../../components/form';
import {useHistory} from 'react-router-dom';
import {validateEmail} from '../../../services/validation.service';
import {resetPassword} from '../../../services/auth.service';
import {useTranslation} from 'react-i18next';
import {IonToast} from "@ionic/react";

const ForgotPasswordForm: React.FC = () => {
    const {t} = useTranslation();
    const {register, handleSubmit, watch, errors, formState, reset} = useForm({
        mode: 'onBlur'
    });
    const history = useHistory();
    const {isValid, isDirty} = formState;

    const watchEmail = watch(FORM_FIELDS.EMAIL, '');

    const disableButtonCondition: boolean = (watchEmail == '');
    const [submitted, setSubmitted] = useState<any>('');
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');

    const onSubmit = async (data: FormFieldsType) => {
        if (isValid) {
            const response = await resetPassword(data)
                .then(response => {
                    if (response.data.result === 'error' && response.data.message === 'domain_user_not_allowed') {
                        setToast(t("createAccount.badCredentialsDomainUserError"));
                        setShowToast(true);
                    } else {
                        reset();
                        return true;
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        setToast(t("login.lockedAccountContactAdministration"));
                        setShowToast(true);
                    }
                    return false;
                });

            if (response) {
                history.push('/forgot-password/check-email');
            }
        }
    }

    let invalidEmailError = t("forgotPassword.invalidEmail");

    return (
        <Form.Container onSubmit={handleSubmit(onSubmit)}>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <Form.Input name={FORM_FIELDS.EMAIL}
                        ref={register({
                            required: true,
                            validate: value =>
                                validateEmail(value) || invalidEmailError
                        })}
                        placeholder={t("forgotPassword.inputEmailPlaceholder")}
                        className={errors.email && 'hasErrors'}
                        type="email"
            />
            {errors.email && errors.email.message && <p className="errorMessage">{errors.email.message}</p>}
            <Form.Button type="submit" disabled={disableButtonCondition}>{t("forgotPassword.button")}</Form.Button>
        </Form.Container>
    )
}

export default ForgotPasswordForm;
