import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {IonAlert, IonButtons, IonHeader, IonPage} from "@ionic/react";
import {
    StyledEmptyContainer,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from "../../components/content/content.style";
import StaticPane from "../../components/pane/static-pane.component";
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import {useTranslation} from "react-i18next";
import {removeUser} from "../../services/profile.service";
import {StyledHeaderButton, StyledHeaderButtonImage} from "../../components/button/button.style";
import ArrowLeftImage from "../../assets/images/arrow-left.svg";
import {Preferences} from "@capacitor/preferences";
import Logo from "../../components/logo";
import {logout, useProvideAuth} from "../../services/auth.service";
import {Links} from "../links";

const UserRemovePage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory()
    const auth = useProvideAuth();

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showAlert, setShowAlert] = useState(false);
    const [ avatarUrl, setAvatarUrl] = useState<string | null>();
    const [ firstName, setFirstName] = useState<string>();
    const [ lastName, setLastName] = useState<string>();

    const getUserData = async () => {
        let avatarUrl = await Preferences.get({'key': 'avatar_url'});
        setAvatarUrl(avatarUrl?.value);

        let firstName = await Preferences.get({'key': 'first_name'});
        if (firstName.value && firstName.value != 'null') {
            setFirstName(firstName.value);
        }

        let lastName = await Preferences.get({'key': 'last_name'});
        if (lastName.value && lastName.value != 'null') {
            setLastName(lastName.value);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const handleBack = () => {
        history.goBack();
    };

    const handleRemove = async () => {
        await removeUser();
        await logout().then(() => {
            auth.signOut();
            history.replace(Links.login)
            //window.location.reload();
            window.location.href = '/';
        });
    }

    return (
        <IonPage>

            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            await handleRemove();
                        }
                    }
                ]}
            />

            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("userRemove.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <StyledEmptyContainer>
                        {
                            avatarUrl ?
                                <img className="avatar" src={avatarUrl} /> : <Logo />
                        }

                        <h3>{firstName ? firstName : ''} {lastName ? lastName : ''}</h3>
                        <p className="center">{t('userRemove.description')}</p>

                        <StyledFooter className="no-shadow">
                            <Form.Button type="submit" onClick={() => setShowAlert(true)}>{t('userRemove.confirmation')}</Form.Button>
                        </StyledFooter>
                    </StyledEmptyContainer>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default UserRemovePage;
