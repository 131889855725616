import {appApi} from "./api.service";
import * as TeamMemberFormFieldsTypes from '../app/becameTeamMemberPage/becameTeamMember.types';
import {getProfile} from "./profile.service";
import {storeProfile} from "./auth.service";
import {Preferences} from "@capacitor/preferences";

let endpointPrefix = 'user';

const checkFormType = (rcp: string) => {
	return appApi.post(endpointPrefix + '/connect-worker/step/1', {
		rcp: rcp
	});
}

const connect = async (data: TeamMemberFormFieldsTypes.FormFieldsType) => {
	let model = {
		phone: data.phone,
		number: data.number,
		rcp: data.rcp,
		email: data.email,
		matchingField: data.matchingField,
		matchingType: data.matchingType
	};

	return appApi.post(endpointPrefix + '/connect-worker/step/2/sign', model);
};

const signVerify = async (code: string) => {
	return appApi.post(endpointPrefix + '/connect-worker/step/2/sign/code-verification', {
		'verificationCode': code
	});
}

const hasPhone = async () => {
	let number = '';
	let apiPhone = null;
	let phone = await Preferences.get({'key': 'phone'});
	if (phone.value == null || phone.value == 'null' || phone.value == '') {
		await getProfile(true)
			.then(response => {
				number = response.data.number;
				apiPhone = response.data.phone;
				storeProfile(response.data);
			})


		return !(!apiPhone && number);
	}
};

export {
	checkFormType,
	connect,
	signVerify,
	hasPhone
};
