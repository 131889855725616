import {IonCol, IonGrid, IonRow, IonSkeletonText, useIonViewWillEnter} from '@ionic/react';
import React, {useContext, useEffect, useState} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import EmployerDetailsActionSheet from '../components/employerDetailsActionSheet/employerDetailsActionSheet.component';
import WalletDetailsActionSheet from '../components/walletDetailsActionSheet/walletDetailsActionSheet.component';
import {StyledDisclaimer, StyledWalletGrid, StyledWalletOption} from './wallet.style';
import {StyledEmptyContainer} from "../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {getWalletData} from "../../../services/worker.service";
import moment from "moment";
import useNavigation from "../../../services/navigation.service";
import { useHistory } from 'react-router-dom';
import WalletAddonsActionSheet from "../components/walletAddonsActionSheet/walletAddonsActionSheet.component";
import WalletCutsActionSheet from "../components/walletCutsActionSheet/walletCutsActionSheet.component";
import {isEmployed} from "../../../services/auth.service";
import {minutesToHoursMinutes} from "../../../utils/tools/time";
import {log} from "../../../services/firebaseAnalytics.service";

type WalletPaneProps = {
    topEdge?: number;
}

export type MonthWallet = {
    month: string;
    employers: EmployerWallet[];
	addons: EmployerAddon[];
	cuts: EmployerCut[];
}

export type EmployerWallet = {
    name: string;
    workingHours: WorkingHour[]
}

export type WorkingHour = {
    date: Date;
    quantity: number;
    rate: number;
}

export type EmployerAddon = {
	name: string;
	comment: string;
	value: number;
}

export type EmployerCut = {
	name: string;
	comment: string;
	value: number;
}

enum WalletOption {
	CURRENT_MONTH,
	PREVIOUS_MONTH
}

const WalletPane: React.FC<WalletPaneProps> = (props: WalletPaneProps) => {
    const { t } = useTranslation();
	const i18 = getI18n();
	moment.locale(i18.language);

	const history = useHistory();
	const navigation = useNavigation(history);

	const [showDetails, updateShowDetails] = useState(false);
    const [currentWallet, setCurrentWallet] = useState<MonthWallet>();
    const [wallet, setWallet] = useState<MonthWallet[]>();
	const [option, updateOption] = useState<WalletOption>(parseInt(navigation.getParam<string>('optionWallet')) || WalletOption.CURRENT_MONTH);

    const [showEmployerDetails, updateShowEmployerDetails] = useState(false);
	const [showAddons, updateShowAddons] = useState(false);
	const [showAddonsDetails, updateShowAddonsDetails] = useState(false);
	const [showCuts, updateShowCuts] = useState(false);
	const [showCutsDetails, updateShowCutsDetails] = useState(false);
	const [currentEmployerWallet, setCurrentEmployerWallet] = useState<EmployerWallet>();

	const [loading, setLoading] = useState<boolean>(false);

	const [employed, setEmployed] = useState<boolean>(false);

	const startDate = (option == WalletOption.PREVIOUS_MONTH) ? moment().subtract(1, 'months').startOf('month') : moment().startOf('month');
	const endDate = (option == WalletOption.PREVIOUS_MONTH) ? moment().subtract(1, 'months').endOf('month') : moment().endOf('month');

	const selectWalletTab = (option: WalletOption) => {
		navigation.setParam('optionWallet', option.toString());
		updateOption(option);

		if (option === WalletOption.PREVIOUS_MONTH) {
			let startDate = moment().subtract(1, 'months').startOf('month');
			let endDate = moment().subtract(1, 'months').endOf('month');
			fetchWalletData(startDate, endDate);
		} else {
			let startDate = moment().startOf('month');
			let endDate = moment().endOf('month');
			fetchWalletData(startDate, endDate);
		}
	}

	const isEmployee = async() => {
		const response = await isEmployed();
		let result:boolean = response == '1' ? true : false;
		return result;
	}

	const fetchWalletData = async (startDate: any, endDate: any) => {
		let isEmployed:boolean = await isEmployee();
		setEmployed(isEmployed);

		if (isEmployed == true) {
			setLoading(true);
			await getWalletData(startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY'))
				.then(response => {
					let data = response.data;

					if (data) {

						// HOURS
						let employers = Array();
						data.hours.forEach((hours:any) => {
							let el : { [id: string]: any; } = {};
							el['name'] = hours.pos;
							el['workingHours'] = Array();

							hours.hours.forEach((hour:any) => {
								el['workingHours'].push({
									date: hour['date'],
									rate: hour['rate'],
									quantity: hour['quantity']
								});
							});

							employers.push(el);
						});

						// ADDONS
						let addons = Array();
						if  (data.addons.length > 0) {
							updateShowAddons(true);

							data.addons.forEach((addon:any) => {
								let el : { [id: string]: any; } = {};
								el['name'] = addon.pos;
								el['comment'] = addon.comment;
								el['value'] = addon.value;

								addons.push(el);
							});
						}

						// CUTS
						let cuts = Array();
						if (data.cuts.length > 0) {
							updateShowCuts(true);

							data.cuts.forEach((cut:any) => {
								let el : { [id: string]: any; } = {};
								el['name'] = cut.pos;
								el['comment'] = cut.comment;
								el['value'] = cut.value;

								cuts.push(el);
							});
						}

						let monthName = moment(startDate).locale(i18.language).format('MMMM');
						const wallet: MonthWallet[] = [
							{
								month: monthName.charAt(0).toUpperCase() + monthName.substr(1) + ' ' + startDate.format('YYYY'),
								employers: employers, //[]
								addons: addons,
								cuts: cuts
							}
						];

						setWallet(wallet);
					}
				});
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchWalletData(startDate, endDate);
	}, []);

	useIonViewWillEnter(() => {
		fetchWalletData(startDate, endDate);
	});

    const getEmployerTotalHours = (employerWallet: EmployerWallet): number => {
        const sum = employerWallet.workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + curr.quantity;
        }, 0);

        return sum;
    };

    const getEmployerTotal = (employerWallet: EmployerWallet): number => {
        const sum = employerWallet.workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + (curr.quantity * curr.rate);
        }, 0);

        return sum;
    };

    const getMonthTotalHours = (monthWallet: MonthWallet): number => {
        const sum = monthWallet.employers.reduce((prev: number, curr: EmployerWallet) => {
            return prev + getEmployerTotalHours(curr);
        }, 0);

        return sum;
    };

    const getMonthTotal = (monthWallet: MonthWallet): number => {
        const sum = monthWallet.employers.reduce((prev: number, curr: EmployerWallet) => {
            return prev + getEmployerTotal(curr);
        }, 0);

        return sum;
    };

	const getMonthAddonsTotal = (monthWallet: MonthWallet): number => {
		const sum = monthWallet.addons.reduce((prev: number, curr: EmployerAddon) => {
			return prev + (curr.value * 1);
		}, 0);

		return sum;
	};

	const getMonthCutsTotal = (monthWallet: MonthWallet): number => {
		const sum = monthWallet.cuts.reduce((prev: number, curr: EmployerCut) => {
			return prev + (curr.value * 1);
		}, 0);

		return sum;
	};

	useEffect(() => {
		log('page_visit', {
			page: 'Raporty -> Portfel'
		});
	}, []);

    return employed == false ? (
			<StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
				<StyledEmptyContainer>
					<CircleImage image={ImageTypes.NOTE} color="grey"/>
					<h3>{t('reportsTab.wallet.soonAvailableNotWorker')}</h3>
				</StyledEmptyContainer>
			</StaticPane>
		) : (
			<Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
				<StyledWalletOption>
					<div onClick={() => selectWalletTab(WalletOption.PREVIOUS_MONTH)}
						 className={option === WalletOption.PREVIOUS_MONTH ? 'selected' : ''}>{t('reportsTab.wallet.previousMonth')}</div>
					<div onClick={() => selectWalletTab(WalletOption.CURRENT_MONTH)}
						 className={option === WalletOption.CURRENT_MONTH ? 'selected' : ''}>{t('reportsTab.wallet.currentMonth')}</div>
				</StyledWalletOption>
				{
					!loading && wallet && wallet.length > 0 &&
					wallet.map((monthWallet, key) =>
						<StyledWalletGrid key={key} onClick={() => {
							setCurrentWallet(monthWallet);
							updateShowDetails(true);
						}} className="wallet">
							<div className="title">{monthWallet.month}</div>
							<IonGrid>
								<IonRow>
									<IonCol className="aligment header" size="5.5">
										{t("reportsTab.wallet.workingPlace")}
									</IonCol>
									<IonCol className="aligment end header" size="3">
										{t("reportsTab.wallet.workingHoursCount")}
									</IonCol>
									<IonCol className="aligment end header" size="3.5">
										{t("reportsTab.wallet.toPay")}<span className="required">*</span>
									</IonCol>
								</IonRow>

								{monthWallet.employers && monthWallet.employers.length > 0 &&
								monthWallet.employers.map((employerWallet, employerKey) =>
									<IonRow key={employerKey}
											onClick={(e) => {
												setCurrentEmployerWallet(employerWallet);
												updateShowEmployerDetails(true);
												e.stopPropagation();
											}}>
										<IonCol className="aligment row" size="5.5">
											{employerWallet.name}
										</IonCol>
										<IonCol className="aligment end row value" size="3">
											{minutesToHoursMinutes(getEmployerTotalHours(employerWallet) * 60)}
										</IonCol>
										<IonCol className="aligment end row value" size="3.5">
											{getEmployerTotal(employerWallet).toFixed(2)}
										</IonCol>
									</IonRow>
								)
								}

								{monthWallet.employers && monthWallet.employers.length == 0 &&
								<IonRow>
									<IonCol className="aligment row" size="12">
										{t("reportsTab.wallet.noHours")}
									</IonCol>
								</IonRow>
								}

								{monthWallet.employers && monthWallet.employers.length > 0 &&
								<IonRow className="footer">
									<IonCol className="aligment footer" size="5.5">
										{t("reportsTab.wallet.sum")}
									</IonCol>
									<IonCol className="aligment end footer" size="3">
										{minutesToHoursMinutes(getMonthTotalHours(monthWallet) * 60)}
									</IonCol>
									<IonCol className="aligment end footer" size="3.5">
										{(Math.floor(getMonthTotal(monthWallet) * 100)/100).toFixed(2)}
									</IonCol>
								</IonRow>
								}
							</IonGrid>
						</StyledWalletGrid>
					)
				}

				{
					!loading && wallet && wallet.length > 0 &&
					<StyledDisclaimer>*{t("reportsTab.wallet.toPayDisclaimer")}</StyledDisclaimer>
				}

				{
					!loading && wallet && wallet.length > 0 && showAddons &&
					wallet.map((monthWallet, key) =>
						<StyledWalletGrid style={{"marginTop": "10px"}} key={key} onClick={() => {
							setCurrentWallet(monthWallet);
							updateShowAddonsDetails(true);
						}} className="wallet">
							<div className="title">{t('reportsTab.wallet.addons')}</div>
							<IonGrid>
								{monthWallet.addons && monthWallet.addons.length > 0 &&
									<IonRow className="footer">
										<IonCol className="aligment footer" size="5.5">
											{t("reportsTab.wallet.addonsSum")}
										</IonCol>
										<IonCol className="aligment end footer" size="3">

										</IonCol>
										<IonCol className="aligment end footer" size="3.5">
											{(Math.floor(getMonthAddonsTotal(monthWallet) * 100)/100).toFixed(2)}
										</IonCol>
									</IonRow>
								}
							</IonGrid>
						</StyledWalletGrid>
					)
				}

				{
					!loading && wallet && wallet.length > 0 && showCuts &&
					wallet.map((monthWallet, key) =>
						<StyledWalletGrid style={{"marginTop": "10px"}} key={key} onClick={() => {
							setCurrentWallet(monthWallet);
							updateShowCutsDetails(true);
						}} className="wallet">
							<div className="title">{t('reportsTab.wallet.cuts')}</div>
							<IonGrid>
								{monthWallet.addons && monthWallet.cuts.length > 0 &&
									<IonRow className="footer">
										<IonCol className="aligment footer" size="5.5">
											{t("reportsTab.wallet.cutsSum")}
										</IonCol>
										<IonCol className="aligment end footer" size="3">

										</IonCol>
										<IonCol className="aligment end footer" size="3.5">
											{(Math.floor(getMonthCutsTotal(monthWallet) * 100)/100).toFixed(2)}
										</IonCol>
									</IonRow>
								}
							</IonGrid>
						</StyledWalletGrid>
					)
				}

				{
					loading && <StyledWalletGrid>
						<div className="title">
							<IonSkeletonText animated style={{width: (Math.random() >= 0.5) ? '65%' : '55%'}}/>
						</div>
						<IonGrid>
							<IonRow>
								<IonCol className="aligment header" size="5.5">
									<IonSkeletonText animated style={{width: '100%'}}/>
								</IonCol>
								<IonCol className="aligment end  header" size="3">
									<IonSkeletonText animated style={{width: '60%'}}/>
								</IonCol>
								<IonCol className="aligment end header" size="3.5">
									<IonSkeletonText animated style={{width: '60%'}}/>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol className="aligment row" size="5.5">
									<IonSkeletonText animated style={{width: (Math.random() >= 0.5) ? '85%' : '65%'}}/>
								</IonCol>
								<IonCol className="aligment end row value" size="3">
									<IonSkeletonText animated style={{width: '50%'}}/>
								</IonCol>
								<IonCol className="aligment end row value" size="3.5">
									<IonSkeletonText animated style={{width: '50%'}}/>
								</IonCol>
							</IonRow>
						</IonGrid>
					</StyledWalletGrid>
				}

				<WalletDetailsActionSheet isVisible={showDetails} onDismiss={() => updateShowDetails(false)}
										  wallet={currentWallet}></WalletDetailsActionSheet>
				<EmployerDetailsActionSheet isVisible={showEmployerDetails}
											onDismiss={() => updateShowEmployerDetails(false)}
											wallet={currentEmployerWallet}></EmployerDetailsActionSheet>
				<WalletAddonsActionSheet isVisible={showAddonsDetails} onDismiss={() => updateShowAddonsDetails(false)}
										 wallet={currentWallet}></WalletAddonsActionSheet>
				<WalletCutsActionSheet isVisible={showCutsDetails} onDismiss={() => updateShowCutsDetails(false)}
										 wallet={currentWallet}></WalletCutsActionSheet>
			</Pane>
		);
};

export default WalletPane;
