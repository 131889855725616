import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {IonItem} from '@ionic/react';
import {StyledIonCheckbox} from '../../../components/form/input/checkbox.style';
import {StyledIonLabel} from '../../../components/form/input/label.style';
import {StyledModalContent} from '../../../components/modal/modal.style';
import LinkLabel from "../../../components/form/input/label.component";
import {Loan as LoanModel, Loan} from "../../../models/loan";
import {getLoan} from "../../../services/loan.service";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ApprovalsModalProps = {
    isOpen: boolean;
    loan: Loan | undefined;
    onClose: () => void;
    onSign: () => void;
    onReject: () => void;
};

const ApprovalsModal: React.FC<ApprovalsModalProps> = (props: ApprovalsModalProps) => {

    const {t} = useTranslation();

    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);
    const [showLoan, updateShowLoan] = useState<boolean>(false);
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);
    const [loan, setLoan] = useState<LoanModel>();

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const [loanAccepted, updateLoanAccepted] = useState<boolean>(false);
    const [rulesAccepted, updateRulesAccepted] = useState<boolean>(false);
    const [deductionAccepted, updateDeductionAccepted] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        updateShowRegulations(false);
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
        if (props.isOpen !== showAddRemarks) {
            updateLinesToShow([2, 2, 2]);
        }
    }, [props.isOpen]);

    const handleOpen = () => {
        fetchLoan(props.loan?.id);
        updateShowAddRemarks(props.isOpen);
    };

    const onSubmit = () => {
        props.onSign();
    };

    const onReject = () => {
        props.onReject();
    }

    const [linesToShow, updateLinesToShow] = useState([2, 2, 2]);
    const readMore = (index: number) => {
        const newLinesConfiguration = [...linesToShow];
        newLinesConfiguration[index] = 1000;
        updateLinesToShow(newLinesConfiguration);
    }

    const fetchLoan = async (id:any) => {
        if (id) {
            await getLoan(id)
                .then(response => {
                    let loan = response.data.loan as LoanModel;
                    loan.content = response.data.loan_content;
                    loan.regulations_content = response.data.regulations_content;
                    setLoan(loan);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    };

    return (
        <>
            <Modal isOpen={showLoan} onDidDismiss={() => updateShowLoan(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("loanPage.loanModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: loan?.content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("loanPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: loan?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={showAddRemarks}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleCloseRemarks()}>
                <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                    <StyledModalContent>
                        <h1 className="title full">{t("loanPage.approvals.title")}</h1>
                            <div className="content">
                                <LinkLabel urlString={t("loanPage.approvals.checkboxes.loanAcceptedLink")} url={'#'} onClick={() => {updateShowLoan(true)}}>
                                    <LinkLabel urlString={t('loanPage.approvals.checkboxes.informationClause')} url={t("loanPage.approvals.checkboxes.informationClauseUrl")} external={true}>
                                        <IonItem lines="none">
                                            <StyledIonLabel className="ion-text-wrap">{t('loanPage.approvals.checkboxes.loanAccepted')}</StyledIonLabel>
                                            <StyledIonCheckbox name="loanAccepted" slot="start" onIonChange={(e) => {
                                                updateLoanAccepted(e.detail.checked);
                                            }}/>
                                        </IonItem>
                                    </LinkLabel>
                                </LinkLabel>
                                <LinkLabel urlString={t("loanPage.approvals.checkboxes.rulesAcceptedLink")} url={'#'} onClick={() => {updateShowRegulations(true)}}>
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('loanPage.approvals.checkboxes.rulesAccepted')}</StyledIonLabel>
                                        <StyledIonCheckbox name="rulesAccepted" slot="start" onIonChange={(e) => {
                                            updateRulesAccepted(e.detail.checked);
                                        }}/>
                                    </IonItem>
                                </LinkLabel>
                                <LinkLabel urlString={t("")} url={'#'}>
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('loanPage.approvals.checkboxes.deductionAccepted')}</StyledIonLabel>
                                        <StyledIonCheckbox name="deductionAccepted" slot="start" onIonChange={(e) => {
                                            updateDeductionAccepted(e.detail.checked);
                                        }}/>
                                    </IonItem>
                                </LinkLabel>
                            </div>
                            <StyledFooter className="modal footer no-shadow">
                                <Form.Button type="button"
                                             onClick={() => onReject()}>{t("loanPage.content.rejectButton")}</Form.Button>
                                <Form.Button type="submit"
                                             disabled={!rulesAccepted || !loanAccepted || !deductionAccepted}>{t("loanPage.approvals.acceptButton")}</Form.Button>
                            </StyledFooter>
                    </StyledModalContent>
                </Form.Container>
            </Modal>
        </>
    );
};

export default ApprovalsModal;