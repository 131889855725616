import {appApi} from "./api.service";
import {ContractAppendix} from "../models/contract";
import moment from "moment";


let endpointPrefix = 'worker/contract-appendix';

const getAppendix = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id);
}

const getAppendixPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download', {
        responseType: "blob"
    });
}

const getAppendixRegulationsPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-regulations', {
        responseType: "blob"
    });
}

const getAppendixQualifiedSignatureFilePdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-qualified-signature', {
        responseType: "blob"
    });
}

const saveAppendixComments = async (id: any, comment: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/comment', {
        'comment': comment
    });
}

const signAppendix = async (id: any) => {
    return appApi.post(endpointPrefix + '/' + id + '/sign', {});
}

const signAppendixVerify = async (id: any, code: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/sign/code-verification', {
        'verificationCode': code
    });
}

const rejectAppendix = async (id: bigint) => {
    return appApi.post(endpointPrefix + '/' + id + '/reject');
}

const isStarted = (appendix: ContractAppendix) => {
    return moment(appendix.start_at).isSameOrBefore(moment(), 'd');
}

export {
    isStarted,
    getAppendix,
    getAppendixPdf,
    getAppendixRegulationsPdf,
    getAppendixQualifiedSignatureFilePdf,
    signAppendix,
    saveAppendixComments,
    signAppendixVerify,
    rejectAppendix
};
