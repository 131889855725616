import axios, {AxiosRequestConfig} from 'axios';
import {getToken} from './auth.service';
import {API_ENDPOINT, TRAVEL_ALLOWANCE_API_ENDPOINT} from '../variables/variables';
import {Preferences} from "@capacitor/preferences";

const authApi = axios.create({
    baseURL: API_ENDPOINT,
});

const appApi = axios.create({
    baseURL: API_ENDPOINT + 'api/v2'
});

const appOpenApi = axios.create({
    baseURL: API_ENDPOINT + 'api/v2'
});

const websiteApi = axios.create({
	baseURL: API_ENDPOINT,
});

const travelAllowanceApi = axios.create({
    baseURL: TRAVEL_ALLOWANCE_API_ENDPOINT + 'api/mobile'
});

// Loader handling
let requestCount = 0;
let inProgress = () => requestCount > 0;
appApi.interceptors.request.use(request => {
        if (!request.headers['No-Loader'] && request.method === 'post') {
            requestCount++;
        }
        return request;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

appApi.interceptors.response.use(response => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        return response;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

authApi.interceptors.request.use(request => {
        if (!request.headers['No-Loader'] && request.method === 'post') {
            requestCount++;
        }
        return request;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

authApi.interceptors.response.use(response => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        return response;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

websiteApi.interceptors.request.use(request => {
        if (!request.headers['No-Loader'] && request.method === 'post') {
            requestCount++;
        }
        
        return request;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

websiteApi.interceptors.response.use(response => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        return response;
    },
    (error) => {
        requestCount--;

        if (requestCount < 0) {
            requestCount = 0;
        }

        throw error;
    });

// Use interceptor to inject the token to requests and additional params
appApi.interceptors.request.use(async request => {
    let token = await getToken();
    request.headers['Authorization'] = 'Bearer ' + token;

    request = await setRequestParams(request);

    return request;
});

travelAllowanceApi.interceptors.request.use(async request => {
    let token = await getToken();
    request.headers['Authorization'] = 'Bearer ' + token;

    return request;
});

// // Function that will be called to refresh authorization
// const refreshAuthLogic = (failedRequest: any) => refreshAccessToken()
//     .then(tokenRefreshResponse => {
//         let authResult = tokenRefreshResponse.data as AuthResult;
//         authResult.is_fb_user = undefined;
//         storeToken(authResult);
//         failedRequest.response.config.headers['Authorization'] = 'Bearer ' + authResult.access_token;
//
//         return Promise.resolve();
//     }).catch(async (reason) => {
//         await logout().then(() => {
//             window.history.replaceState({}, '', Links.login);
//             window.location.reload();
//         });
//     });
// createAuthRefreshInterceptor(appApi, refreshAuthLogic);

const getStatus = async () => {
    return appOpenApi.get('status', {
        timeout: 15000
    });
}

const setRequestParams = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    let workerId = await Preferences.get({'key': 'worker_id'});

    if (workerId.value !== null) {
        request.params = {
            ...request.params,
            'workerId': workerId.value,
        };
    }

    return request;
}

export {authApi, appApi, websiteApi, appOpenApi, travelAllowanceApi, inProgress, getStatus};
