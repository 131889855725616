import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }

  input, button {
    &:focus {
      outline: none;
    }
  }

  input.hasErrors, textarea.hasErrors {
    border: 1px #F43030 solid;
  }

  p.errorMessage {
    color: #F43030;
    font-size: small;
    text-align: center;
    margin-top: 3px;
  }
  
  p.fieldErrorMessage {
    color: #F43030;
    font-size: small;
    margin-top: 3px;
  }

  p.errorMessage::before {
    display: inline;
    content: "⚠ ";
  }

  p.noticeMessage {
    color: #B5B7C6;
    font-size: small;
    text-align: center;
    margin-top: 3px;
  }

  p.noticeMessage::before {
    display: inline;
    content: "⚠ ";
  }
  
  .showLessMore {
     padding-left: 60px;
     font-size: 12px;
     line-height: 30px;
     color: #0085FF;
  }
  
  .termsDesc {
     padding-left: 60px;
     font-size: 12px;
  }
  
  .center {
     text-align: center; 
  }
  
  .alertButtonDisabled {
     color: #ccc !important;
     pointer-events: none;
  }
  
  ion-searchbar:not(.listSearch) {
      margin-top: 20px;
  }
  
  ion-searchbar input {
      font-size: 12px !important;
      --background: #F4F4F9;
  }
  
  ion-tab-button {
      --background: #FFFFFF;
  }
    
  .action-sheet-button {
      font-size: 15px !important;
  }
  
  body.scanner-active {
      opacity: 0 !important;
      background: transparent !important;
      --background: transparent;
      --ion-background-color: transparent;
  }

  .chose-other {
      display: flex;
      align-items: center;
      margin: 8px 0;

      hr {
          width: 100%;
          border-bottom: 1px solid #888A98;
      }

      .one-line {
          font-size: 11px;
          white-space: nowrap;
          padding: 0 8px;
      }
  }
`;

export default GlobalStyle;
