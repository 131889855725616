import {appApi} from "./api.service";

let endpointPrefix = 'projects';

const getProject = async (projectId: number|bigint) => {
    return appApi.get(`${endpointPrefix}/${projectId}`);
}

export {
    getProject,
}