import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import { StyledInput } from '../../components/form/input/input.style';
import { useForm } from 'react-hook-form';
import { StyledContent } from '../reportPage/manualBarcodePage.style';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import { useTranslation } from 'react-i18next';
import {AuditReportProps} from "./auditReport.component";
import {getAuditReportWorkerConfiguration} from "../../services/qrCodePage.service";

const AuditManualWorkerFind: React.FC<AuditReportProps> = ({match}) => {

    const fieldName = "workerNumber";
    const history = useHistory();
    const { t } = useTranslation();
	const [error, setError] = useState<string>();

    const {params: {type}} = match;

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const { register, formState: {isValid}, getValues, handleSubmit } = useForm({
        mode: 'all'
    });

    const configuration = getAuditReportWorkerConfiguration();

    const handleSave = async () => {
        if (configuration?.handleScan && getValues(fieldName)) {
            let error = await configuration?.handleScan(getValues(fieldName));
            if (error === false) {
                setError(t('auditReport.manualWorkerFind.error'))
            }
        }
    };

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(fieldName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle className="wrap-text">
                            <div className="wrap-text">
                                {t('auditReport.manualWorkerFind.title')}
                            </div>
                        </StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <h3 className="desc">{t("auditReport.manualWorkerFind.number")}</h3>
                            <StyledInput name={fieldName} className={error && 'hasErrors'} inputMode="text" ref={register({
                                required: true
                            })} />
                            {error && <p className="errorMessage">{error}</p>}
                            <Form.Button type="submit" disabled={!isValid}>{t("auditReport.manualWorkerFind.addButton")}</Form.Button>
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AuditManualWorkerFind;
