import styled from "styled-components";

export const StyledAutocompleteList = styled.ul`
    position: absolute;
    z-index: 999;
    width: calc(100% - 10px);
    margin: 0;
    padding: 8px 20px;
    background-color: white;
    list-style: none;
    box-shadow: 0px 31px 39px -1px rgba(224, 224, 224, 1);
`;

export const StyledAutocompleteListItem = styled.li`
    padding: 8px 0;
    font-size: 12px;
    
    &:first-of-type {
        padding-top: 0;
    }
    
    &:last-of-type {
        padding-bottom: 0;
    }
`;