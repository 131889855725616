import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    IonButtons,
    IonGrid,
    IonHeader,
    IonPage,
    useIonViewDidEnter,
    useIonViewWillEnter
} from '@ionic/react';
import {
    StyledEmptyContainer,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from '../../../../components/content/content.style';
import {useTranslation} from 'react-i18next';
import StaticPane from '../../../../components/pane/static-pane.component';
import CircleImage, {ImageTypes} from '../../../../components/circleImage/circleImage.component';
import {StyledButtonBadge, StyledHeaderButton, StyledHeaderButtonImage} from '../../../../components/button/button.style';
import PreferencesImage from '../../../../assets/images/system-preferences.svg';
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import OffersPane from './panes/offersPane.component';
import FilterActionSheet, {
    Filter,
    FilterActionSheetContext,
    FilterParam,
    FilterType,
    OffersCounts
} from './components/filterActionSheet/filterActionSheet.component';
import FilterChips from './components/filterChips/filterChips.component';
import {JobOffer} from "../../../../models/jobOffer";
import {
    filtersToParams,
    filtersToParamsObjects,
    getOffers,
    getOffersCounts
} from "../../../../services/jobOffer.service";
import {StyledOffer} from "./offersTab.style";
import JobOfferSkeleton from "./components/skeleton.component";
import { useHistory } from 'react-router-dom';
import useNavigation from "../../../../services/navigation.service";
import {appStatusBarUpdate} from "../../../../utils/tools/statusbar";
import {Preferences} from "@capacitor/preferences";
import {SplashScreen} from "@capacitor/splash-screen";

const OffersTab: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const filtersName = [
        'title',
        'domestic',
        'city',
        'province',
        'sortBy',
        'company',
        'country'
    ];

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [offers, setOffers] = useState<JobOffer[]|undefined|[]>(undefined);
    const [offersCounts, setOffersCounts] = useState<OffersCounts|undefined>(undefined);
    const [loggedIn, setLoggedIn] = useState(false);
    const [filters, updateFilters] = useState<Filter[]>([]);
    const [showFilters, updateShowFilters] = useState(true);

    const filterActionSheet = useContext(FilterActionSheetContext);

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const getProfileData = async () => {
        let token = await Preferences.get({'key': 'access_token'});
        setLoggedIn(!!token.value);
    }

    useEffect(() => {
        getProfileData();
    }, []);

    useIonViewWillEnter(() => {
        getProfileData();
    });

    const fetchOffers = async (params: any = null) => {
        if (offers === undefined) {
            setOffers([]);
            setLoading(true);
            const response = await getOffers(params)
                .then(response => {
                    setOffers(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    // todo what we show??
                });

            getOffersCounts(params)
                .then(response => {
                    let counts = response.data as OffersCounts;
                    setOffersCounts(counts)
                });

            setLoading(false);
        }
    };

    const navigationToParams = () => {
        let params: string[] = [];
        filtersName.forEach(value => {
            if (navigation.getParam(value)) {
                params.push(value + '=' + navigation.getParam(value))
            }
        });

        return params.join('&');
    }

    useEffect(() => {
        fetchOffers(navigationToParams());
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    useIonViewWillEnter(() => {
        SplashScreen.hide();
        appStatusBarUpdate();

        fetchOffers(navigationToParams());
        handleSkipFilter();
    });

    const handleSkipFilter = async () => {
        let skipFilters = await Preferences.get({'key': 'offers_skip_filters'});
        if (!skipFilters.value) {
            updateShowFilters(true);
        }

        await Preferences.remove({'key': 'offers_skip_filters'});
    }

    const handleSelectFilter = async (filters: Filter[]) => {
        updateFilters(filters);
        await handleFiltersNavigation(filters)

        let params = filtersToParams(filters);

        setOffers([]);
        setLoading(true);

        await getOffers(params)
            .then(response => {
                updateShowFilters(false);
                setOffers(response.data);
            });

        setLoading(false);
    };

    const handleClearFilter = (filter: Filter) => {
        const index = filters.indexOf(filter);

        if (index !== -1) {
            filters.splice(index, 1);
            updateFilters([...filters]);
        }

        handleSelectFilter(filters);
        handleFiltersNavigation(filters);

        if (filter.type === FilterType.TITLE) {
            filterActionSheet.setSelectedTitle('');
        }

        if (filter.type === FilterType.LOCATION) {
            filterActionSheet.setSelectedOfferType(undefined);
        }

        if (filter.type === FilterType.CITY) {
            filterActionSheet.setSelectedCity(undefined);
        }

        if (filter.type === FilterType.PROVINCE) {
            filterActionSheet.setSelectedProvince(undefined);
        }

        if (filter.type === FilterType.COMPANY) {
            filterActionSheet.setSelectedCompany(undefined);
        }

        if (filter.type === FilterType.COUNTRY) {
            filterActionSheet.setSelectedCountry(undefined);
        }

        if (filter.type === FilterType.SORTING) {
            const sortOptions = [
                {
                    name: t("offersTab.filtersActionSheet.newestOption"),
                    value: 'createdAt',
                    selected: false
                },
                {
                    name: t("offersTab.filtersActionSheet.validityOption"),
                    value: 'expiresAt',
                    selected: false
                }];

            filterActionSheet.setSorting(sortOptions);
        }
    };

    const handleFiltersNavigation = async (filters: Filter[]) => {
        let params: FilterParam[] = filtersToParamsObjects(filters);
        filtersName.forEach(value => navigation.setParam(value, ''));

        params.forEach((value, index) => {
            navigation.setParam(value.name, value.value);
        })
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            {!loggedIn &&
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            }
                        </IonButtons>
                        <StyledIonTitle className={loggedIn ? "left" : ""}>{t("offersTab.title")}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => updateShowFilters(true)}>
                                <StyledHeaderButtonImage src={PreferencesImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                    { filters && filters.length > 0 && <StyledButtonBadge className="header-right-badge">{filters.length}</StyledButtonBadge>}
                    <FilterChips filters={filters} onFilterClear={handleClearFilter}></FilterChips>
                </IonHeader>
                { (!loading && (!offers || offers.length === 0)) &&
                    <StaticPane topEdge={topEdge} marginTop={35} paddingBottom={97}>
                        <StyledEmptyContainer>
                            <CircleImage image={ImageTypes.BRIEF_CASE} color="grey"/>
                            <h2>{t("offersTab.empty")}</h2>
                        </StyledEmptyContainer>
                    </StaticPane>
                }
                {
                    !loading && offers && offers.length > 0 &&
                        <OffersPane topEdge={topEdge} offers={offers}/>
                }
                {
                    loading &&
                        <StaticPane topEdge={topEdge} marginTop={35} paddingBottom={97}>
                            <StyledOffer>
                                <IonGrid>
                                    <JobOfferSkeleton />
                                    <JobOfferSkeleton />
                                    <JobOfferSkeleton />
                                    <JobOfferSkeleton />
                                    <JobOfferSkeleton />
                                    <JobOfferSkeleton />
                                </IonGrid>
                            </StyledOffer>
                        </StaticPane>
                }
            </StyledIonContent>
            <FilterActionSheet counts={offersCounts} isVisible={showFilters} filters={filters} onDismiss={() => updateShowFilters(false)} onSave={(filters) => handleSelectFilter(filters)} />
        </IonPage>
    );
};

export default OffersTab;
