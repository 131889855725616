import styled from 'styled-components';

export const StyledComponentFormContainer = styled.div`
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  padding: 0px 11px 24px 11px;
  margin: 24px 0;
  
  &.padding-top {
    padding-top: 10px;
  }
  
  &.padding-top-bg {
    padding-top: 25px;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    
    &.required:after {
      content: '*';
      color: red;
    }
  }  

  .report-button {
    margin: 0;

    &.disabled {
      background-color: #F4F4F9;
    }
  }
  
  .worker-row {
    margin-top: 5px;
  }
  
  .editWorker {
    font-size: 11px;
  }

  .select {
    position: relative;

    input {
      font-weight: 600;
      color: #000000;
    }

    input:disabled {
        color: rgba(0, 0, 0, 0.3);
    }
      
    img.down {
      position: absolute;
      width: 10px;
      right: 15px;
      top: 37%;
    }
    
    img.clear {
      position: absolute;
      width: 15px;
      right: 15px;
      top: 32%;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    padding-left: 5px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  
  .name.no-flex {
    display: block !important;
  }
  
  .name.done {
    color: #09a93f;
  }
    
  .name.hasErrors {
    color: #F43030;
  }

  img.avatar {
    width: 33px;
    height: 33px;
    border-radius: 33px;
  }

  .badge {
      margin-left: 10px;
  }
  
  .extraTopPadding {
      padding-top: 10px;
  }
    
    .scanSingleField {
        line-height: 45px;
    }
`;

export const StyledComponentsContainer = styled.div`
    
   overflow: auto;
   padding-bottom: 30px;
    
   .component {
       font-size: 11px;

       ul {
           padding-inline-start: 0px;
       }
   }

  .label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 20px;
  }
`;