import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type AttachmentsGuideModalProps = {
    isOpen: boolean;
    contract?: Contract;
    onClose: () => void;
};

const AttachmentsGuideModal: React.FC<AttachmentsGuideModalProps> = (props: AttachmentsGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
    };

    return (
        <Modal
            isOpen={showGuide}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.attachmentsGuide.title")}</h1>
                    <div className="content full-height">
                        {
                            props.contract && props.contract.foreigner_with_polish_language &&
                            <div>
                                <p>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p1.intro")}</p>
                                <ul>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p1.ul.li1")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p1.ul.li2")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p1.ul.li3")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p1.ul.li4")}</li>
                                </ul>

                                <p>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p2.intro")}</p>
                                <ul>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p2.ul.li1")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p2.ul.li2")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p2.ul.li3")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p2.ul.li4")}</li>
                                </ul>

                                <p>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p3.intro")}</p>
                                <ul>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p3.ul.li1")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p3.ul.li2")}</li>
                                    <li>{t("contractPage.attachmentsGuide.foreigner_with_polish_language.p3.ul.li3")}</li>
                                </ul>
                            </div>
                        }

                        <p>{props.contract && props.contract.foreigner_with_polish_language ? t("contractPage.attachmentsGuide.intro_foreigner_with_polish_language") : t("contractPage.attachmentsGuide.intro")}</p>

                        <p>{t("contractPage.attachmentsGuide.p1")}</p>
                        <ol>
                            <li>{t("contractPage.attachmentsGuide.p2")}</li>
                            <li>{t("contractPage.attachmentsGuide.p3")}</li>
                            <li>{t("contractPage.attachmentsGuide.p4")}</li>
                            <li>{t("contractPage.attachmentsGuide.p5")}</li>
                            <li>{t("contractPage.attachmentsGuide.p6")}</li>
                            <li>{t("contractPage.attachmentsGuide.p7")}</li>
                        </ol>
                        <p>{t("contractPage.attachmentsGuide.p8")}</p>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default AttachmentsGuideModal;