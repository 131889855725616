import {IonModal} from '@ionic/react';
import styled from 'styled-components';

export const StyledPhotoPreviewModal = styled(IonModal)`
  --height: 100%;
  --width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;

  ion-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;
