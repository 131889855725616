import {IonCol, IonGrid, IonRow, IonSkeletonText} from '@ionic/react';
import React from 'react';
import { StyledTaskDate, StyledTaskItemContainer } from './taskItem.style';

const TaskItemSkeleton: React.FC = () => {
    const showTwoDates = Math.random() >=0.5 ? true : false;

    return (
        <StyledTaskItemContainer>
            <IonGrid>
                <IonRow>
                    <IonCol size="5">
                        <IonGrid>
                            <IonRow>
                                <IonCol size={showTwoDates ? '5.5' : '12'}>
                                    <StyledTaskDate>
										<span className="day" style={{ width: '60%' }}><IonSkeletonText animated /></span>
                                        <span className="month" style={{ width: '60%' }}><IonSkeletonText animated /></span>
                                    </StyledTaskDate>
                                </IonCol>
                                {
                                	showTwoDates &&
									<IonCol size="1">
										<span className="separator">-</span>
									</IonCol>
                                }
                                {
                                	showTwoDates &&
									<IonCol size="5.5">
										<StyledTaskDate>
											<span className="day" style={{ width: '60%' }}><IonSkeletonText animated /></span>
											<span className="month" style={{ width: '60%' }}><IonSkeletonText animated /></span>
										</StyledTaskDate>
									</IonCol>
                                }
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                    <IonCol size="7" class="content">
						<h3 className="title" style={{ width: '60%' }}>
							<IonSkeletonText animated />
						</h3>
						<h3 className="description" style={{ width: (Math.random()>=0.5) ? '70%' : '50%' }}><IonSkeletonText animated /></h3>
					</IonCol>
                </IonRow>
            </IonGrid>
        </StyledTaskItemContainer>
    );
};

export default TaskItemSkeleton;
