import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";

const initializeFirebase = () => {
	FirebaseAnalytics.initializeFirebase({
		apiKey: "AIzaSyC-ZszfpV1VBnLbXoJojaoISk4FAAG1K8o",
		authDomain: "exactpeople-v2.firebaseapp.com",
		projectId: "exactpeople-v2",
		storageBucket: "exactpeople-v2.appspot.com",
		messagingSenderId: "382269859850",
		appId: "1:382269859850:web:aa037d441ac80303f317f3",
		measurementId: "G-H7NCL81G97"
	});
}

const log = (name: string, params: {}) => {
	FirebaseAnalytics.logEvent({
		name: name,
		params: params,
	})
		// .then(() => console.log('Event successfully tracked'))
		// .catch(err => console.log('Error tracking event:', err))
	;
}

export {
	initializeFirebase,
	log,
};
