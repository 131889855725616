import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Browser } from '@capacitor/browser';

type FormProps = {
    urlString?: string;
    url?: string;
    external?: boolean;
    onClick?: () => void;
}


const LinkLabel: React.FC<PropsWithChildren<FormProps>> = (props) => {
    const container = useRef<HTMLParagraphElement>(null);
    const history = useHistory();

    const openUrl = async (event: MouseEvent) => {
        event.stopPropagation();

        if (props.onClick) {
            props.onClick();
        } else {
            if (props.url) {
                if (props.external) {
                    if (props.url !== '#') {
                        await Browser.open({ url: props.url });
                    }
                } else {
                    history.push(props.url);
                }
            }
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.stopPropagation();

        if ((event.target as HTMLElement).tagName.toLocaleLowerCase() === 'ion-checkbox') {
            return;
        }

        if (container.current) {
            clickCheckBox(container.current);
        }
    };

    useEffect(() => {
        if (container.current && props.urlString && props.url) {
            const node = findNodeWithText(container.current, props.urlString);

            if (node) {
                addLink(node, props.urlString);
            }
        }
    }, []);

    function findNodeWithText(rootEl: Element, text: string): Element | undefined  {
        let finalNode: Element | undefined = undefined;

        for (let i = 0; i < rootEl.children.length; i++) {
            const child = rootEl.children.item(i);

            if (!child) {
                continue;
            }

            if (child.children.length > 0) {
                finalNode = findNodeWithText(child, text); 

                if (finalNode) {
                    return finalNode;
                }
            } else if (child.textContent?.indexOf(text) !== -1) {
                return child;
            }
        }

        return finalNode;
    }

    function addLink(el: Element, text: string) {
        const link = createSpan(text, 'label-link');
        link.style.color = '#0085FF';
        link.style.display = 'inline-block';
        link.onclick = openUrl;

        const label = document.createElement('span');

        const linkIndex = el.textContent?.indexOf(text) ?? -1;
        const firstPart = createSpan(el.textContent?.substring(0, linkIndex) ?? '');
        const secondPart = createSpan(el.textContent?.substring(linkIndex + text.length) ?? '');

        label.appendChild(firstPart);
        label.appendChild(link);
        label.appendChild(secondPart);

        el.innerHTML = '';
        el.appendChild(label);
        (el as HTMLElement).style.zIndex = "10";
    }

    function createSpan(text: string, className?: string): HTMLSpanElement {
        const span = document.createElement('span');

        if (className) {
            span.classList.add(className);
        }

        span.textContent = text;

        return span;
    }

    function clickCheckBox(rootEl: Element) {
        let finalNode: Element | undefined = undefined;

        if (rootEl.tagName.toLocaleLowerCase() === 'ion-checkbox') {
            (rootEl as HTMLElement).click();
        }

        for (let i = 0; i < rootEl.children.length; i++) {
            const child = rootEl.children.item(i);

            if (!child) {
                continue;
            }

            if (child.children.length > 0) {
                clickCheckBox(child); 
            } 
        }
    }
    
    return (
        <p ref={container} onClick={handleClick}>
            {props.children}
        </p>
    )
}

export default LinkLabel;