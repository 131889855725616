import styled from 'styled-components';

type StyledCircleImageContainerProps = {
  color?: string;
}

export const StyledCircleImageContainer = styled.div<StyledCircleImageContainerProps>`
  width: 80px;
  height: 80px;
  ${(props) => {
    switch(props.color) {
      case 'red':
        return `
          background: #F43030;
          box-shadow: 0px 24px 48px rgba(244, 48, 48, 0.25);
        `;
      case 'grey':
          return `
          background: #FFFFFF;
          box-shadow: 0px 24px 48px rgba(181, 183, 198, 0.25);
          `;
      default:
        return `
          background: #0085FF;
          box-shadow: 0px 24px 48px rgba(0, 133, 255, 0.25);
        `;
    }
  }}
  border-radius: 40px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    switch (props.color) {
    case 'grey':
      return `
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          background: #FFFFFF;
          width: 80px;
          height: 80px;
          border-radius: 55px;
        }
      `;
    }
  }}

  &:before {
    content: ' ';
    display: block;
    top: -14px;
    left: -14px;
    position: absolute;
    ${(props) => {
      switch (props.color) {
        case 'red': 
          return `
            background: linear-gradient(180deg, rgba(244, 48, 48, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
          `;
        case 'grey':
          return `
            background: linear-gradient(180deg, #B5B7C6 0%, rgba(181, 183, 198, 0) 100%);
            opacity: 0.25;
          `;
        default:
          return `
            background: linear-gradient(180deg, rgba(0, 133, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
          `;
      }
    }}
    width: 108px;
    height: 108px;
    border-radius: 55px;
  }
`;

export const StyledCircleImagePicture = styled.img`
  display: inline-block;
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 10;
`;
