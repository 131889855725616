import {TimepickerMinutesIterationsType} from "../../enums/timepickerMinutesIterationsType";

const getTimepickerMinutesIterations = (minutesIterationsType: TimepickerMinutesIterationsType) => {
    switch (minutesIterationsType) {
        case TimepickerMinutesIterationsType.EVERY_5_MINUTES:
            return get5MinutesIterations();
        case TimepickerMinutesIterationsType.EVERY_10_MINUTES:
            return get10MinutesIterations();
        case TimepickerMinutesIterationsType.EVERY_15_MINUTES:
            return get15MinutesIterations();
    }
}

const get5MinutesIterations = () => {
    let iterations = [];
    for (let i = 0; i <= 55; i += 5) {
        iterations.push(i);
    }

    return iterations.join(',');
}

const get10MinutesIterations = () => {
    let iterations = [];
    for (let i = 0; i <= 50; i += 10) {
        iterations.push(i);
    }

    return iterations.join(',');
}

const get15MinutesIterations = () => {
    let iterations = [];
    for (let i = 0; i <= 45; i += 15) {
        iterations.push(i);
    }

    return iterations.join(',');
};

export {
    getTimepickerMinutesIterations,
};
