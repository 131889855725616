import styled, { css } from 'styled-components';

type StyledButtonPropsType = {
    disabled?: boolean;
}

const buttonDisabledStyle = css`
  background: #B5B7C6;
`;

const buttonDisabledLightStyle = css`
  background: #F4F4F9;
`;

const buttonSharedStyle = css`
  border-radius: 12px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  height: 42px;
  min-height: 42px;

  &.no-margin-top {
    margin-top: 0;
  }
`;

export const StyledButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle};

  background-color: #0085FF;
  color: #fff;

  &.orange {
    color: #FFF;
    background: #FA8128;
  }

  &.non-clickable {
    cursor: default;
  }

  &.disabled {
    color: #fff;
    background: #B5B7C6;
  }
  
  &.danger {
    color: red;
    background: #fff;
    box-shadow: 1px 1px 1px 1px rgba(136, 138, 152, 0.25);
  }
  
  ${({ disabled }) => disabled && buttonDisabledStyle}
`;

export const StyledSocialButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle};
  
  background-color: #fff;
  color: #0084FF;
  border: 1px solid #EDEDF3;
  box-shadow: 0 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;
  justify-content: center;

  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;

    &.button-image {
      max-width: 14px;
    }
  }

  &.red {
    background: rgba(244, 48, 48, 0.5);
    color: #FFFFFF;
  }

  &.greyedout {
    color: #000;
    background: #EDEDF3;
  }

  &.auto-width {
    color: #000;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  &.inverted {
    background-color: #0084FF;
    color: #fff;
    display: flex;
    justify-content: center;
    margin-top: 0;

    img {
      width: 14px;
      height: 14px;
    }
  }
  
  &.black-text {
    color: #000000;
  }
  
  &.bottom-margin {
    margin-bottom: 30px;
  }

  ${({disabled}) => disabled && buttonDisabledLightStyle}

  
  &.hasErrors {
    border-color: #F43030 !important;
  }
  
  &.selected {
    border-color: #0085FF !important;
  }
`;

export const StyledWiteButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  background-color: #fff;
  color: #000;
  border: 1px solid #EDEDF3;
  box-shadow: 0 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;

  &.red {
    color: #F43030;
  }
`;

export const StyledRedButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  background-color: #F43030;
  color: #FFF;
  box-shadow: 0 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;

  ${({disabled}) => disabled && buttonDisabledStyle}
`;

export const StyledLightRedButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  background-color: rgba(244, 48, 48, 0.12);
  color: #F43030;
  box-shadow: 0 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;

  ${({disabled}) => disabled && buttonDisabledStyle}
`;

export const StyledRedIconButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  background-color: #F43030;
  color: #FFF;
  box-shadow: 0 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  line-height: 12px;
  height: 30px;
  min-height: 30px;
  margin-top: 0px;

  ${({disabled}) => disabled && buttonDisabledStyle}
`;

export const StyledLightBlueButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  background-color: rgba(0, 133, 255, 0.08);
  color: #0085FF;
  border-radius: 12px;

  ${({disabled}) => disabled && buttonDisabledStyle}
`;

const headerButtonDisabledStyle = css`
  background: #313041;
`;

export const StyledHeaderButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}
  width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  background: #363543;
  display: flex;
  justyfy-content: center;
  align-items: center;
  margin-top: 0;
  color: #FFF;
  
  ${({disabled}) => disabled && headerButtonDisabledStyle}

`;

export const StyledHeaderButtonImage = styled.img`
  display: inline-block;
  margin: 12px;
  width: 17px;
`;

export const StyledCircleButton = styled.button`
  width: 24px;
  height: 24px;
  background: #0085FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &.delete {
  	background-color: #F43030;
  }
   
  &.light-red {
  	background-color: #f65959;
  }
   
  &.white {
  	background-color: #fff;
  }
  
  &.white-border {
  	background-color: #fff;
    border: 1px solid #6b6b6b;
    color: #6b6b6b !important;
  }
  
  &.small {
    font-size: 10px;
  }
  
  &.m-10 {
    margin-top: 10px;
  }
`;

export const StyledButtonBadge = styled.div`
  width: 20px;
  height: 20px;

  background: #F43030;
  border-radius: 32px;

  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: #FFFFFF;
  right: 11px;
  position: absolute;

  &.default-position {
    position: unset;
  }

  &.header-right-badge {
    right: 18px;
    top: 30px;
    z-index: 60;
  }
`;

export const StyledGrayButton = styled.button<StyledButtonPropsType>`
  ${buttonSharedStyle}

  background: #EDEDF3;
  color: #000000;

  ${({ disabled }) => disabled && buttonDisabledStyle}
`;