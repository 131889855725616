import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Info from '../../../components/info/info.component';
import Pane from '../../../components/pane/pane.component';
import {Links} from '../../links';
import AttachementFolder from '../components/attachementFolder/attachementFolder.component';
import {setConfiguration} from '../../../services/addEditValuePage.service';
import {ContractStatus} from "../../../enums/contractStatus";
import {Contract as ContractModel} from "../../../models/contract";
import {getContractFolders, newContractFolder} from "../../../services/contract.service";
import {ContractAttachmentFolder} from "../../../models/contractAttachmentFolder";
import useNavigation from "../../../services/navigation.service";
import { StyledButton } from '../../../components/button/button.style';
import {IonList} from "@ionic/react";
import AttachmentFolderSkeleton from "../components/attachementFolder/attachmentFolderSkeleton.component";
import AttachmentsGuideModal from "../modals/attachmentsGuideModal.component";

type AttachementsFolderPaneProps = {
    topEdge?: number;
    openFolder: (folder: ContractAttachmentFolder) => void;
    status?: ContractStatus;
    contract?: ContractModel;
}

interface FoldersStateInterface {
    reload: false,
    folders?: ContractAttachmentFolder[],
}

interface FoldersManager {
    setFolders: Function;
}

const contextFolders: FoldersManager = {
    setFolders: () => {

    }
};

export const FoldersContext = React.createContext<FoldersManager>(contextFolders);

const AttachementsFolderPane: React.FC<AttachementsFolderPaneProps> = (props: AttachementsFolderPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const location = useLocation();
    const state = location.state as FoldersStateInterface;
    const [folders, setFolders] = useState<ContractAttachmentFolder[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const fetchFolders = async () => {
        if (props.contract) {
            setLoading(true);
            await getContractFolders(props.contract?.id)
                .then(response => {
                    setFolders(response.data);
                });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFolders();
    }, [props.contract]);

    useEffect(() => {
        if (state !== undefined && state.reload) {
            state.reload = false;
            setFolders(state.folders?.length ? state.folders : []);
        }
    }, [state]);

    const saveFolder = async (value: string) => {
        if (props.contract?.id) {
            let done = false
            await newContractFolder(props.contract?.id, value).then(response => {
                done = true;
            }).catch(reason => reason);

            if (done) {
                await getContractFolders(props.contract?.id)
                    .then(response => {
                        setFolders(response.data);
                        let newFolder = response.data[0] as ContractAttachmentFolder;
                        history.replace(Links.contract + '/' + props.contract?.id + Links.folder + '/' + newFolder.id);
                    });
            } else {
                return t('common.required');
            }
        }
    }

    const addFolder = () => {
        setConfiguration({
            title: t("addFolderPage.title"),
            placeholder: t("addFolderPage.placeholder"),
            url: `${history.location.pathname}${history.location.search}`,
            handleSave: value => {
                return saveFolder(value);
            }
        })
        history.push(Links.addEditValue, location.state);
    };

    const contextFolders = useContext(FoldersContext);
    contextFolders.setFolders = setFolders;

    return (
        <FoldersContext.Provider value={contextFolders}>
            <AttachmentsGuideModal contract={props.contract} isOpen={showGuide} onClose={() => updateShowGuide(false)} />
            <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={0}>
                <span onClick={() => updateShowGuide(true)}><Info>{t("contractPage.attachements.info")}</Info></span>
                <StyledButton onClick={(e) => { addFolder() }}>{t("contractPage.attachements.addDocument")}</StyledButton>
                {!loading && folders.map((folder: ContractAttachmentFolder, key) => {
                    return <AttachementFolder key={key} folder={folder} contract={props.contract}
                                              onClick={() => props.openFolder(folder)}></AttachementFolder>
                })}

                {loading &&
                    <IonList>
                        <AttachmentFolderSkeleton />
                        <AttachmentFolderSkeleton />
                        <AttachmentFolderSkeleton />
                    </IonList>
                }
            </Pane>
        </FoldersContext.Provider>
    );
};

export default AttachementsFolderPane;
