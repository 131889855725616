export interface IEditDailyAvailabilityPageConfiguration {
    availabilities: string;
    handleSave: (value: any) => void
}

let configuration: IEditDailyAvailabilityPageConfiguration | undefined = undefined;

export function setConfiguration(newConfiguration: IEditDailyAvailabilityPageConfiguration) {
    configuration = newConfiguration;
}

export function getConfiguration(): IEditDailyAvailabilityPageConfiguration | undefined {
    return configuration;
}

let availabilities: string[] | undefined;

export function setAvailabilities(aval: string[] | undefined) {
    availabilities = aval;
}

export function getAvailabilitiesMemo(): string[] | undefined {
    return availabilities;
}
