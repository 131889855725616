import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { StyledButton } from '../../../components/button/button.style';
import { StyledTextarea } from '../../../components/form/input/textarea.style';
import Modal from '../../../components/modal/modal.component';
import { StyledFooter } from '../../../components/pane/pane.style';
import CloseImage from "../../../assets/images/e-remove.svg";
import {StyledModalContent} from "../../../components/modal/modal.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ReportRemarksModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onRemarks: (remark: string) => void;
};

const ReportRemarksModal: React.FC<ReportRemarksModalProps> = (props: ReportRemarksModalProps) => {

    const {t} = useTranslation();

    const manualRemarkRef = useRef<HTMLTextAreaElement>(null);

    const [manualRemark, updateManualRemark] = useState<string>('');
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    const handleManualChange = (event: any) => {
        updateManualRemark(event.target.value);
    };

    const handleSaveRemarks = () => {
        props.onRemarks(manualRemark)
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        if (props.isOpen !== showAddRemarks) {
            updateShowAddRemarks(props.isOpen);
        }
    };

    return (
        <Modal
            isOpen={showAddRemarks}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleCloseRemarks()}>
            <StyledModalContent>
                <img src={CloseImage} className="close" onClick={() => handleCloseRemarks() } />
                <h1 className="title">{t("reportsTab.registry.remarksModal.titleWriteRemark")}</h1>
                <div className="content">
                    <StyledTextarea className="full-width" ref={manualRemarkRef} onChange={handleManualChange}></StyledTextarea>
                </div>
                <StyledFooter className="modal">
                    <StyledButton
                        onClick={() => handleSaveRemarks()}
                        disabled={!manualRemark}>{t("reportsTab.registry.remarksModal.sendButton")}</StyledButton>
                </StyledFooter>
            </StyledModalContent>
        </Modal>
    );
};

export default ReportRemarksModal;
