import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {IonItem} from '@ionic/react';
import {FORM_FIELDS} from './sign.types';
import Modal from '../../components/modal/modal.component';
import Info from '../../components/info/info.component';
import {StyledIonLabel} from '../../components/form/input/label.style';
import {StyledIonCheckbox} from '../../components/form/input/checkbox.style';
import {StyledFooter} from '../../components/pane/pane.style';
import Form from '../../components/form'
import {StyledPinRow} from './sign.style';
import CloseImage from "../../assets/images/e-remove.svg";
import {StyledModalContent} from "../../components/modal/modal.style";
import {Preferences} from "@capacitor/preferences";
import {ifNull} from "../../utils/tools/numbers";
import {disableSwipeToClose} from "../../utils/tools/modals";

const DEFAULT_PHONE_PREFIX = '48';

type SignModalProps = {
    configuration: ISignModalConfigration;
    isOpen: boolean;
    onClose: () => void;
    onSave?: (code: string) => void;
    formSubmitted?: boolean;
};

export interface ISignModalConfigration {
    title: string;
    info?: string;
    buttonText: string;
    approvementText?: string;
    beforeCodeText?: string;
    afterCodeText?: string;
    phone?: string;
    email?: string;
};

export interface ISignModalFormData {
    pin1: string;
    pin2: string;
    pin3: string;
    pin4: string;
};


const SignModal: React.FC<SignModalProps> = (props: SignModalProps) => {

    const {t} = useTranslation();

    const [showSign, updateShowSign] = useState<boolean>(false);
    const [accepted, updateAccepted] = useState<boolean>(false);

    const {register, handleSubmit, formState: {isValid}} = useForm({
        mode: 'onChange'
    });

    const handleClose = () => {
        props.onClose();
    };


    const [phoneNumber, updatePhoneNumber] = useState('');
    const [email, updateEmail] = useState('');
    const getPersonalData = async () => {
        if (props.configuration.email) {
            let email = props.configuration.email;
            let extensionLength = email.substr(email.lastIndexOf("@") + 1).length + 1;
            let count = email.length - extensionLength;
            let endOf = email.substring(email.length - extensionLength);

            let resultEmail = '';
            for (let i = 0; i < count; i++) {
                resultEmail += "*";
            }
            resultEmail += endOf;
            updateEmail(resultEmail);
        } else if (!props.configuration.phone) {
            let phone = await Preferences.get({'key': 'phone'});
            let phoneCode = await Preferences.get({'key': 'phone_code'});

            let phoneNumber = `${ifNull(phone?.value) || ''}`;
            let count = phoneNumber.length - 3;
            let endOf = phoneNumber.substring(phoneNumber.length - 3);

            let resultPhone = '';
            for (let i = 0; i < count; i++) {
                resultPhone += "*";
            }
            resultPhone += endOf;

            resultPhone = `${ifNull(phoneCode?.value) || DEFAULT_PHONE_PREFIX} ${resultPhone}`;
            updatePhoneNumber('+' + resultPhone);
        } else {
            let phoneNumber = props.configuration.phone;
            let count = phoneNumber.length - 3;
            let endOf = phoneNumber.substring(phoneNumber.length - 3);

            let resultPhone = '';
            for (let i = 0; i < count; i++) {
                resultPhone += "*";
            }
            resultPhone += endOf;
            updatePhoneNumber('+' + resultPhone);
        }
    };

    useEffect(() => {
        getPersonalData();
        handleOpen();
    });

    const handleOpen = () => {
        updateShowSign(props.isOpen);
    };

    const onSubmit = (data: ISignModalFormData) => {
        const onSave = props.onSave;
        if (onSave) {
            let code = data.pin1 + data.pin2 + data.pin3 + data.pin4;
            onSave(code);
        }
    };

    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        const input = event.target as HTMLInputElement;

        if (input.value?.length > 1) {
            input.value = input.value.substring(0, 1);
            event.preventDefault();
        }

        if (input.value?.length === 1) {
            const form = input.form;
            const index = Array.prototype.indexOf.call(form, event.target);

            if (form?.elements[index + 1] && form?.elements[index + 1].tagName === "INPUT" &&
                (form?.elements[index + 1] as HTMLInputElement).name !== 'accept') {
                (form?.elements[index + 1] as HTMLInputElement).focus();
            } else {
                input.blur();
            }

            event.preventDefault();
        }

        if (input.value?.length === 0) {
            const form = input.form;
            const index = Array.prototype.indexOf.call(form, event.target);

            if (form?.elements[index - 1] && form?.elements[index - 1].tagName === "INPUT" &&
                (form?.elements[index - 1] as HTMLInputElement).name !== 'accept') {
                (form?.elements[index - 1] as HTMLInputElement).focus();
            } else {
                input.blur();
            }

            event.preventDefault();
        }
    }

    return (
        <Modal
            isOpen={showSign}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleClose()}/>
                    <h1 className="title full">{props.configuration.title}</h1>
                    <Info>{props.configuration.info} {email ? email : phoneNumber}</Info>
                    <StyledPinRow>
                        <Form.PinInput name={FORM_FIELDS.PIN_1} placeholder=" " type="text" pattern="[0-9]*"
                                       inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_2} placeholder=" " type="text" pattern="[0-9]*"
                                       inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_3} placeholder=" " type="text" pattern="[0-9]*"
                                       inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_4} placeholder=" " type="text" pattern="[0-9]*"
                                       inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                    </StyledPinRow>

                    <div>
                        {
                            props.configuration.approvementText &&
                            <IonItem lines="none">
                                <StyledIonLabel
                                    className="ion-text-wrap">{props.configuration.approvementText}</StyledIonLabel>
                                <StyledIonCheckbox name={FORM_FIELDS.ACCEPT} slot="start" onIonChange={() => {
                                    updateAccepted(!accepted);
                                }}/>
                            </IonItem>
                        }
                    </div>

                    <>
                        {
                            props.configuration?.afterCodeText && <Info className="stacked transparent"><span
                                dangerouslySetInnerHTML={{__html: props.configuration.afterCodeText || ''}}/></Info>
                        }
                    </>

                    <StyledFooter className="modal">
                        <Form.Button type="submit"
                                     disabled={!isValid || props.formSubmitted || (!!props.configuration.approvementText && !accepted)}>{props.configuration.buttonText}</Form.Button>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default SignModal;