import styled from "styled-components";

export const StyledFilterContainer = styled.div`
    width: 100%;
    height: 100%;
    .header-row ion-col {
        display: flex;
        align-items: center;
    }
    
    .header {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
    }

    .clear-button {
        border: 1px solid #EDEDF3;
        border-radius: 39px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8.5px 12px;

        font-weight: normal;
        font-size: 11px;
        line-height: 140%;
        color: #000000;

        span {
            text-align: right;
        }
    }

    .offer-type {
        border: 1px solid #EDEDF3;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        padding: 21px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.selected {
            background: rgba(0, 133, 255, 0.1);
            border: 1px solid #0085FF;
        }

        .offer-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #000000;
        }

        .offer-results {
            font-size: 12px;
            line-height: 140%;
            color: #888A98;
        }
    }

    .options-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #888A98;
        padding-top: 14px;
    }
`;