import {travelAllowanceApi} from "@services/api.service";

import {RideCost, RideCostCalculate} from "@models/travelAllowance/rideCost";

import {getWorkerId} from "@utils/tools/worker";

const calculateRideCost = async (calculateRideCostData: RideCostCalculate): Promise<RideCost | { message: string }> => {
    const workerId = await getWorkerId();
    const response = await travelAllowanceApi.get(`ride-cost/worker/${workerId}/calculate-cost`, {
        params: calculateRideCostData
    });

    if (response.data.message) {
        return response.data;
    }

    return response.data;
}

export {
    calculateRideCost,
};
