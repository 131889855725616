import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {IonItem, IonSkeletonText} from '@ionic/react';
import {StyledIonCheckbox} from '../../../components/form/input/checkbox.style';
import {StyledIonLabel} from '../../../components/form/input/label.style';
import {StyledModalContent} from '../../../components/modal/modal.style';
import LinkLabel from "../../../components/form/input/label.component";
import {Loan} from "../../../models/loan";
import {getDocuments, getLoans} from "../../../services/loan.service";
import {Browser} from "@capacitor/browser";
import {StyledWiteButton} from "../../../components/button/button.style";
import StaticPane from "../../../components/pane/static-pane.component";
import {StyledDataTable} from "../../../components/content/content.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ApprovalsModalProps = {
    isOpen: boolean;
    loan: Loan | undefined;
    onClose: () => void;
    onAccept: () => void;
};

const PreconditionsModal: React.FC<ApprovalsModalProps> = (props: ApprovalsModalProps) => {

    const {t} = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);
    const [showInformationClause, updateShowInformationClause] = useState<boolean>(false);
    const [regulationsContent, updateRegulationsContent] = useState<string>('');

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleCloseRemarks = () => {
        updateShowAddRemarks(false);
        props.onClose();
        setLoading(false);
    };

    useEffect(() => {
        handleOpen();
        if (props.isOpen !== showAddRemarks) {
            updateLinesToShow([2, 2, 2]);
        }
    }, [props.isOpen]);

    const handleOpen = () => {
        fetchDocuments();
        updateShowAddRemarks(props.isOpen);
    };

    const onAccept = () => {
        setLoading(true);
        props.onAccept();
    };

    const onReject = () => {
        setLoading(false);
        handleCloseRemarks();
    }

    const onSubmit = () => {

    };

    const [linesToShow, updateLinesToShow] = useState([2, 2, 2]);
    const readMore = (index: number) => {
        const newLinesConfiguration = [...linesToShow];
        newLinesConfiguration[index] = 1000;
        updateLinesToShow(newLinesConfiguration);
    }

    const fetchDocuments = async () => {
        const response = await getDocuments()
            .then(response => {
                if (response.data?.result && response.data.result === 'error') {
                    updateRegulationsContent(t("loanPage.regulationsNotAvailable"));
                } else {
                    updateRegulationsContent(response.data.regulations_content);
                }
            })
            .catch(error => {
                // todo what we show??
            });
    };

    const openInformationClauseUrl = async () => {
        await Browser.open({ url: t("loanPage.preconditions.approvals.informationClauseUrl")})
    };

    return (
        <>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("loanPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: regulationsContent || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showInformationClause} onDidDismiss={() => updateShowInformationClause(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("loanPage.informationClauseModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.loan?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={showAddRemarks}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleCloseRemarks()}>

                {
                    loading &&
                    <StaticPane>
                        <StyledDataTable>
                            <IonSkeletonText animated style={{ width: '60%', borderRadius: 0}} />
                            <IonSkeletonText animated style={{ width: 0, borderRadius: 0}} />
                            <IonSkeletonText animated style={{ width: '90%', borderRadius: 0}} />
                            <IonSkeletonText animated style={{ width: '75%', borderRadius: 0}} />
                            <IonSkeletonText animated style={{ width: '55%', borderRadius: 0}} />
                        </StyledDataTable>
                    </StaticPane>
                }

                {
                    !loading && <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                        <StyledModalContent>
                            <h1 className="title full">{t("loanPage.preconditions.title")}</h1>
                            <div className="content">
                                <LinkLabel urlString={t("loanPage.preconditions.approvals.rulesAcceptedLink")} url={'#'} onClick={() => {updateShowRegulations(true)}}>
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('loanPage.preconditions.approvals.rulesAccepted')}</StyledIonLabel>
                                    </IonItem>
                                </LinkLabel>
                                <LinkLabel urlString={t("loanPage.preconditions.approvals.informationClauseLink")} url={'#'} onClick={() => openInformationClauseUrl()}>
                                    <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('loanPage.preconditions.approvals.informationClause')}</StyledIonLabel>
                                    </IonItem>
                                </LinkLabel>
                            </div>
                            <StyledFooter className="modal footer no-shadow">
                                <Form.Button type="button"
                                             onClick={() => onReject()}>{t("loanPage.approvals.cancelButton")}</Form.Button>
                                <Form.Button type="button"
                                             onClick={() => onAccept()}>{t("loanPage.approvals.acceptButton")}</Form.Button>
                            </StyledFooter>
                        </StyledModalContent>
                    </Form.Container>
                }
            </Modal>
        </>
    );
};

export default PreconditionsModal;