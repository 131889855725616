import styled from 'styled-components';

export const StyledNoAuditReports = styled.div`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 16px;

  img {
    width: 108px;
    height: 150px;
  }

  img.reports {
    width: 24px;
    height: 24px;
    position: relative;
    top: -100px;
  }

  span.message {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }
`;

export const StyledAuditReports = styled.div`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 16px;

  .date-separator {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;

    .box {
      background: #FFFFFF;
      border: 1px solid #F6F6F8;
      box-sizing: border-box;
      border-radius: 39px;
      padding: 3px 8px;
      font-size: 11px;
      line-height: 140%;
      color: #888A98;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      width: 200vw;
      border: 1px solid #F6F6F8;
      position: absolute;
      left: -50vw;
      z-index: -1;
    }
  }

  .order {
    background: #FFFFFF;
    border: 1px solid #EDEDF3;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
    border-radius: 16px;
    padding: 24px;
    margin-top: 20px;
    width: 100%;

    .order-number {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
    
    .draft {
      margin-top: 10px;
    }

    .members {
      margin: 16px 0;

      img {
        position: relative;
        border-radius: 16px;
        width: 32px;
        height: 32px;

        ${moveAvatars()}
      }
    }

    .details {
      .detail {
        padding-top: 3px;

        &:nth-child(1) {
          padding-top: 0;
        }
      }

      font-size: 12px;
      line-height: 140%;
      color: #888A98;
      
      .value {
        font-weight: bolder;
      }
      
      /* COLORS */
      .text-navy {
        color: #1ab394;
      }
      .text-primary {
        color: inherit;
      }
      .text-success {
        color: #1c84c6;
      }
      .text-info {
        color: #23c6c8;
      }
      .text-warning {
        color: #f8ac59;
      }
      .text-danger {
        color: #ed5565;
      }
    }
  }
`;

function moveAvatars() {
  let avatarsCSS = '';

  // for (let i = 0; i < 20; i++) {
  //   avatarsCSS += `
  //     &:nth-child(${i}) {
  //       left: -${ (i - 1) * 8}px;
  //     }
  //   `
  // }

  return avatarsCSS;
}