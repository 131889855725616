import React from 'react';
import AvatarImage from "../../../../../assets/images/logo/x-black.png";
import {IonSkeletonText} from "@ionic/react";

const InvitationUserSkeleton: React.FC = () => {
    return (
        <div className="data-row">
            <div className="data-icon-title">
                <img className="avatar" src={AvatarImage} />
                <IonSkeletonText animated style={{ width: (Math.random()>=0.5) ? '70%' : '50%' }} />
            </div>
        </div>
    );
};

export default InvitationUserSkeleton;
