import React, {useEffect, useRef, useState} from 'react';
import {IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {useLocation} from "react-router-dom";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import TravelAllowanceSubMenuModal from "@modals/menu/travelAllowanceSubMenu.component";

const SubMenuPage: React.FC = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const header = useRef<HTMLIonHeaderElement>(null);

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [subMenuModal, setSubMenuModal] = useState<JSX.Element | null>(null);

    useEffect(() => {
        setTitle(getTitle());
        setSubMenuModal(renderSubMenuModal());
    }, [location.pathname, topEdge]);

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const getTitle = () => {
        switch (location.pathname) {
            case '/main/travel-allowance':
                return t('travelAllowance.subMenu.title');
            default:
                return '';
        }
    }

    const renderSubMenuModal = () => {
        switch (location.pathname) {
            case '/main/travel-allowance':
                return <TravelAllowanceSubMenuModal topEdge={topEdge}/>;
            default:
                return null;
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle>{title}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                {subMenuModal}
            </StyledIonContent>
        </IonPage>
    );
};

export default SubMenuPage;