export enum FORM_FIELDS {
    PIN_1 = 'pin1',
    PIN_2 = 'pin2',
    PIN_3 = 'pin3',
    PIN_4 = 'pin4',
    PASSWORD = 'password',
    ACCEPT = 'accept',
}

export type FormFieldsType = {
    [FORM_FIELDS.PIN_1]: string;
    [FORM_FIELDS.PIN_2]: string;
    [FORM_FIELDS.PIN_3]: string;
    [FORM_FIELDS.PIN_4]: string;
    [FORM_FIELDS.PASSWORD]: string;
    [FORM_FIELDS.ACCEPT]: boolean;
}