import {appApi} from "./api.service";
import {Invitation} from "../app/mainPage/tabs/planning/panes/newInvitationPane.component";
import moment from "moment";

let endpointPrefix = 'planning';

const getProposals = async (params: any = null) => {
    return appApi.get(endpointPrefix + '/list');
}

const postProposal = async (data: Invitation) => {
    let startDate = moment(moment(data.date).format('DD-MM-YYYY') + ' ' + moment(data.start).format('H:mm'), 'DD-MM-YYYY H:mm');
    let endDate = moment(moment(data.date).format('DD-MM-YYYY') + ' ' + moment(data.end).format('H:mm'), 'DD-MM-YYYY H:mm');

    if (startDate > endDate) {
        endDate.add(1, 'day');
    }

    let params = {
        proposal: {
            startDate: startDate.format('YYYY-MM-DD HH:mm'),
            endDate: endDate.format('YYYY-MM-DD HH:mm'),
            numberOfPeople: data.numberOfPeople,
            project: data.project,
            placeOfService: data.placeOfService,
            jobPosition: data.jobPosition,
            description: data.description,
            repeat: data.sendForNextDays,
            repeatNumber: data.nextDaysAmount,
            repeatSkipSaturday: data.skipSaturdays,
            repeatSkipSunday: data.skipSundays,
            users: data.workers,
        }
    };

    return appApi.post(endpointPrefix + '/post-proposal', params);
}

const getProjects = async () => {
    return appApi.get(endpointPrefix + '/possible-values/projects');
}

const getPlaceOfServices = async (projectId: string | bigint) => {
    return appApi.get(endpointPrefix + '/possible-values/place-of-services/'+ projectId);
}

const getWorkers = async (data: Invitation, onlyActive: boolean = true) => {
    let startDate = moment(moment(data.date).format('DD-MM-YYYY') + ' ' + moment(data.start).format('H:mm'), 'DD-MM-YYYY H:mm');
    let endDate = moment(moment(data.date).format('DD-MM-YYYY') + ' ' + moment(data.end).format('H:mm'), 'DD-MM-YYYY H:mm');

    if (startDate > endDate) {
        endDate.add(1, 'day');
    }

    return appApi.get(endpointPrefix + '/possible-values/workers/'+ data.project +
        '?startDate='+startDate.format('YYYY-MM-DD HH:mm')+
        '&endDate='+endDate.format('YYYY-MM-DD HH:mm')+
        '&repeat='+(data.sendForNextDays ?? '')+
        '&repeatNumber='+(data.nextDaysAmount ?? '')+
        '&repeatSkipSaturday='+(data.skipSaturdays ?? '')+
        '&repeatSkipSunday='+(data.skipSundays ?? '')+
        '&onlyActive='+onlyActive
    );
}

const removeProposal = async (id: bigint | string, reason: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/remove-proposal', {
        textRemoving: reason
    });
}

const removeUserProposal = async (id: bigint | string, reason: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/remove-user-proposal', {
        textRemoving: reason
    });
}

export {
    getProposals,
    postProposal,
    getProjects,
    getPlaceOfServices,
    getWorkers,
    removeUserProposal,
    removeProposal
}

