import {appApi} from "./api.service";

let endpointPrefix = 'possible-values';

const getTaxOffices = async () => {
    return appApi.get(endpointPrefix + '/tax-offices');
}

const getNationalities = async () => {
    return appApi.get(endpointPrefix + '/nationonality');
}

const getCities = async (communityId: string | bigint) => {
    return appApi.get(endpointPrefix + '/' + communityId + '/cities');
}

export {
    getTaxOffices,
    getNationalities,
    getCities,
};
