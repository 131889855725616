import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton} from '../../../components/button/button.style';
import {StyledTextarea} from '../../../components/form/input/textarea.style';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {StyledOption} from '../../../components/options/options.style';
import {RemarkType} from "../../../enums/remarkType";
import {Contract} from "../../../models/contract";
import {StyledModalContent} from "../../../components/modal/modal.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type RemarksModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onRemarks: (remark: string, description: string) => void;
    contract: Contract | undefined;
};

const RemarksModal: React.FC<RemarksModalProps> = (props: RemarksModalProps) => {

    const {t} = useTranslation();

    const [showManualRemark, updateShowManualRemark] = useState<boolean>(false);
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);

    let focused = false;
    const manualRemarkRef = useRef<HTMLTextAreaElement>(null);

    var remarks = [
        RemarkType.PERSONAL_DATA,
        RemarkType.ACCOUNT_NUMBER,
        RemarkType.CONTRACT_DATE,
        RemarkType.ADDRESS,
        RemarkType.POSITION,
        RemarkType.RATE,
        RemarkType.OTHER,
    ];

    if (props.contract?.is_ap){
        remarks = [
            RemarkType.PERSONAL_DATA,
            RemarkType.CONTRACT_DATE,
            RemarkType.RATE,
            RemarkType.OTHER,
        ];
    }

    const [selectedRemark, updateSelectedRemark] = useState<string>('');
    const [manualRemark, updateManualRemark] = useState<string>('');
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);

    const handleSelectRemark = (selectedIndex: number) => {
        updateSelectedRemark(remarks[selectedIndex]);
        updateShowManualRemark(true);
    };

    const handleCloseRemarks = () => {
        updateSelectedRemark('');
        updateShowManualRemark(false);
        props.onClose();
    };

    const handleManualChange = (event: any) => {
        updateManualRemark(event.target.value);
    };

    const handleSaveRemarks = () => {
        props.onRemarks('contractPage.remarks.reasons.'+selectedRemark.toLowerCase(), manualRemark)
    };

    useEffect(() => {
        handleFocus();
        handleOpen();
    });

    const handleOpen = () => {
        updateShowAddRemarks(props.isOpen);
    };

    const handleFocus = () => {
        if (showManualRemark && !focused) {
            focused = true;
            manualRemarkRef.current?.focus();
        } else if (!showManualRemark) {
            focused = false;
        }
    };

    return (
        <>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={showAddRemarks}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleCloseRemarks()}>
                <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleCloseRemarks() } />
                    {!props.contract?.is_ap && !showManualRemark && <h1 className="title">{t("contractPage.remarks.titleSelectRemarks")} {t('contractPage.remarks.titleOr')} <a onClick={() => updateShowRegulations(true)}>{t('contractPage.remarks.titleRegulations')}</a></h1>}
                    {props.contract?.is_ap && !showManualRemark && <h1 className="title">{t("contractPage.remarks.titleSelectRemarks")}</h1>}
                    {showManualRemark && (selectedRemark == RemarkType.POSITION || selectedRemark == RemarkType.RATE || selectedRemark == RemarkType.OTHER) && <h1 className="title">{t("contractPage.remarks.titleWriteRemark")}</h1>}
                    {showManualRemark && (selectedRemark == RemarkType.PERSONAL_DATA || selectedRemark == RemarkType.ACCOUNT_NUMBER || selectedRemark == RemarkType.CONTRACT_DATE || selectedRemark == RemarkType.ADDRESS) && <h1 className="title">{t("contractPage.remarks.titleWriteCorrectDataRemark")}</h1>}
                    <div className="content">
                        {
                            !showManualRemark &&
                            remarks.map((remark: RemarkType, index) => {
                                return <StyledOption key={index} className={selectedRemark === remarks[index] ? 'selected' : ''}
                                                     onClick={() => handleSelectRemark(index)}>{t('contractPage.remarks.reasons.'+remark.toLowerCase())}</StyledOption>
                            })
                        }
                        {
                            showManualRemark &&
                            <StyledTextarea className="full-width" ref={manualRemarkRef} onChange={handleManualChange}></StyledTextarea>
                        }
                    </div>
                    <StyledFooter className="modal">
                        <StyledButton
                            onClick={() => handleSaveRemarks()}
                            disabled={showManualRemark && !manualRemark}>{t("contractPage.content.sendButton")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Modal>
        </>

    )
};

export default RemarksModal;