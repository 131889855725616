import { SplashScreen } from "@capacitor/splash-screen";
import { IonContent, IonPage, isPlatform, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { StyledSocialButton } from "../../components/button/button.style";
import Logo from '../../components/logo';
import { StyledText, StyledTextLink } from '../../components/text';
import { StyledTextHref } from "../../components/text/text.styles";
import StyledTitle from '../../components/title/index';
import AppUpdateModal from "../../modals/appUpdate/appUpdate.component";
import LanguageSwitcherModal from "../../modals/language/languageSwitcher.component";
import SupportRequestModal from "../../modals/supportRequest/supportRequest.component";
import { compareVersions } from "../../services/validation.service";
import { loginStatusBarUpdate } from "../../utils/tools/statusbar";
import { Links } from '../links';
import FacebookLogin from './components/facebookLogin.component';
import LoginForm from './form/loginForm.component';
import { StyledLoginContainer, StyledLoginTextContainer } from './login.styles';


const Login: React.FC = () => {
    const compareVersionsHandle = async () => {
        let version = null;

        await compareVersions().then(value => {
            version = value;
        });

        if (version) {
            updateShowAppUpdateModal(version);
        }
    }

    useIonViewWillEnter(() => {
        SplashScreen.hide();
        loginStatusBarUpdate();
        compareVersionsHandle();
    });

    
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [showSupportRequest, updateShowSupportRequest] = useState<boolean>(false);
    const [showAppUpdateModal, updateShowAppUpdateModal] = useState<boolean>(false);
    const [showLanguageModal, updateLanguageModal] = useState<boolean>(false);

    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledLoginContainer>
                    <Logo />
                    <StyledTitle>{t("login.title")}</StyledTitle>
                    <LoginForm />
					{
						!isPlatform('ios') &&
						<>
							<FacebookLogin/>
							<StyledLoginTextContainer>
								<StyledText>
									<StyledTextHref
										onClick={() => history.push(Links.main + Links.offersOpen)}>{t("login.checkJobOffersWithoutLogin")}</StyledTextHref>
								</StyledText>
							</StyledLoginTextContainer>
						</>
					}

                    {
                        isPlatform('ios') &&
                        <StyledSocialButton onClick={() => history.push(Links.main + Links.offersOpen)}>
                            {t("login.checkJobOffersWithoutLogin")}
                        </StyledSocialButton>
                    }

					<StyledLoginTextContainer>
                        <StyledText>
                            {t("login.createAccountDescription")}
                            <StyledTextLink to={Links.createAccount}>
                                {t("login.createAccountLink")}
                            </StyledTextLink>
                        </StyledText>
                    </StyledLoginTextContainer>

                    <StyledLoginTextContainer>
                        <StyledText>
                            {t("login.createAccountHelpDescription")} <StyledTextHref onClick={() => updateShowSupportRequest(true)}>{t("login.createAccountHelpLink")}</StyledTextHref>
                        </StyledText>
                    </StyledLoginTextContainer>

                    {/*<StyledLoginTextContainer>*/}
                    {/*    <StyledText>*/}
                    {/*        <StyledTextHref onClick={() => updateLanguageModal(true)}>{t(`languageTab.${i18n.language}`)}</StyledTextHref>*/}
                    {/*    </StyledText>*/}
                    {/*</StyledLoginTextContainer>*/}

                </StyledLoginContainer>
            </IonContent>
            <SupportRequestModal isOpen={showSupportRequest} onClose={() => updateShowSupportRequest(false)} />
            <AppUpdateModal isOpen={showAppUpdateModal} onClose={() => updateShowAppUpdateModal(false)} />
            <LanguageSwitcherModal isOpen={showLanguageModal} onClose={() => updateLanguageModal(false)} />



        </IonPage>
    );
};

export default Login;
