import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import CameraImage from '../../../assets/images/camera.svg';
import LayoutImage from '../../../assets/images/layout-11.svg';
import Info from '../../../components/info/info.component';
import Pane from '../../../components/pane/pane.component';
import {StyledFooter, StyledPaneFooterOption} from '../../../components/pane/pane.style';
import Attachment from '../components/attachement/attachement.component';
import {StyledAttachementsContainer} from '../components/attachement/attachement.style';

import {CameraResultType, CameraSource} from "@capacitor/camera";
import {Preferences} from "@capacitor/preferences";
import {Camera} from "@capacitor/camera";
import {ContractStatus} from "../../../enums/contractStatus";
import {ContractAttachmentFolder} from "../../../models/contractAttachmentFolder";
import {getContract, getContractFolderFiles, getContracts, uploadPhoto} from "../../../services/contract.service";
import {ContractAttachmentFolderFile} from "../../../models/contractAttachmentFolderFile";
import {changeBase46ToBlob, generateFileName} from "../../../services/file.service";
import { Links } from '../../links';
import PreviewModal from "../modals/previewModal.component";
import {ContractAttachmentFolderStatus} from "../../../enums/contractAttachmentFolderStatus";
import { StyledEmptyContainer } from '../../../components/content/content.style';
import CircleImage, { ImageTypes } from '../../../components/circleImage/circleImage.component';
import StaticPane from '../../../components/pane/static-pane.component';
import {documentTextOutline} from "ionicons/icons";
import {IonAlert, IonIcon} from "@ionic/react";
import AttachmentSkeleton from "../components/attachement/attachementSkeleton.component";
import {Contract as ContractModel} from "../../../models/contract";
import {ContractsContext} from "../../contractPage/panes/contractsPane.component";
import {ContractPageContext} from "../../contractPage/contractPage.component";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

type AttachementsPaneProps = {
    topEdge?: number;
    status?: ContractStatus;
    contractId: bigint;
    folder?: ContractAttachmentFolder;
    onFilesUpdate: (files: any) => void;
}

const AttachementsPane: React.FC<AttachementsPaneProps> = (props: AttachementsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [files, setFiles] = useState([]);
    const [showPreview, updateShowPreview] = useState<boolean>(false);
    const [preview, updatePreview] = useState<string>();
    const fileInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const contractsContext = useContext(ContractsContext);
    const contractContext = useContext(ContractPageContext);

    const fetchFiles = async () => {
        setLoading(true)
        if (props.folder) {
            let files = props.folder?.files as never;
            setFiles(files);
            setLoading(false)
        }
    };

    const fetchApiFiles = async () => {
        if (props.contractId && props.folder) {
            await getContractFolderFiles(props.contractId, props.folder.id)
                .then(response => {
                    setFiles(response.data.folder.files);
                    props.onFilesUpdate(response.data.folder.files);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    };

    useEffect(() => {
        fetchFiles();
    }, [props.folder]);

    const loadGalleryPhoto = async () => {
        let hasPermissions = await Camera.checkPermissions();
        if (hasPermissions.photos === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Photos,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64,
            });

            if (image.base64String) {
                saveFile(image.base64String);
            }
        }
    };

    // deprecated
    const takePhoto = () => {
        history.push(Links.takePhoto, {
            contractId: props.contractId,
            folder: props.folder,
        });
    };

    const takeSystemPhoto = async () => {
        let hasPermissions = await Camera.checkPermissions();
        if (hasPermissions.camera === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Camera,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64
            });

            if (image.base64String) {
                updateShowPreview(true);
                updatePreview(image.base64String);
            }
        }
    };

    const saveFile = async (base64String: string) => {
        if (base64String && props.contractId && props.folder) {
            let rcpObject = await Preferences.get({'key': 'employee_number'});
            let blob = changeBase46ToBlob(base64String);
            await uploadPhoto(props.contractId, props.folder.id, blob, generateFileName(props.folder.name, rcpObject.value))
                .then(response => {
                    fetchApiFiles();
                    updateList();
                    updateContract();
                });
        }
    }

    const saveUploadedFile = async (file: File) => {
        if (file && props.contractId && props.folder) {
            await uploadPhoto(props.contractId, props.folder.id, file, file.name)
                .then(response => {
                    fetchApiFiles();
                    updateList();
                    updateContract();
                });
        }
    }

    const updateList = async () => {
        await getContracts()
            .then(response => {
                contractsContext.setContracts(response.data);
            });
    }

    const updateContract = async () => {
        if (props.contractId) {
            await getContract(props.contractId)
                .then(response => {
                    let contract = response.data.contract as ContractModel;
                    contractContext.setStatus(contract.sign_status);
                    contractContext.setCommentsStatus(contract.comments_status);
                });
        }
    }

    return (
        <div>
            {!loading && files && files.length > 0 &&
                <Pane topEdge={props.topEdge} marginTop={40}
                    paddingBottom={(!props.folder?.expiration_date && !props.folder?.qualification_date) ? 160 : 0}>
                    <StyledAttachementsContainer>
                        {files.map((file: ContractAttachmentFolderFile, key) => {
                            return <Attachment key={key}
                                            folder={props.folder}
                                            contractId={props.contractId}
                                            file={file}
                                            onRemove={() => {
                                                fetchApiFiles();
                                            }}
                                            onUpdate={() => {
                                                fetchApiFiles();
                                            }}
                            ></Attachment>
                        })}
                    </StyledAttachementsContainer>      
                </Pane>
            }

            {!loading && (!files || files.length === 0) &&
                <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.ATTACHEMENT} color="grey"/>
                        <h2>{t("contractPage.attachements.empty")}</h2>
                    </StyledEmptyContainer>
                </StaticPane>
            }

            {loading &&
            <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
                <StyledAttachementsContainer>
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                    <AttachmentSkeleton />
                </StyledAttachementsContainer>
            </StaticPane>
            }

            {
                (!props.folder?.expiration_date && !props.folder?.qualification_date) &&
                <StyledFooter>
                    <StyledPaneFooterOption onClick={() => takeSystemPhoto()}>
                        <img src={CameraImage}/>
                        <span>{t("contractPage.attachements.takePicture")}</span>
                    </StyledPaneFooterOption>
                    <StyledPaneFooterOption onClick={() => loadGalleryPhoto()}>
                        <img src={LayoutImage}/>
                        <span>{t("contractPage.attachements.addDocumentFromGallery")}</span>
                    </StyledPaneFooterOption>
                    <StyledPaneFooterOption onClick={() => fileInput?.current?.click()}>
                        <input
                            ref={fileInput}
                            hidden
                            type="file"
                            accept="application/pdf"
                            onChange={(file) => {
                                let fileObject = file.target.files?.item(0);
                                if (fileObject) {
                                    saveUploadedFile(fileObject);
                                }
                            }}
                        />
                        <IonIcon src={documentTextOutline} style={{"paddingBottom": "10px", "fontSize": "16px"}} />
                        <span>{t("contractPage.attachements.addPdf")}</span>
                    </StyledPaneFooterOption>
                </StyledFooter>
            }

            {
                preview &&
                <PreviewModal
                    isOpen={showPreview}
                    preview={preview}
                    onSave={() => {
                        saveFile(preview);
                        updateShowPreview(false);
                        updatePreview('');
                    }}
                    onNewPage={() => {
                        saveFile(preview);
                        takeSystemPhoto();
                    }}
                    onClose={() => {
                        updateShowPreview(false);
                        updatePreview('');
                    }}></PreviewModal>
            }
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </div>
    );
};

export default AttachementsPane;
