import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AppLauncher} from "@capacitor/app-launcher";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, isPlatform} from "@ionic/react";
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import {APP_STORE_URL, GOOGLE_PLAY_URL} from "../../variables/variables";
import Logo from "../../components/logo";
import {useHistory} from "react-router-dom";
import {StyledHeaderButton, StyledHeaderButtonImage, StyledWiteButton} from "../../components/button/button.style";
import LogoffImage from "../../assets/images/log-out.svg";
import {logout, useAuth} from "../../services/auth.service";
import {Links} from "../links";
import {UserContext} from "../../services/auth.service";
import {
    StyledEmptyContainer,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from "../../components/content/content.style";
import StaticPane from "../../components/pane/static-pane.component";
import ArrowLeftImage from "../../assets/images/arrow-left.svg";

const AppUpdatePage: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const openStore = async () => {
        if (isPlatform('android')) {
            await AppLauncher.openUrl({ url: GOOGLE_PLAY_URL});
        }
        if (isPlatform('ios')) {
            await AppLauncher.openUrl({ url: APP_STORE_URL});
        }
    };

    const user = useAuth();

    const logoutClick = async () => {
        await logout().then(() => {
            user.signOut();
            history.replace(Links.login)
            window.top.location.reload();
        });
    }

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    document.addEventListener('ionBackButton', (ev: Event) => {
        ev.stopPropagation();
    });

    useEffect(() => {
        updateHeight();
    });

    return (

        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => history.replace(Links.root)}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("appUpdate.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <StyledEmptyContainer>
                    <Logo />
                    <p className="center">{t('appUpdate.description')}</p>

                    <StyledFooter className="no-shadow">
                        <Form.Button type="submit" onClick={() => openStore()}>{t('appUpdate.button')}</Form.Button>
                        <StyledWiteButton onClick={e => logoutClick()}>{t("settingsTab.logoff")} <img src={LogoffImage}/></StyledWiteButton>
                    </StyledFooter>
                    </StyledEmptyContainer>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AppUpdatePage;