import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getBillingPdf, getBillings} from "../../../services/biliing.service";
import {Billing} from "../../../models/billing";
import {useIonViewWillEnter, IonSkeletonText, IonItem, IonAvatar, IonLabel, IonList, isPlatform, IonAlert} from "@ionic/react";
import AvatarImage from '../../../../../assets/images/avatar-blue.svg';
import Pane from "../../../components/pane/pane.component";
import {StyledBilling} from "../billingPage.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import {log} from "../../../services/firebaseAnalytics.service";
import moment from "moment";
import {getLoanRegulationsPdf} from "../../../services/loan.service";
import {blobToBase64, changeBase46ToBlob, downloadFile} from "../../../services/file.service";
import {Filesystem, Directory} from "@capacitor/filesystem";
import Info from "../../../components/info/info.component";
import BillingsGuideModal from "../modals/billingsGuideModal.component";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";

type NotificationsPaneProps = {
    topEdge?: number;
}

const BillingsPane: React.FC<NotificationsPaneProps> = (props: NotificationsPaneProps) => {
    const {t} = useTranslation();

    const [billings, setBillings] = useState<[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showGuide, updateShowGuide] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const fetchBillings = async () => {
        if (billings === undefined) {
            setLoading(true);
            setBillings([]);
            const response = await getBillings()
                .then(response => {
                    setBillings(response.data);
                });

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBillings();
    })

    useIonViewWillEnter(() => {
        fetchBillings();
    });

    const downloadPdf = async (billing: Billing) => {
        const info = await Device.getInfo();
        let deniedPermissions = false;
        if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
            let hasPermissions = await Filesystem.checkPermissions();
            deniedPermissions = hasPermissions.publicStorage === 'denied';
        }

        if (deniedPermissions) {
            setShowPermissionAlert(true);
        } else {
            let fileData = null;
            fileData = await getBillingPdf(billing.id)
                .then(response => {
                    return response.data;
                })

            if (isPlatform('desktop') || isPlatform('mobileweb')) {
                downloadFile(fileData, 'ExactPeople/' + billing.file_name + '.pdf');
            } else {
                let base64 = null;
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/Pasek_' + moment(billing.period_from).format('MM_YYYY') + '.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch(e) {

                    }
                }
            }
        }
    }

	useEffect(() => {
		log('page_visit', {
			page: 'Paski'
		});
	}, []);

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                !loading && billings && <span onClick={() => updateShowGuide(true)}><Info className="info" >{t('billingPage.password')}</Info></span>

            }

            {
                !loading && billings && billings.map((billing: Billing, i) =>
                    <StyledBilling key={i}>
                        <div className="content">
                            <div className="title">
                                {
                                    t('billingPage.billing.dateOfPayment') + ': ' + moment(billing.date_of_payment).format('DD.MM.YYYY')
                                }

                                <button className="action-button" onClick={event => {
                                    event.stopPropagation();
                                    downloadPdf(billing)
                                }}>{t('billingPage.billing.download')}</button>
                            </div>
							<div className="description">
                                { t('billingPage.billing.dates') + ': ' +
                                    moment(billing.period_from).format('DD.MM.YYYY') + ' - ' +
                                    moment(billing.period_to).format('DD.MM.YYYY') }
							</div>
                            <div className="description">
                                { t('billingPage.billing.amount') + ': ' + billing.amount_of_payment + ' ' + billing.currency }
                            </div>
                        </div>
                    </StyledBilling>
                )
            }

            {
                !loading && billings && billings.length === 0 &&
                <StyledEmptyContainer>
                    <CircleImage image={ImageTypes.NOTE} color="grey"/>
                    <h3>{t('billingTab.empty')}</h3>
                </StyledEmptyContainer>
            }

            {
                loading &&
                <IonList>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonList>
            }
            <BillingsGuideModal isOpen={showGuide}
                             onClose={() => updateShowGuide(false)} />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Pane>
    );
};

export default BillingsPane;
