import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {AppLauncher} from "@capacitor/app-launcher";
import {isPlatform} from "@ionic/react";
import {appVersion} from "../../services/auth.service";
import {StyledFooter, StyledPaneBar, StylePaneGrabber} from "../../components/pane/pane.style";
import {StyledContent} from "../status/status.style";
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";
import Form from "../../components/form";
import {APP_STORE_URL, GOOGLE_PLAY_URL} from "../../variables/variables";
import Logo from "../../components/logo";
import {StyledContainer} from "../../app/mainPage/tabs/settings/modals/appVersionModal.style";
import {disableSwipeToClose} from "../../utils/tools/modals";

type AppUpdateModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const AppUpdateModal: React.FC<AppUpdateModalProps> = (props: AppUpdateModalProps) => {

    const {t} = useTranslation();

    const openStore = async () => {
        if (isPlatform('android')) {
            await AppLauncher.openUrl({ url: GOOGLE_PLAY_URL});
        }
        if (isPlatform('ios')) {
            await AppLauncher.openUrl({ url: APP_STORE_URL});
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            canDismiss={disableSwipeToClose}
            disableClose={true}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
            onDidDismiss={() => props.onClose()}>

            <StyledContainer>
                <Logo />
                <p className="center">{t('appUpdate.description')}</p>

                <StyledFooter className="no-shadow">
                    <Form.Button type="submit" onClick={() => openStore()}>{t('appUpdate.button')}</Form.Button>
                </StyledFooter>
            </StyledContainer>

        </Modal>
    );
};

export default AppUpdateModal;