import {CancelTokenSource} from "axios";

import {appApi, travelAllowanceApi} from "@services/api.service";

import {Passenger, Ride, RideFormDataForApi} from "@models/travelAllowance/ride";

import {getWorkerId} from "@utils/tools/worker";
import moment from "moment";

const getRides = async (date: string, cancelToken?: CancelTokenSource): Promise<Ride[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`rides/worker/${workerId}`, {
        params: {date: date},
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getRide = async (rideId: number, cancelToken?: CancelTokenSource) => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`rides/${rideId}/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const addRide = async (rideData: RideFormDataForApi) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`rides/worker/${workerId}`, rideData)
}

const editRide = async (rideId: number, rideData: RideFormDataForApi) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.put(`rides/${rideId}/worker/${workerId}`, rideData)
}

const getPossiblePassengers = async (coordinatorId?: number, placeOfServiceId?: number, date?: string, cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<Passenger[]> => {
    if (date) {
        date = moment(date).format('YYYY-MM-DD');
    }

    const response: any = await appApi.get(`ride/possible-passengers?page=${page}&searchText=${searchText}&coordinatorId=${coordinatorId}&placeOfServiceId=${placeOfServiceId}` + (date ? `&date=${date}` : ''), {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const handleCommonRideErrorResponseMessage = (errorMessage: string, defaultErrorMessageKey: string): string => {
    let errorMessageKey = defaultErrorMessageKey;

    if (errorMessage === 'Company vehicle rate not found') {
        errorMessageKey = 'travelAllowance.rideCost.companyVehicleRateNotFound';
    }

    if (errorMessage === 'Capacity rate not found') {
        errorMessageKey = 'travelAllowance.rideCost.capacityRateNotFound';
    }

    if (errorMessage === 'Passenger rate not found') {
        errorMessageKey = 'travelAllowance.rideCost.passengerRateNotFound';
    }

    if (errorMessage === 'Foreign rate not found') {
        errorMessageKey = 'travelAllowance.rideCost.foreignRateNotFound';
    }

    return errorMessageKey;
}

export {
    getRides,
    getRide,
    addRide,
    editRide,
    getPossiblePassengers,
    handleCommonRideErrorResponseMessage,
};
