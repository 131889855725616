import React, {useContext, useEffect, useState} from "react";
import {AuditReportFormModel} from "../../../../models/auditReport";
import {DataSelectable} from "../../../../models/common";
import {IonAlert, IonCol, IonGrid, IonItem, IonRow, IonToast} from "@ionic/react";
import {StyledInput} from "../../../../components/form/input/input.style";
import {StyledContent} from "../../../reportPage/reportPage.style";
import {useTranslation} from "react-i18next";
import {Profile} from "../../../../models/profile";
import {
    findOrder, findSpecification, findWorker,
    getOrderComponentsNumbers, getOrderSpecifications,
    getPlaceOfServices,
    getPlaceOfServicesProfiles,
    getPlaceOfServicesProjects, getProjectPlaceOfServiceComponentsNumbers, getProjectPlaceOfServiceOrders,
    getShitPeriodReadable, validateStep1
} from "../../../../services/audit.service";
import ArrowDownImage from "../../../../assets/images/down-arrow.svg";
import CrossImage from "../../../../assets/images/e-remove.svg";
import PaginatedListModal from "../../modals/paginatedListModal.component";
import Form from "../../../../components/form";
import {StyledFooter, StyledStaticFooter} from "../../../../components/pane/pane.style";
import {StyledComponentFormContainer} from "../../../reportPage/components/report/reportRowForm.style";
import {Order, OrderSpecification} from "../../../../models/order";
import DateInput from "../../../../components/form/input/date.component";
import {StyledIonLabel} from "../../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../../components/form/input/checkbox.style";
import {StyledTextarea} from "../../../../components/form/input/textarea.style";
import { StyledAccordion } from "../../../reportPage/reportPage.style";
import {setAuditReportWorkerConfiguration} from "../../../../services/qrCodePage.service";
import {Links} from "../../../links";
import {useHistory} from "react-router-dom";
import {StyledCircleButton, StyledSocialButton} from "../../../../components/button/button.style";
import AddPersonImage from "../../../../assets/images/a-add.svg";
import TrashRedImage from "../../../../assets/images/trash-can-red.svg";
import {AuditReportContext} from "../../auditReport.component";
import { loadPlaceOfServicesProfiles, loadPlaceOfServicesProjects } from "../../helpers";
import moment from "moment";
import {AuditType} from "../../../../enums/audit";
import {getProject} from "../../../../services/project.service";
import {Project} from "../../../../models/project";

export interface RemoveWorkerState {
    showAlert: boolean;
    worker?: DataSelectable;
    index?: number;
}

export type AuditReportStep1Props = {
    report: AuditReportFormModel,
    profile?: Profile,
    onChange: (report: AuditReportFormModel) => void,
    onSubmit: () => void,
}

const AuditReportStep1: React.FC<AuditReportStep1Props> = (props: AuditReportStep1Props) => {
    const { t } = useTranslation();

    const history = useHistory();
    const [report, setReport] = useState(props.report)
    const [showPlaceOfServiceModal, updateShowPlaceOfServicesModal] = useState<boolean>(false);
    const [showProjectsModal, updateShowProjectsModal] = useState<boolean>(false);
    const [showProfilesModal, updateShowProfilesModal] = useState<boolean>(false);
    const [showComponentsModal, updateShowComponentsModal] = useState<boolean>(false);
    const [showOrdersModal, updateShowOrdersModal] = useState<boolean>(false);
    const [showSpecificationsModal, updateShowSpecificationsModal] = useState<boolean>(false);
    const [removeWorkerState, updateRemoveWorkerState] = useState({ showAlert: false, worker: undefined, index: undefined } as RemoveWorkerState);
    const [formSubmitted, setFormSubmitted] = useState<any>(false);
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');

    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setFormSubmitted = setFormSubmitted;
    contextAuditReport.getFormSubmitted = () => {
        return formSubmitted;
    };

    const auditContext = useContext(AuditReportContext);

    const loadPlaceOfServices = async (report: AuditReportFormModel, page: number, searchText?: string) => {
        let data:DataSelectable[] = await getPlaceOfServices(page, searchText).then(response => {
            return response.data as DataSelectable[];
        });

        return data;
    }

    const loadOrders = async (report: AuditReportFormModel, page: number, searchText?: string) => {
        if (report.placeOfService && report.project) {
            let data:DataSelectable[] = await getProjectPlaceOfServiceOrders(report.project.id.toString(), report.placeOfService.id.toString(), page, searchText).then((response) => {
                return response.data as DataSelectable[];
            });

            return data;
        } else {
            return [];
        }
    }

    const loadSpecifications = async (report: AuditReportFormModel, page: number, searchText?: string) => {
        if (report.order) {
            let data:DataSelectable[] = await getOrderSpecifications(report.order.id.toString(), page).then((response) => {
                return response.data as DataSelectable[];
            });

            return data;
        } else {
            return [];
        }
    }

    const loadComponentNumbers = async (report: AuditReportFormModel, page: number, searchText?: string) => {
        if (report.order) {
            let data:DataSelectable[] =await getOrderComponentsNumbers(report.order.id.toString(), page, searchText).then((response) => {
                return response.data as DataSelectable[];
            });

            return data;
        } else if (report.placeOfService && report.project) {
            let data:DataSelectable[] = await getProjectPlaceOfServiceComponentsNumbers(report.project.id.toString(), report.placeOfService.id.toString(), page, searchText).then((response) => {
                return response.data as DataSelectable[];
            });

            return data;
        } else {
            return [];
        }
    }

    const loadProject = async (projectId: bigint): Promise<Project> => {
        const response = await getProject(projectId);

        return response.data as Project;
    }

    useEffect(() => {
        props.onChange(report);
    }, [report]);

    useEffect(() => {
        setReport({
            ...report,
            componentNumber: undefined,
            noOrder: false,
            noOrderComment: ''
        })
    }, [report.order]);

    useEffect(() => {
        setReport({
            ...report,
            noComponent: false,
            noComponentComment: ''
        })
    }, [report.componentNumber]);

    const addWorker = () => {
        setAuditReportWorkerConfiguration({
            report: report,
            title: 'auditReport.form.addWorker.title',
            subtitle: 'auditReport.form.addWorker.subtitle',
            manualButtonText: 'auditReport.form.addWorker.manualFind',
            handleManualButton: () => {
                history.replace(Links.auditManualWorkerFind)
            },
            errorText: 'auditReport.form.addWorker.error',
            handleScan: async (val: any) => {
                let workerData:DataSelectable|null = await findWorker(val).then(response => {
                    return response.data as DataSelectable;
                }).catch(reason => {
                    return null;
                });

                if (workerData === null) {
                    return false;
                }

                if (workerData && report.workers) {
                    let workers:DataSelectable[] = report.workers;
                    workers.push(workerData)

                    setReport({
                        ...report,
                        workers: workers
                    });
                }

                history.goBack();
            },
            handleListSelected: worker => {
                if (worker && report.workers) {
                    let workers:DataSelectable[] = report.workers;
                    workers.push(worker)

                    setReport({
                        ...report,
                        workers: workers
                    });
                }

                history.goBack();
            }
        });

        history.push(Links.auditWorkerFind);
    }

    const removeWorker = (worker: DataSelectable, index: number) => {
        if (report.workers) {
            let workers:DataSelectable[] = report.workers;
            workers.splice(index, 1);

            setReport({
                ...report,
                workers: workers
            });
        }
    }

    const onSubmit = () => {
        setFormSubmitted(true);
        if (!validateStep1(report)) {
            setToast(t("reportPage.validation.commonError"));
            setShowToast(true);

            const errorElement = document.querySelector(
                `.hasErrors`
            )

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            props.onSubmit();
        }
    }

    return <>
        <StyledContent className="withFooter">
            <h1 className="title">{t('auditReport.form.header.step1')}</h1>

            <StyledComponentFormContainer className="padding-top">
                <IonGrid className="title">
                    <IonRow>
                        <IonCol size="12" className="label">
                            {t('auditReport.form.person')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="label">
                            <StyledInput readOnly={true} value={props.profile?.name}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" className="label">
                            {t('auditReport.form.shift')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="label">
                            <StyledInput readOnly={true} value={getShitPeriodReadable(props.report.shiftPeriod)}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" className="label required">
                            {t('auditReport.form.placeOfService')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="select" onClick={() => {
                            if (!report.order) {
                                updateShowPlaceOfServicesModal(true)
                            }
                        }}>
                            <StyledInput readOnly={true}
                                         className={auditContext.getFormSubmitted() && !report.placeOfService ? 'hasErrors' : ''}
                                         value={report.placeOfService ? report.placeOfService.name : ''}
                                         placeholder={t("common.select")}/>
                            {!report.order && <img className="down" src={ArrowDownImage}/>}

                            {report.placeOfService && <img className="clear" src={CrossImage} onClick={(e) => {
                                e.stopPropagation();
                                setReport({
                                    ...report,
                                    placeOfService: undefined,
                                    project: undefined,
                                    order: undefined,
                                });
                            }}/>}
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" className="label required">
                            {t('auditReport.form.project')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="select" onClick={() => {
                            if (!report.order && report.placeOfService) {
                                updateShowProjectsModal(true)
                            }
                        }}>
                            <StyledInput readOnly={true}
                                         className={auditContext.getFormSubmitted() && !report.project ? 'hasErrors' : ''}
                                         value={report.project ? report.project.name : ''}
                                         placeholder={t("common.select")}/>
                            {!report.order && report.placeOfService && <img className="down" src={ArrowDownImage}/>}

                            {report.project && <img className="clear" src={CrossImage} onClick={(e) => {
                                e.stopPropagation();
                                setReport({
                                    ...report,
                                    project: undefined,
                                    order: undefined,
                                });
                            }}/>}
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="6" className="label">
                            {t('auditReport.form.order')}
                        </IonCol>
                        <IonCol size="6" className="label">
                            {t('auditReport.form.orderType')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" className="select" onClick={() => {
                            if (report.placeOfService && report.project) {
                                updateShowOrdersModal(true)
                            }
                        }}>
                            <StyledInput readOnly={true}
                                         className={auditContext.getFormSubmitted() && (!report.order && !report.noOrder) ? 'hasErrors' : ''}
                                         value={report.order ? report.order.formatted_number : ''}
                                         placeholder={t("common.select")}/>
                            {report.placeOfService && report.project && <img className="down" src={ArrowDownImage}/>}

                            {report.order && <img className="clear" src={CrossImage} onClick={(e) => {
                                e.stopPropagation();
                                setReport({
                                    ...report,
                                    order: undefined
                                });
                            }}/>}
                        </IonCol>

                        <IonCol size="6" className="label">
                            <StyledInput readOnly={true}
                                         value={report.order ? t(report.order.sub_type_readable).toString() : ''}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" className="label">
                            <IonItem lines="none">
                                <StyledIonLabel
                                    className="ion-text-wrap">{t('auditReport.form.noOrder')}</StyledIonLabel>
                                <StyledIonCheckbox disabled={!!report.order} name="noOrder" checked={report.noOrder}
                                                   slot="start" onIonChange={(e) => {
                                    setReport({
                                        ...report,
                                        noOrder: e.detail.checked,
                                        noOrderComment: ''
                                    });
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    {report.noOrder && <>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.noOrderComment')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledTextarea
                                    onKeyPress={event => {
                                        if (event.key == 'Enter') {
                                            const input = event.target as HTMLInputElement;
                                            input.blur();
                                        }
                                    }}
                                    className={auditContext.getFormSubmitted() && !report.noOrderComment ? 'full-width hasErrors' : 'full-width'}
                                    onChange={(e) => {
                                        setReport({
                                            ...report,
                                            noOrderComment: e.target.value
                                        });
                                    }}
                                    value={report.noOrderComment}
                                    placeholder={t('auditReport.form.enter')}/>
                            </IonCol>
                        </IonRow>
                    </>
                    }

                    <IonRow>
                        <IonCol size="12" className="label">
                            {t('auditReport.form.specification')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="select" onClick={() => {
                            if (!report.specification && report.order) {
                                updateShowSpecificationsModal(true)
                            }
                        }}>
                            <StyledInput readOnly={true} value={report.specification ? report.specification.name : ''}
                                         placeholder={t("common.select")}/>
                            {!report.specification && report.order && <img className="down" src={ArrowDownImage}/>}

                            {report.specification && <img className="clear" src={CrossImage} onClick={(e) => {
                                e.stopPropagation();
                                setReport({
                                    ...report,
                                    specification: undefined
                                });
                            }}/>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" className="label">
                            {t('auditReport.form.componentNumber')}
                        </IonCol>
                        <IonCol size="6" className="label">
                            {t('auditReport.form.componentName')}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6" className="select" onClick={() => {
                            if (report.placeOfService && report.project) {
                                updateShowComponentsModal(true)
                            }
                        }}>
                            <StyledInput readOnly={true}
                                         className={auditContext.getFormSubmitted() && (!report.componentNumber && !report.noComponent) ? 'hasErrors' : ''}
                                         value={report.componentNumber ? report.componentNumber?.name : ''}
                                         placeholder={t("common.select")}/>
                            {report.placeOfService && report.project && <img className="down" src={ArrowDownImage}/>}
                            {report.componentNumber && <img className="clear" src={CrossImage} onClick={(e) => {
                                e.stopPropagation();
                                setReport({
                                    ...report,
                                    componentNumber: undefined
                                });
                            }}/>}
                        </IonCol>
                        <IonCol size="6" className="label">
                            <StyledInput readOnly={true} value={report.order ? report.order.component_name : ''}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" className="label">
                            <IonItem lines="none">
                                <StyledIonLabel
                                    className="ion-text-wrap">{t('auditReport.form.noComponent')}</StyledIonLabel>
                                <StyledIonCheckbox disabled={!!report.componentNumber} name="noOrder"
                                                   checked={report.noComponent} slot="start" onIonChange={(e) => {
                                    setReport({
                                        ...report,
                                        noComponent: e.detail.checked,
                                        noComponentComment: ''
                                    });
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    {report.noComponent && <>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.noComponentComment')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledTextarea
                                    onKeyPress={event => {
                                        if (event.key == 'Enter') {
                                            const input = event.target as HTMLInputElement;
                                            input.blur();
                                        }
                                    }}
                                    className={auditContext.getFormSubmitted() && !report.noComponentComment ? 'full-width hasErrors' : 'full-width'}
                                    onChange={(e) => {
                                        setReport({
                                            ...report,
                                            noComponentComment: e.target.value
                                        });
                                    }}
                                    value={report.noComponentComment}
                                    placeholder={t('auditReport.form.enter')}/>
                            </IonCol>
                        </IonRow>
                    </>
                    }

                    <section className="gap-style">
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.coordinatorOrTeamLeader')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select" onClick={() => {
                                if (report.placeOfService) {
                                    updateShowProfilesModal(true)
                                }
                            }}>
                                <StyledInput readOnly={true}
                                             className={auditContext.getFormSubmitted() && (!report.coordinatorOrTeamLeader && !report.coordinatorOrTeamLeaderOther) ? 'hasErrors' : ''}
                                             value={report.coordinatorOrTeamLeader ? report.coordinatorOrTeamLeader.name : ''}
                                             placeholder={t("common.select")}/>
                                {report.placeOfService && !report.coordinatorOrTeamLeader &&
                                    <img className="down" src={ArrowDownImage}/>}
                                {report.coordinatorOrTeamLeader &&
                                    <img className="clear" src={CrossImage} onClick={(e) => {
                                        e.stopPropagation();
                                        setReport({
                                            ...report,
                                            coordinatorOrTeamLeader: undefined
                                        });
                                    }}/>}
                            </IonCol>
                        </IonRow>
                    </section>
                    <section>
                        <div className="chose-other">
                            <hr></hr>
                            <span className="one-line">{t('auditReport.form.orOther')}</span>
                            <hr></hr>
                        </div>
                        <IonRow>
                            <IonCol size="12" className={!report.coordinatorOrTeamLeader ? "label required" : "label"}>
                                {t('auditReport.form.coordinatorOrTeamLeaderOther')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledInput disabled={!!report.coordinatorOrTeamLeader}
                                             type="text"
                                             value={report.coordinatorOrTeamLeaderOther}
                                             className={auditContext.getFormSubmitted() && (!report.coordinatorOrTeamLeader && !report.coordinatorOrTeamLeaderOther) ? 'hasErrors' : ''}
                                             onChange={(e) => {
                                                 setReport({
                                                     ...report,
                                                     coordinatorOrTeamLeaderOther: e.target.value
                                                 });
                                             }}
                                             placeholder={t('auditReport.form.enter')}/>
                            </IonCol>
                        </IonRow>
                    </section>

                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.date')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="date-field">
                                <DateInput
                                    disabled={true}
                                    presentation="date"
                                    format="DD.MM.YYYY"
                                    value={report.date}
                                    onChange={(value) => {
                                        setReport({
                                            ...report,
                                            date: value
                                        });
                                    }}
                                    cancelText={t("common.dateCancel")}
                                    doneText={t("common.dateDone")}
                                    placeholder={t("common.select")}
                                    max={moment().format('YYYY-MM-DD')}
                                    className={auditContext.getFormSubmitted() && !report.date ? 'hasErrors' : ''}
                                />
                            </IonCol>

                        </IonRow>

                    {report.type !== AuditType.BHP && <>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.workers')}
                            </IonCol>
                        </IonRow>
                        {report.workers?.map((worker, key) =>
                            <IonRow key={'workers_' + key} className="worker-row">
                                <IonCol size="10">
                                    <StyledInput disabled={true} type="text" value={worker.name}/>
                                </IonCol>
                                <IonCol size="2" className="center">
                                    <StyledCircleButton className="white" onClick={() => updateRemoveWorkerState({
                                        showAlert: true,
                                        worker: worker,
                                        index: key
                                    })}><img src={TrashRedImage}/></StyledCircleButton>
                                </IonCol>
                            </IonRow>
                        )}

                        <IonRow>
                            <IonCol>
                                <StyledSocialButton disabled={!report.project || report.noWorkers}
                                                    className="report-button black-text" onClick={() => addWorker()}>
                                    <img className="button-image" src={AddPersonImage}/>
                                    {t('auditReport.form.addWorker.button')}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>

                        {auditContext.getFormSubmitted() && !report.noWorkers && report.workers?.length === 0 &&
                            <p className="errorMessage">{t('auditReport.form.workersRequired')}</p>
                        }

                        <IonRow>
                            <IonCol size="12" className="label">
                                <IonItem lines="none">
                                    <StyledIonLabel
                                        className="ion-text-wrap">{t('auditReport.form.noWorkers')}</StyledIonLabel>
                                    <StyledIonCheckbox disabled={report.workers && report.workers?.length > 0}
                                                       name="noWorkers" checked={report.noWorkers} slot="start"
                                                       onIonChange={(e) => {
                                                           setReport({
                                                               ...report,
                                                               noWorkers: e.detail.checked
                                                           });
                                                       }}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </>}
                </IonGrid>

                {showPlaceOfServiceModal &&
                    <PaginatedListModal search={true} report={props.report} isOpen={showPlaceOfServiceModal}
                                        onFetch={(report: AuditReportFormModel, page: number, searchText?: string) => {
                                            let data = loadPlaceOfServices(report, page, searchText);

                    return data;
                }} onSubmit={(selected) => {
                    setReport({
                        ...report,
                        placeOfService: selected,
                        project: undefined,
                        order: undefined,
                        clientEmails: [],
                        coordinatorEmail: undefined,
                        regionalManagerEmail: undefined
                    });

                    updateShowPlaceOfServicesModal(false);
                }} onClose={() => {
                    updateShowPlaceOfServicesModal(false);
                }} />
            }

            {showProjectsModal && <PaginatedListModal search={true} report={props.report} isOpen={showProjectsModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    let data = loadPlaceOfServicesProjects(report, page, searchText);

                    return data;
                }} onSubmit={async (selected) => {
                    const project = await loadProject(selected.id);

                    setReport({
                        ...report,
                        project: selected,
                        coordinatorEmail: project.coordinator_email,
                        regionalManagerEmail: project.regional_manager_email
                    });

                    updateShowProjectsModal(false);
                }} onClose={() => {
                    updateShowProjectsModal(false);
                }} />
            }

            {showComponentsModal && <PaginatedListModal search={true} report={props.report} isOpen={showComponentsModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    let data = loadComponentNumbers(report, page, searchText);

                    return data;
                }} onSubmit={(selected) => {
                    setReport({
                        ...report,
                        componentNumber: selected,
                        noComponent: false,
                        noComponentComment: ''
                    });

                    updateShowComponentsModal(false);
                }} onClose={() => {
                    updateShowComponentsModal(false);
                }} />
            }

            {showOrdersModal && <PaginatedListModal search={true} report={props.report} isOpen={showOrdersModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    let data = loadOrders(report, page, searchText);

                    return data;
                }} onSubmit={async (selected) => {
                    await findOrder(selected.id, true).then((response) => {
                        let order = response.data as Order;

                        setReport({
                            ...report,
                            order: order,
                        });
                    })

                    updateShowOrdersModal(false);
                }} onClose={() => {
                    updateShowOrdersModal(false);
                }} />
            }

            {showProfilesModal && <PaginatedListModal search={true} report={props.report} isOpen={showProfilesModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    let data = loadPlaceOfServicesProfiles(report, page, searchText);

                    return data;
                }} onSubmit={(selected) => {
                    setReport({
                        ...report,
                        coordinatorOrTeamLeader: selected,
                        coordinatorOrTeamLeaderOther: ''
                    });

                    updateShowProfilesModal(false);
                }} onClose={() => {
                    updateShowProfilesModal(false);
                }} />
            }

            {showSpecificationsModal && <PaginatedListModal search={true} report={props.report} isOpen={showSpecificationsModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    let data = loadSpecifications(report, page, searchText);

                    return data;
                }} onSubmit={async (selected) => {

                    await findSpecification(selected.id).then((response) => {
                        let spec = response.data as OrderSpecification;

                        setReport({
                            ...report,
                            specification: spec
                        });
                    })

                    updateShowSpecificationsModal(false);
                }} onClose={() => {
                    updateShowSpecificationsModal(false);
                }} />
            }
            </StyledComponentFormContainer>

            <StyledStaticFooter className="no-shadow">
                <Form.Button type="submit" onClick={onSubmit}>{t('auditReport.next')}</Form.Button>
            </StyledStaticFooter>
        </StyledContent>

        <IonAlert
            isOpen={removeWorkerState.showAlert}
            onDidDismiss={() => updateRemoveWorkerState({showAlert: false, worker: undefined, index: undefined})}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateRemoveWorkerState({showAlert: false, worker: undefined, index: undefined})
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        if (removeWorkerState.worker !== undefined && removeWorkerState.index !== undefined) {
                            removeWorker(removeWorkerState.worker, removeWorkerState.index);
                        }
                    }
                }
            ]}
        />

        <IonToast
            isOpen={showToast}
            onDidDismiss={() => {
                setShowToast(false);
                setToast('');
            }}
            message={toast}
            duration={6000}
            position="top"
            color="danger"
        />
    </>
}

export default AuditReportStep1;