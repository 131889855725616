import { CreateAnimation, createAnimation, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {StyledActionIonModal, StyledActionSheet, StyledIonModal} from '../../../../components/modal/modal.style';
import { StyledHoursWorkedGrid } from '../../panes/hoursWorked.style';
import { EmployerHoursWorked, MonthHoursWorked, WorkingHour } from '../../panes/hoursWorkedPane.component';
import moment from 'moment';
import CloseImage from '../../../../assets/images/e-remove.svg';
import { StyledHoursWorkedDetailsActionSheetContainer } from './hoursWorkedDetailsActionSheet.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../../components/pane/pane.style';
import { useSwipeable } from 'react-swipeable';
import HoursWorkedHourlyDetailsActionSheet from '../hoursWorkedHourlyDetailsActionSheet/hoursWorkedHourlyDetailsActionSheet.component';
import {minutesToHoursMinutes} from "../../../../utils/tools/time";
import { groupHoursWorkedByDate } from '../../../../utils/tools/hoursWorked';
import ModalService from '../../../../services/modal.service';

type HoursWorkedDetailsActionSheetProps = {
    isVisible: boolean;
    hoursWorked?: MonthHoursWorked;
    onDismiss: () => void;
}

const HoursWorkedetailsActionSheet: React.FC<HoursWorkedDetailsActionSheetProps> = (props: HoursWorkedDetailsActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const [hiding, startHiding] = useState(false);
    const [showDetails, updateShowDetails] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (isVisible !== props.isVisible) {
            if (props.isVisible) {
                ModalService.modalOpened(props.onDismiss);
                startHiding(false);
                changeVisibility(props.isVisible);
            } else {
                ModalService.modalDismissed();
                startHiding(true);
                setTimeout(() => changeVisibility(props.isVisible), 275);
            }
        }
    }, [isVisible, props.isVisible]);

    const [workingHours, setWorkingHours] = useState<WorkingHour[]>([]);
    useEffect(() => {
        if (!props.hoursWorked) {
            return;
        }

        let newWorkingHours: WorkingHour[] = [];

        for (let employer of props.hoursWorked.employers) {
            newWorkingHours = [...newWorkingHours, ...employer.workingHours];
        }

        setWorkingHours(groupHoursWorkedByDate(newWorkingHours));
    }, [props.hoursWorked]);

    const getTotalHours = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + curr.quantity;
        }, 0);

        return sum;
    };

    const getTotal = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + (curr.quantity * curr.rate);
        }, 0);

        return sum;
    };

    const config = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
      };

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            props.onDismiss();
        },
        ...config,
    });

    const [currentDate, setCurrentDate] = useState<Date|undefined>();
    const [hourlyHoursWorked, setHourlyHoursWorked] = useState<MonthHoursWorked>();
    const displayHourlyDetails = (date: Date|undefined = undefined) => {
        if (!props.hoursWorked) {
            return;
        }

		setCurrentDate(date);

        if (date === undefined) {
            setHourlyHoursWorked(props.hoursWorked);
        } else {
            const dateHoursWorked: MonthHoursWorked = {
                month: props.hoursWorked?.month,
                employers: []
            };

            props.hoursWorked?.employers.forEach((employer) => {
                const empl: EmployerHoursWorked = {
                    name: employer.name,
                    workingHours: []
                }

                employer.workingHours.forEach((wh) => {
                    if (moment(wh.date).format('yyyyMMDD') === moment(date).format('yyyyMMDD')) {
                        empl.workingHours.push(wh);
                    }
                });

                if (empl.workingHours.length > 0) {
                    dateHoursWorked.employers.push(empl);
                }
            });

            setHourlyHoursWorked(dateHoursWorked);
        }

        updateShowDetails(true);
    }

    return isVisible ? (
            <StyledIonModal
                isOpen={props.isVisible}
                onDidDismiss={() => props.onDismiss()}
                initialBreakpoint={0.99}
                breakpoints={[0, 0.65, 0.99]}
            >
                <StyledHoursWorkedDetailsActionSheetContainer className={hiding ? 'hide': 't-p-50'} onClick={(e) => e.stopPropagation()}>
                    <img src={CloseImage} className="close" onClick={(e) =>{ props.onDismiss(); e.stopPropagation()} } />
                    <StyledHoursWorkedGrid onClick={() => displayHourlyDetails() }>
                        <div className="title">{t("reportsTab.hoursWorked.detailsView")}</div>
                        <IonGrid>
                            <IonRow>
                                <IonCol className="aligment header" size="8">
                                {t("reportsTab.hoursWorked.date")}
                                </IonCol>
                                <IonCol className="aligment end  header" size="4">
                                {t("reportsTab.hoursWorked.workingHoursCount")}
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="content">
                                { workingHours && workingHours.length > 0 &&
                                    workingHours.map((workingHour, key) =>
                                    <IonRow key={key} onClick={(event) => { displayHourlyDetails(workingHour.date); event.stopPropagation(); } }>
                                        <IonCol className="aligment row" size="8">
                                            {moment(workingHour.date).format('YYYY-MM-DD')}
                                        </IonCol>
                                        <IonCol className="aligment end row value" size="4">
                                            {minutesToHoursMinutes(workingHour.quantity * 60)}
                                        </IonCol>
                                    </IonRow>
                                    )
                                }

							{ workingHours && workingHours.length == 0 &&
								<IonRow>
									<IonCol className="aligment row" size="12">
										{t("reportsTab.hoursWorked.noHours")}
									</IonCol>
								</IonRow>
							}

						</IonGrid>

						<IonGrid>

							{ workingHours && workingHours.length > 0 &&
								<IonRow className="footer">
									<IonCol className="aligment footer" size="8">
									{t("reportsTab.hoursWorked.sum")}
									</IonCol>
									<IonCol className="aligment end footer" size="4">
										{minutesToHoursMinutes(getTotalHours() * 60)}
									</IonCol>
								</IonRow>
							}
						</IonGrid>
                    </StyledHoursWorkedGrid>
                </StyledHoursWorkedDetailsActionSheetContainer>
            <HoursWorkedHourlyDetailsActionSheet isVisible={showDetails} onDismiss={() => updateShowDetails(false)} hoursWorked={hourlyHoursWorked} date={currentDate}></HoursWorkedHourlyDetailsActionSheet>
            </StyledIonModal>
    ) : (<span></span>);
};

export default HoursWorkedetailsActionSheet;
