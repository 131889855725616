import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton} from '../../../components/button/button.style';
import {StyledTextarea} from '../../../components/form/input/textarea.style';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {StyledOption} from '../../../components/options/options.style';
import {RemarkType} from "../../../enums/remarkType";
import {Contract} from "../../../models/contract";
import {StyledModalContent} from "../../../components/modal/modal.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import VectorImage from '../../../assets/images/checkMarkGreen.svg';
import StaticPane from "../../../components/pane/static-pane.component";
import {SearchInput} from "../../../components/form/input/input.style";
import {StyledDataTable} from "../../../components/content/content.style";
import {useLocation} from "react-router-dom";
import {getCitiesData} from "../../../services/profile.service";
import {useIonViewWillEnter, useIonViewWillLeave} from "@ionic/react";
import {Keyboard} from "@capacitor/keyboard";
import {LocationState} from "@ionic/react-router/dist/types/ReactRouter/IonRouter";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type CityModalProps = {
    isOpen: boolean;
    onClose: () => void;
    updateCity: (city: ICity | undefined) => void;
    closeModalCity: (city: ICity | undefined) => void;
};

interface ICity {
    id: string;
    name: string;
    selected: boolean
}

const CityModal: React.FC<CityModalProps> = (props: CityModalProps) => {

    const {t} = useTranslation();
    const [selectedRemark, updateSelectedRemark] = useState<string>('');
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const header = useRef<HTMLIonHeaderElement>(null);

    let allCities: ICity[] = [];
    let debounceTimeout: number;

    const cityLocation = useLocation<string>();
    const [selectedCity, selectCity] = useState(cityLocation.state);
    const [selected, setSelect] = useState<ICity>();
    const [cities, updateCities] = useState<ICity[]>([]);

    const location = useLocation<LocationState>();
    const {toastInit, showToastInit} = location.state || {toastInit: "", showToastInit: false};
    const [showToast, setShowToast] = useState(showToastInit);
    const [toast, setToast] = useState<any>(toastInit);

    const filterInput = useRef<HTMLInputElement>(null);

    const [focus, forceFocus] = useState<boolean>(true);

    const [showCity, updateShowCity] = useState<boolean>(false);

    useIonViewWillEnter(() => {
        forceFocus(true);
    })


    const handleClose = () => {
        updateShowCity(false);
        props.onClose();
        props.closeModalCity(selected);
    };

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };


    const onSearchInputChange = async (keyword: string) => {

        if (keyword.length >= 2) {
            updateManualCity(false);
            if (debounceTimeout) {
                clearTimeout(debounceTimeout)
            }

            debounceTimeout = window.setTimeout(async () => {
                const response = await getCitiesData(keyword)
                    .then(response => {
                        response.data.map((city: any) => {
                            allCities.push({
                                id: city.id,
                                name: city.name,
                                selected: city.name === selectedCity
                            });
                        });
                        updateCities(allCities);
                        return true;
                    })
                    .catch(error => {
                        setToast(t("common.serverErrorMsg"));
                        setShowToast(true);
                        return false;
                    });
            }, 350);
        }
    }

    const onCitySelect = (city: ICity) => {
        selectCity(city.name);
        setSelect(city);

        if (filterInput.current) {
            filterInput.current.value = city.name;
            filterInput.current.focus();
            updateManualCity(true);
        }
    }

    const onSubmit = (city: ICity | undefined) => {
        props.updateCity(city);
        updateShowCity(false);
    }

    useEffect(() => {
        if (props.isOpen) {
            updateShowCity(true);
        }
    }, [props.isOpen]);

    useIonViewWillEnter(() => {
        showKeyboard();
    });

    useIonViewWillLeave(() => {
        Keyboard.hide();
    });

    const showKeyboard = () => {
        Keyboard.show();
    };

    const [manualCity, updateManualCity] = useState<boolean>(false);

    const handleSaveCity = () => {
        props.onClose()
    };

    return (
        <>
            <Modal
                isOpen={showCity}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <StaticPane topEdge={topEdge} paddingBottom={95}>
                        <h6 className="title search-city">{t("cityModal.header")}</h6>
                        <SearchInput ref={filterInput} name="searchInputCity"
                                     placeholder={t("citiesTab.searchPlaceholder")}
                                     onChange={(e) => onSearchInputChange(e.target.value)}></SearchInput>
                        <StyledDataTable className="no-margin">
                            {cities.map((city, i) => <div key={i} className="data-row"
                                                          onClick={() => onCitySelect(city)}>
                                <div className="data-icon-title">{city.name}</div>
                                <div className="data-action">
                                    {city.name === selectedCity && <img src={VectorImage}/>}
                                </div>
                            </div>)
                            }
                        </StyledDataTable>
                    </StaticPane>
                    <StyledFooter className="modal">
                    <StyledButton
                            onClick={() => onSubmit(selected)}
                            disabled={!manualCity}>{t("cityModal.saveButton")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Modal>
        </>

    )
};

export default CityModal;

