import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {App as AppPlugin} from "@capacitor/app";
import {Capacitor} from "@capacitor/core";
import {ConnectionStatus, Network} from "@capacitor/network";
import {getStatus} from "../../services/api.service";
import StatusModal, {StatusModalType} from "./status.component";

enum API_STATE {
    OK = 'OK',
    TIMEOUT = 'connection.apiError.timeout',
    SERVER_ERROR = 'connection.apiError.internalServerError',
}

const StatusConnectionModal: React.FC = () => {
    const {t} = useTranslation();

    const [isNetworkConnection, setIsNetworkConnection] = useState<boolean>(true);
    const [apiState, setApiState] = useState<string>(API_STATE.OK);
    const [isAppActive, setIsAppActive] = useState<boolean>(true);

    useEffect(() => {
        const appStateChangeListener = Capacitor.isNativePlatform()
            ? AppPlugin.addListener("appStateChange", async (state) => setIsAppActive(state.isActive))
            : null;

        return () => {
            appStateChangeListener?.remove();
        };
    }, []);

    useEffect(() => {
        let networkInterval: NodeJS.Timeout | undefined;
        let apiInterval: NodeJS.Timeout | undefined;
        let networkTimeout: NodeJS.Timeout | undefined;
        let apiTimeout: NodeJS.Timeout | undefined;

        if (isAppActive) {
            const initNetworkStatus = async () => {
                const networkStatusListener = Network.addListener("networkStatusChange", handleNetworkStatusChange);
                return () => networkStatusListener.remove();
            };

            initNetworkStatus();

            networkInterval = setInterval(checkNetworkStatus, 10000);
            apiInterval = setInterval(checkApiStatus, 60000);

            networkTimeout = setTimeout(checkNetworkStatus, 2500);
            apiTimeout = setTimeout(checkApiStatus, 5000);
        } else {
            if (networkInterval) clearInterval(networkInterval);
            if (apiInterval) clearInterval(apiInterval);
            if (networkTimeout) clearTimeout(networkTimeout);
            if (apiTimeout) clearTimeout(apiTimeout);
        }

        return () => {
            if (networkInterval) clearInterval(networkInterval);
            if (apiInterval) clearInterval(apiInterval);
            if (networkTimeout) clearTimeout(networkTimeout);
            if (apiTimeout) clearTimeout(apiTimeout);
        };
    }, [isAppActive]);

    const checkNetworkStatus = async () => {
        const status = await Network.getStatus();
        setIsNetworkConnection(status.connected);
    };

    const handleNetworkStatusChange = async (status: ConnectionStatus) => {
        setIsNetworkConnection(status.connected);
    };

    const checkApiStatus = async () => {
        if (isNetworkConnection) {
            try {
                const response = await getStatus();
                setApiState(response.data);
            } catch (error) {
                handleApiError(error);
            }
        }
    };

    const handleApiError = (error: any) => {
        if (error.code === 'ECONNABORTED') {
            setApiState(t(API_STATE.TIMEOUT));
        } else {
            try {
                setApiState(error.response.data);
            } catch (e) {
                setApiState(t(API_STATE.SERVER_ERROR));
            }
        }
    };

    return (
        <StatusModal
            isOpen={!isNetworkConnection || apiState !== API_STATE.OK}
            message={t('connection.apiError.title')}
            description={apiState !== API_STATE.OK ? apiState : ''}
            status={StatusModalType.ERROR}
            onClose={() => setApiState(API_STATE.OK)}
            onRefresh={checkApiStatus}
            allowCloseApp={true}
            maxHeight="99%"
        />
    );
};

export default StatusConnectionModal;
