import {CancelTokenSource} from "axios";

import {travelAllowanceApi} from "@services/api.service";

import {getWorkerId} from "@utils/tools/worker";

const getRideMatrix = async (date?: string, cancelToken?: CancelTokenSource) => {
    const workerId = await getWorkerId();
    const dateParam = date ? `?date=${date}` : '';

    const response: any = await travelAllowanceApi.get(`ride-matrices/worker/${workerId}${dateParam}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getRideMatrixByRideReportId = async (rideReportId: number, cancelToken?: CancelTokenSource) => {
    const workerId = await getWorkerId();

    const response: any = await travelAllowanceApi.get(`ride-matrices/worker/${workerId}/ride-report/${rideReportId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

export {
    getRideMatrix,
    getRideMatrixByRideReportId,
};
