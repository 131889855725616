import React, {useEffect} from 'react';
import QRCode from 'react-qr-code';
import {StyledIonModal} from '../../../../../components/modal/modal.style';
import {StyledPaneBar, StylePaneGrabber} from '../../../../../components/pane/pane.style';
import {StyledContainer} from './qrModal.style';
import {useIonViewDidEnter} from "@ionic/react";
import {appStatusBarUpdate} from "../../../../../utils/tools/statusbar";

type QrModalProps = {
    isOpen: boolean;
    name: string;
    number: string;
    onClose: () => void;
};

const QrModal: React.FC<QrModalProps> = (props: QrModalProps) => {

    return (
        <StyledIonModal
            isOpen={props.isOpen}
            onDidDismiss={() => props.onClose()}
            maxHeight={'80%'}
            initialBreakpoint={0.8}
            breakpoints={[0, 0.8]}
        >
            <StyledContainer className="modal-wrap-with-max">
                <QRCode size={135} value={props.number}></QRCode>
                <span className="name">{props.name}</span>
                <span className="number">{props.number}</span>
            </StyledContainer>
        </StyledIonModal>
    );
};

export default QrModal;
