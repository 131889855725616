import React, {useEffect, useState} from 'react';
import axios from "axios";

import Pane from "@components/pane/pane.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import RidePreviewFormFields from "@app/travelAllowance/ride/components/ridePreviewFormFields.component";

import {StyledTravelAllowanceContent} from "@app/travelAllowance/travelAllowance.style";

import {Ride} from "@models/travelAllowance/ride";

import {getRide} from "@services/travelAllowance/ride.service";

type RidePreviewPaneProps = {
    topEdge?: number,
    rideId: number
}

const RidePreviewPane: React.FC<RidePreviewPaneProps> = ({topEdge, rideId}: RidePreviewPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const [loading, setLoading] = useState<boolean>(false);
    const [ride, setRide] = useState<Ride>();

    useEffect(() => {
        setLoading(true);

        fetchRide()
            .then(() => {
                setLoading(false)
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            cancelToken.cancel();
        };
    }, []);

    const fetchRide = async () => {
        if (rideId !== undefined) {
            const rideData = await getRide(rideId, cancelToken);

            setRide(rideData);
        }
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                <StyledTravelAllowanceContent>
                    {
                        loading && !ride &&
                        <>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                            <MyDataSkeleton></MyDataSkeleton>
                        </>
                    }
                    {
                        !loading && ride && <RidePreviewFormFields ride={ride}/>
                    }
                </StyledTravelAllowanceContent>
            }
        </Pane>
    );
};

export default RidePreviewPane;