import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../../components/modal/modal.component';
import { StyledModalContent } from '../../../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../../../utils/tools/modals";

type PrivacyModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const PrivacyModal: React.FC<PrivacyModalProps> = (props: PrivacyModalProps) => {

    const {t} = useTranslation();

    const [showPrivacy, updateShowPrivacy] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowPrivacy(props.isOpen);
    };

    return (
        <Modal
            isOpen={showPrivacy}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleCloseRemarks()}>
            <StyledModalContent>
                <h1 className="title">{t("settingsTab.privacyModal.title")}</h1>
                <div className="content full-height">
                    {t("settingsTab.privacyModal.content")}
                </div>
            </StyledModalContent>
        </Modal>
    );
};

export default PrivacyModal;