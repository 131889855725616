import { appApi, websiteApi } from "./api.service";
import {hasUnsignedContracts} from "./contract.service";
import {Links} from "../app/links";

let endpointPrefix = 'user/profile';

const getProfile = async (skipAuthRefresh: boolean = false) => {
    return appApi.get(endpointPrefix, <any>{
		skipAuthRefresh: skipAuthRefresh,
		headers: {
			'No-Loader': true
		}
	});
}

const getCities = async(keyword: string) => {
	return appApi.get('user/cities?keyword=' + keyword + '&unique=1', {
		headers: {
			'No-Loader': true
		}
	});
}

export const getCitiesData = async(keyword: string) => {
	return appApi.get('user/cities?keyword=' + keyword + '&unique=1&char=2&format=1', {
		headers: {
			'No-Loader': true
		}
	});
}

const updateInlineFieldValue = async (field: string, value: string) => {
	return appApi.post(endpointPrefix + '/' + field + '/update', {
		[field]: value,
	});
}

const updateAcceptanceFieldValue = async (action: string, field: string, value: boolean) => {
	return appApi.post('user/' + action + '/update', {
		[field]: value,
	});
}

const updateLanguages = async(languages:string[], levels:string[]) => {
	let data = { languages: languages, levels: levels };
	return appApi.post(endpointPrefix + '/languages/update', data);
}

const updateCourses = async(courses:string[], details:string[]) => {
	let data = { courses: courses, details: details };
	return appApi.post(endpointPrefix + '/courses/update', data);
}

const updateCity = async(city: string) => {
	let data = { city: city };
	return appApi.post(endpointPrefix + '/city/update', data);
}

const updateLang = async(lang: string) => {
	let data = { lang: lang };
	return appApi.post(endpointPrefix + '/language/update', data);
}

const generateBotToken = async() => {
	return appApi.post( 'user/generate-bot-token', {});
}

const generateBenefitsToken = async() => {
	return appApi.post( 'user/generate-benefits-token', {});
}

const uploadPhoto = async(photo: Blob, refresh: boolean) => {
	let formData = new FormData();
	formData.append("photo", photo);

	return appApi.post(endpointPrefix + '/photo/upload', formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		}
	});
}

const getRedirectAfterLogin = async (number: string|null) => {
	let redirect = null;
	if (number) {
		let hasUnsigned = await hasUnsignedContracts();
		if (hasUnsigned) {
			redirect = Links.main + Links.contracts;
		} else {
			redirect = Links.main + Links.availability;
		}
	} else {
		redirect = Links.main + Links.profile;
	}

	return redirect;
}

const getRoles = async () => {
	return appApi.get(endpointPrefix + '/roles');
}

export const removeUser = async () => {
	return appApi.delete( 'user/remove');
}

export {
	getProfile,
	getCities,
	updateInlineFieldValue,
	updateAcceptanceFieldValue,
	updateLanguages,
	updateCourses,
	updateCity,
	uploadPhoto,
	getRedirectAfterLogin,
	getRoles,
	updateLang,
	generateBotToken,
	generateBenefitsToken
};
