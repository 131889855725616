import React, {useState} from 'react';
import {IonItem, IonToast} from '@ionic/react';
import {useForm} from 'react-hook-form';
import {FORM_FIELDS, FormFieldsType} from './createAccountForm.types';
import Form from '../../../components/form';
import {useHistory} from 'react-router-dom';
import {validateEmail, validatePassword} from '../../../services/validation.service';
import {createAccount} from '../../../services/auth.service';
import {useTranslation} from 'react-i18next';
import {StyledIonCheckbox} from '../../../components/form/input/checkbox.style';
import {StyledIonLabel} from '../../../components/form/input/label.style';
import {Preferences} from "@capacitor/preferences";
import LinkLabel from '../../../components/form/input/label.component';
import StatusModal, {StatusModalType} from "../../../modals/status/status.component";
import {ImageTypes} from "../../../components/circleImage/circleImage.component";

const CreateAccountForm: React.FC = () => {
    const {t, i18n} = useTranslation();
    const {register, handleSubmit, reset, watch, errors, formState} = useForm({
        mode: 'onBlur'
    });
    const history = useHistory();
    const {isValid, isDirty} = formState;

    const [allChecked, setAllChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [workerChecked, setWorkerChecked] = useState(false);
    // const [policyChecked, setPolicyChecked] = useState(false);
    const [emailMarketingChecked, setEmailMarketingChecked] = useState(false);
    const [phoneMarketingChecked, setPhoneMarketingChecked] = useState(false);

    const [emailMarketingShowDesc, setEmailMarketingShowDesc] = useState(false);
    const [phoneMarketingShowDesc, setPhoneMarketingShowDesc] = useState(false);

    const watchEmail = watch(FORM_FIELDS.EMAIL, '');
    const watchPassword = watch(FORM_FIELDS.PASSWORD, '');

    const disableButtonCondition: boolean = (watchEmail == '' || watchPassword == '' || !termsChecked);

    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [successToast, setSuccessToast] = useState<any>('');

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const onSubmit = async (data: FormFieldsType) => {
        if (isValid) {
            data.terms = termsChecked;
            // data.policy = policyChecked;
            data.mail = emailMarketingChecked;
            data.phone = phoneMarketingChecked;

            const response = await createAccount(data, i18n.language)
                .then(response => {
                    if (response.data.result === 'error' && response.data.message === 'domain_user_not_allowed') {
                        setToast(t("createAccount.badCredentialsDomainUserError"));
                        setShowToast(true);
                    } else {
                        if (workerChecked) {
                            Preferences.set({'key': 'register_already_worker', 'value': '1'});
                        }

                        setSuccessToast(t("createAccount.successEmailCheck"));
                        setShowSuccessToast(true);
                        return true;
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        try {
                            let message = error.response.data.errors.children.email.errors[0];
                            if (message === "This value is already used.") {
                                setToast(t("createAccount.userExistsError"));
                            } else if (message === "This value is not valid.") {
                                setToast(t("createAccount.badCredentialsError"));
                            } else {
                                setToast(message);
                            }
                        } catch (e) {
                            setToast(t("createAccount.badCredentialsError"));
                        }
                    } else {
                        setToast(t("common.serverErrorMsg"));
                    }

                    setShowToast(true);
                });

            if (response === true) {
                reset();
                history.push('/login', {
                    toastInit: toast,
                    showToastInit: true
                });
            }
        }
    }

    let invalidEmailError = t("createAccount.invalidEmail");
    let invalidPasswordError = t("createAccount.invalidPassword");

    return (
        <Form.Container onSubmit={handleSubmit(onSubmit)}>
                <StatusModal
                    isOpen={showSuccessToast}
                    buttonText="Zamknij"
                    message={successToast}
                    status={StatusModalType.SUCCESS}
                    image={ImageTypes.LETTER}
                    onClose={() => {
                        setShowSuccessToast(false);
                        setSuccessToast('');
                    }}
                />

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <Form.Input name={FORM_FIELDS.EMAIL}
                        ref={register({
                            required: true,
                            validate: value =>
                                validateEmail(value) || invalidEmailError
                        })}
                        placeholder={t("createAccount.inputEmailPlaceholder")}
                        className={errors.email && 'hasErrors'}
                        type="email"
            />
            {errors.email && errors.email.message && <p className="errorMessage">{errors.email.message}</p>}
            <Form.PasswordInput name={FORM_FIELDS.PASSWORD}
                                ref={register({
                                    required: true,
                                    validate: value =>
                                        validatePassword(value) || invalidPasswordError
                                })}
                                placeholder={t("createAccount.inputPasswordPlaceholder")}
                                className={errors.password && 'hasErrors'}
            />
            {errors.password && errors.password.message && <p className="errorMessage">{errors.password.message}</p>}

            <IonItem lines="none">
                <StyledIonLabel>{t("createAccount.alreadyWorker")}</StyledIonLabel>
                <StyledIonCheckbox slot="start" checked={workerChecked}
                                   onIonChange={e => setWorkerChecked(e.detail.checked)}/>
            </IonItem>

            <IonItem lines="none">
                <StyledIonLabel>{t("createAccount.acceptAll")}</StyledIonLabel>
                <StyledIonCheckbox slot="start" checked={allChecked}
                                   onIonChange={e => {
                                       setAllChecked(e.detail.checked)
                                       // setPolicyChecked(e.detail.checked)
                                       setTermsChecked(e.detail.checked)
                                       setEmailMarketingChecked(e.detail.checked)
                                       setPhoneMarketingChecked(e.detail.checked)
                                   }}/>
            </IonItem>
            <LinkLabel urlString={t("createAccount.acceptTermsPolicyURL")} url="https://exactsystems.pl/gdpr-file/EP-polityka_prywatnosci.htm" external={true}>
                <LinkLabel urlString={t("createAccount.acceptTermsURL")} url="https://exactsystems.pl/gdpr-file/Warunki-Uzytkowania-Aplikacji-Exact-People.htm" external={true}>
                    <IonItem lines="none">
                        <StyledIonLabel className="required">{t("createAccount.acceptTerms")}</StyledIonLabel>
                        <StyledIonCheckbox slot="start" checked={termsChecked}
                                           onIonChange={e => setTermsChecked(e.detail.checked)}/>
                    </IonItem>
                </LinkLabel>
            </LinkLabel>

            {/*<LinkLabel urlString={t("createAccount.acceptPolicyURL")} url="https://exactsystems.pl/gdpr-file/Regulamin-Uslugi-Ofert-Zatrudnienia.htm" external={true}>*/}
            {/*    <IonItem lines="none">*/}
            {/*        <StyledIonLabel>{t("createAccount.acceptPolicy")}</StyledIonLabel>*/}
            {/*        <StyledIonCheckbox slot="start" checked={policyChecked}*/}
            {/*                           onIonChange={e => setPolicyChecked(e.detail.checked)}/>*/}
            {/*    </IonItem>*/}
            {/*</LinkLabel>*/}

            <IonItem lines="none">
                <StyledIonLabel>{t("createAccount.acceptMailMarketing")}</StyledIonLabel>
                <StyledIonCheckbox slot="start" checked={emailMarketingChecked}
                                   onIonChange={e => setEmailMarketingChecked(e.detail.checked)}/>
            </IonItem>

            <>
                { !emailMarketingShowDesc &&
                    <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                }
                { emailMarketingShowDesc &&
                    <LinkLabel urlString={t("createAccount.acceptMailMarketingDescURL")}
                               url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                        <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc1")}</p>
                        <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc2")}</p>
                    </LinkLabel>
                }
                { emailMarketingShowDesc &&
                    <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                }
            </>

            <IonItem lines="none">
                <StyledIonLabel>{t("createAccount.acceptPhoneMarketing")}</StyledIonLabel>
                <StyledIonCheckbox slot="start" checked={phoneMarketingChecked}
                                   onIonChange={e => setPhoneMarketingChecked(e.detail.checked)}/>
            </IonItem>

            <>
                { !phoneMarketingShowDesc &&
                    <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                }

                { phoneMarketingShowDesc &&
                    <LinkLabel urlString={t("createAccount.acceptPhoneMarketingDescURL")}
                               url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                        <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc1")}</p>
                        <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc2")}</p>
                    </LinkLabel>
                }

                { phoneMarketingShowDesc &&
                    <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                }
            </>

            <Form.Button type="submit" disabled={disableButtonCondition}>{t("createAccount.button")}</Form.Button>
        </Form.Container>
    )
}

export default CreateAccountForm;
