import React, {useEffect, useRef, useState} from 'react';
import {IonSearchbar} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import {StyledIonModal, StyledModalContent} from "@components/modal/modal.style";
import {StyledDataTable} from "@components/content/content.style";
import LoadingSkeleton from "@components/loading/loadingSkeleton.component";
import Form from '@components/form';

import {ReactComponent as CloseIcon} from "@assets/images/e-remove.svg";
import PaginatedListManualModal from "@components/modal/paginatedListManualModal.component";

export type DataType = {
    id: number,
    name: string,
    [key: string]: any,
}

type PaginatedListModalProps = {
    isOpen: boolean,
    onClose: () => void,
    fetchData?: (...params: any) => Promise<any[]>,
    fetchParams?: any,
    setValue: (value: any) => void,
    allowManual?: boolean,
}

const PaginatedListModal: React.FC<PaginatedListModalProps> = ({isOpen, onClose, fetchData, fetchParams, setValue, allowManual}) => {
    const {t} = useTranslation();

    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [list, setList] = useState<DataType[] | undefined>();
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);
    const [showManualModal, setShowManualModal] = useState<boolean>(false);

    const searchbar = useRef<HTMLIonSearchbarElement>(null);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const onSubmit = () => {
        if (searchbar && searchbar.current?.value) {
            setSearchValue(searchbar.current.value);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchList();
        }
    }, [isOpen]);

    useEffect(() => {
        setPage(1);

        if (searchValue !== undefined) {
            fetchList(true, false, 1);
        }
    }, [searchValue])

    const fetchList = async (forceReload: boolean = false, append: boolean = false, page: number = 1) => {
        if (!append) {
            setLoading(true)
        } else {
            setReloading(true);
        }

        if (list === undefined || forceReload) {
            if (!append) {
                setList([]);
            }

            if (fetchData) {
                const params = {
                    ...fetchParams,
                    page,
                    searchValue
                }

                fetchData(...Object.values(params)).then(data => {
                    if (append) {
                        if (list) {
                            if (data && data.length === 0) {
                                setLoadMoreDisabled(true);
                            } else {
                                setLoadMoreDisabled(false);
                            }
                            setList([...list, ...data]);
                        }
                        setReloading(false);
                    } else {
                        setLoadMoreDisabled(false);
                        setList(data);
                    }

                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                    setReloading(false)
                })
            }
        }
    }

    const handleItemSelect = (itemValue: DataType) => {
        setValue(itemValue);
        setShowManualModal(false);
        onClose();
    };

    const handleClose = () => {
        setSearchValue(undefined);
        setList(undefined);
        onClose();
    };

    const loadMoreList = async () => {
        if (list && list.length > 0) {
            let currentPage = page + 1;
            setPage(page + 1);

            await fetchList(true, true, currentPage);
        }
    };

    return (
        <StyledIonModal
            isOpen={isOpen}
            onDidDismiss={() => handleClose()}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
        >
            <PaginatedListManualModal
                isOpen={showManualModal}
                onClose={() => setShowManualModal(false)}
                setValue={handleItemSelect}
            />
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent className="modal-wrap t-p-50 scrollable">
                    <CloseIcon className="close" onClick={() => handleClose()}/>
                    <div style={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}}>
                        <IonSearchbar
                            className={'listSearch'}
                            debounce={500}
                            onIonInput={e => setSearchValue(e.detail.value!)}
                            placeholder={t('listModal.search')}
                        />
                    </div>

                    {loading && <LoadingSkeleton includeAvatar={false}/>}

                    {!loading && <StyledDataTable className="no-margin">
                        {allowManual &&
                            <div className="data-row" onClick={() => setShowManualModal(true)}>
                                <div className="data-icon-title blue">{t('listModal.manualInput')}</div>
                            </div>
                        }
                        {list && list.map((data, i) =>
                            <div key={i}
                                 className="data-row"
                                 onClick={() => handleItemSelect(data)}
                            >
                                <div className="data-icon-title">{data.name}</div>
                            </div>
                        )
                        }
                    </StyledDataTable>}

                    {reloading && <LoadingSkeleton includeAvatar={false}/>}

                    {!loading && list && list.length > 49 && !loadMoreDisabled &&
                        <StyledDataTable className="no-margin">
                            <div className="data-row"
                                 onClick={async () => await loadMoreList()}
                            >
                                <div className="data-icon-title blue">{t('listModal.loadMore')}</div>
                            </div>
                        </StyledDataTable>
                    }
                </StyledModalContent>
            </Form.Container>
        </StyledIonModal>
    );
};

export default React.memo(PaginatedListModal);