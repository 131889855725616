import styled from "styled-components";

export const StyledReadMore = styled.span`
  display: inline-block;
`;

export const StyledAdditionalDocument = styled.div`
  border-bottom: 1px solid #F6F6F8;
  padding: 12px 0;

  display: flex;
  flex-direction: row;

  position: relative;

  .content {
    width: 100%;
    max-width: calc(100% - 5px);
    padding: 5px 0px;

    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;

      color: #000000;
    }

    .description {
      padding-top: 6px;
      font-size: 12px;
      line-height: 140%;

      color: #888A98;
    }
  }

  button.action-button {
    min-height: 26px;
    background: #0085FF;
    border-radius: 32px;

    padding: 5px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #FFFFFF;

    float: right;
  }
`;