import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import Pane from "../../../components/pane/pane.component";

type ContractAgreementModalProps = {
    isOpen: boolean;
    contract: Contract | undefined;
    onReject: () => void;
    onSign: () => void;
    onClose: () => void;
};

const ContractAgreementModal: React.FC<ContractAgreementModalProps> = (props: ContractAgreementModalProps) => {

    const {t} = useTranslation();

    const [showModal, updateShowModal] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleReject = () => {
        updateShowModal(true);
        props.onReject();
    };

    const handleSign = () => {
        updateShowModal(false);
        props.onSign();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    return (
        <Modal
            isOpen={showModal}
            canDismiss={false}
            onDidDismiss={() => props.onClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(handleSign)}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.contractAgreement.title")}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.agreement_content || ''}}/>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledWiteButton className="red" type="button" onClick={handleReject}>{t("contractPage.contractAgreement.reject")}</StyledWiteButton>
                        <StyledButton type="submit">{t("contractPage.contractAgreement.accept")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default ContractAgreementModal;