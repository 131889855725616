import React, {useEffect, useState} from 'react';

import {RideReport} from "@models/travelAllowance/rideReport";
import Pane from '@components/pane/pane.component';
import {getRideDocumentContentByType} from "@services/travelAllowance/rideDocument.service";
import {RideDocumentType} from "@enums/travelAllowance/rideDocument";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";

type RideDocumentPaneProps = {
    header: React.RefObject<HTMLIonHeaderElement>,
    report: RideReport,
    type: RideDocumentType,
}

const RideDocumentPane: React.FC<RideDocumentPaneProps> = ({header, report, type}: RideDocumentPaneProps) => {
    const [topEdge, setTopEdge] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [content, setContent] = useState<string | undefined>(undefined);

    useEffect(() => {
        setLoading(true);

        fetchRideDocumentContent()
            .then(() => {
                setLoading(false);
            });
    }, [type]);

    useEffect(() => {
        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header) {
            if (header.current?.clientHeight === 0) {
                setTimeout(updateHeight);
            } else {
                setTopEdge(header.current?.clientHeight);
            }
        }
    };

    const fetchRideDocumentContent = async () => {
        const rideDocumentContent = await getRideDocumentContentByType(report.id, type);

        setContent(rideDocumentContent);
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                loading && <MyDataSkeleton/>
            }
            <div style={{overflowY: 'auto'}}>
                {
                    !loading && content && <div dangerouslySetInnerHTML={{__html: content}}/>
                }
            </div>
        </Pane>
    );
};

export default RideDocumentPane;
