import styled from 'styled-components';

// Move empty data to separate file.
export const StyledNoWallets = styled.div`
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 16px;

  img {
    width: 108px;
    height: 150px;
  }

  img.reports {
    width: 24px;
    height: 24px;
    position: relative;
    top: -100px;
  }

  span.message {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }
`;

export const StyledWalletGrid = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  padding: 10px;
  width: 100%;

  ion-grid {
    &.content {
      max-height: 300px;
      overflow-y: auto;
    }

    ion-row {
      border-bottom: 1px solid #E6E5EB;
      min-height: 40px;
      padding-right: 0;
      padding-left: 0;

      &.footer {
        border: none;
      }
    }
  }

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;

    margin: 24px 15px;
    margin-top: 5;
  }
    
  &.wallet .title {
    margin: 24px 15px 10px 10px;
  } 
  
  .required {
    display: contents;
    color: #F43030;
  }
  
  .subtitle {
    font-size: 14px;

    color: #888A98;

    margin: -15px 15px 12px 15px;
    margin-top: 5;
  }

  .aligment {
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    display: flex;

    &.end {
      justify-content: flex-end;
      text-align: right;
    }
  }

  .header {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.24px;

    color: #888A98;
  }

  .row {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: #000000;

    &.value {
      font-weight: 600;
    }
    
    &.long-text {
    	word-break: break-all;
    }
  }

  .footer {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: #0085FF;

    &.black {
      color: #000000;
    }
  }
`;

export const StyledWalletOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #B5B7C6;
  margin-bottom: 25px;

    div {
      display: inline-block;

      &.selected {
        font-weight: 600;
        color: #000000;
      }
    }
  }
`;

export const StyledDisclaimer = styled.div`
  font-size: 12px;
  color: #F43030;
  margin-top: 5px;
`;