import styled from 'styled-components';

export type PaneProps = {
  topEdge?: number;
  marginTop?: number;
  paddingBottom?: number;
  hideGrabber?: boolean;
  scrollable?: boolean;
  background?: string;
  hasForm?: boolean;
}

export const StylePaneGrabber = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 32px;
  background: #ffffff;

  &.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 20px 20px 0 0;
    padding-top: 36px;
    z-index: 30;
  }
`;

export const StyledPaneBar = styled.div`
    width: 120px;
    height: 4px;
    background: #B5B7C6;
    opacity: 0.25;
    border-radius: 4px;
    margin: auto;
`;

export const StyledPaneContent = styled.div<PaneProps>`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 0 24px;
  padding-top: ${props => props.hideGrabber ? '0' : '40px'};
  padding-bottom: ${props => props.paddingBottom !== undefined ? (props.paddingBottom + 'px') : 'unset'};

  &.expanding {
    overflow-y: hidden;
  }

  &.open {
    overflow-y: scroll !important;
  }
`;

export const StyledStaticPaneContent = styled.div<PaneProps>`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - ${props => (props.topEdge !== undefined) ? ((props.topEdge + (props.marginTop ?? 40) + 'px') ) : '40px'});
  flex-grow: 1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0 24px;
  background: #ffffff;
  margin-top: ${props => (props.marginTop !== undefined) ? (props.marginTop + 'px') : '40px'};
  padding-top: ${props => props.hideGrabber ? '0' : (props.hasForm === true ? '20px' : '40px')};
  padding-bottom: ${props => props.paddingBottom !== undefined ? (props.paddingBottom + 'px') : 'unset'};
  bottom: 0;
  position: absolute;

  .modalForm {
    height: 100%;
  }
`;

export const StyledFooter = styled.div`
  bottom: 0;
  background: #FFFFFF;
  position: fixed;
  min-height: 100px;
  width: 100%;
  left: 0;
  box-shadow: 0px 16px 100px rgba(181, 183, 198, 0.6);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  padding: 0 16px;
  z-index: 12;
  -webkit-backface-visibility: hidden;

  &.no-shadow {
    box-shadow: none;
  }

  &.modal {
    position: absolute;
  }

  button:nth-child(1), button:nth-child(2) {
    margin-right: 7.5px;
    margin-top: 0px !important;
  }

  ion-grid, ion-row {
    padding-left: 0;
    padding-right: 0;
  }
  
  // &:not(.modal) button {
  //   margin-top: 0px;
  // }
`;

export const StyledStaticFooter = styled.div`
  padding-bottom: 40px;
`;

export const StyledPaneFooterOption = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 140%;
  text-align: center;
  color: #888A98;
  max-width: 71px;
  align-items: center;
  height: 52px;
  display: flex;
  flex-direction: column;

  img {
    width: 16px;
    height: 16px;
    margin-bottom: 10px;
  }
`;