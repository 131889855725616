import styled from 'styled-components';

export const StyledCalendarContainer = styled.div`
  align-items: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ion-grid {
    padding: 0;
  }

  .calendar-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .day {
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.24px;
      text-align: center;
      color: #000000;
      margin: 3px 0;
      display: inline-flex;
      width: calc(((100vw - 38px) / 7) - 7vw);
      height: calc(((100vw - 38px) / 7) - 7vw);
      align-items: center;
      justify-content: center;
      border-radius: calc(100vw / 7);

      &.current {
        background: #007AFF;
        color: #ffffff;
      }
    }

    .task-indicator {
      display: flex;
      justify-content: flex-end;
      height: 6px;
      width: 100%;

      .indicator {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #F43030;
      }
    }

    img {
      width: 100%;
      padding: 0 3px;
    }
  }

`;