import React, {useEffect, useRef, useState} from "react";
import {IonButtons, IonHeader, IonPage, IonToast} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import {StyledHeaderButton, StyledHeaderButtonImage} from "../../components/button/button.style";
import ArrowLeftImage from "../../assets/images/arrow-left.svg";
import {FormFieldsType} from "../becameTeamMemberPage/becameTeamMember.types";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Form from '../../components/form';
import StaticPane from "../../components/pane/static-pane.component";
import {useForm} from "react-hook-form";
import {validatePassword} from "../../services/validation.service";
import {StyledField} from "../../components/form/input/input.style";
import {StyledFooter} from "../../components/pane/pane.style";
import {changePassword} from "../../services/auth.service";
import {Preferences} from "@capacitor/preferences";

const ChangePassword: React.FC = () => {

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [allowedToChange, setAllowedToChange] = useState<boolean>(true);
    const [toast, setToast] = useState<string>('');
    const [toastColor, setToastColor] = useState<string>('danger');
    const header = useRef<HTMLIonHeaderElement>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { register, handleSubmit, setError, errors, watch, formState, getValues, clearErrors } = useForm(
        {
            mode: 'onChange'
        }
    );

    const {isValid} = formState;

    let invalidPasswordError = t("createAccount.invalidPassword");
    let passwordNotMatchError = t("createAccount.passwordNotMatch");
    const password = watch("password", "");
    const passwordRepeated = watch("password_repeated", "");

    useEffect(() => {
        updateHeight();
        fetchAllowedToChangePassword();
    }, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };
    const handleBack = () => {
        history.goBack();
    };

    const fetchAllowedToChangePassword = async () => {
        let allowed = await Preferences.get({
            'key': 'allowed_to_change_password',
        });

        setAllowedToChange(allowed.value != '0');
    };

    const onSubmit = async (data: any) => {
        if (passwordRepeated !== password) {
            setError('password_repeated', { type: 'custom', message: passwordNotMatchError });
        } else {
            await changePassword(data.currentPassword, data.password)
                .then((response) => {
                    setToastColor('success')
                    setToast(t('changePassword.success'));
                }).catch((reason) => {
                    if (reason.response.data.message == 'Validation Failed') {
                        setToast(passwordNotMatchError);
                        setToastColor('danger')
                    }
                })
        }
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("changePassword.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={105} hasForm={true}>
                    {allowedToChange &&
                        <Form.Container onSubmit={handleSubmit(onSubmit)}>
                            <StyledField>
                                <label className='required'>{ t('changePassword.fields.currentPassword') }</label>
                                <Form.PasswordInput name="currentPassword"
                                                    ref={register({
                                                        required: true
                                                    })}
                                                    className={errors.currentPassword && 'hasErrors'}
                                />
                                {errors.currentPassword && errors.currentPassword.message && <p className="errorMessage">{errors.currentPassword.message}</p>}
                            </StyledField>

                            <StyledField>
                                <label className='required'>{ t('changePassword.fields.password') }</label>
                                <Form.PasswordInput name="password"
                                                    ref={register({
                                                        required: true,
                                                        validate: {
                                                            pass: value => validatePassword(value) || invalidPasswordError,
                                                        }
                                                    })}
                                                    className={errors.password && 'hasErrors'}
                                                    onChange={() => {
                                                        clearErrors('password_repeated')
                                                    }}
                                />
                                {errors.password && errors.password.message && <p className="errorMessage">{errors.password.message}</p>}
                            </StyledField>
                            <StyledField>
                                <label className='required'>{ t('changePassword.fields.password_repeated') }</label>
                                <Form.PasswordInput name="password_repeated"
                                                    ref={register({
                                                        required: true,
                                                        validate: {
                                                            notMatch: value => value === password || passwordNotMatchError
                                                        }
                                                    })}
                                                    className={errors.password_repeated && 'hasErrors'}
                                />
                                {errors.password_repeated && errors.password_repeated.message && <p className="errorMessage">{errors.password_repeated.message}</p>}
                            </StyledField>

                            <StyledFooter className="footer animated-footer no-shadow">
                                <Form.Button type="submit" disabled={!isValid}>{t("changePassword.submit")}</Form.Button>
                            </StyledFooter>
                        </Form.Container>
                    }
                    {!allowedToChange &&
                        <p>{t("changePassword.notAllowedToChange")}</p>
                    }
                </StaticPane>
            </StyledIonContent>
            <IonToast
                isOpen={!!toast}
                onDidDismiss={() => {
                    setToast('');
                    if (toastColor == 'success') {
                        history.goBack();
                    }
                }}
                message={toast}
                duration={toastColor == 'danger' ? 6000 : 2000}
                position="top"
                color={toastColor}
            />
        </IonPage>
    )
}

export default ChangePassword;