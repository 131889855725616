import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton, StyledWiteButton} from '../../../components/button/button.style';
import {StyledIonModal, StyledModalContent} from "../../../components/modal/modal.style";
import {StyledFooter} from "../../../components/pane/pane.style";

type PreviewModalProps = {
    isOpen: boolean;
    preview: string;
    onClose: () => void;
    onSave: () => void;
    onNewPage: () => void;
};

const PreviewModal: React.FC<PreviewModalProps> = (props: PreviewModalProps) => {

    const {t} = useTranslation();

    const [showPreview, updateShowPreview] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    const handleOpen = () => {
        updateShowPreview(props.isOpen);
    };

    useEffect(() => {
        handleOpen();
    });

    return (
        <StyledIonModal
            isOpen={showPreview}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
            onDidDismiss={() => handleClose()}>

            <StyledModalContent className="modal-wrap hidden">
                <h1 className="title">{t("folderPage.preview.addFile")}</h1>

                {props.preview && <img className="preview content" src={'data:image/jpeg;base64,' + props.preview}/>}

                <StyledFooter className="modal footer no-shadow">
                    <StyledWiteButton
                        onClick={() => props.onSave()}>{t("folderPage.preview.saveAndClose")}</StyledWiteButton>
                    <StyledButton
                        onClick={() => props.onNewPage()}>{t("folderPage.preview.addNewPage")}</StyledButton>
                </StyledFooter>
            </StyledModalContent>

        </StyledIonModal>
    );
};

export default PreviewModal;