import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import {documentsStatementPdf} from "../../../services/contract.service";
import {blobToBase64} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
// import {FileOpener} from "@ionic-native/file-opener";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledLightBlueButton} from "../../../components/button/button.style";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type DocumentsStatementPreviewModalProps = {
    isOpen: boolean;
    contract?: Contract,
    onClose: () => void;
};

const DocumentsStatementPreviewModal: React.FC<DocumentsStatementPreviewModalProps> = (props: DocumentsStatementPreviewModalProps) => {

    const {t} = useTranslation();

    const [show, updateShow] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    const openPdf = async () => {
        if (props.contract?.id) {
            const info = await Device.getInfo();
            let permissions = true;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                permissions = hasPermissions.publicStorage === 'denied';
            }

            if (!permissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await documentsStatementPdf(props.contract?.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - ' + t("contractPage.documentsStatementPreview.title") + '.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch(e) {

                    }
                }
            }
        }
    }

    return (
        <Modal
            isOpen={show}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.documentsStatementPreview.title")}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.documents_statement_content || ''}}/>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledLightBlueButton onClick={() => openPdf()}>{t("contractPage.documentsStatementPreview.download")}</StyledLightBlueButton>
                    </StyledFooter>
                </StyledModalContent>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default DocumentsStatementPreviewModal;