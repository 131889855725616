import styled from "styled-components";

export const StyledContent = styled.div`
    div.logo {
        text-align: center;
        margin-top: 20px;
    }
   
    .results-test-1 {
        display: flex;
        justify-content: space-around;
        margin: 15px 0 30px 0;
        row-gap: 20px;
        column-gap: 1em;
        flex-wrap: wrap;

        .item {
          width: 30px;
        }
      
        .test-number {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          padding-top: 15px;
        }
    }

    .results-test-1-series {
      text-align: center;
      margin: 10px 0;
    }
  
    .results-test-2-series {
      text-align: center; 
      margin: 25px 0;
    }
  
    .results-test-2 {
        display: flex;
        justify-content: space-around;
        margin: 15px 0 30px 0;
        row-gap: 20px;
        column-gap: 1em;
        flex-wrap: wrap;
    
        .item {
            width: 30px;
        }
      
        .test-number {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding-top: 15px;
        }
    }
  
    .title {
        text-align: left;
        
        &.full {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0 !important;
        }
      
        &.center {
          text-align: center;
          width: 100% !important;
        }
    }

    .justify {
      text-align: justify;
    }
`