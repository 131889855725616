import styled from 'styled-components';
import {ContractStatus} from "../../enums/contractStatus";
import {ContractCommentsStatus} from "../../enums/contractCommentsStatus";
import {StyledContractAppendixProps} from "../contract/contract.style";
import {Contract, ContractAppendix} from "../../models/contract";
import {IMyDataChange} from "../../models/myData";
import {WorkerDataChangeStatus} from "../../enums/workerDataChangeStatus";

export const StyledWorkerDataChangeContainer = styled.div`
  width: 100%;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 16px;

  small {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;

    img {
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }

    span {
      font-weight: bolder;
    }
  }

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0;
    margin-top: 8px;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 16px;

    img {
      max-height: 29px;
      margin-right: 9px;
    }
  }
`;

export type WorkerDataChangeProps = {
    workerDataChange: IMyDataChange
};

export const StyledWorkerDataChangeButton = styled.button<WorkerDataChangeProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => changeFontColor(props.workerDataChange.verified)};
  background: ${props => changeButtonColor(props.workerDataChange.verified)};
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 14px;
  position: relative;
  margin-top: 5px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

function changeButtonColor(status: WorkerDataChangeStatus) {
    switch (status) {
        case WorkerDataChangeStatus.VERIFIED:
        case WorkerDataChangeStatus.VERIFIED_AUTOMATICALLY:
        case WorkerDataChangeStatus.REJECTED:
            return '#EDEDF3';
        case WorkerDataChangeStatus.NOT_VERIFIED:
            return 'rgba(244, 48, 48, 0.12)';
    }
}

function changeFontColor(status: WorkerDataChangeStatus) {
    switch (status) {
        case WorkerDataChangeStatus.VERIFIED:
        case WorkerDataChangeStatus.VERIFIED_AUTOMATICALLY:
        case WorkerDataChangeStatus.REJECTED:
            return '#000000';
        case WorkerDataChangeStatus.NOT_VERIFIED:
            return '#F43030';
    }
}
