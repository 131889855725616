import {Preferences} from "@capacitor/preferences";

const getWorkerId = async () => {
    const workerId = await Preferences.get({'key': 'worker_id'});

    return workerId.value;
}

export {
    getWorkerId
};