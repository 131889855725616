import styled from "styled-components";


export const StyledContent = styled.div`
    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    textarea {
        width: 100%;
    }
    
    .scanComment {
        color: #888A98;
        font-size: 11px;
        line-height: 15px;
    }
    
    .scanComment div {
        display: flex;
        align-items: center;
    }
    
    .scanComment div span {
        padding-right: 5px;
    }
    
    .listLabel {
        font-size: 12px;
    }
   
`