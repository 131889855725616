import {CancelTokenSource} from "axios";

import {travelAllowanceApi} from "@services/api.service";

import {Car, CarBrand, CarForm} from "@models/travelAllowance/car";

import {getWorkerId} from "@utils/tools/worker";

const getCars = async (cancelToken?: CancelTokenSource): Promise<Car[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`/cars/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getCar = async (carId: number, cancelToken?: CancelTokenSource): Promise<Car> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`/cars/${carId}/worker/${workerId}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const addCar = async (carData: CarForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`/cars/worker/${workerId}`, carData)
}

const editCar = async (carId: number, carData: CarForm) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.put(`/cars/${carId}/worker/${workerId}`, carData)
}

const removeCar = async (carId: number) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.patch(`/cars/${carId}/worker/${workerId}/deactivate`)
}

const getCarBrands = async (cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<CarBrand[]> => {
    const response: any = await travelAllowanceApi.get(`/car-schemes/brands?page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

const getCarModels = async (brand: string, cancelToken?: CancelTokenSource, page: number = 1, searchText: string = ''): Promise<CarBrand[]> => {
    const response: any = await travelAllowanceApi.get(`/car-schemes/models?brand=${brand}&page=${page}&searchText=${searchText}`, {
        cancelToken: cancelToken?.token
    });

    return response.data;
}

export {
    getCars,
    getCar,
    addCar,
    editCar,
    removeCar,
    getCarBrands,
    getCarModels,
};
