import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {Loan as LoanModel} from "../../../models/loan";
import {blobToBase64} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
// import {FileOpener} from "@ionic-native/file-opener";
import {getLoan, getLoanRegulationsPdf} from "../../../services/loan.service";
import {StyledFooter} from "../../../components/pane/pane.style";
import {IonAlert, IonCol, IonGrid, IonRow, IonToast} from "@ionic/react";
import {StyledLightBlueButton} from "../../../components/button/button.style";
import {useHistory} from "react-router-dom";
import useNavigation from "../../../services/navigation.service";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";

type RegulationsPaneProps = {
    topEdge?: number;
    loan?: LoanModel;
}

const RegulationsPane: React.FC<RegulationsPaneProps> = (props: RegulationsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const downloadPdf = async () => {
        if (props.loan?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getLoanRegulationsPdf(props.loan?.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + props.loan?.number.replace(/\//g, '_') + ' - regulamin.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch(e) {}
                }
            }
        }
    };

    return (
        <div>
            <Pane topEdge={props.topEdge} marginTop={40}
                  paddingBottom={160}>
                <div dangerouslySetInnerHTML={{__html: props.loan?.regulations_content || ''}}/>
            </Pane>

            <StyledFooter>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <StyledLightBlueButton className="no-margin-top"
                                                   onClick={() => downloadPdf()}>{t("loanPage.regulations.downloadPdf")}</StyledLightBlueButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </StyledFooter>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </div>
    );
};

export default RegulationsPane;
