import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonIcon, IonList, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledField} from "../../../../components/form/input/input.style";
import {StyledContent} from "./../recruitmentPath.style";
import {StyledButton, StyledLightBlueButton, StyledWiteButton} from "../../../../components/button/button.style";
import {useTranslation} from "react-i18next";
import {StyledFooter} from "../../../../components/pane/pane.style";
import Timer from "./../timer/timer.component";
import {RecruitmentPathTestType} from "../../../../enums/recruitmentPathTestType";
import {
    RecruitmentPathData, RecruitmentPathResult,
    RecruitmentPathTest, RecruitmentPathTestAnswer,
    RecruitmentPathTestResult
} from "../../../../models/recruitmentPathData";
import Test1Game from "./test1game.component";
import Path1PreviewImage from "../../../../assets/images/recruitment/path-1-preview-image.svg";
import TestsCounter from "./../testCounter/testsCounter.component";
import ResultTrue from "../../../../assets/images/recruitment/result-true.svg";
import ResultFalse from "../../../../assets/images/recruitment/result-false.svg";
import {
    getTestPathByHash, getTestPreviewImage,
    historyInsert,
    refreshPath,
    updatePathResult
} from "../../../../services/recruitment.service";
import Pane from "../../../../components/pane/pane.component";
import RecruitmentPathSkeleton from "../../../../components/recruitment/recruitmentPathSkeleton.component";
import {App, BackButtonListener, BackButtonListenerEvent} from '@capacitor/app';
import {useForm} from "react-hook-form";

type RecruitmentPathTestProps = {
    currentTest: number;
    tests: [];
    goToNextPath: (type: string) => void;
    exitRecruitment: () => void;
    abortRecruitment: () => void;
    hash?: string;
    updateTitle: (title: string) => void;
    updateTestInProgress: (inProgress: string|null) => void;
    durations: [];
    version?: number;
    series: any;
};

const Test1: React.FC<RecruitmentPathTestProps> = (props: RecruitmentPathTestProps) => {

    const {t} = useTranslation();

    const testType = RecruitmentPathTestType.TEST_1;
    //const showTestBreakAfterTestNumber: number = 3;

    const [showInfo, updateShowInfo] = useState<boolean>(true);
    const [showPreview, updateShowPreview] = useState<boolean>(false);
    const [showTimer, updateShowTimer] = useState<boolean>(false);
    const [showResults, updateShowResults] = useState<boolean>(false);
    const [showConfirmExit, updateShowConfirmExit] = useState<boolean>(false);
    const [pathTests, updatePathTests] = useState(props.tests);
    const [tests, updateTests] = useState([]);
    const [currentTest, updateCurrentTest] = useState<RecruitmentPathTest|null>(null);
    const [currentTestNumber, updateCurrentTestNumber] = useState<number>(0);
    const [results, updateResults] = useState<RecruitmentPathTestResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [duration, updateDuration] = useState<number>(7);
    const [previewImage, updatePreviewImage] = useState<string|null>(null);
    const [showTestBreakAfterTestNumber, updateShowTestBreakAfterTestNumber] = useState<number>(props.series[testType] ?? 3);

    useEffect(() => {
        for (const [key, value] of Object.entries(pathTests)) {
            if (key == testType) {
                updateTests(value);
            }
        }

        updateDuration(getDuration(props.durations, testType, 7));
    }, [pathTests]);

    const goToPreview = () => {
        refreshTest(true);
        updateShowInfo(false);
        updateShowPreview(true);
    };

    const goToTimer = () => {
        insertHistory({type: testType, action_code: 'TEST_START'}, props.hash);
        updateShowPreview(false);
        updateShowTimer(true);
    }

    const goToTest = () => {
        updateShowTimer(false);
        updateCurrentTest(tests[currentTestNumber]);
        props.updateTitle(t("recruitment.recruitmentPath.test1.equation.title"));
        props.updateTestInProgress(testType);
    }

    const goToResults = () => {
        updateShowConfirmExit(false);
        updateShowResults(true);
        props.updateTitle('');
        props.updateTestInProgress(null);
    }

    const goToTestBreak = () => {
        updateShowTimer(true);
    }

    const insertHistory = async(data: {}, hash?:string) => {
        await historyInsert(data, hash).then(response => {
        })
        .catch(error => {
            // todo what we show??
        });
    }

    const sendResult = async (result: RecruitmentPathResult) => {
        setLoading(true);
        await updatePathResult(result, props?.hash)
            .then(response => {
                //console.log(response);
            })
            .catch(error => {
                // todo what we show??
            });

        setLoading(false);
    }

    useEffect(() => {
        if (tests.length > 0 && results.length > 0 && tests.length == results.length) {
            sendResult({type: testType, result: isTestPassed()});
        }
    }, [results]);

    const updateResultsSet = (id: number, result: boolean) => {
        insertHistory({result: result ? 1 : 0, id: id, type: testType}, props.hash);

        let r: RecruitmentPathTestResult = {
            id: id,
            result: result
        };

        updateResults(current => [...current, r]);

        let nextTestNumber:number = currentTestNumber + 1;
        updateCurrentTestNumber(nextTestNumber);

        if (tests[nextTestNumber]) {
            if (nextTestNumber % showTestBreakAfterTestNumber == 0) {
                goToTestBreak();
                updateCurrentTest(null);
                updateCurrentTestNumber(nextTestNumber);
            } else {
                updateCurrentTest(tests[nextTestNumber]);
                updateCurrentTestNumber(nextTestNumber);
            }
        } else {
            updateCurrentTest(null);
            goToResults();
        }
    }

    const isTestPassed = () => {
        if (results.length < tests.length) {
            return false;
        }

        let failed: number = 0;

        for (const value of Object.values(results)) {
            if (!value.result) {
                failed++;
            }
        }

        return failed == 0;
    }

    const refreshTest = async (onlyRefresh:boolean) => {
        setLoading(true);
        await refreshPath(testType, props?.hash)
            .then(response => {
                if (response.data.id) {
                    let recruitmentPathData = response.data as RecruitmentPathData;
                    updatePathTests(recruitmentPathData.details);
                    updateShowResults(false);
                    updateResults([]);
                    updateCurrentTest(null);
                    updateCurrentTestNumber(0);
                    updateDuration(getDuration(recruitmentPathData.times, testType, 7));

                    if (onlyRefresh == false) {
                        goToTimer();
                    }
                }
            })
            .catch(error => {
                // todo what we show??
            });

        setLoading(false);
    }

    const repeatTest = () => {
        refreshTest(false);
    }

    const isNextPathAvailable = () => {
        const nextPathAvailable = Object.keys(pathTests).find(path => {
            return path == RecruitmentPathTestType.TEST_2;
        });

        return !!nextPathAvailable;
    }

    const goToConfirmExit = () => {
        updateShowResults(false);
        updateShowConfirmExit(true);
    }

    const getDuration = (times:[], type: string, defaultDuration:number = 10): number => {
        if (Object.keys(times).indexOf(type) != -1) {
            return Object.values(times)[Object.keys(times).indexOf(type)];
        }

        return defaultDuration;
    }

    const getTotalTests = (testsNumber:number, currentTestNumber:number, showTestBreakAfterTestNumber:number) => {
        return testsNumber - (Math.floor(currentTestNumber / showTestBreakAfterTestNumber) * showTestBreakAfterTestNumber) >= showTestBreakAfterTestNumber ? showTestBreakAfterTestNumber : testsNumber - (Math.floor(currentTestNumber / showTestBreakAfterTestNumber) * showTestBreakAfterTestNumber)
    }

    const getSeries = () : number => {
        return tests.length / props.series[testType];
    }

    const loadTestPreviewImage = async (testType: string) => {
        setLoading(true);
        await getTestPreviewImage(testType)
            .then(response => {
                updatePreviewImage(response.data);
            })
            .catch(error => {
                // todo what we show??
            });

        setLoading(false);
    }

    useEffect(() => {
        loadTestPreviewImage(testType);
    }, []);

    return (
        <StyledContent>
            {loading &&
                <Pane marginTop={35} paddingBottom={97}>
                    <IonList>
                        <RecruitmentPathSkeleton />
                    </IonList>
                </Pane>
            }

            {!loading && showInfo == true && <div>
                <h1 className="title full">{t("recruitment.recruitmentPath.test1.info.title")}</h1>
                <div className="content full-height justify" dangerouslySetInnerHTML={{
                    __html: t("recruitment.recruitmentPath.test1.info.content", {
                        series: getSeries(),
                        excercises: props.series[testType],
                        time: getDuration(props.durations, testType, 10)
                    }) || ''
                }}/>

                <StyledFooter className="modal no-shadow">
                    <StyledButton
                        onClick={() => goToPreview()}>{t("recruitment.recruitmentPath.test1.info.continue")}</StyledButton>
                </StyledFooter>
            </div>
            }

            {!loading && showPreview == true && <div>
                <h1 className="title full center">{t("recruitment.recruitmentPath.test1.preview.title")}</h1>
                {
                    previewImage && <div className="content full-height justify">
                        <img src={previewImage} />
                        </div>
                    }

                    <StyledFooter className="modal no-shadow">
                        <StyledButton
                            onClick={() => goToTimer()}>{t("recruitment.recruitmentPath.test1.preview.continue")}</StyledButton>
                    </StyledFooter>
                </div>
            }

            {!loading && showTimer == true &&
                <Timer onFinish={goToTest}/>
            }

            {!loading && currentTest && <div>
                    <Test1Game currentTest={currentTest} updateResults={updateResultsSet}
                               currentTestNumber={currentTestNumber + 1 - (Math.floor(currentTestNumber / showTestBreakAfterTestNumber) * showTestBreakAfterTestNumber)}
                               totalTests={getTotalTests(tests.length, currentTestNumber, showTestBreakAfterTestNumber)}
                               duration={duration}
                    ></Test1Game>
                </div>
            }

            {!loading && showResults == true && <div>
                    <h1 className="title full justify">
                        {
                            isTestPassed() == true && <span>{t("recruitment.recruitmentPath.test1.results_title.success")}</span>
                        }

                        {
                            isTestPassed() == false && <span>{t("recruitment.recruitmentPath.test1.results_title.fail")}</span>
                        }
                    </h1>
                    <div className="content full-height">
                        <div className="results-test-1-series">{t("recruitment.recruitmentPath.test1.results_content.series1")}</div>
                        <div className="results-test-1">
                            {
                                results.length > 0 && results.map((result, key) =>
                                    key < showTestBreakAfterTestNumber &&
                                    <div className="item">
                                        <img src={result.result == true ? ResultTrue : ResultFalse} width={70} />
                                        <div className="test-number">{key + 1}</div>
                                    </div>
                                )
                            }
                        </div>

                        {results.length >= showTestBreakAfterTestNumber && <div><div className="results-test-1-series">{t("recruitment.recruitmentPath.test1.results_content.series2")}</div>
                            <div className="results-test-1">
                                {
                                    results.length >= showTestBreakAfterTestNumber && results.map((result, key) =>
                                        key >= showTestBreakAfterTestNumber && key < showTestBreakAfterTestNumber * 2 &&
                                        <div className="item">
                                            <img src={result.result == true ? ResultTrue : ResultFalse} width={70} />
                                            <div className="test-number">{key + 1 - showTestBreakAfterTestNumber}</div>
                                        </div>
                                    )
                                }
                            </div></div>
                        }

                        {results.length > showTestBreakAfterTestNumber * 2 && <div><div className="results-test-1-series">{t("recruitment.recruitmentPath.test1.results_content.series3")}</div>
                            <div className="results-test-1">
                                {
                                    results.length >= showTestBreakAfterTestNumber && results.map((result, key) =>
                                        key >= showTestBreakAfterTestNumber * 2 &&
                                        <div className="item">
                                            <img src={result.result == true ? ResultTrue : ResultFalse} width={70} />
                                            <div className="test-number">{key + 1 - (showTestBreakAfterTestNumber * 2)}</div>
                                        </div>
                                    )
                                }
                            </div></div>
                        }

                        <span className="justify">
                            {
                                isTestPassed() == true && <span>{t("recruitment.recruitmentPath.test1.results_content.success")}</span>
                            }

                            {
                                isTestPassed() == false && <span>{t("recruitment.recruitmentPath.test1.results_content.fail")}</span>
                            }
                        </span>
                    </div>

                    <StyledFooter className="modal no-shadow">
                        <IonGrid>
                            {isTestPassed() == true && isNextPathAvailable() == true && <IonRow>
                                    <IonCol size="12">
                                        <StyledButton onClick={() => props.goToNextPath(RecruitmentPathTestType.TEST_2)}>{t("recruitment.recruitmentPath.test1.next")}</StyledButton>
                                    </IonCol>
                                </IonRow>
                            }

                            {isTestPassed() == false && <IonRow>
                                    <IonCol size="12">
                                        <StyledButton onClick={() => repeatTest()} disabled={loading}>{t("recruitment.recruitmentPath.test1.repeat")}</StyledButton>
                                    </IonCol>
                                </IonRow>
                            }

                            {(isNextPathAvailable() == false || isTestPassed() == false) &&
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledWiteButton
                                            onClick={() => {props.abortRecruitment()}}>{t("recruitment.recruitmentPath.test.exit")}</StyledWiteButton>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonGrid>
                    </StyledFooter>
                </div>
            }

            {!loading && showConfirmExit == true && <div>
                <h1 className="title full">{t("recruitment.recruitmentPath.test1.confirm_exit.title")}</h1>

                <StyledFooter className="modal no-shadow">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <StyledWiteButton
                                    onClick={() => props.abortRecruitment()}>{t("recruitment.recruitmentPath.test.confirm_exit.button")}</StyledWiteButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledButton
                                    onClick={() => goToResults()}>{t("recruitment.recruitmentPath.test.confirm_exit_stay.button")}</StyledButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </StyledFooter>
            </div>
            }
        </StyledContent>
    )
};

export default Test1;