import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledIonModal, StyledModalContent} from '../../../components/modal/modal.style';
import Form from '../../../components/form';
import { StyledDataTable } from '../../../components/content/content.style';
import {IonSearchbar} from '@ionic/react';
import CloseImage from "../../../assets/images/e-remove.svg";
import {useForm} from "react-hook-form";
import {DataSelectable} from "../../../models/common";
import {useIsInViewport} from "../../../utils/tools/viewport";
import LoadingSkeleton from "../../../components/loading/loadingSkeleton.component";
import {AuditReportFormModel} from "../../../models/auditReport";

type ListModalProps = {
    report: AuditReportFormModel,
    isOpen: boolean;
    onFetch?: (report: AuditReportFormModel, page: number, searchText?: string) => Promise<DataSelectable[]>
    onFetchGeneric?: (page: number, searchText?: string) => Promise<DataSelectable[]>
    onSubmit: (selected: DataSelectable) => void;
    onClose: () => void;
    translate?: boolean,
    search?: boolean,
};

const PaginatedListModal: React.FC<ListModalProps> = (props: ListModalProps) => {

    const {t} = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string|undefined>();
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
    const [list, updateList] = useState<DataSelectable[]>();

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        if (props.isOpen) {
            fetchList();
        }
    }, [props.isOpen]);

    const fetchList = async (forceReload: boolean = false, append: boolean = false, page: number = 1) => {
        if (!append) {
            setLoading(true)
        } else {
            setReloading(true);
        }
        if (list === undefined || forceReload) {
            if (!append) {
                updateList([]);
            }

            if (props.onFetch) {
                props.onFetch(props.report, page, searchText).then(data => {
                    if (append) {
                        if (list) {
                            if (data && data.length == 0) {
                                setLoadMoreDisabled(true);
                            } else {
                                setLoadMoreDisabled(false);
                            }
                            updateList([...list, ...data]);
                        }
                        setReloading(false);
                    } else {
                        setLoadMoreDisabled(false);
                        updateList(data);
                    }

                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                    setReloading(false)
                })
            } else if (props.onFetchGeneric) {
                props.onFetchGeneric(page, searchText).then(data => {
                    if (append) {
                        if (list) {
                            if (data && data.length == 0) {
                                setLoadMoreDisabled(true);
                            } else {
                                setLoadMoreDisabled(false);
                            }
                            updateList([...list, ...data]);
                        }
                        setReloading(false);
                    } else {
                        setLoadMoreDisabled(false);
                        updateList(data);
                    }

                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                    setReloading(false)
                })
            }
        }
    }

    useEffect(() => {
        setPage(1);
        if (searchText !== undefined) {
            fetchList(true, false, 1);
        }
    }, [searchText])

    const loadMoreList = async () => {
        let tmp = page + 1;
        setPage(page + 1);
        fetchList(true, true, tmp);
    };

    const searchbar = useRef<HTMLIonSearchbarElement>(null);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const onSubmit = () => {
        if (searchbar && searchbar.current?.value) {
            setSearchText(searchbar.current.value);
        }
    };

    return (
        <StyledIonModal
            isOpen={props.isOpen}
            onDidDismiss={() => handleClose()}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
        >
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent className="modal-wrap t-p-50 scrollable">
                    <img src={CloseImage} className="close" onClick={() => handleClose() } />
                    {props.search && <IonSearchbar
                        className={'listSearch'}
                        ref={searchbar}
                        debounce={500}
                        onIonChange={e => setSearchText(e.detail.value!)}
                        placeholder={t('auditReport.listModal.search')}
                    />
                    }

                    {loading && <LoadingSkeleton includeAvatar={true} />}
                    {!loading && <StyledDataTable className="no-margin">
                        { list && list.map((data, i) => <div key={i} className="data-row" onClick={() => props.onSubmit(data)}>
                            <div className="data-icon-title">{props.translate ? t(data.name) : data.name}</div>
                        </div>)
                        }
                    </StyledDataTable>}

                    {reloading && <LoadingSkeleton includeAvatar={true} />}

                    {!loading && list && list.length > 49 &&
                        <StyledDataTable className="no-margin">
                            <div className="data-row" onClick={() => {
                                if (list && list.length > 0 && !loadMoreDisabled) {
                                    loadMoreList();
                                }
                            }}>
                                <div className="data-icon-title blue">{t('auditReport.listModal.loadMore')}</div>
                            </div>
                        </StyledDataTable>
                    }
                </StyledModalContent>
            </Form.Container>
        </StyledIonModal>
    );
};

export default PaginatedListModal;