import styled from 'styled-components';

export const StyledAttachementsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: space-around;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const StyledAttachementContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 92px;
  max-width: 30%;
  margin: 8px auto;

  span.preview {
    background: #FFFFFF;
    border: 1px solid #EDEDF3;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px;
    width: 20vw;
    text-align: center;

    img {
      height: 10vh;
      max-height: 10vh;
      min-height: 10vh;
    }
  }

  span.name {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    min-height: 30px;
  }

  span.date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;

    img {
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  }

  span.menu {
    background: #FFFFFF;
    border: 1px solid #EDEDF3;
    box-sizing: border-box;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 10px;
      height: 10px;
    }
  }
`;