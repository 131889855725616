import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledField} from "../../../../components/form/input/input.style";
import {StyledButton} from "../../../../components/button/button.style";
import {useTranslation} from "react-i18next";
import {StyledFooter} from "../../../../components/pane/pane.style";
import Timer from "./../timer/timer.component";
import {RecruitmentPathTestType} from "../../../../enums/recruitmentPathTestType";
import {RecruitmentPathTest} from "../../../../models/recruitmentPathData";
import {StyledContent} from "./testCounter.style";

type StepCounterProps = {
    currentTest: number;
    totalTests: number;
    duration: number;
    onDurationElapsed: () => void;
};


const TestsCounter: React.FC<StepCounterProps> = (props: StepCounterProps) => {

    const {t} = useTranslation();

    const [currentSecond, updateCurrentSecond] = useState<number>(props.duration);
    const [progressBarStep, updateProgressBarStep] = useState<number>(100 / props.duration);

    useEffect(() => {
        if (currentSecond == 0) {
            props.onDurationElapsed();
            updateCurrentSecond(props.duration);
        }

        const interval = setInterval(() => {
            updateCurrentSecond(currentSecond - 1);
        }, 1000);
        return () => clearInterval(interval)
    }, [currentSecond]);

    useEffect(() => {
        updateCurrentSecond(props.duration);
    }, [props.currentTest]);

    return (
        <StyledContent>
            <IonGrid>
                <IonRow className="progress-bar">
                    <IonCol size="10" style={{"paddingTop": "12px"}}>
                        <div className="progress-bar-background">
                            <div className="progress-bar-progress" style={{"width": ((1 -  (currentSecond / props.duration)) * 100) + "%"}}></div>
                        </div>
                    </IonCol>
                    <IonCol size="2" className="counter">
                        {currentSecond}s
                    </IonCol>
                </IonRow>
                <IonRow className="steps">
                    <IonCol size="12">
                        {props.currentTest} / {props.totalTests}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </StyledContent>
    )
};

export default TestsCounter;