import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../utils/tools/modals";

type LoansGuideModalProps = {
    isOpen: boolean;
    onClose: () => void;
    content: string;
};

const LoansGuideModal: React.FC<LoansGuideModalProps> = (props: LoansGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
    };

    return (
        <Modal
            isOpen={showGuide}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title full">{t("loanPage.loansGuide.title")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.content || ''}}/>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default LoansGuideModal;