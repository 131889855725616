import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledIonModal, StyledModalContent} from '../../../components/modal/modal.style';
import {Contract, ContractAdditionalDocument} from "../../../models/contract";
import VectorImage from "../../../assets/images/vector.svg";
import {StyledDataTable} from "../../../components/content/content.style";
import {StyledAdditionalDocument} from "./additionalDocumentsModal.style";
import moment from "moment/moment";
import {Billing} from "../../../models/billing";
import {Filesystem, FilesystemDirectory} from "@capacitor/filesystem";
import {getBillingPdf} from "../../../services/biliing.service";
import {blobToBase64} from "../../../services/file.service";
import {FileOpener} from "@capacitor-community/file-opener";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {ContractFile} from "../../../models/contractFile";
import {getAdditionalDocumentBase64, getContractFileBase64} from "../../../services/contract.service";

type AdditionalDocumentsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    contract: Contract|null;
};

const AdditionalDocumentsModal: React.FC<AdditionalDocumentsModalProps> = (props: AdditionalDocumentsModalProps) => {

    const {t} = useTranslation();

    const [showAdditionalDocuments, updateShowAdditionalDocuments] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowAdditionalDocuments(props.isOpen);
    };

    const openFile = async (file: ContractAdditionalDocument) => {
        if (props.contract?.id && file.mime_type) {
            let hasPermissions = await Filesystem.checkPermissions();
            if (hasPermissions.publicStorage === 'denied') {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getAdditionalDocumentBase64(props.contract?.id, file.id)
                    .then(response => {
                        return response.data.data;
                    })

                try {
                    const result = await Filesystem.writeFile({
                        path: 'ExactPeople/' + file.original_name,
                        data: fileData,
                        directory: FilesystemDirectory.Documents,
                        recursive: true
                    })

                    FileOpener.open({
                        'filePath': result.uri,
                        'contentType': file.mime_type
                    });
                } catch(e) {}
            }
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            canDismiss={false}
            maxHeight={'50%'}
            initialBreakpoint={0.50}
            breakpoints={[0, 0.50]}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <StyledDataTable>
                        {
                            props.contract?.additional_documents.map((document, i) =>
                                <StyledAdditionalDocument key={i}>
                                    <div className="content">
                                        <div className="title">
                                            {document.original_name}

                                            <button className="action-button" onClick={event => {
                                                event.stopPropagation();
                                                openFile(document);
                                            }}>{t('billingPage.billing.download')}</button>
                                        </div>
                                    </div>
                                </StyledAdditionalDocument>
                            )
                        }
                    </StyledDataTable>
                </StyledModalContent>
                <IonAlert
                    isOpen={showPermissionAlert}
                    onDidDismiss={() => setShowPermissionAlert(false)}
                    header={t('common.file_permissions.alert')}
                    buttons={[
                        {
                            text: t('common.alertCancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowPermissionAlert(false);
                            },
                        },
                        {
                            text: t('common.file_permissions.goToAppSettings'),
                            handler: async () => {
                                setShowPermissionAlert(false);
                                NativeSettings.open({
                                    optionAndroid: AndroidSettings.ApplicationDetails,
                                    optionIOS: IOSSettings.App
                                })
                            }
                        }
                    ]}
                />
        </Modal>
    );
};

export default AdditionalDocumentsModal;