import styled from 'styled-components';
import { IonButton, IonRange } from "@ionic/react";

type StyledIonRangeProps = {
    currency?: string;
}

export const StyledAnswerButton = styled(IonButton)`
    --background: #D7EAFB;
    
    --background-activated: transparent;
  
    font-size: 14px;
    
    &.positive.active {
        --background: #00C917;
    }
    &.negative.active {
        --background: #FE0200;
    }
    &.neutral.active {
        --background: #aaaaaa;
    }
    &.disabled {
        --background: #d9d9d9;
    }

    svg {
        width: 30px;
    }
`

export const StyledIonRange = styled(IonRange)<StyledIonRangeProps>`

    --bar-height: 12px;
    --bar-border-radius: 16px;

    &::part(pin) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    
        
        color: #0085FF;
    
        border-radius: 50%;
        transform: scale(1.01);

        top: -20px;
        font-size: 15px;
        font-weight: bold;
        min-width: 28px;
        height: 28px;
        transition: transform 120ms ease, background 120ms ease;
    }

    &::part(pin)::before {
        content: none
    }

    &::part(pin)::after {
        content: ${(props) => props.currency || ' PLN'};
        padding-left: 4px;
        font-size: 15px;
        font-weight: bold;
    }

    &::part(bar) {
        background: #DBEEFF;
        border: 6px solid #DBEEFF;
    }

    &::part(bar-active) {
        background: #0085FF;
        border: 6px solid #DBEEFF;
    }

    &::part(knob) {
        background: white;
        width: 30px;
        height: 30px;
        border: 10px solid #0085FF;
        box-sizing: border-box;
        position: relative;
        top: -9px;
    }

    .range-values {
        display: flex;
        justify-content: space-between;
    }

    
`

