import React, { PropsWithChildren } from 'react';
import { StyledInfoContainer, StyledInfoImage } from './info.style';
import InfoImg from '../../assets/images/info.svg';
import InfoErrorImg from '../../assets/images/infoRed.svg';

type InfoProps = {
    className?: string;
}

const Info: React.FC<PropsWithChildren<InfoProps>> = (props) => {
    return (
        <StyledInfoContainer className={props.className}>
            { props.className !== 'error' && <StyledInfoImage src={InfoImg} /> }
            { props.className === 'error' && <StyledInfoImage src={InfoErrorImg} /> }
            <div>{props.children}</div>
        </StyledInfoContainer>
    );
};

export default Info;