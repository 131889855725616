import styled from "styled-components";

export const StyledPhoneInput = styled.div`
    position: relative;

    .react-tel-input {
        background: transparent;
        border: none;
        width: auto;
        position: absolute;
        z-index: 1;
    }

//     .phone-hidden-input {
//         display: none;
//     }

    .phone-input {
        padding-left: 69px;
        font-size: 14px;
        letter-spacing: .01rem;

        &::placeholder {
            color: #363543;
        }
    }
`;