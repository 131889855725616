import {IonAlert, IonCol, IonItem, IonRow, IonToast} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AuditReportFormModel, AuditReportQuestion} from "../../../../models/auditReport";
import {Profile} from "../../../../models/profile";
import {StyledAccordion, StyledContent} from "../../../reportPage/reportPage.style";
import {AuditReportContext} from "../../auditReport.component";
import Accordion from './accordion';
import {StyledInput} from "../../../../components/form/input/input.style";
import {StyledIonCheckbox} from "../../../../components/form/input/checkbox.style";
import {StyledIonLabel} from "../../../../components/form/input/label.style";
import {checkIfAnyAnswerIsNotOk, checkIfRandomInspectionIsNok, validateStep3} from "../../../../services/audit.service";
import {getPenaltiesItems, loadPlaceOfServiceCompanyAdditionalEmails} from "../../helpers";
import {AuditReportPenaltyType, AuditType} from "../../../../enums/audit";
import {StyledComponentFormContainer} from "../../../reportPage/components/report/reportRowForm.style";
import {StyledStaticFooter} from "../../../../components/pane/pane.style";
import FormComponent from "../../../../components/form";
import {StyledTextarea} from "../../../../components/form/input/textarea.style";
import {validateEmail} from "../../../../services/validation.service";
import {StyledCircleButton, StyledSocialButton} from "../../../../components/button/button.style";
import AddPersonImage from "../../../../assets/images/a-add.svg";
import TrashRedImage from "../../../../assets/images/trash-can-red.svg";
import PaginatedListModal from "../../modals/paginatedListModal.component";
import {DataSelectable} from "../../../../models/common";

export type AuditReportStep3Props = {
    report: AuditReportFormModel,
    profile?: Profile,
    onChange: (report: AuditReportFormModel) => void,
    onSubmit: () => void,
}
const AuditReportStep3: React.FC<AuditReportStep3Props> = (props: AuditReportStep3Props) => {
    const { t } = useTranslation();

    const [report, setReport] = useState(props.report)
    const [formSubmitted, setFormSubmitted] = useState<any>(false);
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');
    const [showSaveAlert, setShowSaveAlert] = useState(false);

    const [showAdditionalEmailModal, setShowAdditionalEmailModal] = useState<boolean>(false);
    const [selectedAdditionalEmailKey, setSelectedAdditionalEmailKey] = useState<number|undefined>();

    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setFormSubmitted = setFormSubmitted;
    contextAuditReport.getFormSubmitted = () => {
        return formSubmitted;
    };

    const auditContext = useContext(AuditReportContext);

    useEffect(() => {
        props.onChange(report);
    }, [report]);

    const onSubmit = () => {
        setFormSubmitted(true);
        if (!validateStep3(report)) {
            setToast(t("reportPage.validation.commonError"));
            setShowToast(true);

            const errorElement = document.querySelector(
                `.hasErrors`
            )

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            setShowSaveAlert(true);
        }
    }

    const handleShowAdditionalEmailModal = (key: number) => {
        setShowAdditionalEmailModal(true);
        setSelectedAdditionalEmailKey(key);
    }

    const handleCloseShowAdditionalEmailModal = () => {
        setShowAdditionalEmailModal(false);
        setSelectedAdditionalEmailKey(undefined);
    }

    const addAdditionalEmail = () => {
        const tmp = report.additionalEmails;
        tmp.push('');

        setReport({
            ...report,
            additionalEmails: tmp
        })
    }

    const removeAdditionalEmail = (key: number) => {
        const tmp = report.additionalEmails;
        tmp.splice(key, 1);

        setReport({
            ...report,
            additionalEmails: tmp
        })
    }

    const updateAdditionalEmail = (value: DataSelectable) => {
        if (typeof selectedAdditionalEmailKey === 'number') {
            const tmp = report.additionalEmails;
            tmp.splice(selectedAdditionalEmailKey, 1, extractEmailFromAdditionalEmail(value.name));

            setReport({
                ...report,
                additionalEmails: tmp
            })
        }
    }

    const extractEmailFromAdditionalEmail = (additionalEmail: string) => {
        const parts = additionalEmail.split('(');
        return parts[1].replace(')', '').trim();
    }

    const addClientEmail = () => {
        let tmp = report.clientEmails;
        tmp.push('');

        setReport({
            ...report,
            clientEmails: tmp
        })
    }

    const removeClientEmail = (key: number) => {
        let tmp = report.clientEmails;
        tmp.splice(key, 1);

        setReport({
            ...report,
            clientEmails: tmp
        })
    }

    const updateClientEmail = (key: number, value: string) => {
        let tmp = report.clientEmails;
        tmp.splice(key, 1, value);

        setReport({
            ...report,
            clientEmails: tmp
        })
    }

    return <>
        <StyledContent className="withFooter">
            <StyledComponentFormContainer className="padding-top-bg">
                <div className="mt-20"></div>
                <Accordion allowToggle={false} isOpen={true} title={"auditReport.form.summary.title"}>
                    <StyledAccordion>
                        <IonRow>
                            <IonCol class="label">
                            {t('auditReport.form.auditPerson')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            {report.type === AuditType.QUALITY &&
                                <IonCol >
                                    {
                                        report.workers?.map((worker, index) => {
                                            return <IonRow key={index}>
                                                    <span>
                                                        {worker.name}
                                                    </span>
                                            </IonRow>
                                        })
                                    }
                                </IonCol>
                            }
                            {report.type === AuditType.BHP &&
                                <IonCol >
                                    <IonRow>
                                        <span>
                                            {report.coordinatorOrTeamLeader?.name || report.coordinatorOrTeamLeaderOther}
                                        </span>
                                    </IonRow>
                                </IonCol>
                            }
                        </IonRow>
                        <IonRow>
                            <IonCol class="label">
                            {t('auditReport.form.questionsNumber')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            <IonCol>
                                {report.questions?.length}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="label">
                            {t('auditReport.form.status')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            <IonCol>
                                {
                                    ((checkIfAnyAnswerIsNotOk(report) || checkIfRandomInspectionIsNok(report))) ?
                                    <span className="red">{t('auditReport.form.negative')}</span> :
                                    <span className="green">{t('auditReport.form.positive')}</span>
                                }
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol class="label">
                                {t('auditReport.form.negativeQuestionsNumber')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            <IonCol>
                                {/* number of NOK answers */}
                                {report.questions?.filter((question: AuditReportQuestion) => question.answer.value === "NOK").length}
                            </IonCol>
                        </IonRow>
                        {report.randomInspectionEnabled && <>
                            <IonRow>
                                <IonCol class="label">
                                    {t('auditReport.form.randomInspection.result')}
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-8">
                                <IonCol>
                                    {
                                        (checkIfRandomInspectionIsNok(report)) ?
                                            <span className="red">{t('auditReport.form.negative')}</span> :
                                            <span className="green">{t('auditReport.form.positive')}</span>
                                    }
                                </IonCol>
                            </IonRow>
                        </>}
                        {report.form?.is_enabled_audit_percentage_correct && report.auditPercentageCorrectValue && <>
                            <IonRow>
                                <IonCol class="label">
                                    {t('auditReport.form.negativeQuestionsPercentageValue')}
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-8">
                                <IonCol>
                                    {report.auditPercentageCorrectValue < 0 ? 0 : report.auditPercentageCorrectValue}%
                                </IonCol>
                            </IonRow></>
                        }
                    {
                        getPenaltiesItems(AuditReportPenaltyType.WORKER, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) > 0 && <>
                        <IonRow>
                            <IonCol class="label">
                                {t('auditReport.form.workerPenaltyFee')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            <IonCol>
                                { getPenaltiesItems(AuditReportPenaltyType.WORKER, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) } { report.form?.penalty_table?.currency }
                            </IonCol>
                        </IonRow>
                        </>
                    }
                    {
                        getPenaltiesItems(AuditReportPenaltyType.TEAM_LEADER, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) > 0 && <>
                        <IonRow>
                            <IonCol class="label">
                            {t('auditReport.form.teamLeaderPenaltyFee')}
                            </IonCol>
                        </IonRow>
                        <IonRow className="mb-8">
                            <IonCol>
                            { getPenaltiesItems(AuditReportPenaltyType.TEAM_LEADER, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) } { report.form?.penalty_table?.currency }
                            </IonCol>
                        </IonRow>
                        </>
                    }
                    {
                        getPenaltiesItems(AuditReportPenaltyType.COORDINATOR, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) > 0 && <>
                            <IonRow>
                                <IonCol class="label">
                                    {t('auditReport.form.coordinatorPenaltyFee')}
                                </IonCol>
                            </IonRow>
                            <IonRow className="mb-8">
                                <IonCol>
                                { getPenaltiesItems(AuditReportPenaltyType.COORDINATOR, report).reduce( (total,penalty) =>   total + (penalty.amount || 0), 0) } { report.form?.penalty_table?.currency }
                                </IonCol>
                            </IonRow>
                            </>
                    }

                    </StyledAccordion>
                </Accordion>
            </StyledComponentFormContainer>
            <StyledComponentFormContainer className="padding-top-bg">
                <Accordion allowToggle={false} isOpen={true} title={"auditReport.form.summary.save"}>
                    <StyledAccordion className="padding-0-10">
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.coordinatorEmail')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledInput
                                             type="text"
                                             value={report.coordinatorEmail}
                                             readOnly={!!(report.order && report.order.notification_emails.coordinator)}
                                             className={auditContext.getFormSubmitted() && (!report.coordinatorEmail || (report.coordinatorEmail && !validateEmail(report.coordinatorEmail))) ? 'hasErrors' : ''}
                                             onChange={(e) => {
                                                 setReport({
                                                     ...report,
                                                     coordinatorEmail: e.target.value
                                                 });
                                             }}
                                             placeholder={t('auditReport.form.enter')} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.regionalManagerEmail')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledInput
                                             type="text"
                                             readOnly={!!(report.order && report.order.notification_emails.regional_manager)}
                                             value={report.regionalManagerEmail}
                                             className={auditContext.getFormSubmitted() && (!report.regionalManagerEmail || (report.regionalManagerEmail && !validateEmail(report.regionalManagerEmail))) ? 'hasErrors' : ''}
                                             onChange={(e) => {
                                                 setReport({
                                                     ...report,
                                                     regionalManagerEmail: e.target.value
                                                 });
                                             }}
                                             placeholder={t('auditReport.form.enter')} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                {t('auditReport.form.additionalEmail')}
                            </IonCol>
                        </IonRow>
                        {report.additionalEmails.map((additionalEmail: string, key: number) =>
                            <IonRow key={`${key}_${additionalEmail}`}>
                                <IonCol size="11"
                                        onClick={() => handleShowAdditionalEmailModal(key)}
                                >
                                    <StyledInput
                                        readOnly={true}
                                        value={additionalEmail}
                                        className={auditContext.getFormSubmitted() && (!additionalEmail || (additionalEmail && !validateEmail(additionalEmail))) ? 'hasErrors' : ''}
                                        placeholder={t('auditReport.form.enter')} />
                                </IonCol>

                                <IonCol size="1" className="center">
                                    <StyledCircleButton className="white" onClick={() => removeAdditionalEmail(key)}><img src={TrashRedImage} /></StyledCircleButton>
                                </IonCol>
                            </IonRow>
                        )}

                        <IonRow>
                            <IonCol>
                                <StyledSocialButton className="report-button black-text" onClick={() => addAdditionalEmail()}>
                                    <img className="button-image" src={AddPersonImage}/>
                                    {t('auditReport.form.additionalEmailAdd')}
                                </StyledSocialButton>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size="12" className="label">
                                {t('auditReport.form.comment')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledTextarea
                                    onKeyPress={event => {
                                        if (event.key == 'Enter') {
                                            const input = event.target as HTMLInputElement;
                                            input.blur();
                                        }
                                    }}
                                    className="full-width"
                                    onChange={(e) => {
                                        setReport({
                                            ...report,
                                            comment: e.target.value
                                        });
                                    }}
                                    value={report.comment}
                                    placeholder={t('auditReport.form.enter')} />
                            </IonCol>
                        </IonRow>

                        {report.noOrder &&
                            <>
                                <IonRow>
                                    <IonCol size="12" className="label">
                                        {t('auditReport.form.noOrderComment')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledTextarea
                                            readOnly={true}
                                            onKeyPress={event => {
                                                if (event.key == 'Enter') {
                                                    const input = event.target as HTMLInputElement;
                                                    input.blur();
                                                }
                                            }}
                                            className="full-width"
                                            onChange={(e) => {
                                                setReport({
                                                    ...report,
                                                    noOrderComment: e.target.value
                                                });
                                            }}
                                            value={report.noOrderComment}
                                            placeholder={t('auditReport.form.enter')} />
                                    </IonCol>
                                </IonRow>
                            </>
                        }

                        {report.noComponent &&
                            <>
                                <IonRow>
                                    <IonCol size="12" className="label">
                                        {t('auditReport.form.noComponentComment')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledTextarea
                                            readOnly={true}
                                            onKeyPress={event => {
                                                if (event.key == 'Enter') {
                                                    const input = event.target as HTMLInputElement;
                                                    input.blur();
                                                }
                                            }}
                                            className="full-width"
                                            onChange={(e) => {
                                                setReport({
                                                    ...report,
                                                    noComponentComment: e.target.value
                                                });
                                            }}
                                            value={report.noComponentComment}
                                            placeholder={t('auditReport.form.enter')} />
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        <IonRow>
                            <IonCol size="12" className="label">
                                <IonItem lines="none">
                                    <StyledIonLabel className="ion-text-wrap">{t('auditReport.form.sendToClient')}</StyledIonLabel>
                                    <StyledIonCheckbox name="sendToClient" checked={report.sendToClient} slot="start" onIonChange={(e) => {
                                        setReport({
                                            ...report,
                                            sendToClient: e.detail.checked,
                                            // clientEmails: e.detail.checked ? [''] : [],
                                        });
                                    }}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        {report.sendToClient && <>
                            <IonRow>
                                <IonCol size="12" className="label required">
                                    {t('auditReport.form.clientEmail')}
                                </IonCol>
                            </IonRow>
                            {report.clientEmails.map((clientEmail, key) =>
                                <IonRow key={'clientEmails_'+key}>
                                    <IonCol size="11">
                                        <StyledInput
                                            type="text"
                                            value={clientEmail}
                                            className={auditContext.getFormSubmitted() && (report.sendToClient && (!clientEmail || (clientEmail && !validateEmail(clientEmail)))) ? 'hasErrors' : ''}
                                            onChange={(e) => {
                                                updateClientEmail(key, e.target.value);
                                            }}
                                            placeholder={t('auditReport.form.enter')} />
                                    </IonCol>
                                    <IonCol size="1" className="center">
                                        {report.clientEmails.length > 1 && <StyledCircleButton className="white" onClick={() => removeClientEmail(key)}><img src={TrashRedImage} /></StyledCircleButton>}
                                    </IonCol>
                                </IonRow>
                            )}

                            <IonRow>
                                <IonCol>
                                    <StyledSocialButton className="report-button black-text" onClick={() => addClientEmail()}>
                                        <img className="button-image" src={AddPersonImage}/>
                                        {t('auditReport.form.clientEmailAdd')}
                                    </StyledSocialButton>
                                </IonCol>
                            </IonRow>
                        </>}
                    </StyledAccordion>
                </Accordion>
            </StyledComponentFormContainer>

            <StyledStaticFooter className="no-shadow">
                <FormComponent.Button type="submit" onClick={onSubmit}>{t('auditReport.form.save')}</FormComponent.Button>
            </StyledStaticFooter>
                    
        </StyledContent>

        <IonToast
            isOpen={showToast}
            onDidDismiss={() => {
                setShowToast(false);
                setToast('');
            }}
            message={toast}
            duration={6000}
            position="top"
            color="danger"
        />

        <IonAlert
            isOpen={showSaveAlert}
            onDidDismiss={() => setShowSaveAlert(false)}
            header={t('auditReport.saveAlert.title')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        setShowSaveAlert(false)
                    },
                },
                {
                    text: t('auditReport.saveAlert.save'),
                    handler: async () => {
                        await props.onSubmit();
                        setShowSaveAlert(false)
                    }
                }
            ]}
        />

        {showAdditionalEmailModal && <PaginatedListModal search={true} report={props.report} isOpen={showAdditionalEmailModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
            return loadPlaceOfServiceCompanyAdditionalEmails(report, page, searchText);
        }} onSubmit={(selected) => {
            updateAdditionalEmail(selected);
            handleCloseShowAdditionalEmailModal();
        }} onClose={() => {
            handleCloseShowAdditionalEmailModal();
        }} />
        }
    </>
}

export default AuditReportStep3;