import {IonDatetime} from '@ionic/react';
import styled from 'styled-components';

export const StyledIonDatetime = styled(IonDatetime)`
    background: #F4F4F9;
    border-radius: 12px;

    font-size: 12px;
    line-height: 26px;
    letter-spacing: -0.408px;
    
    ${({ disabled }) => disabled && 'opacity: 1;'}
    
    &.focused {
        background: #FFFFFF;
        border: 1px solid #0085FF;
        box-sizing: border-box;
        border-radius: 12px;
        color: #000000;
    }

    &.timetable {
		padding: 10px;
		text-align: center;
    }
    
    &.hasErrors {
        border: 1px solid #F43030;
    }
`;

export const StyledDate = styled.div`
    
`;