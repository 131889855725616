import styled from "styled-components";
import {StyledModalContent} from "../../../components/modal/modal.style";

export const StyledContent = styled(StyledModalContent)`
    margin-top: 20px;
  
    h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #000000;
        padding: 0 24px;
    
        &.blue {
            color: #0F1664;
        }
    }

    .item {
        color: #0F1664; 
        border-radius: 16px;
        margin-bottom: 5px;

        .number {
          font-weight: bold;
          display: inline-block;
          width: 10%;
          vertical-align: top;
        }

        .title {
          display: inline-block;
          width: 80%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      
        .ion-accordion-toggle-icon {
          position: absolute;
          right: 16px;
        }
    }
  
    /*.accordion {
      background-color: #ffc409;

      .icon-inner {
        margin-top: 9px;
        background-color: #3dc2ff;
      }
    }*/
  
    .item-content {
        margin-bottom: 5px;
      
        iframe {
          border: 1px solid #fff;
          border-radius: 10px;
        }
    }
  
    .item-to-watch {
        --ion-item-background: #F3B3BB;
    }
  
    .item-watched {
        --ion-item-background: #B3D5B3;
    }

    .box {
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      margin-top: 50px;
    
      .back-button {
        width: 40px;
        margin-top: 5px;
      }
    
      .push {
        text-align: center;
        background-color: #ffc409;
      }
    
      .logo {
        text-align: center;
        margin-bottom: 30px;
    
        img {
          width: 100%;
        }
      }
  }

  .logo {
    text-align: center;
    padding-top: 5px;

    img {
      width: 100%;
      height: 44px;
    }
  }
`