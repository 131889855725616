const ifNull = (value: any) => {
	if (value != null && value !== "null" && value !== "undefined") {
		return value;
	}

	return null;
};

const range = (start:number, end:number, step:number) => {
	return Array.from(Array.from(Array(Math.ceil((end-start)/step)).keys()), x => start+ x*step);
}

export { ifNull, range };
