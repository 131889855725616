import React, {useEffect, useState} from 'react';
import Modal from '../../components/modal/modal.component';
import {getBadge} from "../../services/worker.service";
import {WorkerBadge} from "./workerBadge.style";
import {StyledModalContent} from "../../components/modal/modal.style";
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';
import {useTranslation} from "react-i18next";
import {StyledButton} from "../../components/button/button.style";
import {StyledFooter} from "../../components/pane/pane.style";
import {IonLoading, IonToast} from "@ionic/react";
import {Browser} from "@capacitor/browser";
import {getCoursesLoginLink} from "../../services/course.service";

type WorkerCoursesInfoProps = {
	isOpen: boolean;
	onClose: () => void;
};

const WorkerCoursesInfoModal: React.FC<WorkerCoursesInfoProps> = (props: WorkerCoursesInfoProps) => {

	const {t} = useTranslation();

	const [showModal, updateShowModal] = useState<boolean>(false);
	const [showToast, updateShowToast] = useState<boolean>(false);
	const [showLoading, updateShowLoading] = useState(false);

	const handleClose = () => {
		props.onClose();
	};

	useEffect(() => {
		handleOpen();
	});

	const handleOpen = () => {
		updateShowModal(props.isOpen);
	};

	const openCourses = async () => {
		updateShowLoading(true);

		await getCoursesLoginLink()
			.then(response => {
				console.log(response.data)
				if (response.data.result == 'success') {
					if (response.data.link) {
						updateShowModal(false);
						Browser.open({url: response.data.link})
					}
				} else {
					updateShowToast(true);
				}

				updateShowLoading(false);
			});
	};

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => props.onClose()}>

			<StyledModalContent>
				<div className="content full-height">
					<h1 className="title full">{t("worker.courses.title")}</h1>
					<div className="content full-height">
						{t("worker.courses.description")}
					</div>
				</div>
				<StyledFooter className="modal footer no-shadow">
					<StyledButton type="button" onClick={openCourses}>{t("worker.courses.button")}</StyledButton>
				</StyledFooter>
			</StyledModalContent>

			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {
					updateShowToast(false);
				}}
				message={t("worker.courses.cant_get_user_login_link")}
				duration={6000}
				position="top"
				color="danger"
			/>
			<IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading}/>
		</Modal>
	);
};

export default WorkerCoursesInfoModal;
