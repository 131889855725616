import React, {useEffect, useState} from 'react';
import {StyledIonModal} from '../../../../../components/modal/modal.style';
import {StyledContainer} from './appVersionModal.style';
import {App, AppInfo} from "@capacitor/app";
import Logo from '../../../../../components/logo';
import {isPlatform} from "@ionic/react";
import {StyledPaneBar, StylePaneGrabber} from "../../../../../components/pane/pane.style";
import {appVersion} from "../../../../../services/auth.service";
import CloseImage from "../../../../../assets/images/e-remove.svg";

type AppVersionModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const AppVersionModal: React.FC<AppVersionModalProps> = (props: AppVersionModalProps) => {
    const [deviceInfo, updateDeviceInfo] = useState<AppInfo>();
    const [apiVersion, updateApiVersion] = useState<string>();

    useEffect(() => {
        if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
            App.getInfo().then((info) => updateDeviceInfo(info));
        }
    }, []);

    const getAppVersion = async () => {
        await appVersion().then(response => {
            updateApiVersion(response.data.version);
        });
    };

    useEffect(() => {
        getAppVersion();
    }, []);

    return (
        <StyledIonModal
            isOpen={props.isOpen}
            initialBreakpoint={0.80}
            maxHeight={'80%'}
            breakpoints={[0, 0.80]}
            onDidDismiss={() => props.onClose()}>

            <StyledContainer className="modal-wrap-with-max" onClick={(e) => e.stopPropagation()}>
                <Logo />
                <span className="name">{deviceInfo?.name}</span>
                <span className="number">v {deviceInfo?.version || apiVersion}</span>
            </StyledContainer>
        </StyledIonModal>
    );
};

export default AppVersionModal;

