import React, { useEffect, useRef, useState } from 'react';
import { IonHeader, IonPage } from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import { useTranslation } from 'react-i18next';
import SubNavigation, { SubNavigationOption } from '../../../../components/sub-navigation/subNavigation.component';
import { useHistory } from 'react-router-dom';
import useNavigation from '../../../../services/navigation.service';
import NewInvitationPane from './panes/newInvitationPane.component';
import InvitationsPane from './panes/invitations.component';

export enum PlanningTabOptions {
    NEW_INVITATION = 0,
    INVITATIONS = 1
}

const PlanningTab: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const options = [{
        name: t("planningTab.optionNewInvitation")
    },
    {
        name: t("planningTab.optionInvitations")
    }];

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('planningSelectedOption')) || PlanningTabOptions.NEW_INVITATION);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('planningSelectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    useEffect(() => {
        updateHeight();
    },[]);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle>{t("planningTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <SubNavigation options={options}
                                   paramName="planningSelectedOption"
                                   defaultValue={selectedOption || PlanningTabOptions.NEW_INVITATION}
                                   onSelect={handleSelect}></SubNavigation>
                </IonHeader>
                {selectedOption === PlanningTabOptions.NEW_INVITATION && <NewInvitationPane topEdge={topEdge}></NewInvitationPane>}
                {selectedOption === PlanningTabOptions.INVITATIONS && <InvitationsPane topEdge={topEdge}></InvitationsPane>}
            </StyledIonContent>
        </IonPage>
    );
};

export default PlanningTab;
