import React from 'react';
import {IonAvatar, IonItem, IonLabel, IonList, IonSkeletonText} from "@ionic/react";
import {StyledDataTable} from "../content/content.style";

type LoadingSkeletonProps = {
    includeAvatar: boolean;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = (props: LoadingSkeletonProps) => {
    return (
        <StyledDataTable>
            <IonList>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    {props.includeAvatar && <IonAvatar slot="start">
                        <IonSkeletonText animated/>
                    </IonAvatar>}
                    <IonLabel>
                        <h3>
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </h3>
                        <p>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </p>
                        <p>
                            <IonSkeletonText animated style={{ width: '60%' }} />
                        </p>
                    </IonLabel>
                </IonItem>
            </IonList>
        </StyledDataTable>
    );
};

export default LoadingSkeleton;