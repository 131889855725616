import queryString from 'query-string'

let h: any;

const getParam = <T>(field: string): T => {
	const query = queryString.parse(h.location.search);
	
	return query[field] as any as T;
};

const getParams = () => {
	return queryString.parse(h.location.search);
};

const clearParams = () => {
	const path = h.location.pathname;

	h.replace(`${path}`, h.location.state);
};

const setParam = (field: string, value: string) => {
	h.replace(getParamUrl(field, value));
};

const getParamUrl = (field: string, value: string) => {
	const path = h.location.pathname;
	const params = queryString.parse(h.location.search);

	params[field] = value;

	return `${path}?${getQuery(params)}`;
};

const setParams = (parameters: any) => {
	const path = h.location.pathname;
	const params = queryString.parse(h.location.search);

	for(let key in parameters) {
		params[key] = parameters[key];
	}

	h.replace(`${path}?${getQuery(params)}`, h.location.state);
};

const getQuery = (params: {}) => {
	return queryString.stringify(params);
};


let blockNavigation: (() => boolean) | undefined;

const setBlockNavigation = (func: (() => boolean) | undefined) => {
	blockNavigation = func;
}

const getBlockNavigation = () : (() => boolean) | undefined  => {
	return blockNavigation;
}

let unblockNavigation: any;

const setUnblockNavigation = (func:any) => {
	unblockNavigation = func;
}

const getUnblockNavigation = () : any  => {
	return unblockNavigation;
}

let destinationUrl:string = '';
const setDestinationUrl = (url: string) => destinationUrl = url;
const getDestinationUrl = () => destinationUrl;

export default function useNavigation(history: any) {
	h = history;

	return {
		setBlockNavigation,
		getBlockNavigation,
		setDestinationUrl,
		getDestinationUrl,
		setUnblockNavigation,
		getUnblockNavigation,
		getParam,
		getParams,
		getParamUrl,
		getQuery,
		setParam,
		setParams,
		clearParams
	};
};
