import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import {Plugins} from "@capacitor/core";
import {
    IonPage,
    useIonViewWillEnter,
    IonSelectOption, IonToast
} from "@ionic/react";
import SignModal, {ISignModalConfigration} from "../../../modals/sign/sign.component";
import MyDataSkeleton from "../components/myDataSkeleton.component";
import {getWorkerData, signWorkerData, signWorkerDataVerify} from "../../../services/worker.service";
import {
    getCities,
    getTaxOffices
} from "../../../services/possibleValues.service";
import {IMyData, MyDataSelectable} from "../../../models/myData";
import fieldsFormComponent from "../form/myDataForm.component";
import MyDataForm from "../form/myDataForm.component";
import StaticPane from "../../../components/pane/static-pane.component";
import CityModal from "../../contractDataPage/modals/cityModal.component";

const {Storage} = Plugins;

type ContentPaneProps = {
    topEdge?: number;
}

export enum MyDataEditType {
    INPUT,
    SELECT,
    DATE,
    PHONE,
    EMAIL,
    NUMBER,
    CHECKBOX,
    SELECT_CITY
};

export enum MyDataEditFields {
    LAST_NAME = 'lastName',
    FIRST_NAME = 'firstName',
    SECOND_NAME = 'secondName',
    FAMILY_NAME = 'familyName',
    CITY = 'city',
    ZIP_CODE = 'zipCode',
    STREET = 'street',
    HOUSE_NUMBER = 'houseNumber',
    APARTMENT_NUMBER = 'apartmentNumber',
    POST_OFFICE = 'postOffice',
    TAX_OFFICE = 'taxOffice',
    BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
    BICSWIFT = 'bicswift',
    BICSWIFT_CONFIRM = 'bicswiftConfirm',
    PHONE = 'phone',
    PHONE_CODE = 'phoneCode',
    EMAIL = 'email',
    PLACE_OF_BIRTH = 'placeOfBirth',
};

export enum MyDataEditFieldsGroups {
    LAST_NAME = 'lastName',
    FIRST_NAME = 'firstName',
    SECOND_NAME = 'secondName',
    FAMILY_NAME = 'familyName',
    ADDRESS = 'address',
    TAX_OFFICE = 'taxOffice',
    BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
    BICSWIFT = 'bicswift',
    BANK_ACCOUNT = 'bankAccount',
    PHONE_NUMBER = 'phoneNumber',
    EMAIL = 'email',
    PLACE_OF_BIRTH = 'placeOfBirth',
};

export interface IMyDataField {
    label: string;
    name: string;
    fieldName: string;
    group: string;
    value: any;
    displayedValue: any;
    type: MyDataEditType;
    required: boolean;
    disabled: boolean;
    editModeButtonVisible: boolean;
    options?: any[];
    onChange?: (value: string) => void;
    helpText?: string;
    valueToVerify: boolean;
};

const ContentPane: React.FC<ContentPaneProps> = (props: ContentPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [myData, setMyData] = useState<IMyData>();
    const [cities, setCities] = useState<MyDataSelectable[]>();
    const [selectedCity, setSelectedCity] = useState<string>();
    const [taxOffices, setTaxOffices] = useState<MyDataSelectable[]>();
    const [selectedTaxOffice, setSelectedTaxOffice] = useState<string>();
    const [manualIsDirty, updateManualIsDirty] = useState<boolean>(false);
    const [showCityModal, updateShowCityModal] = useState<boolean>(false);


    const getMyData = async () => {
        setLoading(true)
        const workerDataResponse = await getWorkerData()
            .then(response => {
                let myData = response.data as IMyData;
                setMyData(myData);

                setSelectedCity(myData.city.id.toString());
                setSelectedTaxOffice(myData.tax_office_object.id.toString());

                return true;
            })
            .catch(error => {
                return false;
            });

        setLoading(false);
    };

    useEffect(() => {
        getMyData();
    }, []); //storageData

    useIonViewWillEnter(() => {
        getMyData();
    });

    const fetchCities = async (communityId: string | bigint) => {
        await getCities(communityId)
            .then(response => {
                setCities(response.data);
            });
    };

    const fetchTaxOffices = async () => {
        await getTaxOffices()
            .then(response => {
                setTaxOffices(response.data);
            });
    };

    useEffect(() => {

        fetchTaxOffices();
    }, []);

    useEffect(() => {
        fetchCities('');
    }, []);

    const toVerify = (field: string): any => {
        if (myData?.change_to_verify) {
            let changes = myData?.change_to_verify.changes;
            for (const [key, value] of Object.entries(changes)) {
                if (field == key) {
                    let v: string[] = value as unknown as [];
                    return v[1];
                }
            }
        }

        return null;
    };

    const lastNameGroupFields: IMyDataField[] = [{
        label: t('myData.lastName'),
        name: MyDataEditFields.LAST_NAME,
        fieldName: 'last_name',
        group: MyDataEditFieldsGroups.LAST_NAME,
        value: myData?.last_name,
        displayedValue: toVerify(MyDataEditFields.LAST_NAME) !== null ? toVerify(MyDataEditFields.LAST_NAME) : myData?.last_name,
        type: MyDataEditType.INPUT,
        required: true,
        disabled: true,
        editModeButtonVisible: true,
        helpText: t('myData.commonHelpText'),
        valueToVerify: toVerify(MyDataEditFields.LAST_NAME) !== null
    }];

    const firstNameGroupFields: IMyDataField[] = [{
        label: t('myData.firstName'),
        name: MyDataEditFields.FIRST_NAME,
        fieldName: 'first_name',
        group: MyDataEditFieldsGroups.FIRST_NAME,
        value: myData?.first_name,
        displayedValue: myData?.first_name,
        type: MyDataEditType.INPUT,
        required: true,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.commonHelpText'),
        valueToVerify: false
    }];

    const secondNameGroupFields: IMyDataField[] = [{
        label: t('myData.secondName'),
        name: MyDataEditFields.SECOND_NAME,
        fieldName: 'second_name',
        group: MyDataEditFieldsGroups.SECOND_NAME,
        value: myData?.second_name,
        displayedValue: myData?.second_name,
        type: MyDataEditType.INPUT,
        required: false,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.commonHelpText'),
        valueToVerify: false
    }];

    const familyNameGroupFields: IMyDataField[] = [{
        label: t('myData.familyName'),
        name: MyDataEditFields.FAMILY_NAME,
        fieldName: 'family_name',
        group: MyDataEditFieldsGroups.FAMILY_NAME,
        value: myData?.family_name,
        displayedValue: myData?.family_name,
        type: MyDataEditType.INPUT,
        required: false,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.commonHelpText'),
        valueToVerify: false
    }];

    const addressGroupFields: IMyDataField[] = [
        {
            label: t('myData.city'),
            name: MyDataEditFields.CITY,
            fieldName: 'city',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: selectedCity,
            displayedValue: myData?.city?.nameFull,
            type: MyDataEditType.SELECT_CITY,
            required: true,
            editModeButtonVisible: false,
            valueToVerify: false,
            options: cities ? cities : (myData ? [{
                id: myData?.city?.id,
                name: myData?.city?.nameFull
            }] : []),
            disabled: false,
            onChange: (value: string) => {
                setSelectedCity(value);
            }
        },
        {
            label: t('myData.zipCode'),
            name: MyDataEditFields.ZIP_CODE,
            fieldName: 'zip_code',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: myData?.zip_code,
            displayedValue: myData?.zip_code,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        },
        {
            label: t('myData.street'),
            name: MyDataEditFields.STREET,
            fieldName: 'street',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: myData?.street,
            displayedValue: myData?.street,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        },
        {
            label: t('myData.houseNumber'),
            name: MyDataEditFields.HOUSE_NUMBER,
            fieldName: 'house_number',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: myData?.house_number,
            displayedValue: myData?.house_number,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        },
        {
            label: t('myData.apartmentNumber'),
            name: MyDataEditFields.APARTMENT_NUMBER,
            fieldName: 'apartment_number',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: myData?.apartment_number,
            displayedValue: myData?.apartment_number,
            type: MyDataEditType.INPUT,
            required: false,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        },
        {
            label: t('myData.postOffice'),
            name: MyDataEditFields.POST_OFFICE,
            fieldName: 'post_office',
            group: MyDataEditFieldsGroups.ADDRESS,
            value: myData?.post_office,
            displayedValue: myData?.post_office,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        }];

    const taxOfficeGroupFields: IMyDataField[] = [{
        label: t('myData.taxOffice'),
        name: MyDataEditFields.TAX_OFFICE,
        fieldName: 'tax_office',
        group: MyDataEditFieldsGroups.TAX_OFFICE,
        value: selectedTaxOffice,
        displayedValue: myData?.tax_office_object?.name,
        type: MyDataEditType.SELECT,
        required: true,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.commonHelpText'),
        valueToVerify: false,
        options: taxOffices ? taxOffices : (myData ? [{
            id: myData?.tax_office_object?.id,
            name: myData?.tax_office_object?.name
        }] : []),
        onChange: (value: string) => {
            if (value !== selectedTaxOffice) {
                setSelectedTaxOffice(value);
            }
        }
    }];

    const bankAccountGroupFields: IMyDataField[] = [{
        label: t('myData.bankAccountNumber'),
        name: MyDataEditFields.BANK_ACCOUNT_NUMBER,
        fieldName: 'bank_account_number',
        group: MyDataEditFieldsGroups.BANK_ACCOUNT,
        value: myData?.bank_account_number,
        displayedValue: myData?.bank_account_number,
        type: MyDataEditType.INPUT,
        required: true,
        disabled: false,
        editModeButtonVisible: true,
        valueToVerify: false
    }];

    if (myData?.worker_form_currency == 'EUR') {
        bankAccountGroupFields.push({
            label: t('myData.bicswift'),
            name: MyDataEditFields.BICSWIFT,
            fieldName: 'bicswift',
            group: MyDataEditFieldsGroups.BANK_ACCOUNT,
            value: myData?.bicswift,
            displayedValue: myData?.bicswift,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            valueToVerify: false
        });

        bankAccountGroupFields.push({
            label: t('myData.bicswift_confirm'),
            name: MyDataEditFields.BICSWIFT_CONFIRM,
            fieldName: 'bicswiftConfirm',
            group: MyDataEditFieldsGroups.BANK_ACCOUNT,
            value: false,
            displayedValue: false,
            type: MyDataEditType.CHECKBOX,
            required: true,
            disabled: false,
            editModeButtonVisible: false,
            helpText: t('myData.bicSwiftHelpText'),
            valueToVerify: false
        });
    }

    const phoneNumberGroupFields: IMyDataField[] = [{
        label: t('myData.phoneNumber'),
        name: MyDataEditFields.PHONE,
        fieldName: 'phone',
        group: MyDataEditFieldsGroups.PHONE_NUMBER,
        value: (myData?.phone_code ? myData?.phone_code + ' ' : '') + (myData?.phone ? myData.phone : ''),
        displayedValue: (myData?.phone_code ? '+' + myData.phone_code : '') + (myData?.phone ? myData.phone : ''),
        type: MyDataEditType.PHONE,
        required: true,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.phoneNumberHelpText'),
        valueToVerify: false
    }];

    const emailGroupFields: IMyDataField[] = [{
        label: t('myData.email'),
        name: MyDataEditFields.EMAIL,
        fieldName: 'email',
        group: MyDataEditFieldsGroups.EMAIL,
        value: myData?.email,
        displayedValue: myData?.email,
        type: MyDataEditType.EMAIL,
        required: true,
        disabled: false,
        editModeButtonVisible: true,
        helpText: t('myData.emailHelpText'),
        valueToVerify: false
    }];

    const placeOfBirthGroupFields: IMyDataField[] = [
        {
            label: t('myData.placeOfBirth'),
            name: MyDataEditFields.PLACE_OF_BIRTH,
            fieldName: 'place_of_birth',
            group: MyDataEditFieldsGroups.PLACE_OF_BIRTH,
            value: myData?.place_of_birth,
            displayedValue: myData?.place_of_birth,
            type: MyDataEditType.INPUT,
            required: true,
            disabled: false,
            editModeButtonVisible: true,
            helpText: t('myData.commonHelpText'),
            valueToVerify: false
        }];

    return (
        <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={147} hideGrabber={true} hasForm={true}>
            {
                loading && <>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                    <MyDataSkeleton/>
                </>
            }

            {
                !loading && <>
                    <MyDataForm fields={lastNameGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={firstNameGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={secondNameGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={familyNameGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={addressGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={taxOfficeGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={bankAccountGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={phoneNumberGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={emailGroupFields} data={myData} refreshData={getMyData}/>
                    <MyDataForm fields={placeOfBirthGroupFields} data={myData} refreshData={getMyData}/>
                </>
            }

        </StaticPane>
    );
};

export default ContentPane;
