import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import ReferFriendsPane from "./panes/referFriendsPane.component";

const ReferFriendsPage: React.FC = () => {
	const {t} = useTranslation();

	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	useEffect(() => {
		updateHeight();
	});

	const updateHeight = () => {
		if (header.current?.clientHeight == 0) {
			setTimeout(updateHeight);
		} else {
			updateTopEdge(header.current?.clientHeight);
		}
	};

	const header = useRef<HTMLIonHeaderElement>(null);

	return (
		<IonPage>
			<StyledIonContent>
				<IonHeader ref={header} className="ion-no-border">
					<StyledIonToolbar>
						<StyledIonTitle>{t("profileTab.referFriends")}</StyledIonTitle>
					</StyledIonToolbar>
				</IonHeader>
				<ReferFriendsPane topEdge={topEdge} />
			</StyledIonContent>
		</IonPage>
	);
};

export default ReferFriendsPage;
