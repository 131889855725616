import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Contract from '../../../components/contract/contract.component';
import {Contract as ContractModel} from '../../../models/contract';
import Info from '../../../components/info/info.component';
import Pane from '../../../components/pane/pane.component';
import {Links} from '../../links';
import {getContracts} from '../../../services/contract.service';
import { StyledEmptyContainer } from '../../../components/content/content.style';
import CircleImage, { ImageTypes } from '../../../components/circleImage/circleImage.component';
import {IonList, useIonViewWillEnter} from "@ionic/react";
import ContractSkeleton from "../../../components/contract/contractSkeleton.component";
import StaticPane from '../../../components/pane/static-pane.component';
import ContractsGuideModal from "../modals/contractsGuideModal.component";
import {isEmployed} from "../../../services/auth.service";
import {log} from "../../../services/firebaseAnalytics.service";
import AdditionalDocumentsModal from "../modals/additionalDocumentsModal.component";

type ContractsPaneProps = {
    topEdge?: number;
}

interface ContractsManager {
    setContracts: Function;
}

const contextContracts: ContractsManager = {
    setContracts: () => {

    }
};

export const ContractsContext = React.createContext<ContractsManager>(contextContracts);

const ContractsPane: React.FC<ContractsPaneProps> = (props: ContractsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [contracts, setContracts] = useState<[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showGuide, updateShowGuide] = useState<boolean>(false);
    const [showAdditionalDocuments, updateShowAdditionalDocuments] = useState<boolean>(false);
    const [currentContract, updateCurrentContract] = useState<ContractModel|null>(null);

    const fetchContracts = async () => {
        if (contracts === undefined) {
            setLoading(true);
            setContracts([]);
            const response = await getContracts()
                .then(response => {
                    setContracts(response.data);
                })
                .catch(error => {
                    // todo what we show??
                });

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContracts();
    });

    useIonViewWillEnter(() => {
        fetchContracts();
    });

    const contextContracts = useContext(ContractsContext);
    contextContracts.setContracts = setContracts;

    const [employed, setEmployed] = useState<boolean>(false);
    const isEmployee = async() => {
        const response = await isEmployed();
        let result:boolean = response == '1' ? true : false;
        return result;
    }

    const fetchUserData = async () => {
        let isEmployed: boolean = await isEmployee();
        setEmployed(isEmployed);
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    useIonViewWillEnter(() => {
        fetchUserData();
    });

	useEffect(() => {
		log('page_visit', {
			page: 'Profil -> Umowy'
		});
	}, []);

    const showAdditionalFiles = (contract: ContractModel) => {
        updateCurrentContract(contract);
        updateShowAdditionalDocuments(true);
    }

    return (
        <ContractsContext.Provider value={contextContracts}>
            {(!loading && (!contracts || contracts.length === 0)) &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.NOTE} color="grey"/>
                        { employed && <h2>{t('profileTab.contracts.empty')}</h2> }
                        { !employed && <h3>{t('profileTab.contracts.notWorker')}</h3> }
                    </StyledEmptyContainer>
                </StaticPane>
            }

            {loading &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <IonList>
                        <ContractSkeleton />
                        <ContractSkeleton />
                        <ContractSkeleton />
                        <ContractSkeleton />
                        <ContractSkeleton />
                        <ContractSkeleton />
                        <ContractSkeleton />
                    </IonList>
                </StaticPane>
            }

            {!loading && contracts && contracts.length > 0 &&
            <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={307}>
                <span onClick={() => updateShowGuide(true)}><Info>{t("profileTab.contracts.info")}</Info></span>
                {contracts && contracts.map((contact: ContractModel, key) => {
                    return <Contract key={key}
                                     contract={contact}
                                     childrenContracts={contact.children}
                                     appendixes={contact.appendixes}
                                     status={contact.sign_status}
                                     commentsStatus={contact.comments_status}
                                     additionalDocuments={contact.additional_documents}
                                     onClick={(e) => {
                                         if (contact.children && contact.children.length == 0) {
                                             history.push(Links.contract + '/' + contact.id)
                                         }
                                     }}
                                     onAdditionalFilesClick={() => showAdditionalFiles(contact)}></Contract>
                })}
            </Pane>
            }

            <ContractsGuideModal contracts={contracts} isOpen={showGuide} onClose={() => updateShowGuide(false)} />
            <AdditionalDocumentsModal isOpen={showAdditionalDocuments} onClose={() => {updateShowAdditionalDocuments(false); updateCurrentContract(null)}} contract={currentContract} />
        </ContractsContext.Provider>
    );
};

export default ContractsPane;
