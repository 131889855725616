import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import { Plugins } from "@capacitor/core";
import {
    IonPage,
    useIonViewWillEnter,
    IonSelectOption, IonToast
} from "@ionic/react";
import MyDataSkeleton from "../components/myDataSkeleton.component";
import {
    getWorkerDataChangesHistory
} from "../../../services/worker.service";
import {IMyDataChange} from "../../../models/myData";
import WorkerDataChange from "../../../components/workerDataChange/workerDataChange.component";

const { Storage } = Plugins;

type ChangesHistoryPaneProps = {
    topEdge?: number;
}

const ChangesHistoryPane: React.FC<ChangesHistoryPaneProps> = (props: ChangesHistoryPaneProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [myDataChanges, setMyDataChanges] = useState<IMyDataChange[]>();

    const getMyDataChangesHistory = async () => {
        setLoading(true)
        const workerDataChangesResponse = await getWorkerDataChangesHistory()
            .then(response => {
                let data = response.data.map((change: IMyDataChange) => (
                    {
                        id: change.id,
                        created_at: change.created_at,
                        changes: change.changes,
                        verified: change.verified,
                        rejected_reason: change.rejected_reason,
                        worker: change.worker
                    }
                ));
                setMyDataChanges(data);

                return true;
            })
            .catch(error => {
                return false;
            });

        setLoading(false);
    };

    useEffect(() => {
        getMyDataChangesHistory();
    }, []);

    useIonViewWillEnter(() => {
        getMyDataChangesHistory();
    });

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147} hideGrabber={false}>
            {
                loading && <>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                <MyDataSkeleton/>
                </>
            }

            {!loading && myDataChanges && myDataChanges.length > 0 &&
                myDataChanges.map((change: IMyDataChange, key) => {
                    return <WorkerDataChange key={key}
                                             workerDataChange={change}></WorkerDataChange>
            })}

        </Pane>
    );
};

export default ChangesHistoryPane;
