import {appApi} from "./api.service";
import moment from "moment";

let endpointPrefix = 'user/absences';

const getAbsences = async () => {
    return appApi.get(endpointPrefix);
}

const addAbsence = async (startDate: Date, endDate:Date, reason:string) => {
	let start:string = moment(startDate).format('DD-MM-YYYY HH:mm');
	let end:string = moment(endDate).format('DD-MM-YYYY HH:mm');

	return appApi.post(endpointPrefix + '/new', {
		'startDate': start,
		'endDate': end,
		'reason': reason
	});
}

const editAbsence = async (id:number, startDate:Date, endDate:Date, reason:string) => {
	let start:string = moment(startDate).format('DD-MM-YYYY HH:mm');
	let end:string = moment(endDate).format('DD-MM-YYYY HH:mm');

	return appApi.post(endpointPrefix + '/' + id + '/edit', {
		'startDate': start,
		'endDate': end,
		'reason': reason
	});
}

const removeAbsence = async (id:number) => {
	return appApi.post(endpointPrefix + '/' + id + '/remove');
}

export {
	getAbsences,
	addAbsence,
	editAbsence,
	removeAbsence
};
