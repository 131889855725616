import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledField} from "../../../../components/form/input/input.style";
import {StyledTimerContent} from "./timer.style";
import Counter0Image from '../../../../assets/images/counter/0.svg';
import Counter1Image from '../../../../assets/images/counter/1.svg';
import Counter2Image from '../../../../assets/images/counter/2.svg';
import Counter3Image from '../../../../assets/images/counter/3.svg';

type TimerProps = {
    onFinish: () => void;
};


const Timer: React.FC<TimerProps> = (props: TimerProps) => {

    const [currentSecond, updateCurrentSecond] = useState<number>(3);

    useEffect(() => {
        if (currentSecond == -1) {
            props.onFinish();
        }

        const interval = setInterval(() => {
            updateCurrentSecond(currentSecond - 1);
        }, 1000);
        return () => clearInterval(interval)
    }, [currentSecond]);

    return (
        <StyledTimerContent>
            {currentSecond == 0 &&
                <div className="item">
                    <img src={Counter0Image} />
                </div>
            }

            {currentSecond == 1 &&
                <div className="item">
                    <img src={Counter1Image} />
                </div>
            }

            {currentSecond == 2 &&
                <div className="item">
                    <img src={Counter2Image} />
                </div>
            }

            {currentSecond == 3 &&
                <div className="item">
                    <img src={Counter3Image} />
                </div>
            }
        </StyledTimerContent>
    )
};

export default Timer;