import styled from 'styled-components';

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledAvailability = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 12px;
  width: 100%;
  position: relative;

  padding: 26px 16px 24px 16px;
  margin-top: 24px;

  display: flex;
  flex-direction: row;

  .delete {
    position: absolute;
    top: 9px;
    right: 13px;
  }

  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

`;