import {appApi} from "./api.service";

let endpointPrefix = 'worker/loans';

const getLoans = async () => {
    return appApi.get(endpointPrefix);
}

const addLoan = async (amount: number) => {
	return appApi.post(endpointPrefix + '/new', {
		'amount': amount
	});
}

const getLoan = async (id: bigint | string) => {
	return appApi.get(endpointPrefix + '/' + id);
}
const checkLoanAvailability = async () => {
	return appApi.get(endpointPrefix + '/availability');
}

const signLoan = async (id: any) => {
	return appApi.post(endpointPrefix + '/' + id + '/sign', {
		'rulesAccepted': 1
	});
}

const signLoanVerify = async (id: any, code: string) => {
	return appApi.post(endpointPrefix + '/' + id + '/sign/code-verification', {
		'verificationCode': code
	});
}

const cancelLoan = async (id: bigint) => {
	return appApi.post(endpointPrefix + '/' + id + '/cancel');
}

const getLoanPdf = async (id: bigint | string) => {
	return appApi.get(endpointPrefix + '/' + id + '/download', {
		responseType: "blob"
	});
}

const getLoanRegulationsPdf = async (id: bigint | string) => {
	return appApi.get(endpointPrefix + '/' + id + '/download-regulations', {
		responseType: "blob"
	});
}

const getDocuments = async () => {
	return appApi.get(endpointPrefix + '/documents');
}

const getLoanDrafts = async () => {
	return appApi.get(endpointPrefix + '?status[]=DRAFT');
}

export {
	getLoans,
	addLoan,
	getLoan,
	checkLoanAvailability,
	signLoan,
	signLoanVerify,
	cancelLoan,
	getLoanPdf,
	getLoanRegulationsPdf,
	getDocuments,
	getLoanDrafts
};
