import React, {useContext, useEffect, useRef, useState} from 'react';
import {getPlatforms, IonButtons, IonHeader, IonPage, isPlatform} from '@ionic/react';
import {
    StyledDataTable,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from '../../../../components/content/content.style';
import {
    StyledHeaderButton,
    StyledHeaderButtonImage
} from '../../../../components/button/button.style';
import {useHistory} from 'react-router-dom';
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import VectorImage from '../../../../assets/images/vector.svg';
import ChatImage from '../../../../assets/images/a-chat-blue.svg';
import PrivacyImage from '../../../../assets/images/privacy-2.svg';
import PermissionsImage from '../../../../assets/images/check-double.svg';
import TranslationImage from '../../../../assets/images/translation.svg';
import AppVersionImage from '../../../../assets/images/app-version.svg';
import TrashRedImage from '../../../../assets/images/trash-can-red.svg';
import LogoffImage from '../../../../assets/images/log-out.svg';
import PenImage from '../../../../assets/images/pen-blue.svg';
import {useTranslation} from 'react-i18next';
import {Links} from '../../../links';
import {isEmployed, logout, logoutHook, useAuth} from "../../../../services/auth.service";
import {UserContext} from "../../../../services/auth.service";
import AppVersionModal from './modals/appVersionModal.component';
import AppApprovalsModal from './modals/appApprovalsModal.component';
import InstructionModal from './modals/instructionModal.component';
import PrivacyModal from './modals/privacyModal.component';
import StaticPane from "../../../../components/pane/static-pane.component";
import {Browser} from "@capacitor/browser";
import {Preferences} from "@capacitor/preferences";
import SupportRequestModal from "../../../../modals/supportRequest/supportRequest.component";

enum SettingEditType {
    INLINE,
    LANGUAGE,
    APP_MODE,
    PRIVACY,
    INSTRUCTION,
    APPROVALS,
    VERSION,
    LOGOFF,
    TERMS,
    SUPPORT,
    CHANGE_PASSWORD,
    USER_REMOVE
};

interface ISetting {
    name: string;
    icon: any;
    field: string;
    classes?: string;
    edit: SettingEditType;
};

const SettingsTab: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showVersion, updateShowVersion] = useState<boolean>(false);
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [showInstruction, updateShowInstruction] = useState<boolean>(false);
    const [showPrivacy, updateShowPrivacy] = useState<boolean>(false);
    const [showSupportRequest, updateShowSupportRequest] = useState<boolean>(false);
    const [employed, updateEmployed] = useState<boolean|string|null>(null);
    const [userEmail, updateUserEmail] = useState<string|null>('');
    const auth = useAuth();

    const settings: ISetting[] = [
        // {
        //     name: t('settingsTab.mode'),
        //     field: '',
        //     edit: SettingEditType.APP_MODE,
        //     icon: MoonImage
        // },
        // {
        //     name: t('settingsTab.instruction'),
        //     field: '',
        //     edit: SettingEditType.INSTRUCTION,
        //     icon: InstructionImage
        // },
        {
            name: t('settingsTab.grantedApprovals'),
            field: '',
            edit: SettingEditType.APPROVALS,
            icon: PermissionsImage,
		},
        {
            name: t('settingsTab.privacy'),
            field: '',
            edit: SettingEditType.PRIVACY,
            icon: PrivacyImage,
		},
        {
            name: t('settingsTab.terms'),
            field: '',
            edit: SettingEditType.TERMS,
            icon: PrivacyImage,
		},
        {
            name: t('settingsTab.changePassword'),
            field: '',
            edit: SettingEditType.CHANGE_PASSWORD,
            icon: PenImage,
        },
        {
            name: t('settingsTab.language'),
            field: '',
            edit: SettingEditType.LANGUAGE,
            classes: 'no-line separator',
            icon: TranslationImage,
		},
        {
            name: t('settingsTab.removeUser'),
            field: '',
            edit: SettingEditType.USER_REMOVE,
            icon: TrashRedImage,
        },
        {
            name: t('settingsTab.version'),
            field: '',
            edit: SettingEditType.VERSION,
            icon: AppVersionImage,
		},
        {
            name: t('settingsTab.support'),
            field: '',
            edit: SettingEditType.SUPPORT,
            icon: ChatImage,
		},
        {
            name: t('settingsTab.logoff'),
            field: '',
            edit: SettingEditType.LOGOFF,
            classes: 'no-line separator',
            icon: LogoffImage,
		}];

    const edit = async (data: ISetting) => {
        switch (data.edit) {
            case SettingEditType.LANGUAGE:
                history.push(`${Links.main}${Links.language}`);
                break;

            case SettingEditType.APP_MODE:
                history.push(`${Links.appMode}`);
                break;

            case SettingEditType.CHANGE_PASSWORD:
                history.push(`${Links.changePassword}`);
                break;

            case SettingEditType.VERSION:
                updateShowVersion(true);
                break;

            case SettingEditType.APPROVALS:
                updateShowApprovals(true);
                break;

            case SettingEditType.INSTRUCTION:
                updateShowInstruction(true);
                break;

            case SettingEditType.SUPPORT:
                updateShowSupportRequest(true);
                break;

            case SettingEditType.PRIVACY:
                await Browser.open({ url: 'https://exactsystems.pl/gdpr-file/EP-polityka_prywatnosci.htm' });
                break;
            case SettingEditType.TERMS:
                await Browser.open({ url: 'https://exactsystems.pl/gdpr-file/Warunki-Uzytkowania-Aplikacji-Exact-People.htm' });
                break;

            case SettingEditType.LOGOFF:

                let hook = await logoutHook().then(() => {
                    return true;
                }).catch(() => {
                    // either way we redirect
                    return true;
                });

                if (hook) {
                    await logout().then(() => {
                        auth.signOut();
                        history.replace(Links.login)
                        window.top.location.reload();
                    });
                }

                break;

            case SettingEditType.USER_REMOVE:
                history.push(`${Links.userRemove}`);
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        updateHeight();
    });

    useEffect(() => {
        updateStorageEmployed();
        updateStorageEmail();
    }, []);

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const updateStorageEmployed = async () => {
        let employedStorage = await isEmployed();
        updateEmployed(employedStorage);
    };

    const updateStorageEmail = async () => {
        let email = await Preferences.get({
            'key': 'email',
        });

        updateUserEmail(email.value);
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => history.goBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("settingsTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <StyledDataTable className="no-margin">
                        {settings.filter((value) => {
                            return !(userEmail?.includes('@exactsystems.com') && (value.edit === SettingEditType.CHANGE_PASSWORD || value.edit === SettingEditType.USER_REMOVE));
                        }).map((data, i) => <div key={i} className={"data-row " + data.classes}
                                                        onClick={() => edit(data)}>
                            <div className="data-icon-title"><img src={data.icon}/> {data.name}</div>
                            <div className="data-action">
                                <img src={VectorImage}/>
                            </div>
                        </div>)
                        }
                    </StyledDataTable>
                    {/*{*/}
                    {/*    employed == '1' &&*/}
                    {/*    <StyledSocialButton className="inverted" onClick={() => history.push(Links.contractData)}>*/}
                    {/*        <img src={ContractDataImage}/>*/}
                    {/*        {t('settingsTab.contractDataButton')}*/}
                    {/*    </StyledSocialButton>*/}
                    {/*}*/}
                </StaticPane>
                {showVersion && <AppVersionModal isOpen={showVersion} onClose={() => updateShowVersion(false)}></AppVersionModal>}
                <AppApprovalsModal isOpen={showApprovals} onClose={() => updateShowApprovals(false)}></AppApprovalsModal>
                <InstructionModal isOpen={showInstruction} onClose={() => updateShowInstruction(false)}></InstructionModal>
                <PrivacyModal isOpen={showPrivacy} onClose={() => updateShowPrivacy(false)}></PrivacyModal>
                <SupportRequestModal isOpen={showSupportRequest} onClose={() => updateShowSupportRequest(false)} userEmail={userEmail}></SupportRequestModal>
            </StyledIonContent>
        </IonPage>
    );
};

export default SettingsTab;
