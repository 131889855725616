import React, {PropsWithChildren, useEffect, useMemo, useRef, useState} from 'react';
import {IonDatetime, IonPopover} from '@ionic/react';
import {DatetimePresentation} from "@ionic/core";
import {StyledFakeField} from "./input.style";
import {isValid, parseISO} from "date-fns";
import {StyledIonModal} from "../../modal/modal.style";
import {StyledDate} from "./date.style";
import {Keyboard} from "@capacitor/keyboard";
import {Capacitor} from "@capacitor/core";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

type DateProps = {
    value?: string | string[] | null;
    disabled?: boolean;
    max?: string;
    min?: string;
    cancelText?: string;
    doneText?: string;
    placeholder?: string;
    presentation: DatetimePresentation;
    format: string;
    onChange: (value: string) => void;
    onFocus?: (e: any) => void;
    onCancel?: (e: any) => void;
    className?: string;
    minuteValues?: number[] | number | string;
    keepContentsMounted?: boolean;
    popover?: boolean;
    defaultPopoverValue?: string;
    onWillPresent?: (e: any) => void;
}

const DateInput: React.FC<PropsWithChildren<DateProps>> = (props) => {
    const [showPicker, updateShowPicker] = useState<boolean>(false);
    const datetime = useRef<null | HTMLIonDatetimeElement>(null);

    const formattedValue = useMemo(() => {
        if (props.value && isValid(parseISO(props.value.toString()))) {
            return moment(props.value.toString(), moment.ISO_8601).format(props.format);
        }

        return props.placeholder ?? '';
    }, [props.value, props.format, props.placeholder]);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
                const firstPickerColumn = datetime.current?.shadowRoot?.querySelector('ion-picker-column-internal');
                const selectedFirstPickerItem = firstPickerColumn?.shadowRoot?.querySelector('.picker-item-active');

                if (selectedFirstPickerItem) {
                    (selectedFirstPickerItem as HTMLElement).focus();
                }
            });

            return () => {
                keyboardDidHideListener.remove();
            };
        }
    }, []);

    useEffect(() => {
        if (!showPicker) return;

        let observer: MutationObserver;
        let timeoutId: NodeJS.Timeout;

        const pickerColumns = datetime.current?.shadowRoot?.querySelectorAll('ion-picker-column-internal');

        if (!pickerColumns) return;

        const hoursPickerColumn = pickerColumns[0];
        const minutesPickerColumn = pickerColumns[1]

        const observePickerColumnValue = (pickerColumn: HTMLIonPickerColumnInternalElement) => {
            const shadowRoot = pickerColumn.shadowRoot;
            if (!shadowRoot) {
                return;
            }

            const handleValueChange = () => {
                disableConfirmButton();

                if (timeoutId) clearTimeout(timeoutId);

                timeoutId = setTimeout(() => {
                    enableConfirmButton();
                }, 300);
            };

            observer = new MutationObserver(() => {
                handleValueChange();
            });

            observer.observe(shadowRoot, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        };

        observePickerColumnValue(hoursPickerColumn);
        observePickerColumnValue(minutesPickerColumn);

        return () => {
            if (observer) observer.disconnect();
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [showPicker]);

    const disableConfirmButton = () => {
        const confirmButton = datetime.current?.shadowRoot?.querySelector('#confirm-button');

        if (confirmButton) {
            confirmButton.setAttribute('disabled', 'true');
        }
    };

    const enableConfirmButton = () => {
        const confirmButton = datetime.current?.shadowRoot?.querySelector('#confirm-button');

        if (confirmButton) {
            confirmButton.removeAttribute('disabled');
        }
    };

    const handleShowPicker = () => {
        if (!props.disabled) {
            updateShowPicker(true);
        }
    };

    const handleIonChange = (e: CustomEvent) => {
        if (e.detail.value) {
            props.onChange(moment(e.detail.value.toString(), moment.ISO_8601).format());
            updateShowPicker(false);
        }
    };

    const handleIonFocus = (e: CustomEvent) => {
        if (props.onFocus) {
            props.onFocus(e);
        }
    }

    const handleIonCancel = (e: CustomEvent) => {
        if (props.onCancel) {
            props.onCancel(e);
        }
    }

    const commonIonDatetimeProps = {
        ref: datetime,
        showDefaultButtons: true,
        presentation: props.presentation,
        doneText: props.doneText,
        cancelText: props.cancelText,
        min: props.min,
        max: props.max,
        minuteValues: props.minuteValues,
        onIonChange: handleIonChange,
        onIonFocus: handleIonFocus,
        onIonCancel: handleIonCancel,
    };

    return (
        <StyledDate>
            <StyledFakeField className={props.className} onClick={() => handleShowPicker()}>
                {formattedValue}
            </StyledFakeField>
            {props.popover &&
                <IonPopover
                    keepContentsMounted={props.keepContentsMounted}
                    isOpen={showPicker}
                    onWillPresent={(e) => {
                        if (props.onWillPresent) {
                            props.onWillPresent(e);
                        }
                    }}
                    onDidDismiss={() => updateShowPicker(false)}
                >
                    <IonDatetime
                        {...commonIonDatetimeProps}
                        hourCycle="h23"
                        value={props.value ? props.value : props.defaultPopoverValue}
                        onIonBlur={async () => {
                            await Keyboard.hide();
                        }}
                    />
                </IonPopover>
            }
            {!props.popover &&
                <StyledIonModal
                    keepContentsMounted={props.keepContentsMounted}
                    className="date-picker"
                    isOpen={showPicker}
                    onDidDismiss={() => updateShowPicker(false)}
                    initialBreakpoint={0.53}
                    breakpoints={[0, 0.53, 0.99]}
                >
                    <IonDatetime
                        {...commonIonDatetimeProps}
                        hourCycle="h23"
                        size="cover"
                        value={props.value}
                    />
                </StyledIonModal>
            }
        </StyledDate>
    );
}

export default React.memo(DateInput);