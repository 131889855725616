import styled from 'styled-components';

export const StyledConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 18px 8px 0 18px;
`;

export const StyledConversationMessages = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 1;
  overflow-y: auto;

  .message {
    align-self: flex-end;
    padding: 10px 16px;
    background: #0085FF;
    border-radius: 12px 0px 12px 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #FFFFFF;
    margin-bottom: 4px;
    margin-left: 23px;

    &.own {
      align-self: flex-start;
      background: #F4F4F9;
      border-radius: 12px 12px 12px 0px;
      color: #000000;
      position: relative;

      img {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        position: absolute;
        bottom: 0;
        left: -23px;
      }
    }
  }
`;

export const StyledNewMessage = styled.div`
  margin-top: 16px;
  position: relative;

  input {
    height: 48px;
  }

  .add-button {
    width: 42px;
    height: 42px;
    min-height: 42px;

    background: #0085FF;
    border-radius: 12px;

    position: absolute;
    right: 3px;
    top: 3px;

    img {
      width: 14px;
      heigth: 14px;
    }
  }
`;