import React, {Ref, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import {PhoneNumberPrefix} from '../../../enums/phoneNumberPrefix';
import {validatePhoneNumber} from '../../../services/validation.service';
import {StyledInput} from './input.style';
import {StyledPhoneInput} from './phoneInput.style';

type ExactPhoneInputProps = {
    phoneNumer: string;
    showListAbove?: boolean;
    name?: string;
    errorChanged: (error: boolean, code: string) => void
    phoneNumerChanged: (phoneNumer: string) => void;
    errorClass?: string;
    isValid?: (isValid: boolean) => void;
    isPhonePl?: (isPhonePl: boolean) => void;
};

const ExactPhoneInput: React.FC<ExactPhoneInputProps> = (props: ExactPhoneInputProps) => {

    const {t} = useTranslation();
    const phoneCodeParts = (props.phoneNumer || '').split(' ');

    const [dialCode, updateDialCode] = useState<string>(phoneCodeParts.length > 1 ? phoneCodeParts[0] : '');
    const [phoneNumber, updatePhoneNumber] = useState<string>(phoneCodeParts.length > 1 ? phoneCodeParts.splice(1, phoneCodeParts.length).join(' ') : phoneCodeParts[0]);
    const [fullPhoneNumber, updateFullPhoneNumber] = useState<string>(props.phoneNumer);

    const handlePhoneInputOnChange = (value: string, data: any, event: any, formattedValue: any) => {
        let newPhoneNumber = '';
        let countryNumber = '';

        if (data.dialCode) {
            countryNumber = data.dialCode;
            updateDialCode(countryNumber);

            if (value.startsWith(data.dialCode)) {
                newPhoneNumber = value.slice(data.dialCode.length);
            } else {
                newPhoneNumber = value;
            }
        } else {
            newPhoneNumber = value;
        }

        updatePhoneNumber(newPhoneNumber);

        updateDialCode(countryNumber);
        updateFullPhoneNumber(`${countryNumber} ${newPhoneNumber}`);
        props.phoneNumerChanged(`${countryNumber} ${newPhoneNumber}`);
        props.errorChanged(!newPhoneNumber || !countryNumber || !validatePhoneNumber(newPhoneNumber, countryNumber), countryNumber);
        if (props.isValid) {
            props.isValid(validatePhoneNumber(newPhoneNumber, countryNumber));
        }
        if (props.isPhonePl) {
            if (countryNumber == '48' || countryNumber == '+48') {
                props.isPhonePl(true);
            } else {
                props.isPhonePl(false);
            }
        }
    }

    const handleInputOnChange = (phoneNumber: string) => {
        updatePhoneNumber(phoneNumber);
        updateFullPhoneNumber(`${dialCode} ${phoneNumber}`);
        props.phoneNumerChanged(`${dialCode} ${phoneNumber}`);

        props.errorChanged(!phoneNumber || !dialCode || !validatePhoneNumber(phoneNumber, dialCode), dialCode);
        if (props.isValid) {
            props.isValid(validatePhoneNumber(phoneNumber, dialCode));
        }
        if (props.isPhonePl) {
            if (dialCode == '48' || dialCode == '+48') {
                props.isPhonePl(true);
            } else {
                props.isPhonePl(false);
            }
        }
    }

    useEffect(() => {
        refreshData();
    }, [props.phoneNumer]);

    const refreshData = () => {
        const phoneCodeParts = (props.phoneNumer || '').split(' ');
        let error = false;
        let pN = '';

        if (phoneCodeParts.length > 1) {
            const phoneNumber = phoneCodeParts.splice(1, phoneCodeParts.length).join(' ');

            updateDialCode(phoneCodeParts[0])
            updatePhoneNumber(phoneNumber);
            pN = phoneNumber;
            error = !phoneNumber.trim();
        } else {
            updateDialCode('');
            updatePhoneNumber(phoneCodeParts[0]);
            pN = phoneCodeParts[0];
            error = true;
        }

        updateFullPhoneNumber(props.phoneNumer);
        props.errorChanged(!validatePhoneNumber(pN, phoneCodeParts[0]) || error, phoneCodeParts[0]);
    }

    return (
        <StyledPhoneInput className={props.showListAbove ? "above" : ""}>
            <PhoneInput
                inputClass={"phone-hidden-input"}
                disableCountryCode={false}
                value={fullPhoneNumber}
                onChange={handlePhoneInputOnChange}
                onlyCountries={Object.keys(PhoneNumberPrefix).map(function (k) {
                    return k.toLowerCase();
                })}
                autoFormat={true}
                countryCodeEditable={false}
                prefix="+"
            />
            <StyledInput
                className={!phoneNumber || !dialCode || !validatePhoneNumber(phoneNumber, dialCode) ? 'phone-input ' + props.errorClass : 'phone-input'}
                name={props.name}
                value={phoneNumber}
                type="tel"
                onChange={(event) => {
                    handleInputOnChange(event.target.value)
                }}
                placeholder={t('profileTab.personalData.phoneNumber')}></StyledInput>
        </StyledPhoneInput>
    )
}

export default ExactPhoneInput;
