import styled from 'styled-components';

type StyledInputType = {
    error?: boolean;
};

export const StyledTextarea = styled.textarea<StyledInputType>`
    height: 120px;
    left: 24px;
    top: 303px;
    background: #F4F4F9;
    padding: 16px;
    border-radius: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    border: none;
    
    &.full-width {
        width: 100%;
    }
  
    &.margin-bottom {
        margin-bottom: 5px;
    }
`;
