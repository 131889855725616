import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Loan} from "../../../models/loan";
import {RateApp} from "capacitor-rate-app";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type NewLoanTakenModalProps = {
    loan: Loan | undefined;
    result: string;
    isOpen: boolean;
    onClose: () => void;
};

const NewLoanTakenModal: React.FC<NewLoanTakenModalProps> = (props: NewLoanTakenModalProps) => {

    const {t} = useTranslation();

    const [showLoanTaken, updateShowLoanTaken] = useState<boolean>(false);
    const [rateRequestSent, updateRateRequestSent] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowLoanTaken(props.isOpen);

        if (props.isOpen == true && props.result == 'success' && rateRequestSent == false) {
            RateApp.requestReview();
            updateRateRequestSent(true);
        }
    };

    return (
        <Modal
            isOpen={showLoanTaken}
            canDismiss={disableSwipeToClose}
            maxHeight={'80%'}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title full">
                        {props.result == 'error' ? t("loanPage.newLoanTaken.title.error") : t("loanPage.newLoanTaken.title.success")}
                    </h1>
                    <div className="content full-height">
                        <p>{props.result == 'error' ? t("loanPage.newLoanTaken.content.error") : t("loanPage.newLoanTaken.content.success", {amount: props.loan?.amount, currency: props.loan?.currency})}</p>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default NewLoanTakenModal;