import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Task} from './components/taskItem/taskItem.component';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import TimetablePane from "./panes/timetable.component";

type TimetablePaneProps = {
    topEdge?: number;
}

enum TimetableOption {
    SIMPLIFIED,
    MONTH
}

type SimplifiedCategory = {
	id: number;
    place?: string;
    title: string;
    tasks: Task[];
}

const TimetablePage: React.FC<TimetablePaneProps> = (props: TimetablePaneProps) => {
	const { t } = useTranslation();
	const header = useRef<HTMLIonHeaderElement>(null);
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	useEffect(() => {
		updateHeight();
	},[]);

	const updateHeight = () => {
		if (header.current ?.clientHeight == 0) {
			setTimeout(updateHeight);
		} else {
			updateTopEdge(header.current?.clientHeight);
		}
	};

	return (
		<IonPage>
			<StyledIonContent>
				<IonHeader ref={header} className="ion-no-border">
					<StyledIonToolbar>
						<StyledIonTitle className="left">{t("notificationTab.optionTimetable")}</StyledIonTitle>
					</StyledIonToolbar>
				</IonHeader>
				<TimetablePane topEdge={topEdge} />
			</StyledIonContent>
		</IonPage>
	);
};

export default TimetablePage;
