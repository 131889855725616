import React, {useEffect, useState} from 'react';
import Modal from '../../../components/modal/modal.component';
import {StyledOption} from "../../../components/options/options.style";
import {ContractComment} from "../../../models/contractComment";
import {useTranslation} from "react-i18next";
import moment from "moment";

type RemarksListModalProps = {
    isOpen: boolean;
    comments: ContractComment[] | undefined;
    onClose: () => void;
    appendix?: boolean;
};

const RemarksListModal: React.FC<RemarksListModalProps> = (props: RemarksListModalProps) => {

    const {t} = useTranslation();

    const [showRemarksList, updateShowRemarksList] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowRemarksList(props.isOpen);
    };

    return (
        <Modal
            isOpen={showRemarksList}
            onDidDismiss={() => handleCloseRemarks()}>
            {!props.appendix && <h1 className="title">{t("contractPage.content.remarksButton")}</h1>}
            {props.appendix && <h1 className="title">{t("contractAppendixPage.content.remarksButton")}</h1>}
            {
                props.comments && props.comments.length && props.comments?.map((comment, index) => {
                    return <StyledOption key={index}>
                        {
                            !props.appendix &&
                                <>{t(comment.comment)}</>
                        }
                        {
                            props.appendix &&
                                <>{comment.comment}</>
                        }
                        {
                            comment.description &&
                                <small>{comment.description}</small>
                        }
                        {
                            comment.status &&
                                <small>{t('contractPage.remarks.reaction.'+comment.status.toLowerCase())}: {moment(comment.status_changed_at).format('DD.MM.YYYY')}</small>
                        }
                    </StyledOption>
                })
            }
        </Modal>
    );
};

export default RemarksListModal;