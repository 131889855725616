import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../utils/tools/modals";
import {Contract} from "../../../models/contract";

type ContractsGuideModalProps = {
    isOpen: boolean;
    onClose: () => void;
    contracts: [] | undefined;
};

const ContractsGuideModal: React.FC<ContractsGuideModalProps> = (props: ContractsGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);
    const [contractData, setContractData] = useState<Contract>();

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
        let contracts = props.contracts;
        if (contracts && contracts.length > 0) {
            let lastElement = contracts[contracts.length - 1];
            if (lastElement) {
                setContractData(lastElement);
            }
        }

    };

    return (
        <Modal
            isOpen={showGuide}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
            {contractData?.is_ap &&   <StyledModalContent>
                <h1 className="title">{t("contractPage.contractsGuide.ap.title")}</h1>
                <div className="content full-height">
                    <h5>{t("contractPage.contractsGuide.sign.ap.title")}</h5>

                    <p>{t("contractPage.contractsGuide.sign.intro1")}</p>
                    <p>{t("contractPage.contractsGuide.sign.intro2")}</p>

                    <h6><strong>{t("contractPage.contractsGuide.sign.waitingForSign.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p5")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p6")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p7")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.comments.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.comments.p1")}</li>
                        <li>
                            {t("contractPage.contractsGuide.sign.comments.p2")}
                            <ul>
                                <li>{t("contractPage.contractsGuide.sign.comments.p21")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p23")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p26")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p27")}</li>
                            </ul>
                        </li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p5")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p6")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.reject.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.reject.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p4")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.change_data.title")}</strong></h6>
                    <p>{t("contractPage.contractsGuide.sign.change_data.intro")}</p>

                </div>
            </StyledModalContent>}

            {!contractData?.is_ap &&   <StyledModalContent>
                <h1 className="title">{t("contractPage.contractsGuide.title")}</h1>
                <div className="content full-height">
                    <h5>{t("contractPage.contractsGuide.sign.title")}</h5>

                    <p>{t("contractPage.contractsGuide.sign.intro1")}</p>
                    <p>{t("contractPage.contractsGuide.sign.intro2")}</p>

                    <h6><strong>{t("contractPage.contractsGuide.sign.waitingForSign.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p5")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p6")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForSign.p7")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.waitingForDocuments.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p5")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p6")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p7")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p8")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p9")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p10")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p11")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p12")}</li>
                        <li>{t("contractPage.contractsGuide.sign.waitingForDocuments.p13")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.comments.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.comments.p1")}</li>
                        <li>
                            {t("contractPage.contractsGuide.sign.comments.p2")}
                            <ul>
                                <li>{t("contractPage.contractsGuide.sign.comments.p21")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p22")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p23")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p24")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p25")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p26")}</li>
                                <li>{t("contractPage.contractsGuide.sign.comments.p27")}</li>
                            </ul>
                        </li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p5")}</li>
                        <li>{t("contractPage.contractsGuide.sign.comments.p6")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.reject.title")}</strong></h6>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.reject.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.reject.p4")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.sign.change_data.title")}</strong></h6>
                    <p>{t("contractPage.contractsGuide.sign.change_data.intro")}</p>

                    <h6><strong>{t("contractPage.contractsGuide.sign.additional_documents.title")}</strong></h6>
                    <p>{t("contractPage.contractsGuide.sign.additional_documents.intro")}</p>

                    <h6><strong>{t("contractPage.contractsGuide.sign.terminate.title")}</strong></h6>
                    <p>{t("contractPage.contractsGuide.sign.terminate.intro")}</p>
                    <ol>
                        <li>{t("contractPage.contractsGuide.sign.terminate.p1")}</li>
                        <li>{t("contractPage.contractsGuide.sign.terminate.p2")}</li>
                        <li>{t("contractPage.contractsGuide.sign.terminate.p3")}</li>
                        <li>{t("contractPage.contractsGuide.sign.terminate.p4")}</li>
                        <li>{t("contractPage.contractsGuide.sign.terminate.p5")}</li>
                    </ol>

                    <h6><strong>{t("contractPage.contractsGuide.attachments.title")}</strong></h6>
                    <p>{t("contractPage.contractsGuide.attachments.intro")}</p>
                </div>
            </StyledModalContent>}
        </Modal>
    );
};

export default ContractsGuideModal;