import {appApi} from "./api.service";
import {RecruitmentPathResult, RecruitmentPathResults, RecruitmentPathTestResult} from "../models/recruitmentPathData";

let recruitmentEndpointPrefix = 'contact-recruitment-test-path';
let contactEndpointPrefix = 'contact-test';

const getTestPathByHash = async (hash: string) => {
	return appApi.get(recruitmentEndpointPrefix + '/path/' + hash);
}

const updatePathResult = async (result: RecruitmentPathResult, hash?: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/update-result/' + hash, {results: result});
}

const updatePathResults = async (result: RecruitmentPathResults, hash?: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/update-result/' + hash, {results: result});
}

const getFaqById = async (id: number) => {
	return appApi.get(contactEndpointPrefix + '/faq/' + id);
}

const getTestById = async (id: number) => {
	return appApi.post(contactEndpointPrefix + '/test/' + id);
}

const finishPath = async(hash: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/finish-path/' + hash);
}

const refreshPath = async(type: string, hash?: string) => {
	return appApi.get(recruitmentEndpointPrefix + '/refresh-test/' + hash + '/' + type);
}

const abortPath = async(hash: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/abort-path/' + hash);
}

const getContactFormByPath = async(hash: string) => {
	return appApi.get(recruitmentEndpointPrefix + '/contract-form/' + hash + '/json');
}

const getContactFormUrlByPath = async(hash?: string) => {
	return appApi.get(recruitmentEndpointPrefix + '/contract-form/' + hash + '/url');
}

const historyInsert = async(data: {}, hash?: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/history-insert/' + hash, data, {
		headers: {
			"No-Loader": true,
		}}
	)
}

const getTestPreviewImage = async(type: string) => {
	return appApi.get(recruitmentEndpointPrefix + '/mime-animation/' + type);
}

const passTests = async(hash: string) => {
	return appApi.post(recruitmentEndpointPrefix + '/pass-tests/' + hash);
}


export {
	getTestPathByHash,
	updatePathResult,
	updatePathResults,
	getFaqById,
	getTestById,
	finishPath,
	refreshPath,
	abortPath,
	getContactFormByPath,
	getContactFormUrlByPath,
	historyInsert,
	getTestPreviewImage,
	passTests
};
