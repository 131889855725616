import React, { useEffect } from 'react';
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import StyledTitle from '../../components/title/index';
import StyledSubTitle from '../../components/title/subTitle';
import { StyledLoginContainer } from '../login/login.styles';
import ForgotPasswordForm from './form/forgotPasswordForm.component';
import { useTranslation } from 'react-i18next';
import CircleImage, { ImageTypes } from '../../components/circleImage/circleImage.component';
import {loginStatusBarUpdate} from "../../utils/tools/statusbar";
import {StyledWiteButton} from "../../components/button/button.style";
import {useHistory} from "react-router-dom";

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    useIonViewWillEnter(() => {
        loginStatusBarUpdate();
    });

    function goToLogin(e: any) {
        e.preventDefault();
        history.push('/login');
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledLoginContainer>
                    <CircleImage image={ImageTypes.PRIVACY}></CircleImage>
                    <StyledSubTitle>{t("forgotPassword.subTitle")}</StyledSubTitle>
                    <StyledTitle>{t("forgotPassword.title")}</StyledTitle>
                    <ForgotPasswordForm />

                    <StyledWiteButton onClick={goToLogin}>
                        {t("forgotPassword.successButtonBackLogin")}
                    </StyledWiteButton>
                </StyledLoginContainer>
            </IonContent>
        </IonPage>
    );
};

export default ForgotPassword;
