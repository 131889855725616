export interface IAddEditValuePageConfiguration {
    title: string;
	type?: string;
	defaultValue?: string;
    handleSave?: (value: any) => void,
    handleDismiss?: () => void;
    url?: string;
    placeholder?: string;
    values?: any[]
}

let configuration: IAddEditValuePageConfiguration | undefined = undefined;

export function setConfiguration(newConfiguration: IAddEditValuePageConfiguration) {
    configuration = newConfiguration;
}

export function getConfiguration(): IAddEditValuePageConfiguration | undefined {
    return configuration;
}
