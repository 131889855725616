import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {IonItem} from "@ionic/react";
import {StyledRedButton} from "../../../components/button/button.style";
import Pane from "../../../components/pane/pane.component";
import {Contract} from "../../../models/contract";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type TerminateModalProps = {
    isOpen: boolean;
    contract?: Contract,
    onClose: () => void;
    onTerminate: () => void;
};

const TerminateModal: React.FC<TerminateModalProps> = (props: TerminateModalProps) => {

    const {t} = useTranslation();

    const [showTerminate, updateShowTerminate] = useState<boolean>(false);
    const [terminateCheckbox, updateTerminateCheckbox] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowTerminate(props.isOpen);
    };

    const onSubmit = () => {
        props.onTerminate();
    };

    return (
        <Modal
            isOpen={showTerminate}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleCloseRemarks()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.terminate.title")}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.contract?.termination_content || ''}}/>

                        <IonItem lines="none">
                            <StyledIonLabel
                                className="ion-text-wrap">{t('contractPage.terminate.terminateCheckbox')}</StyledIonLabel>
                            <StyledIonCheckbox name="terminateCheckbox" slot="start" onIonChange={(e) => {
                                updateTerminateCheckbox(e.detail.checked);
                            }}/>
                        </IonItem>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledRedButton type="submit"
                                         disabled={!terminateCheckbox}>{t("contractPage.terminate.button")}</StyledRedButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default TerminateModal;