import React from 'react';
import {IonSkeletonText} from "@ionic/react";

import {StyledOrders} from '../panes/reports.style';

import AvatarImage from '@assets/images/logo/x-gray.png';

const ReportsSkeleton: React.FC = () => {
    return (
        <StyledOrders>
            <div className="date-separator">
                <div className="box"><IonSkeletonText animated style={{width: '50%'}}/></div>
            </div>
            <div className="order">
                <div className="order-number">
                    <IonSkeletonText animated style={{width: '50%'}}/>
                </div>
                <div className="members">
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                </div>
                <div className="details">
                    <div className="detail"><IonSkeletonText animated style={{width: '50%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '90%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                </div>
            </div>
            <div className="order">
                <div className="order-number">
                    <IonSkeletonText animated style={{width: '50%'}}/>
                </div>
                <div className="members">
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                    <img src={AvatarImage}/>
                </div>
                <div className="details">
                    <div className="detail"><IonSkeletonText animated style={{width: '50%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '90%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '70%'}}/></div>
                    <div className="detail"><IonSkeletonText animated style={{width: '30%'}}/></div>
                </div>
            </div>
        </StyledOrders>
    );
};

export default ReportsSkeleton;
