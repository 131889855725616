import styled from "styled-components";

export const StyledWarehouseItem = styled.div`
    border-bottom: 1px solid #F6F6F8;
    padding: 12px 0;

    display: flex;
    flex-direction: row;

    position: relative;

    .content {

        margin-left: 16px;
        width: 100%;
        max-width: calc(100% - 5px);

        .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 14px;
            padding-top: 14px;
    
            color: #000000;
        }
      
        .status {
            padding-top: 14px;
        }
    
        .description {
            padding-top: 6px;
            font-size: 12px;
            line-height: 140%;
            color: #888A98;
            padding-left: 0;
            
            &.quantity {
                text-align: right;
            }
        }
    }
`;