import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import InconsistencyModuleListPane from './panes/inconcistencyModuleListPane.component';

const InconsistencyModulePage: React.FC = () => {
	const header = useRef<HTMLIonHeaderElement>(null);
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
	const { t } = useTranslation();

	useEffect(() => {
		updateHeight();
	},[]);

	const updateHeight = () => {
		if (header.current ?.clientHeight == 0) {
			setTimeout(updateHeight);
		} else {
			updateTopEdge(header.current?.clientHeight);
		}
	};

	return (
		<IonPage>
			<StyledIonContent>
				<IonHeader ref={header} className="ion-no-border">
					<StyledIonToolbar>
						<StyledIonTitle className="left">{t('inconsistencyTab.title')}</StyledIonTitle>
					</StyledIonToolbar>
				</IonHeader>
				<InconsistencyModuleListPane topEdge={topEdge}  />
			</StyledIonContent>
		</IonPage>
	);
};

export default InconsistencyModulePage;
