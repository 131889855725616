export enum ReportSetting {
    LEV_NUMBER = 'LEV_NUMBER',
    BATCH_NUMBER = 'BATCH_NUMBER',
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    MATERIAL_NUMBER = 'MATERIAL_NUMBER',
    LEV_NUMBER_REQUIRED = 'LEV_NUMBER_REQUIRED',
    BATCH_NUMBER_REQUIRED = 'BATCH_NUMBER_REQUIRED',
    SERIAL_NUMBER_REQUIRED = 'SERIAL_NUMBER_REQUIRED',
    DELIVERY_NOTE_REQUIRED = 'DELIVERY_NOTE_REQUIRED',
    MATERIAL_NUMBER_REQUIRED = 'MATERIAL_NUMBER_REQUIRED',
}
