import { IonBackdrop } from '@ionic/react';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ClockImage from '../../../../assets/images/time-clock.svg';
import { StyledButton, StyledLightRedButton } from '../../../../components/button/button.style';
import CircleImage, { ImageTypes } from '../../../../components/circleImage/circleImage.component';
import { StyledActionIonModal, StyledActionSheet, StyledIonModal } from '../../../../components/modal/modal.style';
import { ContractAttachmentFolderFile } from '../../../../models/contractAttachmentFolderFile';
import {StyledAttachementsActionSheetContainer} from './attachementActionSheet.style';
import TruncateText from "../../../../components/truncateText/truncateText.component";

type AttachementsActionSheetProps = {
    isVisible: boolean;
    file?: ContractAttachmentFolderFile;
    onRemove: () => void;
    onChangeName: () => void;
    onDismiss: () => void;
}

const AttachementsActionSheet: React.FC<AttachementsActionSheetProps> = (props: AttachementsActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        changeVisibility(props.isVisible);
    });
    
    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.5}
            breakpoints={[0, 0.5]}
        >
                <StyledAttachementsActionSheetContainer className="t-p-50" onClick={(e) => e.stopPropagation()}>
                    <CircleImage image={ImageTypes.T}></CircleImage>
                    <br />
                    <span className="name">
                        <TruncateText width={300}>{props.file?.original_name}</TruncateText>
                    </span>
                    <br />
                    <span className="date">
                        <img src={ClockImage}/> {moment(props.file?.created_at).format('DD.MM.YYYY')}
                    </span>
                    <StyledButton onClick={(e) => { props.onChangeName(); e.stopPropagation(); }}>{t("contractPage.attachements.actionSheet.changeName")}</StyledButton>
                    <StyledLightRedButton onClick={(e) => { props.onRemove(); e.stopPropagation(); }}>{t("contractPage.attachements.actionSheet.delete")}</StyledLightRedButton>
                </StyledAttachementsActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default AttachementsActionSheet;