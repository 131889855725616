import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButtons, IonHeader, IonPage, IonSelectOption } from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import { StyledContent } from './addEditCityPage.style';
import { getConfiguration } from '../../services/addEditValuePage.service';
import { StyledIonSelect } from "../../components/form/input/select.style";
import {useTranslation} from "react-i18next";
import StaticPane from '../../components/pane/static-pane.component';

const AddEditCityPage: React.FC = () => {

	const { t } = useTranslation();
	const history = useHistory();
	const [error, setError] = useState<string>();

	if (!getConfiguration()) {
        history.goBack();
    }

    const handleBack = () => {
        const handleDismiss = getConfiguration()?.handleDismiss;

        if (typeof handleDismiss === 'function') {
            handleDismiss();
        } else {
            history.goBack();
        }
    };

    const handleSave = async () => {
        const handleSave = getConfiguration()?.handleSave;

        if (typeof handleSave === 'function') {
            let response:any = await handleSave(selectedCity);
            if (response !== undefined) {
				setError(response);
			}
	    } else {
            history.goBack();
        }
    };

	const [selectedCity, setSelectedCity] = useState<string>('');
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{getConfiguration()?.title}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <StyledContent>
						<StyledIonSelect value={selectedCity} okText="Okay" cancelText="Dismiss" justify="end" onIonChange={e => setSelectedCity(e.detail.value)}>
							<IonSelectOption value="Częstochowa">Częstochowa</IonSelectOption>
							<IonSelectOption value="Poznań">Poznań</IonSelectOption>
							<IonSelectOption value="Warszawa">Warszawa</IonSelectOption>
							<IonSelectOption value="Wrocław">Wrocław</IonSelectOption>
						</StyledIonSelect>
						{error && <p className="errorMessage">{error}</p>}
					</StyledContent>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AddEditCityPage;
