import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {IonAlert, useIonViewWillLeave} from '@ionic/react';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner";
import {Toast} from "@capacitor/toast";
import moment from "moment";
import {Order} from "../reportPage.component";
import {Moment} from "moment/moment";

type QrScannerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    order?: Order;
    onSubmit: (label: ReportRowComponentLabel) => void;
    onSingleSubmit: (labelName: string, labelValue: string) => void;
};

export type ReportRowComponentLabel = {
    quantity: string|null;
    componentNumberText: string|null;
    serialNumber: string|null;
    levNumber: string|null;
    deliveryNote: string|null;
    comment: string|null;
    batchNumber: string|null;
    materialNumber: string|null;
}

const LabelScannerModal: React.FC<QrScannerModalProps> = (props: QrScannerModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [scanStartAt, setScanStartAt] = useState<Moment|undefined>();
    const [scanIntervalAt, setScanIntervalAt] = useState<Moment|undefined>();
    const [scannedLabel, setScannedLabel] = useState<ReportRowComponentLabel>({
        comment: null,
        quantity: null,
        deliveryNote: null,
        levNumber: null,
        serialNumber: null,
        componentNumberText: null,
        batchNumber: null,
        materialNumber: null,
    });

    const handleClose = () => {
        props.onClose();
        stopScan();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
        if (props.isOpen) {
            setScanStartAt(moment());
            setScanIntervalAt(moment());
            scanLabel();
        }
    };

    const onSubmit = async (label: ReportRowComponentLabel) => {
        props.onSubmit(label);
    };

    const onSingleScan = async (labelName: string, labelValue: string, fieldName: string) => {
        await Toast.show({
            text: labelName+': '+labelValue,
            duration: 'short',
            position: 'bottom',
        });

        props.onSingleSubmit(fieldName, labelValue);
    };

    let scanTimeout: NodeJS.Timeout | undefined;
    useEffect(() => {
        checkScanInterval();
        if (scanTimeout) clearInterval(scanTimeout);

        if (scanStartAt) {
            scanTimeout = setInterval(checkScanInterval, 1000);
        }

        return () => {
            if (scanTimeout) clearInterval(scanTimeout);
        };
    }, [scanIntervalAt])

    const checkScanInterval = () => {
        let now = moment();
        if (scanStartAt && now.diff(scanStartAt, 's') > (props.order?.ep2_scanner_timeout ? parseInt(props.order.ep2_scanner_timeout) : 15)) {
            onSubmit(scannedLabel);
            stopScan();
        }
    }

    useIonViewWillLeave(() => {
        stopScan();
    });

    const startScan = async () => {
        let scanStarted:any = undefined;
        let scanned:string[] = [];
        let label:ReportRowComponentLabel = {
            comment: null,
            quantity: null,
            deliveryNote: null,
            levNumber: null,
            serialNumber: null,
            componentNumberText: null,
            batchNumber: null,
            materialNumber: null,
        };

        document.body.classList.add('scanner-active');
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        BarcodeScanner.startScanning({
            targetedFormats: [
                SupportedFormat.ITF,
                SupportedFormat.ITF_14,
                SupportedFormat.CODE_39,
                SupportedFormat.CODE_39_MOD_43,
                SupportedFormat.CODE_93,
                SupportedFormat.CODE_128,
                SupportedFormat.CODABAR,
            ],
        }, (value) => {
            let now = moment();
            if (scanStarted === undefined) {
                scanStarted = now;
                setScanStartAt(now)
            }

            if (value.hasContent && value.content) {
                let labelName = value.content.charAt(0);
                let labelValue = value.content.substring(1);

                switch (labelName) {
                    case 'N':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'deliveryNote');
                            label.deliveryNote = labelValue;
                        }
                        break;
                    case 'P':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'componentNumberText');
                            label.componentNumberText = labelValue;
                        }
                        break;
                    case 'V':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'levNumber');
                            label.levNumber = labelValue;
                        }
                        break;
                    case 'S':
                    case 'M':
                    case 'X':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'serialNumber');
                            label.serialNumber = labelValue;
                        }
                        break;
                    case 'Q':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'quantity');
                            label.quantity = labelValue;
                        }
                        break;
                    case 'H':
                        if (!scanned.includes(labelName)) {
                            scanned.push(labelName);
                            onSingleScan(labelName, labelValue, 'batchNumber');
                            label.batchNumber = labelValue;
                        }
                        break;
                }
            }

            setScannedLabel(label);
            setScanIntervalAt(moment());

            if (now.diff(scanStarted, 's') > (props.order?.ep2_scanner_timeout ? parseInt(props.order.ep2_scanner_timeout) : 15) || scanned.length >= 6) {
                stopScan();
                onSubmit(label);
            }
        });

        setTimeout(() => {
            BarcodeScanner.enableTorch();
        }, 1000);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
        BarcodeScanner.disableTorch();

        document.body.classList.remove('scanner-active');
        setScanStartAt(undefined)
        setScanIntervalAt(undefined)
        if (scanTimeout) clearInterval(scanTimeout);
    };

    const scanLabel = async () => {
        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.scanningLabel'),
            duration: 'short',
            position: 'center',
        });

        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.swipeDownToClose'),
            duration: 'short',
            position: 'center',
        });

        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
    }

    return (
        <Modal
            isOpen={showModal}
            disableClose={false}
            onDidDismiss={() => handleClose()}>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default LabelScannerModal;