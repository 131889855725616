import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Loan as LoanModel} from "../../../models/loan";
import Info from '../../../components/info/info.component';
import Pane from '../../../components/pane/pane.component';
import {Links} from '../../links';
import {
    cancelLoan,
    checkLoanAvailability, getDocuments,
    getLoans,
    signLoan,
    signLoanVerify
} from "../../../services/loan.service";
import {StyledEmptyContainer} from '../../../components/content/content.style';
import CircleImage, {ImageTypes} from '../../../components/circleImage/circleImage.component';
import {IonAlert, IonList, IonToast, useIonViewWillEnter} from "@ionic/react";
import StaticPane from '../../../components/pane/static-pane.component';
import {isEmployed, UserContext} from "../../../services/auth.service";
import {log} from "../../../services/firebaseAnalytics.service";
import LoanSkeleton from "../../../components/loan/loanSkeleton.component";
import Loan from "../../../components/loan/loan.component";
import LoansGuideModal from "../../loanPage/modals/loansGuideModal.component";
import {StyledButton} from "../../../components/button/button.style";
import NewLoanModal, {NewLoanContext} from "../../loanPage/modals/newLoanModal.component";
import ApprovalsModal from "../../loanPage/modals/approvalsModal.component";
import {hasPhone} from "../../../services/teamMember.service";
import {LoanStatus} from "../../../enums/loanStatus";
import SignModal, {ISignModalConfigration} from "../../../modals/sign/sign.component";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import NewLoanTakenModal from "../../loanPage/modals/newLoanTakenModal.component";
import PreconditionsModal from "../../loanPage/modals/preconditionsModal.component";
import Notice from "../../../components/notice/notice.component";

type LoansPaneProps = {
    topEdge?: number;
}

interface LoansManager {
    setLoans: Function;
}

const contextLoans: LoansManager = {
    setLoans: () => {

    }
};

export const LoansContext = React.createContext<LoansManager>(contextLoans);

const LoansHistoryPane: React.FC<LoansPaneProps> = (props: LoansPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const [loans, setLoans] = useState<[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showNew, updateShowNew] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [availabilityMessage, setAvailabilityMessage] = useState<string>('');
    const [minAmount, setMinAmount] = useState<number>(0);
    const [maxAmount, setMaxAmount] = useState<number>(0);
    const [currency, setCurrency] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<number>(0);
    const [showPreconditions, updateShowPreconditions] = useState<boolean>(false);
    const [showApprovals, updateShowApprovals] = useState<boolean>(false);
    const [showPhoneModal, updateShowPhoneModal] = useState<boolean>(false);
    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [currentLoan, updateCurrentLoan] = useState<LoanModel | undefined>();
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();
    const [showNewLoanTaken, updateShowNewLoanTaken] = useState<boolean>(false);
    const [newLoanTakenResult, updateNewLoanTakenResult] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [signFormSubmitted, updateSignFormSubmitted] = useState(false);
    const [instructionContent, updateInstructionContent] = useState<string>('');


    const fetchLoans = async () => {
        //if (loans === undefined) {
            setLoading(true);
            setLoans([]);
            const response = await getLoans()
                .then(response => {
                    setLoans(response.data);
                })
                .catch(error => {
                    // todo what we show??
                });

            setLoading(false);
        //}
    };

    const fetchDocuments = async () => {
        const response = await getDocuments()
            .then(response => {
                if (response.data?.result && response.data.result === 'error') {
                    updateInstructionContent(t("loanPage.instructionNotAvailable"));
                } else {
                    updateInstructionContent(response.data.instruction_content);
                }
            })
            .catch(error => {
                // todo what we show??
            });
    };

    useEffect(() => {
        fetchLoans();
    }, []);

    useIonViewWillEnter(() => {
        fetchLoans();
    });

    useEffect(() => {
        fetchDocuments();
    }, []);

    const updateLoans = async (showNew:boolean = true) => {
        fetchLoans();
    }

    const contextLoans = useContext(LoansContext);
    contextLoans.setLoans = setLoans;

    const contextNewLoan = useContext(NewLoanContext);
    contextNewLoan.setMaxAmount = setMaxAmount;
    contextNewLoan.setAvailabilityMessage = setAvailabilityMessage;

    const [employed, setEmployed] = useState<boolean>(false);
    const isEmployee = async() => {
        const response = await isEmployed();
        let result:boolean = response == '1' ? true : false;
        return result;
    }

    const fetchUserData = async () => {
        let isEmployed: boolean = await isEmployee();
        setEmployed(isEmployed);
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    useIonViewWillEnter(() => {
        fetchUserData();
    });

	useEffect(() => {
		log('page_visit', {
			page: 'Profil -> Pożyczki -> Historia'
		});
	}, []);

    const handleLoanItemClick = (loan: LoanModel) => {
        if (loan.sign_status == LoanStatus.UNSIGNED) {
            updateCurrentLoan(loan);
            preSign();
        } else {
            history.push(Links.loan + '/' + loan.id);
        }
    }

    const preSign = async () => {
        updateShowPreconditions(false);

        let hasPhoneNumber = null;
        await hasPhone().then(value => {
            hasPhoneNumber = value;
        });

        if (hasPhoneNumber === false) {
            updateShowPhoneModal(true);
        } else {
            updateShowApprovals(true)
        }
    }

    const sign = async () => {
        await signLoan(currentLoan?.id)
            .then(response => {
                updateSignModalConfiguration({
                    title: t("loanPage.sign.title"),
                    info: t("loanPage.sign.info"),
                    buttonText: t("loanPage.sign.signButton"),
                    afterCodeText: t("loanPage.sign.afterCodeText", {amount: currentLoan?.amount, currency: currentLoan?.currency}),
                });

                updateShowSignModal(true);
            });

        updateShowApprovals(false);
    };

    return (
        <LoansContext.Provider value={contextLoans}>
            {(!loading && (!loans || loans.length === 0)) &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.NOTE} color="grey"/>
                        { employed && <h2>{t('profileTab.loans.empty')}</h2> }
                        { !employed && <h3>{t('profileTab.loans.notWorker')}</h3> }
                    </StyledEmptyContainer>
                </StaticPane>
            }

            {loading &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <IonList>
                        <LoanSkeleton />
                        <LoanSkeleton />
                        <LoanSkeleton />
                        <LoanSkeleton />
                        <LoanSkeleton />
                        <LoanSkeleton />
                        <LoanSkeleton />
                    </IonList>
                </StaticPane>
            }

            {!loading && employed && loans && loans.length > 0 &&
            <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
                {loans.map((loan: LoanModel, key) => {
                    return <Loan key={key}
                                     loan={loan}
                                     status={loan.sign_status}
                                     onClick={(e) => {
                                         handleLoanItemClick(loan);
                                     }}></Loan>
                })}
            </Pane>
            }

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
        </LoansContext.Provider>
    );
};

export default LoansHistoryPane;
