import {ReportRowWorker} from "../app/reportPage/reportPage.component";
import {AuditReportFormModel} from "../models/auditReport";
import {DataSelectable} from "../models/common";

export type QRCodePageConfiguration = {
    title: string;
	subtitle: string;
    manualButtonText: string;
    errorText?: string;
    handleManualButton?: () => void;
    handleScan?: (value: any) => any;
    handleExtendedSelected?: (worker: ReportRowWorker) => any;
}

export type QRCodeAuditPageConfiguration = {
    title: string;
	subtitle: string;
    manualButtonText: string;
    errorText?: string;
    handleManualButton?: () => void;
    handleScan?: (value: any) => any;
    report: AuditReportFormModel;
    handleListSelected: (worker: DataSelectable) => any;
}

let workerConfiguration: QRCodePageConfiguration | undefined = undefined;

export function setWorkerConfiguration(newConfiguration: QRCodePageConfiguration) {
    workerConfiguration = newConfiguration;
}

export function getWorkerConfiguration(): QRCodePageConfiguration | undefined {
    return workerConfiguration;
}

let auditReportWorkerConfiguration: QRCodeAuditPageConfiguration | undefined = undefined;

export function setAuditReportWorkerConfiguration(newConfiguration: QRCodeAuditPageConfiguration) {
    auditReportWorkerConfiguration = newConfiguration;
}

export function getAuditReportWorkerConfiguration(): QRCodeAuditPageConfiguration | undefined {
    return auditReportWorkerConfiguration;
}

