import {appApi} from "./api.service";
import {Notification} from "../models/notification";
import { PushNotifications, PushNotification, PushNotificationActionPerformed, PushNotificationToken} from "@capacitor/push-notifications";

const push = () => {

	// Register with Apple / Google to receive push via APNS/FCM
	//PushNotifications.register();

	// On success, we should be able to receive notifications
	PushNotifications.addListener('registration',
		(token: PushNotificationToken) => {
			addFcmToken(token.value);
		}
	);

	PushNotifications.requestPermissions().then(result => {
		if (result.receive === 'granted') {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register();
		} else {
			// error
		}
	});
}

const addFcmToken = async (token: string) => {
	return appApi.post('user/profile/fcm/token/add', { fcmToken: token });
};

const getNotifications = async (hideLoader: boolean = false) => {
    if(hideLoader) {
        return appApi.get('user/notifications', {
            headers: {
                'No-Loader': true
            }
        });
    } else {
        return appApi.get('user/notifications');
    }
};

const getUnreadNotifications = async () => {
    return await getNotifications(true).then(response => {
        let notifications: [] = response?.data ;

        return notifications.filter((notification: Notification) => {
            return notification.status === 'sent';
        });
    });
}

const hasUnreadNotifications = async () => {
    return await getUnreadNotifications().then(r => r.length > 0);
}

const markNotificationAsRead = async (notificationId: string|bigint) => {
    return appApi.post('user/notifications/'+notificationId+ '/mark-as-read', {}, {
        headers: {
            'No-Loader': true
        }
    });
};

export {
	push,
    addFcmToken,
    getNotifications,
    markNotificationAsRead,
    getUnreadNotifications,
    hasUnreadNotifications
}
