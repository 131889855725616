export enum ContractStatus {
    SIGNED_BY_KO = 'SIGNED_BY_KO',
    SEND_BY_KO = 'SEND_BY_KO',
    WAITING_FOR_WORKER_SIGN = 'WAITING_FOR_WORKER_SIGN',
    WAITING_FOR_WORKER_SIGN_STATEMENT = 'WAITING_FOR_WORKER_SIGN_STATEMENT',
    WAITING_FOR_DOCUMENTS = 'WAITING_FOR_DOCUMENTS',
    WAITING_FOR_ADDITIONAL_DOCUMENTS = 'WAITING_FOR_ADDITIONAL_DOCUMENTS',
    DOCUMENTS_IN_VERIFICATION = 'DOCUMENTS_IN_VERIFICATION',
    DOCUMENTS_STATEMENT_VERIFICATION = 'DOCUMENTS_STATEMENT_VERIFICATION',
    SIGNED_BY_WORKER = 'SIGNED_BY_WORKER',
    REJECTED_BY_WORKER = 'REJECTED_BY_WORKER',
    WAITING_FOR_KO_SIGN = 'WAITING_FOR_KO_SIGN',
    SIGNED = 'SIGNED',
    SIGNED_WITH_DOCUMENTS_STATEMENT = 'SIGNED_WITH_DOCUMENTS_STATEMENT',
    SIGNED_WITH_ADDITIONAL_DOCUMENTS = 'SIGNED_WITH_ADDITIONAL_DOCUMENTS',
    TERMINATED = 'TERMINATED',
    // appendix only
    ENDED = 'ENDED'
}