import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton} from '../../../components/button/button.style';
import {StyledTextarea} from '../../../components/form/input/textarea.style';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {StyledModalContent} from "../../../components/modal/modal.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import {WorkerWarehouseTransfer} from "../../../models/worker";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (comment: string) => void;
    transfer: WorkerWarehouseTransfer | undefined;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {

    const {t} = useTranslation();

    let focused = false;
    const commentRef = useRef<HTMLTextAreaElement>(null);
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [comment, updateComment] = useState<string>('');

    const handleClose = () => {
        updateComment('');
        props.onClose();
    };

    const handleCommentChange = (event: any) => {
        updateComment(event.target.value);
    };

    const handleSaveRemarks = () => {
        props.onSuccess(comment)
    };

    useEffect(() => {
        handleFocus();
        handleOpen();
    });

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    const handleFocus = () => {
        if (!focused) {
            focused = true;
            commentRef.current?.focus();
        }
    };

    return (
       <Modal
           isOpen={showModal}
           canDismiss={disableSwipeToClose}
           onDidDismiss={() => handleClose()}>
           <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleClose() } />
                    <h1 className="title">{t("workerWarehousePage.reject_modal.title")}</h1>
                    <div className="content">
                        <StyledTextarea className="full-width" ref={commentRef} onChange={handleCommentChange}></StyledTextarea>
                    </div>
                    <StyledFooter className="modal">
                        <StyledButton
                            onClick={() => handleSaveRemarks()}
                            disabled={!comment}>{t("workerWarehousePage.reject_modal.button")}</StyledButton>
                    </StyledFooter>
           </StyledModalContent>
       </Modal>
    )
};

export default ConfirmationModal;