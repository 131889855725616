import styled from "styled-components";

export const StyledFilterChipsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 3px;
    border-radius: 12px;
    justify-content: space-between;
    margin: 16px 24px 0;
    display: inline-flex;
    width: calc(100% - 48px);
    overflow-x: scroll;

    ion-chip {
        font-family: Inter;
        --background: #363543;
        --color: #FFFFFF;
        flex-shrink: 0;
        border-radius: 12px;

        ion-icon {
            color: #B5B7C6;
        }
    }
`;
