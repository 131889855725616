import React from 'react';
import { StyledLogoContainer, StyledLogoImage } from './logo.style';
import LogoImg from '../../assets/images/logo/xpeople.png';

const Logo: React.FC = () => {
    return (
        <StyledLogoContainer>
            <StyledLogoImage src={LogoImg} />
        </StyledLogoContainer>
    );
};

export default Logo;