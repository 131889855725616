import React, { PropsWithChildren } from 'react';
import { StyledInfoContainer, StyledInfoImage } from './notice.style';

type NoticeProps = {
    className?: string;
}

const Notice: React.FC<PropsWithChildren<NoticeProps>> = (props) => {
    return (
        <StyledInfoContainer className={props.className}>
            <div>{props.children}</div>
        </StyledInfoContainer>
    );
};

export default Notice;