import {IonCol, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../../../assets/images/arrow-right.svg";
import React from "react";

const JobOfferSkeleton: React.FC = () => {
    return (
        <IonRow>
            <IonCol size="10.5">
                <div className="title"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                <div className="details"><IonSkeletonText animated style={{ width: '90%' }} /></div>
                <div className="details"><IonSkeletonText animated style={{ width: '80%' }} /></div>
            </IonCol>
            <IonCol size="1.5" className="arrow">
                <img src={ArrowRightImage} />
            </IonCol>
        </IonRow>
    )
}

export default JobOfferSkeleton;
