import React, {useEffect, useState} from 'react';
import {IonAlert, IonCol, IonLoading, IonRow, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import axios from "axios";

import Form from "@components/form";
import Pane from "@components/pane/pane.component";
import Accordion from "@components/accordion/accordion";
import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";
import PaginatedListModal from "@components/modal/paginatedListModal.component";

import {StyledButton, StyledButtonWrapper, StyledTravelAllowanceContent,} from "@app/travelAllowance/travelAllowance.style";
import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";

import {addRideRoute, calculateDistance, editRideRoute, getPlacesOfService, getPossibleCoordinators, validateRideRoute} from "@services/travelAllowance/rideRoute.service";

import {PlaceOfService, RideRoute, RideRouteForm} from "@models/travelAllowance/rideRoute";
import {Coordinator} from "@models/travelAllowance/ride";

import {Links} from "@app/links";

import {ReactComponent as SaveIcon} from '@assets/images/travelAllowance/save.svg';
import {RideRoutePaneType} from "@enums/travelAllowance/rideRoute";
import useComponentMounted from "@hooks/useComponentMounted";
import {RideMatrix} from "@models/travelAllowance/rideMatrix";
import {getRideMatrix} from "@services/travelAllowance/rideMatrix.service";

type RideRouteAddEditPaneProps = {
    type: RideRoutePaneType,
    topEdge?: number,
    rideRouteDefaultValues?: RideRoute
}

const RideRouteAddEditPane: React.FC<RideRouteAddEditPaneProps> = ({type, topEdge, rideRouteDefaultValues}: RideRouteAddEditPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();
    const {register, handleSubmit, control, setValue, errors, watch, reset} = useForm<RideRouteForm>(
        {
            mode: 'onChange',
            defaultValues: {
                startingPlace: rideRouteDefaultValues?.startingPlace,
                destinationPlace: rideRouteDefaultValues?.destinationPlace,
                startingPlaceCountry: rideRouteDefaultValues?.startingPlaceCountry,
                destinationPlaceCountry: rideRouteDefaultValues?.destinationPlaceCountry,
                distance: rideRouteDefaultValues?.distance,
                isLocal: rideRouteDefaultValues?.isLocal,
                coordinator: rideRouteDefaultValues?.coordinator.id,
                placeOfService: rideRouteDefaultValues?.placeOfService.id,
            }
        }
    );
    const isMounted = useComponentMounted();

    const [coordinator, setCoordinator] = useState<Coordinator | undefined>(rideRouteDefaultValues?.coordinator);
    const [placeOfService, setPlaceOfService] = useState<PlaceOfService | undefined>(rideRouteDefaultValues?.placeOfService);

    const [isDistanceEditBlocked, setIsDistanceEditBlocked] = useState<boolean>(true);
    const [isCoordinatorModalOpen, setIsCoordinatorModalOpen] = useState<boolean>(false);
    const [isPlaceOfServiceModalOpen, setIsPlaceOfServiceModalOpen] = useState<boolean>(false);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showDistanceErrorAlert, setShowDistanceErrorAlert] = useState<boolean>(false);

    const startingPlace = watch('startingPlace');
    const startingPlaceCountry = watch('startingPlaceCountry');
    const destinationPlace = watch('destinationPlace');
    const destinationPlaceCountry = watch('destinationPlaceCountry');
    const distance = watch('distance');

    useEffect(() => {
        setShowLoader(true);

        getRideMatrix()
            .then((rideMatrix: RideMatrix) => setIsDistanceEditBlocked(rideMatrix.isDistanceEditBlocked ?? true))
            .finally(() => setShowLoader(false));
    }, []);

    useEffect(() => {
        if (!isMounted) {
            return;
        }

        if (startingPlace && destinationPlace) {
            setShowLoader(true);

            const handleError = (error: any, errorMessageKey: string) => {
                const errorMessage = error.response.data.detail;

                if (errorMessage === 'Could not determine distance.') {
                    setShowDistanceErrorAlert(true);
                } else {
                    setToast(t(errorMessageKey));
                    setShowToast(true);
                }

                console.error(error);
            };

            fetchDistance()
                .then(() => handleIsLocalSet())
                .catch((error) => handleError(error, "travelAllowance.rideRoute.distanceCalculateError"))
                .finally(() => setShowLoader(false))
        }

        return () => {
            cancelToken.cancel();
        };
    }, [startingPlace, destinationPlace]);

    const fetchDistance = async () => {
        const response = await calculateDistance({
            startingPlace,
            destinationPlace
        });
        const data: { distance: number } | { message: string } = response.data;

        if (!('message' in data)) {
            setValue('distance', data.distance, {shouldValidate: true});
        } else {
            setToast(t("travelAllowance.rideRoute.calculateDistanceError"));
            setShowToast(true);

            console.error(data.message);
        }
    }

    const handleIsLocalSet = () => {
        setValue('isLocal', startingPlaceCountry === destinationPlaceCountry);
    }

    const setStartingPlaceCountryCallback = (country: string) => {
        setValue('startingPlaceCountry', country);
    }

    const setDestinationPlaceCountryCallback = (country: string) => {
        setValue('destinationPlaceCountry', country);
    }

    const handleCoordinatorSelect = (coordinator: Coordinator) => {
        setCoordinator(coordinator)
        setValue('coordinator', coordinator.id, {shouldValidate: true});
        setPlaceOfService(undefined);
        setValue('placeOfService', undefined, {shouldValidate: true});
    }

    const handlePlaceOfServiceSelect = (placeOfService: PlaceOfService) => {
        setPlaceOfService(placeOfService);
        setValue('placeOfService', placeOfService.id, {shouldValidate: true});
    }

    const handleDistanceErrorAlertConfirm = () => {
        reset({
            startingPlace: '',
            destinationPlace: '',
            startingPlaceCountry: '',
            destinationPlaceCountry: '',
            distance: undefined,
            isLocal: undefined,
        });
    }

    const onSubmit = async (data: RideRouteForm) => {
        setShowLoader(true);

        const handleSuccess = () => {
            history.replace(Links.main + Links.travelAllowance.rideRoute.list);

            setShowLoader(false);
        };

        const handleError = (error: any, errorMessageKey?: string) => {
            if (errorMessageKey) {
                setToast(t(errorMessageKey));
            } else {
                const errorMessage = error.response.data.detail;

                if (errorMessage.includes('Data integrity failed for coordinatorId')) {
                    setToast(t("travelAllowance.rideRoute.coordinatorIntegrityViolation"));
                }

                if (errorMessage.includes('Data integrity failed for placeOfServiceId')) {
                    setToast(t("travelAllowance.rideRoute.placeOfServiceIntegrityViolation"));
                }
            }

            setShowToast(true);
            setShowLoader(false);

            console.error(error);
        };

        if (type === RideRoutePaneType.ADD) {
            addRideRoute(data)
                .then(handleSuccess)
                .catch((error) => handleError(error, "travelAllowance.rideRoute.addError"));
        }

        if (rideRouteDefaultValues?.id && type === RideRoutePaneType.EDIT) {
            let validationSuccess = true;

            await validateRideRoute(rideRouteDefaultValues.id)
                .catch((error) => {
                    handleError(error);
                    validationSuccess = false;
                });

            if (!validationSuccess) {
                return;
            }

            editRideRoute(rideRouteDefaultValues.id, data)
                .then(handleSuccess)
                .catch((error) => handleError(error, "travelAllowance.rideRoute.editError"));
        }
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                <StyledTravelAllowanceContent>
                    <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>

                    <IonAlert
                        isOpen={showDistanceErrorAlert}
                        onDidDismiss={() => setShowDistanceErrorAlert(false)}
                        header={t('travelAllowance.rideRoute.distanceErrorAlert')}
                        buttons={[
                            {
                                text: t('common.ok'),
                                handler: () => {
                                    handleDistanceErrorAlertConfirm();
                                }
                            }
                        ]}
                    />
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toast}
                        duration={6000}
                        position="top"
                        color="danger"
                    />
                    <PaginatedListModal isOpen={isCoordinatorModalOpen}
                                        fetchData={getPossibleCoordinators}
                                        fetchParams={{date: undefined, cancelToken: cancelToken}}
                                        setValue={handleCoordinatorSelect}
                                        onClose={() => setIsCoordinatorModalOpen(false)}
                    />
                    <PaginatedListModal isOpen={isPlaceOfServiceModalOpen}
                                        fetchData={getPlacesOfService}
                                        fetchParams={{coordinatorId: coordinator?.id, cancelToken: cancelToken}}
                                        setValue={handlePlaceOfServiceSelect}
                                        onClose={() => setIsPlaceOfServiceModalOpen(false)}
                    />
                    <Form.Container onSubmit={handleSubmit(onSubmit)}>
                        <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.generalData')}>
                            {
                                <section>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.startingPlace')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="label">
                                            <Controller
                                                name={'startingPlace'}
                                                control={control}
                                                defaultValue={''}
                                                rules={{required: true}}
                                                render={({onChange}) => (
                                                    <CityAutocomplete setCountry={setStartingPlaceCountryCallback}
                                                                      onChangeCallback={onChange}
                                                                      defaultValue={startingPlace}
                                                                      className={errors.startingPlace ? 'error' : ''}/>
                                                )}
                                            />
                                            <input ref={register}
                                                   name={'startingPlaceCountry'}
                                                   type={"hidden"}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.destinationPlace')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="label">
                                            <Controller
                                                name={'destinationPlace'}
                                                control={control}
                                                defaultValue={''}
                                                rules={{required: true}}
                                                render={({onChange}) => (
                                                    <CityAutocomplete setCountry={setDestinationPlaceCountryCallback}
                                                                      onChangeCallback={onChange}
                                                                      defaultValue={destinationPlace}
                                                                      className={errors.destinationPlace ? 'error' : ''}/>
                                                )}
                                            />
                                            <input ref={register}
                                                   name={'destinationPlaceCountry'}
                                                   type={"hidden"}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <input ref={register({setValueAs: v => v === 'true'})}
                                           name={'isLocal'}
                                           type={"hidden"}/>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.distance')}
                                        </IonCol>
                                    </IonRow>
                                    <StyledInputGroup unit={true} className={errors.distance ? 'error' : ''}>
                                        <IonCol size="10" className="label">
                                            <StyledInput name="distance"
                                                         type="number"
                                                         ref={register({
                                                             required: true,
                                                             valueAsNumber: true,
                                                         })}
                                                         readOnly={isDistanceEditBlocked}
                                            />
                                        </IonCol>
                                        <IonCol size="2" className="label">
                                            <StyledInputUnit>km</StyledInputUnit>
                                        </IonCol>
                                    </StyledInputGroup>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.coordinator')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <Controller
                                                name={'coordinator'}
                                                control={control}
                                                defaultValue={''}
                                                rules={{
                                                    required: true,
                                                    valueAsNumber: true,
                                                }}
                                                render={() => (
                                                    <StyledInput
                                                        placeholder={coordinator ? coordinator.name : t('travelAllowance.rideRoute.selectCoordinator')}
                                                        className={errors.coordinator ? 'error' : ''}
                                                        onClick={() => setIsCoordinatorModalOpen(prevState => !prevState)}
                                                    >
                                                    </StyledInput>
                                                )}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('travelAllowance.rideRoute.placeOfService')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <Controller
                                                name={'placeOfService'}
                                                control={control}
                                                defaultValue={''}
                                                rules={{
                                                    required: true,
                                                    valueAsNumber: true,
                                                }}
                                                render={() => (
                                                    <StyledInput
                                                        disabled={!coordinator}
                                                        placeholder={placeOfService ? placeOfService.name : t('travelAllowance.rideRoute.selectPlaceOfService')}
                                                        className={errors.placeOfService ? 'error' : ''}
                                                        onClick={() => setIsPlaceOfServiceModalOpen(prevState => !prevState)}
                                                    >
                                                    </StyledInput>
                                                )}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </section>
                            }
                        </Accordion>
                        <StyledButtonWrapper>
                            <StyledButton type="submit">
                                <div className="btn center">
                                    <SaveIcon/>
                                    <span>{t('travelAllowance.save')}</span>
                                </div>
                            </StyledButton>
                        </StyledButtonWrapper>
                    </Form.Container>
                </StyledTravelAllowanceContent>
            }
        </Pane>
    );
};

export default RideRouteAddEditPane;