import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage, IonSelectOption} from '@ionic/react';
import {
	StyledDataTable,
	StyledIonContent,
	StyledIonTitle,
	StyledIonToolbar
} from '../../components/content/content.style';
import Pane from "../../components/pane/pane.component";
import {StyledHeaderButton, StyledHeaderButtonImage} from "../../components/button/button.style";
import {useHistory} from 'react-router-dom';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import {useTranslation} from 'react-i18next';
import CheckImage from "../../assets/images/check.svg";
import VectorImage from '../../assets/images/checkMarkGreen.svg';
import {useLocation} from "react-router-dom";
import {updateLanguages} from "../../services/profile.service";
import {updateStorage} from "../../utils/tools/storage";
import {UserLanguageLevel} from "../../enums/userLanguageLevel";

import {Preferences} from "@capacitor/preferences";
import { StyledIonSelect } from '../../components/form/input/select.style';

interface ILanguage {
    name: string;
    key: string;
    selected: boolean;
    level: string;
};

interface ILevel {
	name: string;
	value: string;
};

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

// TODO: possible merge languageTab with appModeTab into select optionTab ??
const LanguagesPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ toast, setToast ] = useState<any>(toastInit);

	const handleBack = () => {
		history.goBack();
	};

	const handleSave = async () => {
		let languages: string[] = [];
		let levels: string[] = [];

		for (const [key, value] of Object.entries(selectedLanguages)) {
			languages.push(key.toLowerCase());
			levels.push((value as string).toLowerCase());
		}

		const response = await updateLanguages(languages, levels)
			.then(response => {
				updateStorage('languages', JSON.stringify(selectedLanguages));
				history.goBack();

				return true;
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
				return false;
			});
	};

	const handleCheck = (e:any, key:string, level:string) => {
		if (selectedLanguages.hasOwnProperty(key)) {
			delete selectedLanguages[key];
		} else {
			selectedLanguages[key] = level;
		}

		setSelectedLanguages({...selectedLanguages});
		e.stopPropagation();
	}

	const handleSelectLanguageLevel = (e:any, key:string, level:string) => {
		selectedLanguages[key] = level;
		setSelectedLanguages({...selectedLanguages});
		e.stopPropagation();
	}

	const [languagesData, setLanguagesData] = useState({});
	const [selectedLanguages, setSelectedLanguages] = useState<any>({});

	const getLanguagesData = async () => {
		let languages:any = await Preferences.get({'key': 'languages'});
		let languageValues = [];

		if (languages.value) {
			languageValues = JSON.parse(languages.value)
		}

		setLanguagesData(languageValues);

		let selectedLanguagesValues : { [id: string]: string; } = {};
		let languagesValues:any = Object.entries(languageValues);
		languagesValues.forEach((language:any) => {
			selectedLanguagesValues[language[0].toLowerCase()] = language[1].toLowerCase();
		});

		setSelectedLanguages({...selectedLanguagesValues});
	};

    const languages: ILanguage[] = [{
        name: t('languagesPage.languages.al'),
		key: 'al',
        selected: selectedLanguages.hasOwnProperty('al') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.en'),
		key: 'en',
		selected: selectedLanguages.hasOwnProperty('en') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.ar'),
		key: 'ar',
		selected: selectedLanguages.hasOwnProperty('ar') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.bl'),
		key: 'bl',
		selected: selectedLanguages.hasOwnProperty('bl') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.bu'),
		key: 'bu',
		selected: selectedLanguages.hasOwnProperty('bu') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.ch'),
		key: 'ch',
		selected: selectedLanguages.hasOwnProperty('ch') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.cr'),
		key: 'cr',
		selected: selectedLanguages.hasOwnProperty('cr') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.dn'),
		key: 'dn',
		selected: selectedLanguages.hasOwnProperty('dn') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.fr'),
		key: 'fr',
		selected: selectedLanguages.hasOwnProperty('fr') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
        name: t('languagesPage.languages.sp'),
		key: 'sp',
		selected: selectedLanguages.hasOwnProperty('sp') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
    },{
		name: t('languagesPage.languages.jp'),
		key: 'jp',
		selected: selectedLanguages.hasOwnProperty('jp') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.de'),
		key: 'de',
		selected: selectedLanguages.hasOwnProperty('de') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.pl'),
		key: 'pl',
		selected: selectedLanguages.hasOwnProperty('pl') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.pr'),
		key: 'pr',
		selected: selectedLanguages.hasOwnProperty('pr') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.ru'),
		key: 'ru',
		selected: selectedLanguages.hasOwnProperty('ru') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.sw'),
		key: 'sw',
		selected: selectedLanguages.hasOwnProperty('sw') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	},{
		name: t('languagesPage.languages.it'),
		key: 'it',
		selected: selectedLanguages.hasOwnProperty('it') ? true : false,
		level: UserLanguageLevel.LANG_LEVEL_1
	}];

	const levels: ILevel[] = [{
		name: t('languagesPage.levels.lang_level_1'),
		value: UserLanguageLevel.LANG_LEVEL_1
	}, {
		name: t('languagesPage.levels.lang_level_2'),
		value: UserLanguageLevel.LANG_LEVEL_2
	}, {
		name: t('languagesPage.levels.lang_level_3'),
		value: UserLanguageLevel.LANG_LEVEL_3
	}, {
		name: t('languagesPage.levels.lang_level_4'),
		value: UserLanguageLevel.LANG_LEVEL_4
	}, {
		name: t('languagesPage.levels.lang_level_5'),
		value: UserLanguageLevel.LANG_LEVEL_5
	}];

	useEffect(() => {
		getLanguagesData();
        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
	};

	const compareWith = (o1: UserLanguageLevel, o2: UserLanguageLevel) => {
		return o1 && o2 ? o1.toLocaleLowerCase() === o2.toLocaleLowerCase() : o1 === o2;
	};

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => handleBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("languagesPage.title")}</StyledIonTitle>
						<IonButtons slot="end">
							<StyledHeaderButton onClick={() => handleSave()}>
								<StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
							</StyledHeaderButton>
						</IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40}>
                <StyledDataTable className="no-margin">
                { languages.map((data, i) => <div key={i} className="data-row" onClick={(e) => handleCheck(e, data.key, data.level)}>
                                                    <div className="data-icon-title min-height">{data.name}</div>
                                                    <div className="data-action">
														{selectedLanguages[data.key] && <img src={VectorImage} />}
													</div>
													{selectedLanguages[data.key] &&

														<StyledIonSelect placeholder={t("languagesPage.selectLanguageLevel")}
															className="data-details extended bolded" onClick={e => e.stopPropagation()}
															okText={t('common.selectOk')} cancelText={t('common.selectCancel')}
															compareWith={compareWith}
															justify="end"
															value={selectedLanguages[data.key]} onIonChange={e => { handleSelectLanguageLevel(e, data.key, e.detail.value); } }>
															{
																levels.map((level, i) =>
																	<IonSelectOption key={i} value={level.value}>{level.name}</IonSelectOption>
																)
															}
														</StyledIonSelect>
													}
                                                </div>)
                }
                </StyledDataTable>
                </Pane>
            </StyledIonContent>
        </IonPage>
    );
};

export default LanguagesPage;
