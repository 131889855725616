import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import ContentPane from "./panes/contentPane.component";
import SubNavigation, {SubNavigationOption} from "../../components/sub-navigation/subNavigation.component";
import useNavigation from "../../services/navigation.service";
import ChangesHistoryPane from "./panes/changesHistoryPane.component";

type MyDataPaneProps = {
    topEdge?: number;
}

export enum MyDataPageTabOptions {
    MY_DATA = 0,
    CHANGES_HISTORY = 1
}

export interface IMyDataTabState {
    activeTab: MyDataPageTabOptions;
}

const MyDataPage: React.FC<MyDataPaneProps> = (props: MyDataPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const options = [
        {
            name: t("myDataPage.myData")
        },
        {
            name: t("myDataPage.changesHistory")
        }
    ];

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || MyDataPageTabOptions.MY_DATA);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    useEffect(() => {
        updateHeight();
    },[]);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("myDataPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <SubNavigation options={options}
                                   defaultValue={selectedOption || MyDataPageTabOptions.MY_DATA}
                                   onSelect={handleSelect}></SubNavigation>
                </IonHeader>
                {
                    selectedOption === MyDataPageTabOptions.MY_DATA &&
                    <ContentPane topEdge={topEdge}></ContentPane>
                }
                {
                    selectedOption === MyDataPageTabOptions.CHANGES_HISTORY &&
                    <ChangesHistoryPane topEdge={topEdge}></ChangesHistoryPane>
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default MyDataPage;