import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {StyledEmptyContainer} from "../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {StyledIonContent} from './referFriends.style';
import {Device} from "@capacitor/device";
import {Directory, Filesystem, FilesystemDirectory} from "@capacitor/filesystem";
import {blobToBase64} from "../../../services/file.service";
import {FileOpener} from "@capacitor-community/file-opener";
import {IonAlert, IonBadge, useIonViewWillEnter} from "@ionic/react";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {getReferFriendsPdf} from "../../../services/referFriends.service";
import {StyledLightBlueButton} from "../../../components/button/button.style";
import {acceptReferFriend, getWorkerReferFriend} from "../../../services/worker.service";
import {Clipboard} from '@capacitor/clipboard';

type RegistryPaneProps = {
    topEdge?: number;
}

const ReferFriendsPane: React.FC<RegistryPaneProps> = (props: RegistryPaneProps) => {
    const {t} = useTranslation();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const [referFriend, setReferFriend] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchWorker = async () => {
        setLoading(true);
        const response = await getWorkerReferFriend()
            .then(response => {
                setReferFriend(response.data.refer_friend_accepted)
            });

        setLoading(false);
    };

    useEffect(() => {
        fetchWorker();
    }, []);

    const accept = async () => {
        await acceptReferFriend()
            .then(response => {
                setReferFriend(true)
            })
    };

    const copy = async () => {
        await Clipboard.write({
            string: "https://eur04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2FPages%2FResponsePage.aspx%3Fid%3DO73rkeUqdEidEIY0U8Vbf3uKWxZDu51IrsoaF8nnxdxUNkgwVDgzVlRZNVcwODRFV0szNUJIUzNGNi4u&data=05%7C02%7Cdorota.bialek%40exactsystems.com%7C2391ec4324d447563f8108dc48e63f76%7C91ebbd3b2ae548749d10863453c55b7f%7C0%7C0%7C638465401230372178%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=oUFSYloTE1PO9InsLMeblC67eg5Awrs%2Fg3DaJ8LLUFk%3D&reserved=0"
        });
    };

    const downloadPdf = async () => {
        const info = await Device.getInfo();
        let deniedPermissions = false;
        if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
            let hasPermissions = await Filesystem.checkPermissions();
            deniedPermissions = hasPermissions.publicStorage === 'denied';
        }

        if (deniedPermissions) {
            setShowPermissionAlert(true);
        } else {
            let fileData = null;
            fileData = await getReferFriendsPdf()
                .then(response => {
                    return response.data;
                })

            let base64 = null;
            if (fileData) {
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/refer_friends.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch (e) {

                    }
                }
            }
        }
    };

    return <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
            <StyledEmptyContainer>
                <CircleImage image={ImageTypes.NOTE} color="grey"/>
                <h3>{t('reportsTab.registry.soonAvailableNotWorker')}</h3>
            </StyledEmptyContainer>
        </StaticPane> &&
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            <StyledIonContent>
                <h1 className="title">{t("")}</h1>
                <div className="content full-height">
                    <p>{t("referFriends.page.intro1")}</p>
                    <p>{t("referFriends.page.intro2")}</p>

                    <h6><strong>{t("referFriends.page.how_to_make_money.title")}</strong></h6>
                    <ol>
                        <li>
                            <div className={'red'}> {t("referFriends.page.how_to_make_money.p1red")}</div>
                            {t("referFriends.page.how_to_make_money.p1")}
                        </li>
                        <li>
                            <div className={'red'}> {t("referFriends.page.how_to_make_money.p2red")}</div>
                            {t("referFriends.page.how_to_make_money.p2")}
                        </li>
                        <li>
                            <div className={'red'}> {t("referFriends.page.how_to_make_money.p3red")}</div>
                            {t("referFriends.page.how_to_make_money.p3")}
                        </li>
                    </ol>

                    <h6><strong>{t("referFriends.page.important.title")}</strong></h6>
                    <p>{t("referFriends.page.important.t1")}
                        <strong> {t("referFriends.page.important.rcp")}</strong> {t("referFriends.page.important.t2")}
                    </p>
                    <p>{t("referFriends.page.important.t3")}</p>

                    <h6><strong>{t("referFriends.page.whats_more.title")}</strong></h6>
                    <ul>
                        <li>{t("referFriends.page.whats_more.p1")}
                            <u> {t("referFriends.page.whats_more.p2")}</u> {t("referFriends.page.whats_more.p3")}</li>
                        <li>{t("referFriends.page.whats_more.p4")}</li>
                    </ul>
                </div>

                <StyledLightBlueButton className="no-margin-top"
                                       onClick={() => downloadPdf()}>{t("referFriends.regulations.download")}</StyledLightBlueButton>

                {!loading && !referFriend &&
                    <h6><strong>{t("referFriends.page.not_accepted_text")}</strong></h6>
                }

                {!loading && !referFriend &&
                    <StyledLightBlueButton className="no-margin-top"
                                           onClick={() => accept()}>{t("referFriends.regulations.accept")}</StyledLightBlueButton>
                }

                {!loading && referFriend &&

                    <StyledLightBlueButton className=""
                                           disabled>{t("referFriends.regulations.accepted")}</StyledLightBlueButton>
                }

                {!loading && referFriend &&
                    <h6><strong>{t("referFriends.page.accepted_text")}</strong></h6>
                }

                {!loading && referFriend &&
                    <StyledLightBlueButton className="no-margin-top"
                                           onClick={() => copy()}>{t("referFriends.link.copy")}</StyledLightBlueButton>
                }

            </StyledIonContent>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Pane>
};

export default ReferFriendsPane;
