import styled from 'styled-components';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFF;
  position: relative;
  height: 100%;

  .message {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    text-align: center;
    margin-top: 47px;
  }
  
  .description {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    margin-top: 47px;
  }
`;
