import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from "../../../components/modal/modal.component";
import {StyledModalContent} from '../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../utils/tools/modals";

type AvailabilityGuideModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const AvailabilityGuideModal: React.FC<AvailabilityGuideModalProps> = (props: AvailabilityGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
    };

    return (
        <Modal
            isOpen={showGuide}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title">{t("profileTab.availability.guide.title")}</h1>
                    <div className="content full-height">
						<p>{t("profileTab.availability.guide.intro1")}</p>
						<p>{t("profileTab.availability.guide.intro2")}</p>

                        <h6><strong>{t("profileTab.availability.guide.normalView.title")}</strong></h6>
						<p>{t("profileTab.availability.guide.normalView.p1")}</p>
						<ul>
                            <li>{t("profileTab.availability.guide.normalView.p11")}</li>
                            <li>{t("profileTab.availability.guide.normalView.p12")}</li>
                            <li>{t("profileTab.availability.guide.normalView.p13")}</li>
                        </ul>
						<p>{t("profileTab.availability.guide.normalView.p2")}</p>

						<h6><strong>{t("profileTab.availability.guide.detailView.title")}</strong></h6>
						<p>{t("profileTab.availability.guide.detailView.p1")}</p>
						<p>{t("profileTab.availability.guide.detailView.p2")}</p>
						<p>{t("profileTab.availability.guide.detailView.p3")}</p>
						<p>{t("profileTab.availability.guide.detailView.p4")}</p>
						<p>{t("profileTab.availability.guide.detailView.p5")}</p>

						<h6><strong>{t("profileTab.availability.guide.exception.title")}</strong></h6>
						<p>{t("profileTab.availability.guide.exception.p1")}</p>
						<p>{t("profileTab.availability.guide.exception.p2")}</p>
						<p>{t("profileTab.availability.guide.exception.p3")}</p>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default AvailabilityGuideModal;
