import React, { useEffect, useState } from 'react';

import VectorImage from '../../../../../../assets/images/checkMarkGreen.svg';

import { StyledWorkerActionSheetContainer } from './workerActionSheet.style';
import { StyledDataTable } from '../../../../../../components/content/content.style';
import { StyledIonModal } from "../../../../../../components/modal/modal.style";

import { Worker } from "../../../../../../models/worker";

type WorkerActionSheetProps = {
    isVisible: boolean;
    selectedWorker?: Worker,
    onDismiss: () => void;
    onSelect: (worker: Worker) => void;
    workers?: Worker[];
}

const WorkerActionSheet: React.FC<WorkerActionSheetProps> = (props: WorkerActionSheetProps) => {
    const [isVisible, changeVisibility] = useState<boolean>(false);

    useEffect(() => {
        changeVisibility(props.isVisible);
    });

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.4}
            breakpoints={[0, 0.4, 0.99]}
        >
                <StyledWorkerActionSheetContainer className="t-p-50" onClick={(e) => e.stopPropagation()}>
                    <StyledDataTable className="no-margin">
                        { props.workers?.length && props.workers.map((data, i) => <div key={i} className="data-row" onClick={() => {
                            props.onSelect(data);
                            props.onDismiss();
                        }}>
                        <div className="data-icon-title bolded min-height">{data.company}</div>
                            <div className="data-action">
                                {data.id === props.selectedWorker?.id && <img src={VectorImage}/>}
                            </div>
                            <div className="data-details small extended">
                                {data.first_name} {data.last_name}
                            </div>
                        </div>)
                        }
                   </StyledDataTable>
                </StyledWorkerActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default WorkerActionSheet;
