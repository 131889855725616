import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {StyledCommonFileContainer, StyledCommonFileContent} from "./file.style";
import {IonAlert} from "@ionic/react";
import {File} from "../../models/common";
import {API_ENDPOINT} from "../../variables/variables";
import {StyledLightRedButton} from "../button/button.style";

type ReportFileProps = {
    onRemove: (file: File) => void;
    file: File;
    preview: boolean;
}

const FileComponent: React.FC<ReportFileProps> = (props: ReportFileProps) => {
    const {t} = useTranslation();
    const [showRemoveAlert, updateShowRemoveAlert] = useState<boolean>(false);

    return (
        <StyledCommonFileContainer>
            <StyledCommonFileContent>
                <div className="title">{props.file.original_name}</div>
                <span className="preview">
                    {
                        props.file?.original_name && props.file?.extension != 'pdf' &&
                            <img src={API_ENDPOINT + 'file/uploads/' + props.file?.path}/>
                    }
                </span>
                {!props.preview && <StyledLightRedButton
                    onClick={() => updateShowRemoveAlert(true)}
                    disabled={props.preview}>
                    {t("common.files.remove")}
                </StyledLightRedButton>}
            </StyledCommonFileContent>

            <IonAlert
                isOpen={showRemoveAlert}
                onDidDismiss={() => updateShowRemoveAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateShowRemoveAlert(false);
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            props.onRemove(props.file)
                            updateShowRemoveAlert(false);
                        }
                    }
                ]}
            />
        </StyledCommonFileContainer>
    );
};

export default FileComponent;