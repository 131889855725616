import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
`;

export const StyledLogoImage = styled.img`
  display: inline-block;
  width: 40%;
  max-width: 420px;
  min-width: 220px;
`;
