import styled from 'styled-components';

export const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 24px;
`;

export const StyledRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  padding-top: 100px;
`;

export const StyledLoginTextContainer = styled.div`
  margin-top: 24px;
`;
