import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonAlert, IonCol, IonContent, IonItem, IonList, IonLoading, IonRow} from '@ionic/react';
import {Order, Report, ReportRow, ReportRowComponent} from '../reportPage.component';
import {StyledFilesContainer} from "../components/file/file.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import {StyledIonModal, StyledModalContent} from "../../../components/modal/modal.style";
import {StyledContent} from "./commentsModal.style";
import {getNumberOfComponent, orderDefects} from "../../../services/report.service";
import {StyledCircleButton} from "../../../components/button/button.style";
import TrashImage from "../../../assets/images/trash-can.svg";
import EditImage from "../../../assets/images/pen-01.svg";
import {RemoveRowComponentState} from "../components/report/reportRowComponentForm.component";
import ReportRowComponentEditModal from "./reportRowComponentEditModal.component";
import {Profile} from "../../../models/profile";
import {ro} from "date-fns/locale";
import {StyledComponentsContainer} from "../components/report/reportRowForm.style";

type ModalProps = {
    isOpen: boolean;
    orderId?: string,
    reportId?: string,
    profile?: Profile,
    specificationId?: string,
    row: ReportRow;
    report: Report;
    order?: Order;
    reportPreview: boolean;
    onClose: (row: ReportRow) => void;
    onRemove: (component: ReportRowComponent, key: number) => void;
    onChange: (component: ReportRowComponent, key: number) => void;
};

const ReportRowComponentsModal: React.FC<ModalProps> = (props: ModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(props.isOpen);
    const [row, setRow] = useState<ReportRow>(props.row);
    const [selectedComponent, setSelectedComponent] = useState<ReportRowComponent|undefined>();
    const [removeRowComponentState, updateRemoveRowComponentState] = useState({ showAlert: false, component: undefined } as RemoveRowComponentState);

    const handleClose = () => {
        props.onClose(props.row);
    };

    const editComponent = (component: ReportRowComponent) => {
        setSelectedComponent(component);
    }

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    return (
        <>
            <StyledIonModal
                isOpen={showModal}
                onDidDismiss={() => {handleClose()}}
                initialBreakpoint={0.99}
                breakpoints={[0, 0.99]}
            >
                <StyledModalContent className="modal-wrap">
                    <img src={CloseImage} className="close" onClick={() => handleClose() } />
                    <StyledContent className="content full-height">
                        <StyledComponentsContainer>
                            <div className="label">{t('reportPage.components.list.title')}</div>

                            <IonList>
                                <IonItem className="component header">
                                    <IonCol size="4.5">
                                        {t('reportPage.components.list.component_nb')}
                                    </IonCol>
                                    <IonCol size="2">
                                        {t('reportPage.components.list.nok')} / {t('reportPage.components.list.ok')}
                                    </IonCol>
                                    <IonCol size="3">
                                        {t('reportPage.components.list.defect')}
                                    </IonCol>
                                    <IonCol size="2">
                                        {t('reportPage.components.list.actions')}
                                    </IonCol>
                                </IonItem>

                                {row.components && row.components.length > 0 && row.components.map((component, key) =>
                                    <IonItem className="component">
                                        <IonCol size="1"><StyledCircleButton disabled={true} className="white-border m-10 small">{getNumberOfComponent(props.row, component)} </StyledCircleButton></IonCol>
                                        <IonCol size="3.5">
                                            {component.componentNumberText}
                                        </IonCol>
                                        <IonCol size="2">
                                            {component.defectGroups.length > 0 ? t('reportPage.components.list.nok'): t('reportPage.components.list.ok')}
                                        </IonCol>
                                        <IonCol size="3">
                                            <ul>
                                                {component.defectGroups.map((defectGroup) => {
                                                    return defectGroup.defects.map((r) => {
                                                        return <li>{r.name}</li>
                                                    });
                                                })}
                                            </ul>
                                        </IonCol>
                                        <IonCol size="1.5">
                                            {!props.reportPreview && <StyledCircleButton className="m-10" onClick={() => editComponent(component)}><img src={EditImage} /></StyledCircleButton>}
                                        </IonCol>
                                        <IonCol size="1">
                                            {!props.reportPreview && props.row.components && props.row.components?.length > 1 && <StyledCircleButton className="light-red m-10" onClick={() => updateRemoveRowComponentState({showAlert: true, component: component})}><img src={TrashImage} /></StyledCircleButton>}
                                        </IonCol>
                                    </IonItem>
                                )}
                            </IonList>

                        </StyledComponentsContainer>
                    </StyledContent>
                </StyledModalContent>

                <IonAlert
                    isOpen={removeRowComponentState.showAlert}
                    onDidDismiss={() => updateRemoveRowComponentState({showAlert: false, component: undefined})}
                    header={t('common.alertHeader')}
                    buttons={[
                        {
                            text: t('common.alertCancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                updateRemoveRowComponentState({showAlert: false, component: undefined})
                            },
                        },
                        {
                            text: t('common.alertConfirm'),
                            handler: async () => {
                                if (removeRowComponentState.component !== undefined) {
                                    let index = row.components?.indexOf(removeRowComponentState.component);
                                    if (index !== null && index !== undefined && index !== -1) {
                                        props.onRemove(removeRowComponentState.component, index);
                                    }

                                }
                            }
                        }
                    ]}
                />
            </StyledIonModal>

            {selectedComponent && <ReportRowComponentEditModal
                isOpen={true}
                reportPreview={props.reportPreview}
                orderId={props.orderId}
                specificationId={props.specificationId}
                reportId={props.reportId}
                profile={props.profile}
                row={props.row}
                component={selectedComponent}
                report={props.report}
                order={props.order}
                onClose={() => {
                    setSelectedComponent(undefined);
                }}
                onSubmit={(component) => {
                    let index = row.components?.indexOf(selectedComponent);
                    if (index !== null && index !== undefined && index !== -1) {
                        props.onChange(component, index)
                        setSelectedComponent(undefined)
                    }
                }}
            />}
        </>
    );
};

export default ReportRowComponentsModal;