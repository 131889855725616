import {appApi} from "./api.service";
import moment from "moment";

let endpointPrefix = 'user';

const getActivities = async () => {
    return appApi.get(endpointPrefix + '/activities?withoutPastActivities=1');
}

const getActivitiesByDate = async (startDate: Date, endDate: Date) => {
	let start: string = moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
	let end: string = moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY');

	return appApi.get(endpointPrefix + '/activities?startDate=' + start + '&endDate=' + end);
}

const approveTask = async (id: number) => {
	return appApi.post(endpointPrefix + '/proposals/' + id + '/accept');
}

const rejectTask = async (id: number) => {
	return appApi.post(endpointPrefix + '/proposals/' + id + '/reject');
}

export {
	getActivities,
	getActivitiesByDate,
	approveTask,
	rejectTask
};
