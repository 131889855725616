import React, { useEffect } from 'react';
import {IonContent, IonPage, isPlatform, useIonViewWillEnter} from '@ionic/react';
import StyledTitle from '../../components/title';
import {StyledLoginTextContainer, StyledRegisterContainer} from '../login/login.styles';
import Logo from '../../components/logo';
import { Links } from '../links';
import { StyledText, StyledTextLink } from '../../components/text';
import CreateAccountForm from './form/createAccountForm.component';
import FacebookLogin from './../login/components/facebookLogin.component';
import { useTranslation } from 'react-i18next';
import {loginStatusBarUpdate} from "../../utils/tools/statusbar";

const CreateAccount: React.FC = () => {

    useIonViewWillEnter(() => {
        loginStatusBarUpdate();
    });

    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent fullscreen>
                <StyledRegisterContainer>
                    <Logo />
                    <StyledTitle>{t("createAccount.title")}</StyledTitle>
                    <CreateAccountForm />

					{
						!isPlatform('ios') &&
						<FacebookLogin/>
					}

                    <StyledLoginTextContainer>
                        <StyledText>
                            {t("createAccount.loginDescription")}
                            <StyledTextLink to={Links.login}>
                                {t("createAccount.loginLink")}
                            </StyledTextLink>
                        </StyledText>
                    </StyledLoginTextContainer>
                </StyledRegisterContainer>
            </IonContent>
        </IonPage>
    );
};

export default CreateAccount;
