import styled from 'styled-components';

export const StyledInfoContainer = styled.div`
  width: 100%;
  background: rgba(255, 243, 205, 0.08);
  border-radius: 12px;
  padding: 13px;
  display: flex;
  color: #0085FF;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  
  &.transparent {
    background: #fff;
    color: #000000;
    
    img {
        visibility: hidden;
    }
  }
  
  &.stacked {
    margin-top: 10px;
  }
  
  &.justify {
    text-align: justify;
  }
`;

export const StyledInfoImage = styled.img`
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 10px;
`;
