import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract as ContractModel, Contract, ContractAppendix} from "../../../models/contract";
import {
    getContractAgreement,
    getContractAgreementPdf
} from "../../../services/contract.service";
import {blobToBase64} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
// import {FileOpener} from "@ionic-native/file-opener";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledLightBlueButton} from "../../../components/button/button.style";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";

type TerminatePreviewModalProps = {
    isOpen: boolean;
    contract?: Contract,
    onClose: () => void;
};

const ContractAgreementPreviewModal: React.FC<TerminatePreviewModalProps> = (props: TerminatePreviewModalProps) => {

    const {t} = useTranslation();

    const [showTerminate, updateShowTerminate] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [contract, setContract] = useState<ContractModel>();

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    useEffect(() => {
        fetchContractAgreement()
    },[]);

    const fetchContractAgreement = async () => {
        if (props.contract?.id && contract?.agreement_content == undefined) {
            await getContractAgreement(props.contract?.id)
                .then(response => {
                    let contract = response.data.contract as ContractModel;
                    contract.agreement_content = response.data.agreement_content;
                    setContract(contract);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    };

    const handleOpen = () => {
        updateShowTerminate(props.isOpen);
    };

    const openContractAgreementPdf = async () => {
        if (props.contract?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getContractAgreementPdf(props.contract?.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                await blobToBase64(fileData).then(value => base64 = value);
                if (base64) {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - ' + t("contractPage.ContractAgreementPreview.title") + '.pdf',
                            data: base64,
                            directory: Directory.Library,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch(e) {

                    }
                }
            }
        }
    }

    return (
        <Modal
            isOpen={showTerminate}
            canDismiss={false}
            onDidDismiss={() => handleCloseRemarks()}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.ContractAgreementPreview.title")}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: contract?.agreement_content || '4'}}/>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledLightBlueButton onClick={() => openContractAgreementPdf()}>{t("contractPage.ContractAgreementPreview.download")}</StyledLightBlueButton>
                    </StyledFooter>
                </StyledModalContent>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default ContractAgreementPreviewModal;