import {
    IonAlert,
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledAccordion } from "../../../reportPage/reportPage.style";
import { StyledIonRange } from "./step2.styles";
import { StyledInput } from "../../../../components/form/input/input.style";
import ArrowDownImage from "../../../../assets/images/down-arrow.svg";
import { StyledTextarea } from "../../../../components/form/input/textarea.style";
import PaginatedListModal from "../../modals/paginatedListModal.component";
import {
    AuditReportFormModel, AuditReportPenalty,
    AuditReportUserPenalty,
    PenaltyItem,
    PenaltyTableGroup
} from "../../../../models/auditReport";
import { DataSelectable } from "../../../../models/common";
import { AuditReportPenaltyType } from "../../../../enums/audit";
import { AuditReportContext } from "../../auditReport.component";
import {StyledCircleButton, StyledSocialButton} from "../../../../components/button/button.style";
import TrashRedImage from "../../../../assets/images/trash-can-red.svg";

export type EmployeePenaltyProps = {
    report: AuditReportFormModel,
    fetchProfiles: (report:AuditReportFormModel, page:number, searchText?: string) => Promise<DataSelectable[]>,
    auditReportPenalty: AuditReportUserPenalty,
    onChange: (report: AuditReportFormModel) => void,
}

export interface RemovePenaltyState {
    showAlert: boolean;
    penalty?: AuditReportPenalty;
    index?: number;
}

const EmployeePenalty: React.FC<EmployeePenaltyProps> = (props: EmployeePenaltyProps) => {
    const { t } = useTranslation();
    const [showProfilesModal, updateShowProfilesModal] = useState<boolean>(false);
    const [report, updateReport] = useState<AuditReportFormModel>(props.report);
    const [updatingReport, setUpdatingReport] = useState<boolean>(false);
    const [showRemoveAlert, setShowRemoveAlert] = useState(false);
    const [removePenaltyState, updateRemovePenaltyState] = useState({ showAlert: false, penalty: undefined, index: undefined } as RemovePenaltyState);

    useEffect(() => {
        if (updatingReport) {
            setUpdatingReport(false);
            return
        }
        setUpdatingReport(true);
        props.onChange(report);
    }, [report]);

    useEffect(() => {
        
        if (updatingReport) {
            setUpdatingReport(false);
            return
        }
        setUpdatingReport(true);

        updateReport(props.report);

    }, [props.report]);

    const auditContext = useContext(AuditReportContext);

    function onProfileSelected(profile: DataSelectable){
        const penaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty);
        if (penaltyIndex !== undefined && penaltyIndex !== -1) {
            const penalties = [ ...(report.userPenalties || []) ];
            penalties[penaltyIndex].person = profile;
            penalties[penaltyIndex].personOther = '';
            updateReport({
                ...report,
                userPenalties: penalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    person: profile,
                    personOther: '',
                    penalties: [],
                }]
            })
        }
    }

    function setPersonOther(value: string){
        const penaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (penaltyIndex !== undefined && penaltyIndex !== -1) {
            const penalties = [ ...(report.userPenalties || []) ];
            penalties[penaltyIndex].person = undefined;
            penalties[penaltyIndex].personOther = value;
            updateReport({
                ...report,
                userPenalties: penalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    person: undefined,
                    personOther: value,
                    penalties: [],
                }]
            })
        }
    }
    
    function selectPenaltyGroup(penalty: AuditReportPenalty, group: PenaltyTableGroup ){
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            const penaltyIndex = userPenalties[userPenaltyIndex].penalties.indexOf(penalty);
            if (penaltyIndex !== undefined && penaltyIndex !== -1) {
                userPenalties[userPenaltyIndex].penalties[penaltyIndex].group = group;
            }
            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    penalties: [{
                        group: group,
                    }]
                }]
            })
        }
    }

    function selectPenaltyItem(penalty: AuditReportPenalty, item: PenaltyItem ){
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            const penaltyIndex = userPenalties[userPenaltyIndex].penalties.indexOf(penalty);
            if (penaltyIndex !== undefined && penaltyIndex !== -1) {
                userPenalties[userPenaltyIndex].penalties[penaltyIndex].item = item;
                userPenalties[userPenaltyIndex].penalties[penaltyIndex].amount = report.form?.penalty_table?.step ?? 5;
            }
            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    penalties: [{
                        item: item,
                        amount: report.form?.penalty_table?.step ?? 5
                    }]
                }]
            })
        }
    }


    function setAuditorComment(penalty: AuditReportPenalty, value:string){
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            const penaltyIndex = userPenalties[userPenaltyIndex].penalties.indexOf(penalty);
            if (penaltyIndex !== undefined && penaltyIndex !== -1) {
                userPenalties[userPenaltyIndex].penalties[penaltyIndex].comment = value;
            }

            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    penalties: [{
                        comment: value
                    }]
                }]
            })
        }
    }

    function setPenaltyAmount(penalty: AuditReportPenalty, value:any){
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            const penaltyIndex = userPenalties[userPenaltyIndex].penalties.indexOf(penalty);
            if (penaltyIndex !== undefined && penaltyIndex !== -1) {
                userPenalties[userPenaltyIndex].penalties[penaltyIndex].amount = value;
            }

            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        } else {
            updateReport({
                ...report,
                userPenalties: [{
                    type: props.auditReportPenalty.type,
                    penalties: [{
                        amount: value
                    }]
                }]
            })
        }
    }

    function removeUserPenalty(){
        const penaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (penaltyIndex !== undefined && penaltyIndex !== -1) {
            const penalties = [ ...(report.userPenalties || []) ];
            penalties.splice(penaltyIndex, 1);
            updateReport({
                ...report,
                userPenalties: penalties
            })
        }
    }

    function removePenalty(penalty: AuditReportPenalty){
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            const penaltyIndex = userPenalties[userPenaltyIndex].penalties.indexOf(penalty);
            if (penaltyIndex !== undefined && penaltyIndex !== -1) {
                userPenalties[userPenaltyIndex].penalties.splice(penaltyIndex, 1);
            }

            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        }
    }

    const addPenalty = () => {
        const userPenaltyIndex = report.userPenalties?.indexOf(props.auditReportPenalty)
        if (userPenaltyIndex !== undefined && userPenaltyIndex !== -1) {
            const userPenalties = [ ...(report.userPenalties || []) ];
            userPenalties[userPenaltyIndex].penalties.push({
                amount: undefined,
                group: undefined,
                item: undefined,
                comment: undefined,
            });

            updateReport({
                ...report,
                userPenalties: userPenalties
            })
        }
    }

    return <>
        <div className="">
            <StyledAccordion>
                <section className="gap-style">
                    <IonRow>
                        <IonCol size="12" className="label mt-8 required">
                            {t('auditReport.form.penalty.person')}
                        </IonCol>
                    </IonRow>
                    <IonRow className="worker-row">
                        <IonCol size={props.auditReportPenalty.type === AuditReportPenaltyType.WORKER ? '10' : '12'} className={(auditContext.getFormSubmitted() && (!props.auditReportPenalty.person && !props.auditReportPenalty.personOther)  ? 'select hasErrors' : 'select')}  onClick={() => { updateShowProfilesModal(true) }}>
                            <StyledInput disabled={!!props.auditReportPenalty.personOther} className="two-input-style " readOnly={true} placeholder={t("common.select")} value={props.auditReportPenalty.person?.name}/>
                            <img className="down" src={ArrowDownImage} />
                        </IonCol>
                        {
                            props.auditReportPenalty.type === AuditReportPenaltyType.WORKER &&
                            <IonCol size="2" className="trash">
                                <StyledCircleButton className="white" onClick={() => setShowRemoveAlert(true)}><img src={TrashRedImage} /></StyledCircleButton>
                            </IonCol>
                        }
                    </IonRow>

                </section>
                {   props.auditReportPenalty.type !== AuditReportPenaltyType.WORKER &&
                    <section>
                        <div className="chose-other">
                            <hr></hr>
                            <span className="one-line">{t('auditReport.form.penalty.personOther')}</span>
                            <hr></hr>
                        </div>
                        <IonItem className="no-padding">
                            <StyledInput disabled={!!props.auditReportPenalty.person} className={auditContext.getFormSubmitted() && (!props.auditReportPenalty.person && props.auditReportPenalty.personOther) ? 'hasErrors' : ''} value={props.auditReportPenalty.personOther} onChange={(e) => setPersonOther(e.target.value as string)}></StyledInput>
                        </IonItem>
                    </section>
                }
                { props.auditReportPenalty.penalties?.map((penalty, key) =>
                    <React.Fragment key={'penalty_'+ key}>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('auditReport.form.penalty.penaltyGroup')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="11" className="select-full-width">
                                {/* <IonItem className="select"> */}
                                <IonSelect
                                    interface="action-sheet"
                                    className={auditContext.getFormSubmitted() && !penalty.group ? 'hasErrors' : ''}
                                    placeholder={t('common.selectPlaceholder')}
                                    okText={t('common.selectOk')}
                                    cancelText={t('common.selectCancel')}
                                    interfaceOptions={
                                        {
                                            header: t('common.select')
                                        }
                                    }
                                    value={penalty.group}
                                    onIonChange={ e => selectPenaltyGroup(penalty, e.detail.value)}>
                                    {
                                        report.form?.penalty_table?.table_penalties_groups.map( (penaltyGroup, key) => {
                                            return <IonSelectOption key={'penalty_group_'+key} value={penaltyGroup}>{penaltyGroup.name}</IonSelectOption>
                                        })
                                    }
                                </IonSelect>
                                {/* </IonItem> */}
                            </IonCol>

                            <IonCol size="1">
                                <StyledCircleButton className="white" onClick={() => updateRemovePenaltyState({showAlert: true, penalty: penalty, index: key})}><img src={TrashRedImage} /></StyledCircleButton>
                            </IonCol>
                        </IonRow>
                        {
                            penalty.group &&
                            <>
                                <IonRow>
                                    <IonCol size="12" className="label mt-8 required">
                                        {t('auditReport.form.penalty.penaltyItem')}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" className="select-full-width">
                                        <IonSelect
                                            interface="action-sheet"
                                            className={auditContext.getFormSubmitted() && !penalty.item ? 'hasErrors' : ''}
                                            placeholder={t('common.selectPlaceholder')}
                                            okText={t('common.selectOk')}
                                            cancelText={t('common.selectCancel')}
                                            interfaceOptions={
                                                {
                                                    header: t('common.select')
                                                }
                                            }
                                            value={penalty.item}
                                            onIonChange={ e => selectPenaltyItem(penalty, e.detail.value)}>
                                            {   penalty.group?.table_penalties_item.map( (penaltyItem, itemKey) => {
                                                return <IonSelectOption key={'penalty_item'+itemKey} value={penaltyItem}>{penaltyItem.name_penalty}</IonSelectOption>
                                            })
                                            }
                                        </IonSelect>
                                        {/* </IonItem> */}
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {   penalty.group && penalty.item &&
                            <section className={"gap-style " + auditContext.getFormSubmitted() && !penalty.group ? 'hasErrors' : ''}>
                                <div className="label required" >{t('auditReport.form.penalty.penaltyValue')} ({t('auditReport.form.penalty.penaltyMultiply')} {report.form?.penalty_table?.step || 5} {report.form?.penalty_table?.currency})</div>

                                <div className="range-frame">
                                    <StyledIonRange currency={report.form?.penalty_table?.currency} min={report.form?.penalty_table?.step || 5} max={parseFloat(penalty.item?.value || "0")} value={penalty.amount} pin={true} step={report.form?.penalty_table?.step || 5} onIonChange={e => setPenaltyAmount(penalty, parseFloat(e.detail.value.toString() || '0'))} ></StyledIonRange>
                                    <div className="range-values">
                                        <div> {report.form?.penalty_table?.step || 5} {report.form?.penalty_table?.currency}</div>
                                        <div> {penalty.item?.value} {report.form?.penalty_table?.currency}</div>
                                    </div>
                                </div>

                            </section>
                        }

                        <section className="gap-style">
                            <IonRow>
                                <IonCol size="12" className="label">
                                    {t('auditReport.form.penalty.comment')}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <StyledTextarea className="textarea-width" value={penalty.comment} onChange={(e) => { setAuditorComment(penalty, e.target.value)}}/>
                                </IonCol>
                            </IonRow>
                        </section>
                    </React.Fragment>
                )}

                <IonRow>
                    <IonCol>
                        <StyledSocialButton className="report-button black-text" onClick={() => addPenalty()}>
                            {t('auditReport.form.addPenalties.button')}
                        </StyledSocialButton>
                    </IonCol>
                </IonRow>

                { auditContext.getFormSubmitted() && props.auditReportPenalty.penalties?.length === 0 &&
                    <p className="errorMessage">{t('auditReport.form.penaltiesRequired')}</p>
                }

            </StyledAccordion>

        </div>

        {showProfilesModal && <PaginatedListModal search={true} report={props.report} isOpen={showProfilesModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                    return props.fetchProfiles(report, page, searchText);
                }} onSubmit={(selected) => {
                    onProfileSelected(selected);
                    updateShowProfilesModal(false);
                }} onClose={() => {
                    updateShowProfilesModal(false);
                }} />
            }

        <IonAlert
            isOpen={showRemoveAlert}
            onDidDismiss={() => setShowRemoveAlert(false)}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        setShowRemoveAlert(false)
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        removeUserPenalty();
                        setShowRemoveAlert(false);
                    }
                }
            ]}
        />

        <IonAlert
            isOpen={removePenaltyState.showAlert}
            onDidDismiss={() => updateRemovePenaltyState({showAlert: false, penalty: undefined, index: undefined})}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateRemovePenaltyState({showAlert: false, penalty: undefined, index: undefined})
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        if (removePenaltyState.penalty !== undefined) {
                            removePenalty(removePenaltyState.penalty);
                        }
                    }
                }
            ]}
        />
    </>
}

export default EmployeePenalty;