import styled, {css} from 'styled-components';

const SharedContainerStyle = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  overflow: scroll;

  .profile-avatar {
    position: relative;
    display: inline-block;

    .add {
      position: absolute;
      bottom: 17px;
      right: -6px;
    }

    .avatar {
      width: 56px;
      min-width: 56px;
      height: 56px;
      min-height: 56px;
      border-radius: 28px;
      margin-bottom: 16px;
    }
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const StyledContainer = styled.div`
  ${SharedContainerStyle}
`;

export const ModalStyledContainer = styled.div`
  ${SharedContainerStyle}
  
  padding: 20px;
`;