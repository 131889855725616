import React, {useContext, useEffect, useRef, useState} from 'react';
import {IonAlert, IonButtons, IonHeader, IonList, IonPage, IonSkeletonText} from '@ionic/react';
import {
	StyledDataTable,
	StyledIonContent,
	StyledIonTitle,
	StyledIonToolbar
} from '../../components/content/content.style';
import Pane from "../../components/pane/pane.component";
import {
	StyledHeaderButton,
	StyledHeaderButtonImage,
	StyledWiteButton
} from "../../components/button/button.style";
import {useHistory} from 'react-router-dom';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import {useTranslation} from 'react-i18next';
import CheckImage from "../../assets/images/check.svg";
import {useLocation} from "react-router-dom";
import {Preferences} from "@capacitor/preferences";
import {StyledDetailViewContainer} from "./absencePage.style";
import AvailabilityExActionSheet from "../availabilityPage/components/availabilityExActionSheet/availabilityExActionSheet.component";
import {getAbsences, addAbsence, editAbsence, removeAbsence} from "../../services/absence.service";
import TaskItem, { Task, TaskStatus } from '../timetablePage/components/taskItem/taskItem.component';
import {AvailabilityExActionSheetContext} from "../availabilityPage/components/availabilityExActionSheet/availabilityExActionSheet.component";
import TaskItemSkeleton from "../timetablePage/components/taskItem/taskItemSkeleton.component";

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

interface IAbsence {
	id: number;
	start_date: string;
	end_date: string;
	reason: string;
}

// TODO: possible merge languageTab with appModeTab into select optionTab ??
const AbsencePage: React.FC = () => {
	const context = useContext(AvailabilityExActionSheetContext);

	const { t } = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ toast, setToast ] = useState<any>(toastInit);
	const [ addException, setAddException ] = useState(false);
	const [ absences, setAbsences ] = useState<Task[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showRemoveAlert, setShowRemoveAlert] = useState(false);

	const [ showSuccessToast, setShowSuccessToast ] = useState(false);
	const [ successToast, setSuccessToast ] = useState<any>('');

	const handleBack = () => {
		history.goBack();
	};

	const handleSave = async () => {

	};

	const fetchAbsences = async () => {
		setLoading(true);
		const response = await getAbsences()
			.then(response => {
				let data = response.data.absences.map((absence: IAbsence) => (
					{
						id: absence.id,
						startDate: new Date(absence.start_date),
						endDate: !!absence.end_date ? new Date(absence.end_date) : undefined,
						description: absence.reason,
						status: TaskStatus.ABSENCE
					}
				));
				setAbsences(data);
			})
			.catch(error => {
				// todo what we show??
			});
		setLoading(false);
	};

	const newAbsence = async (task: Task) => {
		const response = await addAbsence(task.startDate, task?.endDate || task.startDate, task.description)
			.then(response => {
				setSuccessToast(t("profileTab.absence.created"));
				setShowSuccessToast(true);
				setAddException(false);
				return true;
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
				context.setErrors(error.response.data.errors);
				return false;
			});

		return response;
	}

	const updateAbsence = async (task: Task) => {
		if (task.id) {
			const response = await editAbsence(task.id, task.startDate, task?.endDate || task.startDate, task.description)
				.then(response => {
					setSuccessToast(t("profileTab.absence.created"));
					setShowSuccessToast(true);
					setAddException(false);
					return true;
				})
				.catch(error => {
					setToast(t("common.serverErrorMsg"));
					setShowToast(true);
					context.setErrors(error.response.data.errors);
					return false;
				});

			return response;
		}
		setAddException(false);

		return false;
	}

	useEffect(() => {
		fetchAbsences();
        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
	};

	const [currentTask, updateCurrentTask] = useState<Task | undefined>();
	const [edit, updateEdit] = useState<number>(-1);
	const fillException = (task: Task | undefined = undefined) => {
		updateCurrentTask(task);
		updateEdit(absences.indexOf(task as Task));
		setAddException(true);
	};

	const handleExceptionChange = async (task: Task) => {
		if (edit !== -1) {
			if (await updateAbsence(task) === true) {
				absences[edit] = task;
				setAbsences([...absences]);
				fetchAbsences();
				updateCurrentTask(undefined);

				return true;
			} else {
				return false;
			}
		} else {
			if (await newAbsence(task) === true) {
				absences.push(task);
				setAbsences(absences);
				fetchAbsences();
				updateCurrentTask(undefined);

				return true;
			} else {
				return false;
			}
		}
	};

	const onRemoveAlertDismiss = () => {
		setShowRemoveAlert(false);
	}

	const onRemove = (task: Task) => {
		updateCurrentTask(task);
		setShowRemoveAlert(true);
	}

	const remove = async(task: Task) => {
		if (task.id) {
			const response = await removeAbsence(task.id)
				.then(response => {
					fetchAbsences();
				});
		}

		return false;
	}

    return (
        <IonPage>
			<IonAlert
				isOpen={showRemoveAlert}
				onDidDismiss={() => onRemoveAlertDismiss()}
				header={t('profileTab.absence.alert.remove.title')}
				message={t('profileTab.absence.alert.remove.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowRemoveAlert(false);
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								remove(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>

            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => handleBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("profileTab.availability.absences")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40} paddingBottom={57}>
					{
						!loading &&
						<StyledWiteButton onClick={() => fillException()}>
							{t('profileTab.availability.addException')}
						</StyledWiteButton>
					}

					{
						loading &&
						<StyledWiteButton className="greyedout">
							<small style={{width: '50%'}}><IonSkeletonText animated/></small>
						</StyledWiteButton>
					}

					<StyledDataTable className="no-margin">
						{
							loading &&
							<StyledDetailViewContainer>
								<TaskItemSkeleton />
								<TaskItemSkeleton />
								<TaskItemSkeleton />
								<TaskItemSkeleton />
								<TaskItemSkeleton />
							</StyledDetailViewContainer>
						}

						{
							!loading &&
							<StyledDetailViewContainer>
								{
									absences.map((task, key) =>
										<TaskItem key={key} task={task} onEdit={(task) => fillException(task)} onRemove={(task) => onRemove(task)}/>
									)
								}
							</StyledDetailViewContainer>
						}
					</StyledDataTable>

					<AvailabilityExActionSheet
						isVisible={addException}
						task={currentTask}
						onSave={(task: Task) => handleExceptionChange(task)}
						onDismiss={() => { setAddException(false); updateCurrentTask(undefined) }}
					/>
				</Pane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AbsencePage;
