import styled from "styled-components";

export const StyledContent = styled.div`
  width: calc(100% - 32px);
  height: 100%;
  margin: 0 16px;
`;

export const StyledNewInvitation = styled.div`
  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 16px;

    &.no-margin {
        margin-top: 0;
    }

    span {
        float: right;
        color: #888A98;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

`;

export const StyledInvitation = styled.div`
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  margin-bottom: 16px;

  .invitation-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding-left: 20px;
    padding-top: 16px;
  }

  .separator {
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .place {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    .contractor {
      font-size: 12px;
      line-height: 140%;
      color: #888A98;
    }
  }

  .status-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;
    margin: 0 20px;

    &.border {
      padding-top: 15px;
      border-top: 1px solid #F6F6F8;
    }
  }

  .members {
    margin: 0px 16px 11px;

    img {
      position: relative;
      border-radius: 16px;
      width: 32px;
      height: 32px;
    }
  }
`;