import styled from 'styled-components';

type StyledSubNavigationContainerProps = {
  light: boolean;
  marginTop?: number;
}

export const StyledSubNavigationContainer = styled.div<StyledSubNavigationContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 3px;
  background: ${ props => props.light ? '#F4F4F9' : '#363543'};
  border-radius: 12px;
  justify-content: space-between;
  margin: auto 24px;
  margin-top: ${ props => props.marginTop !== undefined ? (props.marginTop + 'px') : '24px'};
  display: inline-flex;
  width: calc(100% - 48px);
  overflow-x: scroll;
`;

type StyledSubNavigationButtonProps = {
  light: boolean;
  active: boolean;
  visible?: boolean
}

export const StyledSubNavigationButton = styled.button<StyledSubNavigationButtonProps>`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display:  ${props => props.visible === false ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  color: ${props => getButtonFontColor(props)};
  background: ${props => getButtonColor(props)};
  padding: 10px 12px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 3px;
  border-radius: 10px;
`;

function getButtonColor(props: StyledSubNavigationButtonProps): string {
  if (props.light) {
    return props.active ? "#fff" : "#F4F4F9";
  } else {
    return props.active ? "#fff" : "#363543";
  }
}

function getButtonFontColor(props: StyledSubNavigationButtonProps): string {
  if (props.light) {
    return props.active ? "#000" : "#888A98";
  } else {
    return props.active ? "#000" : "#B5B7C6";
  }
}