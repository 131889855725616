import React from 'react';
import {StyledRecruitmentPathSkeletonContainer} from './recruitmentPath.style';
import {IonItem, IonLabel, IonList, IonSkeletonText} from "@ionic/react";

const RecruitmentPathSkeleton: React.FC = () => {
    return (
        <StyledRecruitmentPathSkeletonContainer>
            <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
            <h1><IonSkeletonText animated style={{ width: '100%' }} /></h1>
            <h1><IonSkeletonText animated style={{ width: '60%' }} /></h1>
        </StyledRecruitmentPathSkeletonContainer>
    );
};

export default RecruitmentPathSkeleton;
