import { createAnimation } from "@ionic/react";

let openedModals: (() => void)[] = [];

const modalOpened = (dismissFnc: () => void) => {
    openedModals.push(dismissFnc);
};

const modalDismissed = () => {
    openedModals.splice(openedModals.length - 1, 1);
};

const dismissModal = () => {
    const dismissFnc = openedModals[openedModals.length - 1];
    if (typeof(dismissFnc) === 'function') {
        dismissFnc();
    }
};

const isModalOpen = (): boolean => openedModals.length > 0;

const enterAnimation = (baseEl: any) => {
    const backdropAnimation = createAnimation()
      .addElement(baseEl.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' }
      ]);

    return createAnimation()
        .addElement(baseEl)
        .easing('step-start')
        .duration(500)
        .addAnimation([backdropAnimation, wrapperAnimation]);
}

const leaveAnimation = (baseEl: any) => {
    return enterAnimation(baseEl).direction('reverse');
}

const service = {
    modalOpened,
    modalDismissed,
    dismissModal,
    isModalOpen,
    leaveAnimation,
    enterAnimation
};

export default service;