import React, {useEffect, useState} from 'react';
import Modal from '../../components/modal/modal.component';
import {getBadge} from "../../services/worker.service";
import {WorkerBadge} from "./workerBadge.style";
import {StyledModalContent} from "../../components/modal/modal.style";
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';

type WorkerBadgeProps = {
	isOpen: boolean;
	onClose: () => void;
};

const WorkerBadgeModal: React.FC<WorkerBadgeProps> = (props: WorkerBadgeProps) => {

	const [showModal, updateShowModal] = useState<boolean>(false);
	const [badge, updateBadge] = useState<string>('');

	useEffect(() => {
		handleOpen();
	});

	const lock = async () => {
		await ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
	};

	const unlock = async () => {
		await ScreenOrientation.unlock();
	};

	useEffect(() => {
		if (props.isOpen) {
			fetchBadge();
			lock();
		} else {
			unlock();
		}
	}, [props.isOpen]);

	const handleOpen = () => {
		updateShowModal(props.isOpen);
	};

	const fetchBadge = async () => {
	  	await getBadge()
			.then(response => {
				updateBadge(response.data.data);
			});
	}

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => props.onClose()}>

			<StyledModalContent>
				<div className="content full-height">
					{badge ? <WorkerBadge src={'data:image/jpeg;base64,' + badge} /> : <></>}
				</div>
			</StyledModalContent>
		</Modal>
	);
};

export default WorkerBadgeModal;
