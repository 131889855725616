import React from 'react';
import {StyledContractContainer} from './contract.style';
import {IonSkeletonText} from "@ionic/react";

const ContractSkeleton: React.FC = () => {
    return (
        <StyledContractContainer>
            <small>
                <IonSkeletonText animated style={{ width: '50%' }} />
            </small>
            <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
            <p><IonSkeletonText animated style={{ width: '40%' }} /></p>
        </StyledContractContainer>
    );
};

export default ContractSkeleton;
