import React, {useContext, useEffect, useRef, useState} from 'react';
import {Preferences} from "@capacitor/preferences";

import QRImage from '../../../../assets/images/barcode-qr.svg';
import PreferencesImage from '../../../../assets/images/system-preferences.svg';
import DownArrowDarkImage from '../../../../assets/images/down-arrow-dark.svg';
import SwitchWorkerImage from '../../../../assets/images/switch-worker.svg';

import {IonButtons, IonHeader, IonPage, useIonViewWillEnter} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '../../../../components/button/button.style';
import {StyledBanner, StyledPicker} from "./profileTab.style";

import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useEvent} from "../../../../hooks/useEvent";

import QrModal from './modals/qrModal.component';
import PersonalDataPane from './panes/personalDataPane.component';
import RoleActionSheet from './components/roleActionSheet/roleActionSheet.component';
import WorkerActionSheet from "./components/workerActionSheet/workerActionSheet.component";

import {MainPageContext} from "../../mainPage.component";

import {Links} from '../../../links';
import {log} from "../../../../services/firebaseAnalytics.service";
import {getProfile, getRoles} from "../../../../services/profile.service";
import {ifNull} from "../../../../utils/tools/numbers";
import {storeProfile} from "../../../../services/auth.service";
import {getMenu} from "../../../../services/menu.service";

import {Profile} from "../../../../models/profile";
import DownArrowImage from '../../../../assets/images/down-arrow.svg';
import {RecruitmentPathTestVersion} from "../../../../enums/recruitmentPathTestVersion";
import {Worker} from "../../../../models/worker";
import {EventType} from "../../../../enums/eventType";

const ProfileTab: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {dispatch} = useEvent();

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showQRCode, updateShowQRCode] = useState<boolean>(false);
    const [showProfileActionSheet, updateShowProfileActionSheet] = useState<boolean>(false);
    const [showWorkerActionSheet, setShowWorkerActionSheet] = useState<boolean>(false);

    useEffect(() => {
        updateHeight();
    });

    const [name, setName] = useState<any>();
    const [isEmployed, setIsEmployed] = useState<any>();
    const [number, setNumber] = useState<any>();
    const [isExact, updateIsExact] = useState<any>();
    const [selectedProfile, updateSelectedProfile] = useState<Profile | undefined>();
    const [selectedProfileCompanyName, setSelectedProfileCompanyName] = useState<string>('');
    const [roles, updateRoles] = useState<Profile[]>();
    const [isAwaitingForKoContact, updateIsAwaitingForKoContact] = useState<boolean>(false);
    const [userSelectedWorker, setUserSelectedWorker] = useState<Worker | undefined>();
    const [userWorkers, setUserWorkers] = useState<Worker[]>();

    const getWorkerData = async () => {
        const workerId = await Preferences.get({'key': 'worker_id'});

        const storageUserWorkers = await Preferences.get({'key': 'workers'});
        const userWorkers = JSON.parse(storageUserWorkers.value ?? '');

        if (userWorkers.length > 0) {
            const normalizedUserWorkers: Worker[] = userWorkers.map((worker: any): Worker => {
                const {id, company, first_name, last_name}: Worker = worker;

                return {
                    id,
                    company,
                    first_name,
                    last_name
                }
            });

            setUserWorkers(normalizedUserWorkers);

            const selectedWorker: Worker | undefined = normalizedUserWorkers.find((userWorker: any) => {
                return userWorker.id === parseInt(workerId.value ?? '');
            })

            setUserSelectedWorker(selectedWorker);
        }
    }

    const mainPageManager = useContext(MainPageContext);

    const getProfileData = async () => {
        let fullName = await Preferences.get({'key': 'full_name'});
        setName(fullName.value);

        let isEmployedObject = await Preferences.get({'key': 'is_employed'});
        setIsEmployed(isEmployedObject.value);

        let isCoordinator = await Preferences.get({'key': 'is_coordinator'});
        let isTeamLeader = await Preferences.get({'key': 'is_team_leader'});
        updateIsExact(isCoordinator.value === '1' || isTeamLeader.value === '1');

        let profileId = await Preferences.get({'key': 'profile_id'});
        let profileType = await Preferences.get({'key': 'profile_type'});
        let profileCompanyName = await Preferences.get({'key': 'profile_company_name'});

        if (profileId.value !== null && profileType.value !== null) {
            updateSelectedProfile({
                id: parseInt(profileId.value),
                type: profileType.value,
                company: profileCompanyName.value !== null ? {
                    name: profileCompanyName.value
                } : undefined
            })

            if (profileCompanyName.value !== null) {
                setSelectedProfileCompanyName(profileCompanyName.value);
            }
        }

        if (isCoordinator.value === '1' || isTeamLeader.value === '1') {
            await fetchRoles();
        }

        let numberObject = await Preferences.get({'key': 'employee_number'});
        setNumber(numberObject.value);

        let recruitmentPathCompleted = await Preferences.get({'key': 'recruitment_path_completed'});
        let contractFormUrl = await Preferences.get({'key': 'contract_form_url'});
        let contractFormCompleted = await Preferences.get({'key': 'contract_form_completed'});
        let recruitmentPathFlowVersion = await Preferences.get({'key': 'recruitment_path_flow_version'});
        let recruitmentTestCompleted = await Preferences.get({'key': 'recruitment_test_completed'});

        updateIsAwaitingForKoContact(ifNull(contractFormUrl.value) === null &&
            ((RecruitmentPathTestVersion.VER_1 == recruitmentPathFlowVersion.value as unknown as number &&
            recruitmentPathCompleted.value === '1' &&
            contractFormCompleted.value !== '1') ||
            (RecruitmentPathTestVersion.VER_2 == recruitmentPathFlowVersion.value as unknown as number &&
                recruitmentTestCompleted.value === '1' &&
                contractFormCompleted.value !== '1' &&
                !contractFormUrl.value))
        );
    };

    const fetchRoles = async () => {
        await getRoles()
            .then(response => {
                updateRoles(response.data);
            });
    }

    useEffect(() => {
        getProfileData();
        getWorkerData();
    }, []);

    useIonViewWillEnter(() => {
        getProfileData();
        getWorkerData();
    });

    const reloadMenu = async () => {
        await getMenu().then(response => {
            mainPageManager.setMenuItems(response.data);
            dispatch(EventType["REFRESH.MENU.USER_DATA"]);
        });
    };

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const handleShowQRCode = () => {
        updateShowQRCode(true);

        log('page_visit', {
            page: 'Profil -> QR Code'
        });
    };

    const handleShowSettings = () => {
        history.push(`${Links.main}${Links.settings}`)

        log('page_visit', {
            page: 'Profil -> Ustawienia'
        });
    };

    const onSelectProfile = async (profile: Profile) => {
        updateSelectedProfile(profile);

        await Preferences.set({
            'key': 'profile_id',
            'value': profile.id.toString()
        });

        await Preferences.set({
            'key': 'profile_type',
            'value': profile.type.toString()
        });

        const companyName = profile.company ? profile.company.name : (profile.companies ? profile.companies.map(company => company.name).join(', ') : '');
        await Preferences.set({
            'key': 'profile_company_name',
            'value': companyName.toString()
        });
        setSelectedProfileCompanyName(companyName);

        await reloadMenu();
    }

    const onSelectWorker = async (worker: Worker) => {
        setUserSelectedWorker(worker);

        await Preferences.set({
            'key': 'worker_id',
            'value': worker.id.toString()
        });

        const profileResponse = await getProfile(true);
        await storeProfile(profileResponse.data);

        dispatch(EventType["REFRESH.PERSONAL_DATA_PANE.PROFILE_DATA"]);

        await reloadMenu();
    }

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            {isEmployed === '1' &&
                                <StyledHeaderButton onClick={() => handleShowQRCode()}>
                                    <StyledHeaderButtonImage src={QRImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            }
                        </IonButtons>
                        <StyledIonTitle>{t("profileTab.title")}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleShowSettings()}>
                                <StyledHeaderButtonImage src={PreferencesImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                    {isExact && <StyledPicker onClick={() => updateShowProfileActionSheet(true)}>
                        <div>
                            <span>{selectedProfile ? t("profileTab.roles." + selectedProfile.type.toLowerCase()) + (selectedProfileCompanyName ? (' / ' + selectedProfileCompanyName) : '') : ''}</span>
                            <img src={DownArrowDarkImage}/>
                        </div>
                    </StyledPicker>}
                    {!isExact && userWorkers && userWorkers.length > 0 &&
                        <StyledPicker onClick={() => setShowWorkerActionSheet(true)}>
                            <div>
                                <img className="switch-worker-img" src={SwitchWorkerImage}/>
                                <span>{userSelectedWorker ? userSelectedWorker.company : ''}</span>
                                <img src={DownArrowDarkImage}/>
                            </div>
                        </StyledPicker>}
                    {
                        isAwaitingForKoContact && <StyledBanner color="#000000"
                                                                backgroundColor="#ffc409">{t("profileTab.recruitment.awaitingForKoContact")}</StyledBanner>
                    }
                </IonHeader>
                <PersonalDataPane topEdge={topEdge}/>
                <QrModal number={number} name={name} isOpen={showQRCode} onClose={() => updateShowQRCode(false)}/>
            </StyledIonContent>
            <RoleActionSheet
                selectedProfile={selectedProfile}
                roles={roles}
                isVisible={showProfileActionSheet}
                onSelect={(profile) => onSelectProfile(profile)}
                onDismiss={() => updateShowProfileActionSheet(false)}/>
            <WorkerActionSheet
                selectedWorker={userSelectedWorker}
                workers={userWorkers}
                isVisible={showWorkerActionSheet}
                onSelect={(worker) => onSelectWorker(worker)}
                onDismiss={() => setShowWorkerActionSheet(false)}/>
        </IonPage>
    );
};

export default ProfileTab;
