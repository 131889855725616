import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {IonButtons, IonCol, IonGrid, IonHeader, IonPage, IonRow, IonToast} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledCircleButton, StyledHeaderButton, StyledHeaderButtonImage, StyledSocialButton } from '../../components/button/button.style';
import Pane from '../../components/pane/pane.component';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import { StyledAvailability, StyledContent } from './editDailyAvailabilityPage.style';
import { useTranslation } from 'react-i18next';
import AddImage from '../../assets/images/e-add.svg';
import TrashImage from '../../assets/images/trash-can.svg';
import ArrowRightImage from '../../assets/images/arrow-right.svg';
import { StyledIonDatetime } from '../../components/form/input/date.style';
import { getConfiguration } from '../../services/editDailyAvailabilityPage.service';
import useNavigation from '../../services/navigation.service';
import DateInput from "../../components/form/input/date.component";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');

type EditDailyAvailabilityPageState = {
    title: string;
};

interface IAvailability {
    start: Date;
    end: Date;
};

const EditDailyAvailabilityPage: React.FC = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const location = useLocation<EditDailyAvailabilityPageState>();
    const configuration = getConfiguration();

	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState<any>('');

    if (!configuration) {
        history.goBack();
    }


    const convertToDate = (time: string): Date => {
        const [hour, minutes] = time.split(':');
        return new Date(0,0,0,parseInt(hour), parseInt(minutes));
    };

    const convertToAvailabilities = (availabilities: string): IAvailability[] => {
        let result: IAvailability[] = [];
        const intervals = availabilities.split(',');

        for (const interval of intervals) {
            if (!interval) {
                continue;
            }

            const hours = interval.split('-');

            if (hours.length > 1) {
                result.push({
                    start: moment(hours[0], 'HH:mm').toDate(),
                    end: moment(hours[1], 'HH:mm').toDate(),
                })
            }
        }

        return result;
    };

    const convertToString = (): string => {
        let result: string[] = [];

        for (const interval of availabilities) {
            if (!interval) {
                continue;
            }

            result.push(`${interval.start.getHours().toString().padStart(2, '0')}:${interval.start.getMinutes().toString().padStart(2, '0')} - ${interval.end.getHours().toString().padStart(2, '0')}:${interval.end.getMinutes().toString().padStart(2, '0')}`)
        }

        return result.join(', ');
    };

    const [availabilities, setAvailabilities] = useState<IAvailability[]>(convertToAvailabilities(configuration?.availabilities ?? ''));

    const handleBack = () => {
        history.goBack();
    };

    const handleSave = () => {
    	if(hasOverlappingAvailabilities() || hasEndDateLowerThanStartDate()) {
			if (hasOverlappingAvailabilities()) {
				setToast(t("editDailyAvailabilityPage.overlappingAvailabilities"));
				setShowToast(true);
			}

			if (hasEndDateLowerThanStartDate()) {
				setToast(t("editDailyAvailabilityPage.endDateLowerThanStartDate"));
				setShowToast(true);
			}
		} else {
			getConfiguration()?.handleSave(convertToString());
		}
    };

    const remove = (index: number) => {
        availabilities.splice(index, 1);
        setAvailabilities([...availabilities]);
    };

    const add = () => {
    	let endDate = new Date();
    	endDate.setHours(endDate.getHours() + 1);

    	let minutes = Math.ceil(endDate.getMinutes() / 15) * 15;
		minutes = minutes == 60 ? 0 : minutes;
		endDate.setMinutes(minutes);

		let startDate = new Date();
		startDate.setMinutes(minutes);

		availabilities.push({
            start: startDate,
            end: endDate
        });

        setAvailabilities([...availabilities]);
    }

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        updateHeight();
        navigation.setDestinationUrl('back');
    }, [availabilities]);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    const hasOverlappingAvailabilities = () => {
		let result:boolean = false;
		availabilities.map((avail, key) => {
			let availabilitiesCopy:IAvailability[] = availabilities;
			let filtered = availabilitiesCopy.filter(function(value, index, arr) {
				return index != key;
			});

			filtered.map((av:IAvailability) => {
				let avStart:number = (av.start.getHours() + '.' + av.start.getMinutes()) as unknown as number;
				let avEnd:number = (av.end.getHours() + '.' + av.end.getMinutes()) as unknown as number;
				let availStart:number = (avail.start.getHours() + '.' + avail.start.getMinutes()) as unknown as number;
				let availEnd:number = (avail.end.getHours() + '.' + avail.end.getMinutes()) as unknown as number;

				if (Number(avStart) < Number(availEnd) && Number(availStart) < Number(avEnd)) {
					result = true;
				}
			});
		});

		return result;
	}

	const hasEndDateLowerThanStartDate = () => {
		let result:boolean = false;
		availabilities.map((avail, key) => {
			let availStart:number = (avail.start.getHours() + '.' + avail.start.getMinutes()) as unknown as number;
			let availEnd:number = (avail.end.getHours() + '.' + avail.end.getMinutes()) as unknown as number;

			if (Number(availEnd) != 0.0 && Number(availEnd) <= Number(availStart)) {
				result = true;
			}
		});

		return result;
	}

	const onStartDateChange = (value:string, avail:IAvailability) => {
		avail.start = new Date(value as any as string);
		setAvailabilities([...availabilities]);
		onDateChange(avail);
	}

	const onEndDateChange = (value:string, avail:IAvailability) => {
		avail.end = new Date(value as any as string);
		setAvailabilities([...availabilities]);
		onDateChange(avail);
    }

    const onDateChange = (avail:IAvailability) => {
    	if (hasEndDateLowerThanStartDate()) {
			setToast(t("editDailyAvailabilityPage.endDateLowerThanStartDate"));
			setShowToast(true);
		}
	}

	return (
        <IonPage>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message={toast}
				duration={4000}
				position="top"
				color="danger"
			/>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{location?.state?.title}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40}>
                    <StyledContent>
                        <StyledSocialButton className="inverted" onClick={() => add()}>
                            <img src={AddImage} />
                            {t('editDailyAvailabilityPage.addAvailability')}
                        </StyledSocialButton>
                        {availabilities.map((avail, key) =>
                            <StyledAvailability key={key}>
                                <StyledCircleButton className="delete" onClick={() => remove(key)}><img src={TrashImage} /></StyledCircleButton>
                                <IonGrid className="ion-no-padding">
                                    <IonRow>
                                        <IonCol className="title" size="5.5">{t('editDailyAvailabilityPage.begin')}</IonCol>
                                        <IonCol size="1"></IonCol>
                                        <IonCol className="title" size="5.5">{t('editDailyAvailabilityPage.finish')}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="5.5">
                                            <DateInput
                                                popover={true}
                                                format="HH:mm"
                                                presentation="time"
                                                keepContentsMounted={true}
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t('common.select')}
                                                onChange={e => onStartDateChange(e, avail) }
                                                value={moment(avail.start).format()}
                                                minuteValues="0,15,30,45"/>
                                        </IonCol>
                                        <IonCol className="arrow" size="1"><img src={ArrowRightImage} /></IonCol>
                                        <IonCol size="5.5">
                                            <DateInput
                                                popover={true}
                                                format="HH:mm"
                                                presentation="time"
                                                keepContentsMounted={true}
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t('common.select')}
                                                onChange={e => onEndDateChange(e, avail) }
                                                value={moment(avail.end).format()}
                                                minuteValues="0,15,30,45"/>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </StyledAvailability>
                        )}
                    </StyledContent>
                </Pane>
            </StyledIonContent>
        </IonPage>
    );
};

export default EditDailyAvailabilityPage;
