import {isPlatform} from "@ionic/react";
import {appVersion} from "./auth.service";
import {Device} from "@capacitor/device";
import {App} from "@capacitor/app";
import moment from "moment";

const validatePhoneNumber = (input: string, countryNumber: string): boolean => {
    const regex = new RegExp('^(([0-9]|-)+)$');
    if (countryNumber == '48' || countryNumber == '+48'){
        return regex.test(input.trim()) && input.replace(/[^0-9]/g, "").length == 9 && input.replace(/[^0-9]/g, "").length <= 10;
    }else{
        return regex.test(input.trim()) && input.replace(/[^0-9]/g, "").length >= 8 && input.replace(/[^0-9]/g, "").length <= 20;
    }
};

const validateUsername = (input: string): boolean => {
    let pattern = /^[a-z][a-zA-Z0-9]+$/;
    return pattern.test(input);
}

const validateEmail = (email: string): boolean => {
    let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
}

const validatePassword = (password: string): boolean => {
    let pattern = /^.*(?=.{8,})(?=.*[A-Z])(?=.*\d).*$/;
    return pattern.test(password);
}

const validateName = (input: string): boolean => {
    let pattern = /^[a-zA-ZąćĆęłŁńóśŚźŹżŻ]+$/;
    return pattern.test(input);
}

const validateNumber = (input: string): boolean => {
    let pattern = /^\d+$/;
    return pattern.test(input);
}

const isStringNullOrEmpty = (input: string): boolean => {
    return input === null
        || input === undefined
        || input === "";
}

const compareVersions = async () => {
    if (isPlatform('android') || isPlatform('ios')) {
        let deviceVersion = await App.getInfo().then((info) => {
            return info.version.replace(/\./g, '');
        });

        let apiVersion = null;
        let iosReleasedDate = null;
        let androidReleasedDate = null;

        await appVersion().then(response => {
            apiVersion = response.data.version.replace(/\./g, '');
            androidReleasedDate = response.data.android_released_at ? moment(response.data.android_released_at) : null;
            iosReleasedDate = response.data.ios_released_at ? moment(response.data.ios_released_at) : null;
        });

        if (deviceVersion && apiVersion) {
            let now = moment();
            if ((isPlatform('android') && androidReleasedDate && now.isAfter(androidReleasedDate)) || (isPlatform('ios') && iosReleasedDate && now.isAfter(iosReleasedDate))) {
                return deviceVersion < apiVersion;
            }
        }
    }
};

export {
    validatePhoneNumber,
    validateUsername,
    validateEmail,
    validatePassword,
    validateName,
    validateNumber,
    isStringNullOrEmpty,
    compareVersions
};
