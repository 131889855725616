import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {IonItem} from '@ionic/react';
import {StyledIonCheckbox} from '../../../components/form/input/checkbox.style';
import {StyledIonLabel} from '../../../components/form/input/label.style';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ContractDataApprovalsModalProps = {
    isOpen: boolean;
    contract: Contract | undefined;
    onHaveRemarks: () => void;
    onSign: () => void;
    onClose: () => void;
};

const ContractDataApprovalsModal: React.FC<ContractDataApprovalsModalProps> = (props: ContractDataApprovalsModalProps) => {

    const {t} = useTranslation();

    const [showModal, updateShowModal] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const [contractDataApproved, setContractDataApproved] = useState<boolean>(false);

    const handleRemarks = () => {
        updateShowModal(false);
        props.onHaveRemarks();
    };

    const handleSign = () => {
        updateShowModal(false);
        props.onSign();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
        setContractDataApproved(false);
    };

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => props.onClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(handleSign)}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.contractDataApproval.title")}</h1>
                    <div className="content">
                        <ul>
                            {props.contract && props.contract.aas_ap === false && props.contract.params && Object.entries(props.contract.params).map((value, index) => {
                                return <li>{t("contractPage.contractDataApproval."+value[0])}: <strong>{value[1]}</strong></li>;
                            })}
                        </ul>

                        <ul>
                            {props.contract && props.contract.aas_ap === true && props.contract.params && Object.entries(props.contract.params).map((value, index) => {
                                return ['last_name', 'first_name', 'date_of_birth', 'phone'].indexOf(value[0]) >= 0 ? <li>{t("contractPage.contractDataApproval."+value[0])}: <strong>{value[1]}</strong></li> : '';
                            })}
                        </ul>

                        <IonItem lines="none">
                            <StyledIonLabel
                                className="ion-text-wrap">{t('contractPage.contractDataApproval.approvedCheckbox')}</StyledIonLabel>
                            <StyledIonCheckbox name="terminateCheckbox" slot="start" onIonChange={(e) => {
                                setContractDataApproved(e.detail.checked);
                            }}/>
                        </IonItem>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledWiteButton type="button" onClick={handleRemarks}>{t("contractPage.contractDataApproval.remarks")}</StyledWiteButton>
                        <StyledButton type="submit" disabled={!contractDataApproved}>{t("contractPage.contractDataApproval.sign")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default ContractDataApprovalsModal;