import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {ReportRowMeasurement} from '../reportPage.component';
import {getMeasuringEquipments, validateMeasurements} from "../../../services/report.service";
import {StyledIonModal, StyledModalContent} from "../../../components/modal/modal.style";
import {IonCol, IonContent, IonGrid, IonItem, IonRow} from "@ionic/react";
import moment from "moment";
import CloseImage from "../../../assets/images/e-remove.svg";
import ReportRowMeasurmentComponent from "../components/report/reportRowMeasurment.component";
import {StyledButton} from "../../../components/button/button.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledContent} from "../reportPage.style";
import {StyledComponentFormContainer} from "../components/report/reportRowForm.style";
import {StyledSelectedWorkerExtended} from "../choosePersonPage.style";

type MeasurementsModalProps = {
    isOpen: boolean;
    reportPreview: boolean;
    specificationId: string;
    serviceDate: string;
    measurements?: ReportRowMeasurement[];
    onClose: () => void;
    onSubmit: (measurements: ReportRowMeasurement[]) => void;
};

const MeasurementsModal: React.FC<MeasurementsModalProps> = (props: MeasurementsModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [valid, updateValid] = useState<boolean>(false);
    const [measurements, setMeasurements] = useState<ReportRowMeasurement[]>([]);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
        if (props.isOpen && props.serviceDate && measurements.length === 0) {
            fetchMeasurements();
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.measurements) {
            setMeasurements(props.measurements);
        }
    }, [props.measurements]);

    const fetchMeasurements = async () => {
        await getMeasuringEquipments(props.specificationId, moment(props.serviceDate).format('DD-MM-YYYY'))
            .then(response => {
                setMeasurements(response.data);
            });
    }

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    const handleSubmit = () => {
        props.onSubmit(measurements);
    };

    const updateMeasurement = (measurement: ReportRowMeasurement, index: number) => {
        let tmp = measurements;
        tmp.splice(index, 1, measurement);
        setMeasurements(tmp);
        updateValid(validateMeasurements(tmp));
    }

    return (
        <StyledIonModal
            isOpen={showModal}
            onDidDismiss={() => {handleClose()}}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
        >
            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose() } />
                <StyledContent className="content full-height">
                    <StyledSelectedWorkerExtended>
                        { measurements && measurements.map((data, i) =>
                                <ReportRowMeasurmentComponent
                                    key={i}
                                    reportPreview={props.reportPreview}
                                    specificationId={props.specificationId}
                                    serviceDate={props.serviceDate}
                                    measurement={data}
                                    onChange={(measurement) => {
                                        updateMeasurement(measurement, i);
                                    }}
                                />
                            )}
                    </StyledSelectedWorkerExtended>
                </StyledContent>
                <StyledFooter className="modal footer no-shadow">
                    {!props.reportPreview && <StyledButton disabled={!valid} onClick={handleSubmit}>{t('report.measurements.save')}</StyledButton>}
                </StyledFooter>
            </StyledModalContent>
        </StyledIonModal>
    );
};

export default MeasurementsModal;