import styled from 'styled-components';

export const StyledContent = styled.div`

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 52px;
  paddting-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .target {
    flex-grow: 1;
  }
  
  img.preview {
    position: absolute;
    bottom: 0;
    z-index: -1;
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;

    &.preview {
      padding-bottom: 32px;
    }
       
    &.multiple-buttons {
      flex-direction: row;
    }

    button.take-photo {
      display: inline-flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      background: #0085FF;
      border: 3px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 28px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    span.description {
      display: block;
      padding-top: 10px;
      padding-bottom: 20px;
      width: 56px;
      font-size: 9px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }

    &.empty {
      margin-bottom: 24px;
    }
  }
`;