import styled from "styled-components";

export const StyledNewNotification = styled.div`
	width: 6px;
	height: 6px;
	position: absolute;
	background: #F43030;
	border-radius: 6px;
	top: calc(50% - 22px);
    right: calc(50% - 15px);
`;

export const StyledFullMenuButton = styled.button`
	background-color: #FFFFFF;
`;

export const StyledMenuButton = styled.div`
    display: inherit;

    ion-icon,
    img {
        order: 1;
    }

    ion-label {
        order: 2
    }
`;
