import styled from 'styled-components';

export const StyledPinRow = styled.div`
  width:  100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 0 24px;
`;

export const StyledRow = styled.div`
  width:  100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 0 24px;
  
  .password {
    width: 250px;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;

    &.required:after {
      content: '*';
      color: red;
    }
  }
`;