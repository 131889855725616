import {Style, StatusBar} from "@capacitor/status-bar";

const appStatusBarUpdate = async () => {
    const info = await StatusBar.getInfo();

    if (info.style !== Style.Dark) {
        StatusBar.setStyle({
            style: Style.Dark
        });
    }

    if (info.color !== '#262532') {
        StatusBar.setBackgroundColor({
            color: '#262532'
        });
    }
}

const loginStatusBarUpdate = async () => {
    const info = await StatusBar.getInfo();

    if (info.style !== Style.Light) {
        StatusBar.setStyle({
            style: Style.Light
        });
    }

    if (info.color !== '#ffffff') {
        StatusBar.setBackgroundColor({
            color: '#ffffff'
        });
    }
}

export {appStatusBarUpdate, loginStatusBarUpdate}