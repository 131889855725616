import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../../../components/pane/pane.component';
import {StyledContainer} from './personalData.style';
import ProfileImage from '../../../../../assets/images/logo/x-gray.png';
import CheckMarkImage from '../../../../../assets/images/checkMarkGreen.svg';
import VectorImage from '../../../../../assets/images/vector.svg';
import AddImage from '../../../../../assets/images/e-add.svg';
import {StyledButton, StyledCircleButton, StyledSocialButton} from '../../../../../components/button/button.style';
import {Links} from '../../../../links';
import {setConfiguration} from '../../../../../services/addEditValuePage.service';
import {Preferences} from "@capacitor/preferences";
import {getProfile, updateInlineFieldValue} from '../../../../../services/profile.service';
import moment from 'moment';
import {StyledDataTable} from '../../../../../components/content/content.style';
import {ifNull, range} from "../../../../../utils/tools/numbers";
import {snakeToCamel} from "../../../../../utils/tools/string";
import AvatarActionSheet from '../components/avatarActionSheet/avatarActionSheet.component';
import {storeProfile} from "../../../../../services/auth.service";
import {IonSkeletonText, useIonViewWillEnter} from "@ionic/react";
import {log} from "../../../../../services/firebaseAnalytics.service";
import {useEvent} from "../../../../../hooks/useEvent";
import {EventType} from "../../../../../enums/eventType";

type PersonalDataPaneProps = {
    topEdge?: number;
	refresh?: boolean;
}

enum PersonalDataEditType {
    INLINE,
    COURSES,
    LANGUAGES,
	DATE,
	CITY,
	PHONE,
	SIZE
};

interface IPersonalData {
    name: string;
	field: string;
    value: any;
    edit: PersonalDataEditType;
    disabled: boolean;
	type?: any;
	values?: any[]
};

interface IPersonalDataStorage {
	firstName: any;
	lastName: any;
	email: any;
	phone: any;
	phoneCode: any;
	dateOfBirth: any;
	city: any;
	languages: any;
	courses: any;
	isEmployed: any;
	isCoordinator: any;
	isTeamLeader: any;
	avatarUrl: any;
	workerToConnect: any;
	height: any;
	clothingSize: any;
	shoeSize: any;
};

const PersonalDataPane: React.FC<PersonalDataPaneProps> = (props: PersonalDataPaneProps) => {
    const { t } = useTranslation();
    const history = useHistory();
	const {listen} = useEvent();

	const [storageData, setStorageData] = useState<IPersonalDataStorage>();
	const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

    const setProfileData = async (result: any) => {
		let languagesReadable:string = "";
		if (result.languages) {
			Object.keys(result.languages).map(e => {
				languagesReadable += (languagesReadable.length == 0 ? "" : ", ") + t("languagesPage.languages."+ snakeToCamel(e));
			});
		}

		let coursesReadable:string = "";
		if (result.courses) {
			Object.keys(result.courses).map(e => {
				coursesReadable += (coursesReadable.length == 0 ? "" : ", ") + t("coursesPage.courses."+ snakeToCamel(e));
			});
		}

		let personalDataStorage:IPersonalDataStorage = {
			firstName: ifNull(result.first_name) || '',
			lastName: ifNull(result.last_name) || '',
			email: ifNull(result.email) || '',
			phone: ifNull(result.phone) || '',
			phoneCode: ifNull(result.phone_code) || '',
			dateOfBirth: ifNull(result.date_of_birth) || '',
			city: ifNull(result.city) || '',
			languages: languagesReadable,
			courses: coursesReadable,
			isEmployed: result.number ? '1' : '0',
			isCoordinator: result.is_coordinator ? '1' : '0',
			isTeamLeader: result.is_team_leader ? '1' : '0',
			avatarUrl: result.photos.thumbnail ? ifNull(result.photos.thumbnail) || '' : '',
			workerToConnect: result.worker_to_connect ? '1' : '0',
			height: ifNull(result.height) || '',
			clothingSize: ifNull(result.clothing_size) || '',
			shoeSize: ifNull(result.shoe_size) || '',
		};

		setStorageData(personalDataStorage);
	};

	const getProfileData = async () => {
		setLoading(true)

		await getProfile(true)
			.then(response => {
				setProfileData(response.data);
				storeProfile(response.data);
				return true;
			})
			.catch(error => {
				return false;
			});

		setLoading(false);
	}

	useEffect(() => {
		getProfileData();
		listen(EventType["REFRESH.PERSONAL_DATA_PANE.PROFILE_DATA"], getProfileData);
	}, []);

	useIonViewWillEnter(() => {
		getProfileData();
	});

	const personalData: IPersonalData[] = [{
        name: t('profileTab.personalData.email'),
		field: 'email',
        value: storageData?.email,
        edit: PersonalDataEditType.INLINE,
		disabled: true
    },
    {
        name: t('profileTab.personalData.phoneNumber'),
		field: 'phone',
        value: (storageData?.phoneCode ? '+' + storageData.phoneCode + ' ' : '') + storageData?.phone,
        edit: PersonalDataEditType.PHONE,
		disabled: false
    },
    {
        name: t('profileTab.personalData.birthDate'),
		field: 'dateOfBirth',
        value: storageData?.dateOfBirth ? moment(storageData?.dateOfBirth).format('DD.MM.YYYY') : '',
        edit: PersonalDataEditType.DATE,
		disabled: false
	},
    {
        name: t('profileTab.personalData.city'),
		field: 'city',
        value: storageData?.city,
        edit: PersonalDataEditType.CITY,
		disabled: false
	},
	{
		name: t('profileTab.personalData.languages'),
		field: 'languages',
		value: storageData?.languages,
		edit: PersonalDataEditType.LANGUAGES,
		disabled: false
	},
	{
		name: t('profileTab.personalData.courses'),
		field: 'courses',
		value: storageData?.courses,
		edit: PersonalDataEditType.COURSES,
		disabled: false
	},
	{
		name: t('profileTab.personalData.height'),
		field: 'height',
		value: storageData?.height,
		edit: PersonalDataEditType.INLINE,
		disabled: false,
		type: 'number'
	},
	{
		name: t('profileTab.personalData.clothingSize'),
		field: 'clothing-size',
		value: storageData?.clothingSize,
		edit: PersonalDataEditType.SIZE,
		disabled: false,
		values: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
	},
	{
		name: t('profileTab.personalData.shoeSize'),
		field: 'shoe-size',
		value: storageData?.shoeSize,
		edit: PersonalDataEditType.SIZE,
		disabled: false,
		values: range(36, 49, 1)
	}];

	const updateStorage = async (field: string, value: any) => {
		field = field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
		await Preferences.set({
			'key': field,
			'value': value
		});
	}

	const updateInlineField = async (field: string, value: any) => {
		const response = await updateInlineFieldValue(field, value)
			.then(response => {
				updateStorage(field, value);
				history.goBack();
			})
			.catch(error => {
				if (error.response.status === 400) {
					try {
						let message = error.response.data.errors.children.email.errors[0];
						if (message === "Please enter an email.") {
							return t("profileTab.personalData.validation.emptyEmail");
						} else if (message === "The email is not valid.") {
							return t("profileTab.personalData.validation.invalidEmail");
						} else if (message === "The email is already used.") {
							return t("profileTab.personalData.validation.emailExists");
						} else {
							return t("common.serverErrorMsg");
						}
					} catch (e) {
						return t("common.serverErrorMsg");
					}
				} else {
					return t("common.serverErrorMsg");
				}
			})
		;

		return response;
	};

    const edit = (data: IPersonalData) => {
    	switch (data.edit) {
            case PersonalDataEditType.LANGUAGES:
				history.push(Links.languages);
			break;
			case PersonalDataEditType.COURSES:
				history.push(Links.courses);
			break;
			case PersonalDataEditType.CITY:
				history.push(Links.main + Links.cities, data.value);
			break;
			case PersonalDataEditType.DATE:
				setConfiguration({
					title: data.name,
					defaultValue: data.value,
					handleSave: (value) => updateInlineField(data.field, value),
					handleDismiss: () => history.goBack()
				});
				history.push(Links.birthDate);
			break;
			case PersonalDataEditType.PHONE:
				setConfiguration({
					title: data.name,
					defaultValue: data.value,
					handleSave: (value) => updateInlineField(data.field, value),
					handleDismiss: () => history.goBack()
				});
				history.push(Links.phone);
			break;
			case PersonalDataEditType.SIZE:
				setConfiguration({
					title: data.name,
					defaultValue: data.value,
					values: data.values,
					handleSave: (value) => updateInlineField(data.field, value),
					handleDismiss: () => history.goBack()
				});
				history.push(Links.addEditSize);
				break;
			default:
					setConfiguration({
						title: data.name,
						defaultValue: data.value,
						type: data.type,
						handleSave: (value) => updateInlineField(data.field, value),
						handleDismiss: () => history.goBack()
					});
                	history.push(Links.addEditValue);
            break;
        }
    };

    const onAvatarActionSheetDismiss = () => {
		setShowActionSheet(false);
		getProfileData();
	}

	useEffect(() => {
		log('page_visit', {
			page: 'Profil -> Twoje dane'
		});
	}, []);

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={157}>
			<AvatarActionSheet onDismiss={() => onAvatarActionSheetDismiss()} isVisible={showActionSheet}></AvatarActionSheet>
            <StyledContainer>
				<div className="profile-avatar" onClick={(e) => { setShowActionSheet(true); e.stopPropagation(); }}>
					<StyledCircleButton className="add" onClick={() => {}}><img src={AddImage} /></StyledCircleButton>
					<img src={storageData?.avatarUrl || ProfileImage} className="avatar" />
				</div>
				{ loading &&
					<span className="name" style={{ width: '50%' }}><IonSkeletonText animated /></span>
				}
				{ !loading &&
                	<span className="name">{storageData?.firstName || ''} {storageData?.lastName || ''}</span>
				}
				{
					!loading && storageData?.isCoordinator != 1 && (storageData?.isEmployed == 1
						?
						<>
							<StyledSocialButton className="greyedout" onClick={ () => history.push(Links.teamMember) }>
								<img src={CheckMarkImage} />
								{t('profileTab.personalData.partOfTeam')}
							</StyledSocialButton>
							{ storageData?.workerToConnect == 1 ?
								<StyledButton className="orange non-clickable" onClick={e => e.stopPropagation()}>
									{t('profileTab.personalData.waitForAccept')}
								</StyledButton> :
								<StyledButton onClick={() => history.push(Links.becameTeamMember)}>
									{t('profileTab.personalData.connectOtherWorker')}
								</StyledButton>
							}
						</>
						: (
							storageData?.workerToConnect == 1 ?
								<StyledButton className="orange non-clickable" onClick={e => e.stopPropagation()}>
									{t('profileTab.personalData.waitForAccept')}
								</StyledButton> :
								<StyledButton onClick={ () => history.push(Links.becameTeamMember) }>
									{t('profileTab.personalData.notPartOfTeam')}
								</StyledButton>
						))
				}

				{
					loading &&
					<StyledSocialButton className="greyedout">
						<small style={{ width: '50%' }}><IonSkeletonText animated /></small>
					</StyledSocialButton>
				}

				<StyledDataTable>
                { !loading && personalData.map((data, i) => (storageData?.isCoordinator != '1' || (storageData?.isCoordinator == '1' && (data.field == 'email' || data.field == 'phone'))) && <div key={i} className="data-row" onClick={ () => data.disabled == false ? edit(data) : '' }>
                                                    <div className="data-title">{data.name}</div>
                                                    <div className="data-value"><span>{data.value}</span></div>
                                                    <div className="data-action">
														{data.disabled == false && <img src={VectorImage} />}
                                                    </div>
                                                </div>)
                }

                { loading && personalData.map((data, i) => (storageData?.isCoordinator != '1' || (storageData?.isCoordinator == '1' && (data.field == 'email' || data.field == 'phone'))) && <div key={i} className="data-row">
                                                    <div className="data-title">{data.name}</div>
                                                    <div className="data-value"><span style={{ width: (Math.random()>=0.5) ? '70%' : '50%' }}><IonSkeletonText animated /></span></div>
                                                    <div className="data-action"></div>
                                                </div>)
                }
                </StyledDataTable>
            </StyledContainer>
        </Pane>
    );
};

export default PersonalDataPane;
