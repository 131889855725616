import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {
    StyledButton,
    StyledButtonBadge,
    StyledLightBlueButton, StyledSocialButton,
    StyledWiteButton
} from '../../../components/button/button.style';
import {Loan as LoanModel} from '../../../models/loan';
import {IonAlert, IonCol, IonGrid, IonRow, IonToast} from '@ionic/react';
import SignModal, {ISignModalConfigration} from '../../../modals/sign/sign.component';
import ApprovalsModal from '../modals/approvalsModal.component';
import {LoansContext} from "./loansPane.component";
import {FilesystemDirectory, Filesystem, Directory} from '@capacitor/filesystem';
import {blobToBase64} from "../../../services/file.service";
// import { FileOpener } from '@ionic-native/file-opener';
import {LoanPageContext, LoanPageOptions} from "../loanPage.component";
import {useHistory} from "react-router-dom";
import useNavigation from "../../../services/navigation.service";
import {SubNavigationContext} from "../../../components/sub-navigation/subNavigation.component";
import {Links} from "../../links";
import {hasPhone} from "../../../services/teamMember.service";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import {LoanStatus} from "../../../enums/loanStatus";
import {cancelLoan, getLoan, getLoanPdf, getLoans, signLoan, signLoanVerify} from "../../../services/loan.service";
import Loan from "../../../components/loan/loan.component";
import NewLoanTakenModal from "../modals/newLoanTakenModal.component";
import WarningImage from "../../../assets/images/warning-red.svg";
import {StyledLoanButton} from "../../../components/loan/loan.style";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";

type ContentPaneProps = {
    topEdge?: number;
    status?: LoanStatus;
    loan?: LoanModel;
}

const ContentPane: React.FC<ContentPaneProps> = (props: ContentPaneProps) => {
    const context = useContext(LoansContext);
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');

    const [status, setStatus] = useState('');
    const [loan, setLoan] = useState<LoanModel>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const contextLoanPage = useContext(LoanPageContext);
    const contextSubNavigation = useContext(SubNavigationContext);

    useEffect(() => {
        if (props.status !== undefined && !status) {
            setStatus(props.status);
        }

        if (props.loan !== undefined && !loan) {
            setLoan(props.loan);
        }

    }, [loan, status, props.loan, props.status]);

    useEffect(() => {
        updateLoan();
    }, []);

    const updateLoan = async () => {
        if (props.loan?.id) {
            await getLoan(props.loan?.id)
                .then(response => {
                    let loan = response.data.loan as LoanModel;
                    loan.content = response.data.loan_content;
                    loan.regulations_content = response.data.regulations_content;
                    setLoan(loan);
                    setStatus(loan.sign_status);
                    contextLoanPage.setStatus(loan.sign_status);
                })
                .catch(error => {
                    // todo what we show??
                });
        }
    }

    const downloadPdf = async () => {
        if (loan?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = null;

                fileData = await getLoanPdf(loan?.id)
                    .then(response => {
                        return response.data;
                    });

                let base64 = null;
                if (fileData) {
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {

                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + loan.number.replace(/\//g, '_') + '.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch(e) {}
                    }
                }
            }
        }
    };

    return (
        <div>
            <Pane topEdge={props.topEdge} marginTop={40}
                paddingBottom={160}>
                <h1>{loan?.number}</h1>
                <div dangerouslySetInnerHTML={{__html: loan?.content || ''}}/>
            </Pane>

            {status && (status === LoanStatus.SIGNED || status === LoanStatus.UNSIGNED || LoanStatus.EXPIRED) &&
                <StyledFooter>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <StyledLightBlueButton className="no-margin-top"
                                                       onClick={() => downloadPdf()}>{t("loanPage.content.downloadPdf")}</StyledLightBlueButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </StyledFooter>
            }

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />

        </div>
    );
};

export default ContentPane;