import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '@components/modal/modal.component';
import {StyledModalContent} from '@components/modal/modal.style';
import {StyledInput} from '@components/form/input/input.style';
import {StyledButton} from '@components/button/button.style';
import {StyledContent} from "@app/reportPage/modals/commentsModal.style";

type CommentForCoordinatorModalProps = {
    isOpen: boolean;
    onClose: () => void;
    setValue: (value: string) => void;
};

const CommentForCoordinatorModal: React.FC<CommentForCoordinatorModalProps> = ({isOpen, onClose, setValue}: CommentForCoordinatorModalProps) => {
    const {t} = useTranslation();

    const [comment, setComment] = useState<string>('');

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        setValue(comment);
        handleClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            disableClose={true}
            initialBreakpoint={0.5}
            breakpoints={[0, 0.5]}
            onDidDismiss={() => handleClose()}
        >
            <StyledModalContent className="t-p-25">
                <StyledContent className="content">
                    <StyledInput value={comment} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}/>
                    <StyledButton onClick={() => handleSave()}>{t('reportPage.manualModal.addButton')}</StyledButton>
                </StyledContent>
            </StyledModalContent>
        </Modal>
    );
};

export default CommentForCoordinatorModal;