import {appOpenApi} from "./api.service";
import {
    Filter, FilterParam,
    FilterType, OffersRegionSelectable,
    OfferType
} from "../app/mainPage/tabs/offers/components/filterActionSheet/filterActionSheet.component";

let endpointPrefix = 'job-offers';

const getOffers = async (params: any = null) => {
    return appOpenApi.get(endpointPrefix + '?' + params);
}

const getOffersCounts = async (params: any = null) => {
    return appOpenApi.get(endpointPrefix + '/counts?' + params);
}

const getOffersCompanies = async (params: any = null) => {
    return appOpenApi.get(endpointPrefix + '/companies?' + params);
}

const getOffersCountries = async (lang: string) => {
    return appOpenApi.get(endpointPrefix + '/countries?lang=' + lang);
}

const getOffersProvinces = async (country: OffersRegionSelectable) => {
    return appOpenApi.get(endpointPrefix + '/provinces?country=' + country.id);
}

const getOffersCities = async (province: OffersRegionSelectable) => {
    return appOpenApi.get(endpointPrefix + '/cities?province=' + province.id);
}

const getOffer = async (id: bigint | string) => {
    return appOpenApi.get(endpointPrefix + '/' + id);
}

const applyOffer = async (id: bigint | string, phone: string, email: string|null = null) => {
    return appOpenApi.post(endpointPrefix + '/' + id + '/apply', {
        phone: phone,
        email: email
    });
}

const filtersToParamsArray = (filters: Filter[])  => {
    let params:string[] = [];
    filters.forEach((filter) => {
        switch (filter.type) {
            case FilterType.TITLE:
                params.push('title=' + encodeURIComponent(filter.value));
                break;

            case FilterType.LOCATION:
                params.push('domestic=' + encodeURIComponent(filter.value == OfferType.LOCAL));
                break;

            case FilterType.PROVINCE:
                params.push('province=' + encodeURIComponent(filter.value));
                break;

            case FilterType.CITY:
                params.push('city=' + encodeURIComponent(filter.value));
                break;

            case FilterType.COUNTRY:
                params.push('country=' + encodeURIComponent(filter.value));
                break;

            case FilterType.COMPANY:
                params.push('company=' + encodeURIComponent(filter.value));
                break;

            case FilterType.SORTING:
                params.push('sortBy='+ encodeURIComponent(filter.value));
                break;
        }
    });

    return params;
}

const filtersToParamsObjects = (filters: Filter[])  => {
    let params:FilterParam[] = [];
    filters.forEach((filter) => {
        switch (filter.type) {
            case FilterType.TITLE:
                params.push({name: 'title', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.LOCATION:
                params.push({name: 'domestic', value: encodeURIComponent(filter.value == OfferType.LOCAL)});
                break;

            case FilterType.PROVINCE:
                params.push({name: 'province', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.CITY:
                params.push({name: 'city', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.COUNTRY:
                params.push({name: 'country', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.COMPANY:
                params.push({name: 'company', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.SORTING:
                params.push({name: 'sortBy', value: encodeURIComponent(filter.value)});
                break;
        }
    });

    return params;
}

const filtersToParams = (filters: Filter[]) => {
    let params: string[] = filtersToParamsArray(filters);
    return params.join('&');
}

export {
    getOffers,
    getOffersCounts,
    getOffersCompanies,
    getOffersCountries,
    getOffersProvinces,
    getOffersCities,
    getOffer,
    applyOffer,
    filtersToParams,
    filtersToParamsArray,
    filtersToParamsObjects
}