import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import {StyledIonToolbar} from "../../../components/content/content.style";

//import {StyledModalContent} from "../../components/modal/modal.style";

//export const StyledContent = styled(StyledModalContent)`


export const StyledContent = styled.div`
    h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        text-align: center;
        color: #000000;
        padding: 0 24px;
    }
  
    .ion-padding {
        padding: 10px;
    }

    .recruitment-faq-item {
        color: #0F1664;
        border-radius: 16px;
        margin-bottom: 5px;
        --ion-item-background: #f2f4fa;
        --border-color: #f2f4fa;
      
        .number {
            font-weight: bold;
            display: inline-block;
            width: 10%;
        }
      
        .title {
            display: inline-block;
            width: 85%;
            text-align: center;
        }
    }
  
    .recruitment-faq-item-content {
        .icon {
          text-align: center;
          margin-top: 10px;
          
          img {
            width: 70px;
          }
        }
    }
  
    &.info {
      .logo {
        text-align: center;
        
        img {
          width: 30%;
        }
      }
      
      .back-button {
        margin-top: 45px;
      }
    }

    &.accordion {
      .box {
        display: flex;
        justify-content: space-between;
        align-content: space-between;

        .back-button {
          width: 40px;
          margin-top: 5px;
        }

        .push {
          text-align: center;
          background-color: #ffc409;
        }

        .logo {
          text-align: center;
          margin-bottom: 50px;

          img {
            width: 100%;
          }
        }
      }

      .logo {
        text-align: center;
        padding-top: 5px;

        img {
          width: 100%;
          height: 44px;
        }
      }
      
      h1.group-title {
        font-size: 21px;
      }
    }
  
    .justify {
      text-align: justify;
    }
`;

export const StyledIonRecruitmentToolbar = styled(StyledIonToolbar)`
  .logo {
    img {
      width: 15%;
    }
  }
  
  .title {
    font-size: 16px;
    color: #000000;
    padding-left: 10px;
  }
`;