import { IonCol, IonRow } from "@ionic/react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledAccordion } from "../../../reportPage/reportPage.style";

export type AccordionProps = {
    title: string,
    isOpen: boolean,
    allowToggle: boolean,
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = (props: PropsWithChildren<AccordionProps>) => {
    const { t } = useTranslation();
    const [ isOpen, setOpen ] = useState<boolean>(props.isOpen);
    const [ title, setTitle ] = useState<string>('');
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setOpen(!isOpen);
        setIsExpanded(prevState => !prevState);
    }

    const ArrowUpIcon = () => (
        <StyledAccordion>
            <div className="arrow-down">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                    <path fill="none" stroke="#0085FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M328 112L184 256l144 144"/>
                </svg>
            </div>
        </StyledAccordion>
      );

    const ArrowDownIcon = () => (
        <StyledAccordion>
            <div className="arrow-down">
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
                    <path fill="none" stroke="#0085FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 184l144 144 144-144"/>
                </svg>
            </div>
        </StyledAccordion>
      );

    useEffect( () => { setTitle(props.title)}, [props.title] )

    return <>
        <StyledAccordion>
                <IonRow className="row">
                    <IonCol  style={{ padding: 0, margin: 0 }} className="title">{ t(title) }</IonCol>
                    {props.allowToggle && <IonCol  className="toggle" onClick={toggleAccordion}> {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />} </IonCol>}
                </IonRow>
                <IonRow>
                    <hr />
                </IonRow>
            { isOpen && 
                <IonRow>
                    <IonCol className="title">{ props.children }</IonCol>
                </IonRow>
            }
        </StyledAccordion>
    </>
}

export default Accordion;