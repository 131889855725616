import {EventType} from "@enums/eventType";

export const useEvent = () => {
    const dispatch = (eventType: EventType, data?: any) => {
        const event = new CustomEvent(eventType, {detail: data});
        document.dispatchEvent(event);
    };
    const listen = (eventType: EventType, listener: any) => {
        document.addEventListener(eventType, listener);
    };
    const remove = (eventType: EventType, listener: any) => {
        document.removeEventListener(eventType, listener);
    };
    return {dispatch, listen, remove};
};