import React from 'react';
import {StyledLoanContainer} from './loan.style';
import {IonSkeletonText} from "@ionic/react";

const LoanSkeleton: React.FC = () => {
    return (
        <StyledLoanContainer>
            <small>
                <IonSkeletonText animated style={{ width: '50%' }} />
            </small>
            <h1><IonSkeletonText animated style={{ width: '80%' }} /></h1>
            <p><IonSkeletonText animated style={{ width: '40%' }} /></p>
        </StyledLoanContainer>
    );
};

export default LoanSkeleton;
