import {appApi} from "./api.service";
import {ContractData} from "../models/contractData";
import {Preferences} from "@capacitor/preferences";
import {WorkerWarehouseTransfer} from "../models/worker";

let endpointPrefix = 'worker';

const getContractData = async () => {
    return appApi.get(endpointPrefix + '/contract-data');
}

const saveContractData = async (contractData: ContractData) => {
    return appApi.post(endpointPrefix + '/contract-data', contractData);
}

const signSaveContractData = async (code: string) => {
    return appApi.post(endpointPrefix + '/contract-data/code-verification', {
        'verificationCode': code
    });
}

const getWalletData = async (startDate:string, endDate:string) => {
	return appApi.get(endpointPrefix + '/wallet?startDate=' + startDate + '&endDate=' + endDate + '&view=extended');
}

const getRegistryData = async () => {
	return appApi.get(endpointPrefix + '/hours');
}

const getWorkerWarehouse = async () => {
	return appApi.get(endpointPrefix + '/warehouse-items');
}

export const getWorkerWarehouseConfirmation = async (transfer: WorkerWarehouseTransfer, status: string, comment: string|null = null) => {
	return appApi.post(endpointPrefix + '/warehouse-items/' + transfer.id + '/confirmation', {
		warehouse_transfer_confirmation: {
			confirmationStatus: status,
			confirmationStatusComment: comment
		},
	});
}

const acceptRegistryData = async () => {
	return appApi.post(endpointPrefix + '/hours/accept');
}

const sendRegistryDataComment = async (comment:string) => {
	return appApi.post(endpointPrefix + '/hours/comment', {
		email: "",
		phone: "",
		content: comment
	});
}

const getNumber = async () => {
	let object = await Preferences.get({
		'key': 'employee_number',
	});

	return object.value;
}

const getBadge = async () => {
	return appApi.get(endpointPrefix + '/badge');
}

const tisaxSave = async (rulesAccepted: boolean, tisaxSignedByWorker: boolean) => {
	return appApi.post(endpointPrefix + '/tisax/sign', {
		rulesAccepted: rulesAccepted,
		tisaxSignedByWorker: tisaxSignedByWorker
	});
}

const tisaxSaveSign = async (code: string) => {
	return appApi.post(endpointPrefix + '/tisax/sign/code-verification', {
		'verificationCode': code
	});
}
const foreignTermsSave = async (rulesAccepted: boolean) => {
	return appApi.post(endpointPrefix + '/foreign-terms/sign', {
		rulesAccepted: rulesAccepted
	});
}

const foreignTermsSaveSign = async (code: string) => {
	return appApi.post(endpointPrefix + '/foreign-terms/sign/code-verification', {
		'verificationCode': code
	});
}

const getWorkerData = async () => {
	return appApi.get(endpointPrefix + '/my-data');
}

const getWorkerReferFriend = async () => {
	return appApi.get(endpointPrefix + '/refer-friend');
}

const acceptReferFriend = async () => {
	return appApi.post(endpointPrefix + '/refer-friend-accept', {
		'referFriend': 1
	});
}

const signWorkerData = async (data:[]) => {
	return appApi.post(endpointPrefix + '/my-data/sign', data);
}

const signWorkerDataVerify = async (code: string, password?: string) => {
	let data:any = {
		'verificationCode': code
	}

	if (password) {
		data.password = password;
	}

	return appApi.post(endpointPrefix + '/my-data/sign/code-verification', data);
}

const getWorkerDataChangeStatementPdf = async (id: bigint | string) => {
	return appApi.get(endpointPrefix + '/my-data/' + id + '/download-change-statement', {
		responseType: "blob"
	});
}

const getHoursWorkedData = async (startDate:string, endDate:string) => {
	return appApi.get(endpointPrefix + '/hours-worked?startDate=' + startDate + '&endDate=' + endDate + '&view=extended');
}

const getWorkerDataChangesHistory = async () => {
	return appApi.get(endpointPrefix + '/my-data/changes-history');
}

export {
    getContractData,
    saveContractData,
    signSaveContractData,
	getWalletData,
	getRegistryData,
	acceptRegistryData,
	sendRegistryDataComment,
	getNumber,
	tisaxSave,
	tisaxSaveSign,
	foreignTermsSave,
	foreignTermsSaveSign,
	getHoursWorkedData,
	getBadge,
	getWorkerWarehouse,
	getWorkerData,
	getWorkerReferFriend,
	acceptReferFriend,
	signWorkerData,
	signWorkerDataVerify,
	getWorkerDataChangesHistory,
	getWorkerDataChangeStatementPdf
};
