import React, { useEffect, useRef, useState } from 'react';
import { IonButtons, IonHeader, IonPage } from '@ionic/react';
import { StyledDataTable, StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../../../components/button/button.style';
import { useHistory } from 'react-router-dom';
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import VectorImage from '../../../../assets/images/checkMarkGreen.svg';
import { useTranslation } from 'react-i18next';
import StaticPane from '../../../../components/pane/static-pane.component';

interface IAppMode {
    name: string;
    selected: boolean
};

// TODO: possible merge languageTab with appModeTab into select optionTab ??
const AppMode: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const modes: IAppMode[] = [{
        name: t('appModeTab.disabled'),
        selected: true
    },{
        name: t('appModeTab.enabled'),
        selected: false
    },{
        name: t('appModeTab.auto'),
        selected: false
    }];

    useEffect(() => {
        updateHeight();
    });

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };
    
    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => history.goBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("appModeTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={105}>
                    <StyledDataTable className="no-margin">
                    { modes.map((data, i) => <div key={i} className={`data-row ${ modes.length - 1 === i ? 'no-line' : '' }`} onClick={ () => {}}>
                                                        <div className="data-icon-title">{data.name}</div>
                                                        <div className="data-action">
                                                            {data.selected && <img src={VectorImage} />}
                                                        </div>
                                                    </div>)
                    }
                    </StyledDataTable>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AppMode;