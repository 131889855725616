import { StyledInput as Input, PasswordInput, StyledPinInput as PinInput, ClearableInput } from './input/input.style';
import { StyledButton as Button } from '../button/button.style';
import Container from './form/form.component';

export default {
  Container,
  Input,
  PasswordInput,
  ClearableInput,
  Button,
  PinInput
}