import {appApi} from "./api.service";
import {Preferences} from "@capacitor/preferences";

let endpointPrefix = 'permission';

const getPermission = async (permission: string, type: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/' + permission + '/' + type + '?profileId='+(profileId.value ? profileId.value : ''));
}

export {
	getPermission
};
