import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {IonItem} from "@ionic/react";
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import LinkLabel from "../../components/form/input/label.component";
import {StyledIonLabel} from "../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../components/form/input/checkbox.style";
import {StyledModalContent} from "../../components/modal/modal.style";
import {useForm} from "react-hook-form";
import {Contract as ContractModel, Contract} from "../../models/contract";
import {getContract, getContractTisax} from "../../services/contract.service";
import {disableSwipeToClose} from "../../utils/tools/modals";

type TisaxModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSign: (rulesAccepted: boolean, tisaxSignedByWorker: boolean) => void;
};

const TisaxModal: React.FC<TisaxModalProps> = (props: TisaxModalProps) => {

    const {t} = useTranslation();

    const [contract, updateContract] = useState<Contract>();
    const [termsChecked, updateTermsChecked] = useState(false);
    const [tisaxChecked, updateTisaxChecked] = useState(false);
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);
    const [showTisax, updateShowTisax] = useState<boolean>(false);

    useEffect(() => {
        handleOpen();
    }, []);

    const handleOpen = async () => {
        let contactTmp = await getContractTisax() as Contract;
        if (contactTmp) {
            await getContract(contactTmp.id)
                .then(response => {
                    let contract = response.data.contract as Contract;
                    contract.regulations_content = response.data.regulations_content;
                    contract.tisax_content = response.data.tisax_content;
                    updateContract(contract);
                });
        }
    };

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const onSubmit = async () => {
        props.onSign(termsChecked, tisaxChecked);
    };

    return (
        <>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: contract?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>
            <Modal isOpen={showTisax} onDidDismiss={() => updateShowTisax(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.tisaxModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: contract?.tisax_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={props.isOpen}
                maxHeight={'95%'}
                canDismiss={disableSwipeToClose}
                disableClose={true}
                onDidDismiss={() => props.onClose()}>

                <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                    <StyledModalContent>
                        <h1 className="title">{t("contractPage.approvals.title")}</h1>

                        <div className="content">
                            <LinkLabel urlString={t("contractPage.approvals.checkboxes.rulesAcceptedLink")} url={'#'} onClick={() => updateShowRegulations(true)}>
                                <IonItem lines="none">
                                    <StyledIonLabel className="ion-text-wrap">{ contract?.company_code === 'AASPL' ? t('contractPage.approvals.checkboxes.rulesAcceptedAAS') : t('contractPage.approvals.checkboxes.rulesAccepted')}</StyledIonLabel>
                                    <StyledIonCheckbox name="rulesAccepted" slot="start" onIonChange={(e) => {
                                        updateTermsChecked(e.detail.checked);
                                    }}/>
                                </IonItem>
                            </LinkLabel>

                            <LinkLabel urlString={t("contractPage.approvals.checkboxes.tisaxAcceptedLink")} url={'#'} onClick={() => updateShowTisax(true)}>
                                <IonItem lines="none">
                                    <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.tisaxAccepted')}</StyledIonLabel>
                                    <StyledIonCheckbox name="rulesAccepted" slot="start" onIonChange={(e) => {
                                        updateTisaxChecked(e.detail.checked);
                                    }}/>
                                </IonItem>
                            </LinkLabel>
                        </div>

                        <StyledFooter className="no-shadow">
                            <Form.Button type="submit" disabled={!termsChecked || !tisaxChecked}>{t("contractPage.approvals.acceptButton")}</Form.Button>
                        </StyledFooter>
                    </StyledModalContent>
                </Form.Container>
            </Modal>
        </>
    );
};

export default TisaxModal;