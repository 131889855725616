import React, {ReactNode} from 'react';

interface TruncateTextProps extends React.HTMLAttributes<HTMLDivElement> {
    lines?: number,
    width?: number,
    children: ReactNode
}

const TruncateText: React.FC<TruncateTextProps> = ({lines, width, children, ...props}: TruncateTextProps) => {
    const truncateTextStyle: React.CSSProperties = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }

    if (typeof lines === 'number') {
        truncateTextStyle['display'] = '-webkit-box';
        truncateTextStyle['WebkitBoxOrient'] = 'vertical';
        truncateTextStyle['WebkitLineClamp'] = lines;
    }

    if (typeof width === 'number') {
        truncateTextStyle['width'] = `${width}px`;
        truncateTextStyle['whiteSpace'] = 'nowrap';
    }

    return (
        <div style={truncateTextStyle} {...props}>
            {children}
        </div>
    );
};

export default TruncateText;