import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import {StyledNoAuditReports, StyledAuditReports} from './auditReports.style';
import NoOrdersImage from '../../../assets/images/no-orders.svg';
import AddImage from '../../../assets/images/e-add.svg';
import AvatarImage from '../../../assets/images/logo/x-gray.png';
import ReportsImage from '../../../assets/images/chart-pie-36.svg';
import {StyledSocialButton} from '../../../components/button/button.style';
import {Links} from '../../links';
import {IonSkeletonText, useIonViewWillEnter} from "@ionic/react";
import {log} from "../../../services/firebaseAnalytics.service";
import {Profile} from "../../../models/profile";
import {getPermission} from "../../../services/permission.service";
import {getAuditReports, getShitPeriodReadable} from "../../../services/audit.service";
import {AuditReport} from "../../../models/auditReport";
import {AuditType} from "../../../enums/audit";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

type ReportsPaneProps = {
    auditType: AuditType;
    topEdge?: number;
    profile?: Profile;
}

const AuditReportsPane: React.FC<ReportsPaneProps> = (props: ReportsPaneProps) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [forceReload, setForceReload] = useState<boolean>(false);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [reports, setReports] = useState<[]|undefined>(undefined);

    const history = useHistory();

    const fetchReports = async () => {
        if ((reports === undefined && !loading) || forceReload) {
            setLoading(true);
            setReports([]);
            const response = await getAuditReports(props.auditType)
                .then(response => {
                    setReports(response.data);
                })
                .catch(reason => {
                    setReports([]);
                });

            setLoading(false);
        }
    }

    const fetchPermission = async () => {
        await getPermission('QUALITY_DEPARTMENT', 'EDIT')
            .then(response => {
                setEditPermission(response.data);
            })
    }

    useEffect(() => {
        fetchPermission();
        fetchReports();
    }, []);

    useIonViewWillEnter( () => {
        fetchReports();
    });

    useEffect(() => {
        log('page_visit', {
            page: 'Audyty -> Audyty'
        });
    }, []);

    const addReport = (type: AuditType) => {
        history.push(Links.auditStart + '/' + type);
    };

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {
                <>
                    {
                        editPermission &&
                        <StyledSocialButton className="inverted" onClick={() => addReport(props.auditType)}>
                            <img src={AddImage}/>
                            {t('auditsTab.addReportButton')}
                        </StyledSocialButton>
                    }
                    {
                        !loading && reports && reports.length === 0 &&
                        <StyledNoAuditReports>
                            <img src={NoOrdersImage}/>
                            <img className="reports" src={ReportsImage}/>
                            <span className="message">{t('auditsTab.noReports')}</span>
                        </StyledNoAuditReports>
                    }
                    {
                        !loading && reports && reports.length !== 0 &&
                        <StyledAuditReports>
                            {
                                reports.map((report: AuditReport, i) => {
                                        return <React.Fragment key={i}>
                                            <div className="order">
                                                <div className="order-number">
                                                    #{report.id} {report.place_of_service?.name}
                                                </div>
                                                <div className="members"></div>
                                                <div className="details">
                                                    <div className="detail">{t('auditsTab.date')}: <span
                                                        className="value">{ moment(report.date, moment.ISO_8601).tz('Europe/Warsaw').format('DD.MM.YYYY')}</span></div>

                                                    <div className="detail">{t('auditsTab.placeOfService')}: <span
                                                        className="value">{report.place_of_service?.name}</span></div>

                                                    <div className="detail">{t('auditsTab.type')}: <span
                                                        className="value">{report.type}</span></div>

                                                    <div className="detail">{t('auditsTab.shift_period')}: <span
                                                        className="value">{getShitPeriodReadable(report.shift_period)}</span></div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                )
                            }
                        </StyledAuditReports>
                    }
                    {
                        loading &&
                        <StyledAuditReports>
                            <div className="date-separator"><div className="box"><IonSkeletonText animated style={{ width: '50%' }} /></div></div>
                            <div className="order">
                                <div className="order-number">
                                    <IonSkeletonText animated style={{ width: '50%' }} />
                                </div>
                                <div className="members">
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                </div>
                                <div className="details">
                                    <div className="detail"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '90%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                                </div>
                            </div>
                            <div className="order">
                                <div className="order-number">
                                    <IonSkeletonText animated style={{ width: '50%' }} />
                                </div>
                                <div className="members">
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                    <img src={AvatarImage}/>
                                </div>
                                <div className="details">
                                    <div className="detail"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '90%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '70%' }} /></div>
                                    <div className="detail"><IonSkeletonText animated style={{ width: '30%' }} /></div>
                                </div>
                            </div>
                        </StyledAuditReports>
                    }
                </>
            }
        </Pane>
    );
};

export default AuditReportsPane;
