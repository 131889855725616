import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonHeader, IonPage, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import {RouteComponentProps, useHistory} from "react-router-dom";
import RecruitmentPathPane from "./panes/recruitmentPathPane.component";
import useNavigation from "../../services/navigation.service";
import StaticPane from "../../components/pane/static-pane.component";
import {Preferences} from "@capacitor/preferences";

interface RouterProps {
    hash: string;
}

interface RecruitmentProps extends RouteComponentProps<RouterProps> {
}

const RecruitmentPage: React.FC<RecruitmentProps> = ({match}) => {
    const { t } = useTranslation();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [hashCode, updateHashCode] = useState<string | null>(null);
    const history = useHistory();
    const navigation = useNavigation(history);
    const {params: {hash}} = match;

    useEffect(() => {
        updateHeight();
    }); //[]

    useEffect(() => {
        getHashCode();
    }); //[]

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const getHashCode = async () => {
        let hashCode = await Preferences.get({'key': 'recruitment_hash'});

        let hash:string = match.params.hash;
        if (hash) {
            updateHashCode(hash);
        } else {
            updateHashCode(hashCode.value ?? 'DEFAULT');
        }
    };

    const hideTabBar = (): void => {
        const tabBar = document.getElementById('app-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    };

    const showTabBar = (): void => {
        const tabBar = document.getElementById('app-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'flex';
        }
    };

    useIonViewDidEnter(() => {
        hideTabBar();
    });

    useIonViewDidLeave(() => {
        showTabBar();
    });

    return (
        <IonPage>
            <StyledIonContent className="recruitment">
                {hashCode && <RecruitmentPathPane topEdge={topEdge} hash={hashCode} /> }
            </StyledIonContent>
        </IonPage>
    );
};

export default RecruitmentPage;
