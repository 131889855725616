import { WorkingHour } from "../../app/walletPage/panes/walletPane.component";
import moment from 'moment';

export const groupWalletByDate = (workingHours: WorkingHour[]): WorkingHour[] => {
    const grouped: WorkingHour[] = [];

    (workingHours || []).forEach((workingHour) => {
        const currentGroup = grouped.find((wH) => moment(wH.date).format('yyyyMMDD') === moment(workingHour.date).format('yyyyMMDD'));

        if (!currentGroup) {
            grouped.push({...workingHour});
        } else {
            const currentTotal = currentGroup.quantity * currentGroup.rate;
            const total = workingHour.quantity * workingHour.rate;
            currentGroup.quantity += workingHour.quantity;
            currentGroup.rate = (total + currentTotal) / currentGroup.quantity;
        }
    });

    return grouped;
}

export const groupWalletByRate = (workingHours: WorkingHour[]): WorkingHour[] => {
    const grouped: WorkingHour[] = [];

    (workingHours || []).forEach((workingHour) => {
        const currentGroup = grouped.find((wH) => wH.rate === workingHour.rate);

        if (!currentGroup) {
            grouped.push({...workingHour});
        } else {
            currentGroup.quantity += workingHour.quantity;
        }
    });

    return grouped;
}