import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Links} from './../links';
import {IonHeader, IonPage} from "@ionic/react";
import {
    StyledEmptyContainer,
    StyledIonContent,
    StyledIonTitle,
    StyledIonToolbar
} from "../../components/content/content.style";
import StaticPane from "../../components/pane/static-pane.component";
import Logo from "../../components/logo";
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import {useTranslation} from "react-i18next";

const AutoLoggedOut: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory()

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    document.addEventListener('ionBackButton', (ev: Event) => {
        ev.stopPropagation();
    });

    useEffect(() => {
        updateHeight();
    });

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle>{t("autoLoggedOut.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <Logo />
                        <p className="center">{t('autoLoggedOut.description')}</p>

                        <StyledFooter className="no-shadow">
                            <Form.Button type="submit" onClick={() => history.replace(Links.login)}>{t('autoLoggedOut.login')}</Form.Button>
                        </StyledFooter>
                    </StyledEmptyContainer>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AutoLoggedOut;
