import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {ProposalUser} from "../../models/proposal";
import ProfileImage from "../../assets/images/logo/x-gray.png";
import {StyledDataTable} from "../../components/content/content.style";
import {ModalStyledContainer} from "../../app/mainPage/tabs/profile/panes/personalData.style";
import {IonBadge} from "@ionic/react";
import moment from "moment";
import {disableSwipeToClose} from "../../utils/tools/modals";

type WorkerPreviewProps = {
	isOpen: boolean;
	onClose: () => void;
	worker?: ProposalUser | null;
};

const WorkerPreviewModal: React.FC<WorkerPreviewProps> = (props: WorkerPreviewProps) => {

	const {t} = useTranslation();
	return (
		<Modal
			isOpen={props.isOpen}
			canDismiss={disableSwipeToClose}
			maxHeight={'75%'}
			disableClose={true}
			onDidDismiss={() => props.onClose()}>

			<ModalStyledContainer>
				<div className="profile-avatar">
					<img src={props.worker?.user && (props.worker?.user?.photos || props.worker?.user?.avatar) ? (props.worker?.user.photos?.thumbnail ? props.worker?.user.photos.thumbnail : props.worker?.user.avatar) : ProfileImage} className="avatar" />
				</div>
				<h3 className="name">{props.worker?.name}</h3>
				{props.worker?.user.has_active_contract && <IonBadge color="success">{t('profileTab.personalData.hasActiveContract')}</IonBadge>}

				<StyledDataTable>
					<div className="data-row">
						<div className="data-title">{t('profileTab.personalData.email')}</div>
						<div className="data-value no-cut">{props.worker?.user.email}</div>
					</div>
					<div className="data-row">
						<div className="data-title">{t('profileTab.personalData.phoneNumber')}</div>
						<div className="data-value"><span>{props.worker?.user.phone}</span></div>
					</div>
					<div className="data-row">
						<div className="data-title">{t('profileTab.personalData.birthDate')}</div>
						<div className="data-value"><span>{props.worker?.user.date_of_birth ? moment(props.worker?.user.date_of_birth).format('DD.MM.YYYY') : ''}</span></div>
					</div>
					<div className="data-row">
						<div className="data-title">{t('profileTab.personalData.city')}</div>
						<div className="data-value"><span>{props.worker?.user.city}</span></div>
					</div>
				</StyledDataTable>
			</ModalStyledContainer>
		</Modal>
	);
};

export default WorkerPreviewModal;
