import {appApi, authApi} from "./api.service";

const getReferFriendsPdf = async () => {
    return appApi.get('refer-friends', {
        responseType: "blob"
    });
}

export {
    getReferFriendsPdf
}



