import { IonCheckbox } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonCheckbox = styled(IonCheckbox)`   
    width: 20px;
    height: 20px;
    --border-radius: 6px;
    --border-width: 1px;
    --border-style: solid;
    --border-color: #bdbdc2;
    --border-color-checked: #bdbdc2;
    --checkmark-width: 2px;
    --checkmark-color: #0085FF;
    --background-checked: #FFFFFF !important;
`;
