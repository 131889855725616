import styled from 'styled-components';
import {ContractStatus} from '../../enums/contractStatus';
import {Contract, ContractAppendix} from '../../models/contract';
import {ContractCommentsStatus} from "../../enums/contractCommentsStatus";

export const StyledRecruitmentPathSkeletonContainer = styled.div`
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 16px;
  
  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 16px;
    color: #000000;
    margin: 0;
    margin-top: 8px;
  }
`;
