import React, { useEffect, useRef, useState } from 'react';
import { IonHeader, IonPage} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import SubNavigation, { SubNavigationOption } from '../../../../components/sub-navigation/subNavigation.component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useNavigation from '../../../../services/navigation.service';
import Pane from '../../../../components/pane/pane.component';
import { SearchInput } from '../../../../components/form/input/input.style';

export enum CommunicatorTabOptions {
    MESSAGES = 0,
    PEOPLE = 1
}

const CommunicatorTab: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const options = [{
        name: t("communicatorTab.messagesOption")
    },
    {
        name: t("communicatorTab.peopleOption")
    }];

    useEffect(() => {
        updateHeight();
    });

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || CommunicatorTabOptions.MESSAGES);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };
    
    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("communicatorTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <SearchInput margin={24} color="dark" placeholder={t("communicatorTab.search")}></SearchInput>
                </IonHeader>
                <Pane topEdge={topEdge} marginTop={40} paddingBottom={97}>
                    <SubNavigation options={options} light={true} marginTop={0}
                                    defaultValue={selectedOption || CommunicatorTabOptions.MESSAGES}
                                    onSelect={handleSelect}></SubNavigation>
                </Pane>
            </StyledIonContent>
        </IonPage>
    );
};

export default CommunicatorTab;