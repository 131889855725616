import styled from "styled-components";


export const StyledGameContent = styled.div`
  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 20px;
    display: inline-block;
    width: 60%;
  }

  .equation {
    font-size: 31px;
    line-height: 140%;
    color: #0F1664;
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;
    width: 100%;
    text-decoration: underline;
  }
  
  .game1 {
    .answers {
      max-width: 95%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: auto;
      margin-top: 5px;

      .wrapper-image {
        width: 100%;
        display: flex;
        align-items: center;

        &.left{
          justify-content: flex-start;
        }

        &.right{
          justify-content: flex-end;
          margin: -40px 0;
        }

        img {
          width: 100%;
          height: auto;
          position: relative;
          
          &.valid {
            z-index: 100;
          }
        }
      }
    } 
  }
  
  .game2 {
   .answers {
     max-width: 90%;
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     align-items: center;
     margin: auto;
     height: 100%;
     min-height: 0;

     .wrapper-image {
       width: 33.333%;
       display: flex;
       justify-content: center;
       align-items: center;
       min-width: 33.3%;

       img {
         width: 100%;
         height: auto;
         padding: 20px 5px;
       }
     }
   } 
  }
`