import styled from "styled-components";

export const StyledPicker = styled.div`

    display: inline-flex;
    width: calc(100%);
    margin-bottom: 24px;
    margin-top: 9px;
    transform: translate(calc(50% - 106px));

    div {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;

        position: absolute;
        width: 212px;
        height: 25px;

        padding: 0 12px;

        color: #363543;
        border-radius: 26px;

        font-size: 12px;
        line-height: 140%;
        background: #ffffff;
        
        span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            line-height: 16px;
            text-overflow: ellipsis;
        }

        img {
            width: 6px;
            flex-shrink: 0;
            margin-left: 6px;
        }
        
        .switch-worker-img {
            width: 18px;
            margin-left: 0;
            margin-right: 6px;
        }
    }
`;

export type StyledBannerProps = {
    color: string;
    backgroundColor: string;
}

export const StyledBanner = styled.div<StyledBannerProps>`
    display: inline-flex;
    align-items: center;
    justify-items: center;

    width: auto;
    height: 25px;

    padding: 0 12px;

    background: ${(props) => props.backgroundColor};
    border-radius: 26px;

    font-size: 12px;
    line-height: 140%;
    color: ${(props) => props.color};
`;