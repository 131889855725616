import {IonCol, IonGrid, IonRow, IonSelectOption} from '@ionic/react';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledButtonBadge } from '../../../../../../components/button/button.style';
import { StyledDataTable } from '../../../../../../components/content/content.style';
import {SearchInput, StyledField} from '../../../../../../components/form/input/input.style';
import {
    StyledActionIonModal,
    StyledActionSheet,
    StyledActionSheetContainer,
    StyledIonModal
} from '../../../../../../components/modal/modal.style';
import { StyledFilterContainer } from './filterActionSheet.style';
import VectorImage from '../../../../../../assets/images/checkMarkGreen.svg';
import CloseImage from '../../../../../../assets/images/e-remove.svg';
import {
    filtersToParams,
    getOffersCities, getOffersCompanies, getOffersCountries,
    getOffersCounts,
    getOffersProvinces
} from "../../../../../../services/jobOffer.service";
import {StyledIonSelect} from "../../../../../../components/form/input/select.style";
import {useForm} from "react-hook-form";
import Form from "../../../../../../components/form";

type FilterActionSheetProps = {
    isVisible: boolean;
    filters?: Filter[];
    counts?: OffersCounts;
    onSave: (filter: Filter[]) => void;
    onDismiss: () => void;
}

export enum OfferType {
    LOCAL,
    FOREIGN
}

export enum FilterType {
    TITLE,
    LOCATION,
    SORTING,
    OTHER,
    PROVINCE,
    CITY,
    COMPANY,
    COUNTRY,
}

export type Filter = {
    type: FilterType;
    description?: string;
    value: any;
}

export type FilterParam = {
    name: string;
    value: any;
}

export type OffersCounts = {
    all: bigint,
    local: bigint,
    foreign: bigint
}

export type OffersRegionSelectable = {
    id: bigint,
    name: string
}

interface FilterActionSheetManager {
    setSelectedOfferType: Function;
    setSelectedTitle: Function;
    setSelectedProvince: Function;
    setSelectedCity: Function;
    setSelectedCountry: Function;
    setSelectedCompany: Function;
    setSorting: Function;
}

const contextFilterActionSheet: FilterActionSheetManager = {
    setSelectedOfferType: () => {

    },
    setSelectedTitle: () => {

    },
    setSelectedProvince: () => {

    },
    setSelectedCity: () => {

    },
    setSelectedCountry: () => {

    },
    setSelectedCompany: () => {

    },
    setSorting: () => {

    }
};

export const FilterActionSheetContext = React.createContext<FilterActionSheetManager>(contextFilterActionSheet);

const FilterActionSheet: React.FC<FilterActionSheetProps> = (props: FilterActionSheetProps) => {
    const [isVisible, changeVisibility] = useState(false);
    const {t, i18n} = useTranslation();

    const [resultsCount, setResultsCount] = useState<bigint|null>(null);
    const [localOffersCount, setLocalOffersCount] = useState<bigint|null>(null);
    const [foreignOffersCount, setForeignOffersCount] = useState<bigint|null>(null);
    const [activeFilters, setActiveFilters] = useState(0);

    const [companies, setCompanies] = useState<OffersRegionSelectable[]>();
    const [countries, setCountries] = useState<OffersRegionSelectable[]>();
    const [provinces, setProvinces] = useState<OffersRegionSelectable[]>();
    const [cities, setCities] = useState<OffersRegionSelectable[]>();

    const [selectedTitle, updateSelectedTitle] = useState<string | undefined | null>();
    const [selectedOfferType, updateSelectedOfferType] = useState<OfferType | undefined>();
    const [selectedCompanyObject, updateSelectedCompanyObject] = useState<OffersRegionSelectable | undefined>();
    const [selectedCountryObject, updateSelectedCountryObject] = useState<OffersRegionSelectable | undefined>();
    const [selectedProvinceObject, updateSelectedProvinceObject] = useState<OffersRegionSelectable | undefined>();
    const [selectedCityObject, updateSelectedCityObject] = useState<OffersRegionSelectable | undefined>();

    const fetchProvinces = async (country: OffersRegionSelectable) => {
        if (country) {
            await getOffersProvinces(country)
                .then(response => {
                    setProvinces(response.data);
                });
            setCities(undefined);
        } else {
            updateSelectedProvinceObject(undefined);
            setProvinces(undefined);
            setCities(undefined);
        }
    };

    const fetchCities = async (province: OffersRegionSelectable) => {
        if (province) {
            await getOffersCities(province)
                .then(response => {
                    setCities(response.data);
                });
        } else {
            setCities(undefined);
        }
    };

    const fetchCountries = async () => {
        await getOffersCountries(i18n.language)
            .then(response => {
                setCountries(response.data);
            });
    };

    const fetchCompanies = async () => {
        await getOffersCompanies()
            .then(response => {
                setCompanies(response.data);
            });
    };

    useEffect(() => {
        fetchCompanies();
        fetchCountries();
    }, []);

    useEffect(() => {
        changeVisibility(props.isVisible);

        if (props.counts) {
            if (resultsCount == null) {
                setResultsCount(props.counts.all);
            }

            if (foreignOffersCount == null) {
                setForeignOffersCount(props.counts.foreign);
            }

            if (localOffersCount == null) {
                setLocalOffersCount(props.counts.local);
            }
        }
    });



    const selectOfferType = (type: OfferType) => {
        if (selectedOfferType === type) {
            updateSelectedOfferType(undefined);
        } else {
            updateSelectedOfferType(type);
        }
    };

    const updateActiveFilters = () => {
        let count: number = 0;

        if (selectedTitle) {
            count++;
        }

        if (selectedOfferType !== undefined) {
            count++;
        }

        if (selectedProvinceObject !== undefined) {
            count++;
        }

        if (selectedCityObject !== undefined) {
            count++;
        }

        if (selectedCountryObject !== undefined) {
            count++;
        }

        if (selectedCompanyObject !== undefined) {
            count++;
        }

        setActiveFilters(count);
    };
    
    useEffect(() => {
        updateActiveFilters();
    }, [selectedTitle, selectedOfferType, selectedProvinceObject, selectedCityObject, selectedCompanyObject, selectedCountryObject]);

    useEffect(() => {
        const debounceId = setTimeout(() => updateCountOnChange(), 300);
        return () => clearTimeout(debounceId);
    }, [selectedTitle, selectedOfferType, selectedProvinceObject, selectedCityObject, selectedCompanyObject, selectedCountryObject]);

    const handleSave = () => {
        const filters: Filter[] = [];

        if (selectedTitle) {
            filters.push({
                type: FilterType.TITLE,
                description: selectedTitle,
                value: selectedTitle
            });
        }

        if (selectedOfferType !== undefined) {
            filters.push({
                type: FilterType.LOCATION,
                description: (selectedOfferType as OfferType) == OfferType.LOCAL ? t("offersTab.filtersActionSheet.localOffers") : t("offersTab.filtersActionSheet.foreignOffers"),
                value: selectedOfferType
            });
        }

        if (selectedProvinceObject !== undefined) {
            filters.push({
                type: FilterType.PROVINCE,
                description: selectedProvinceObject.name,
                value: selectedProvinceObject.id
            });
        }

        if (selectedCityObject !== undefined) {
            filters.push({
                type: FilterType.CITY,
                description: selectedCityObject.name,
                value: selectedCityObject.id
            });
        }

        if (selectedCountryObject !== undefined) {
            filters.push({
                type: FilterType.COUNTRY,
                description: selectedCountryObject.name,
                value: selectedCountryObject.id
            });
        }

        if (selectedCompanyObject !== undefined) {
            filters.push({
                type: FilterType.COMPANY,
                description: selectedCompanyObject.name,
                value: selectedCompanyObject.id
            });
        }

        props.onSave(filters);
    };

    const { handleSubmit } = useForm({
        mode: 'all'
    });

    const refreshFilters = async (filters: Filter[], clear = true) => {
        if (clear) {
            updateSelectedOfferType(undefined);
            updateSelectedTitle('');
            updateSelectedCityObject(undefined);
            updateSelectedProvinceObject(undefined);
            updateSelectedCompanyObject(undefined);
            updateSelectedCountryObject(undefined);
        }

        filters.forEach((filter) => {
            switch (filter.type) {
                case FilterType.TITLE:
                    updateSelectedTitle(filter.value);
                    break;

                case FilterType.LOCATION:
                    updateSelectedOfferType(filter.value);
                    break;

                case FilterType.PROVINCE:
                    updateSelectedProvinceObject({
                        id: filter.value,
                        name: filter.description ?? ''
                    });
                    break;

                case FilterType.CITY:
                    updateSelectedCityObject({
                        id: filter.value,
                        name: filter.description ?? ''
                    });
                    break;

                case FilterType.COUNTRY:
                    updateSelectedCountryObject({
                        id: filter.value,
                        name: filter.description ?? ''
                    });
                    break;

                case FilterType.COMPANY:
                    updateSelectedCompanyObject({
                        id: filter.value,
                        name: filter.description ?? ''
                    });
                    break;
            }
        });

        updateCounts(filtersToParams(filters));
    }

    const updateCountOnChange = async () => {
        let params = [];
        params.push('title=' + encodeURIComponent(selectedTitle ? selectedTitle.toString() : ''));
        if (selectedOfferType != null) {
            params.push('domestic=' + encodeURIComponent(selectedOfferType == OfferType.LOCAL));
        }

        if (selectedProvinceObject != null) {
            params.push('province=' + encodeURIComponent(selectedProvinceObject.id.toString()));
        }

        if (selectedCityObject != null) {
            params.push('city=' + encodeURIComponent(selectedCityObject.id.toString()));
        }

        if (selectedCountryObject != null) {
            params.push('country=' + encodeURIComponent(selectedCountryObject.id.toString()));
        }

        if (selectedCompanyObject != null) {
            params.push('company=' + encodeURIComponent(selectedCompanyObject.id.toString()));
        }

        updateCounts(params.join('&'));
    }

    const updateCounts = async (params: any = null) => {
        await getOffersCounts(params)
            .then(response => {
                let counts = response.data as OffersCounts;

                setResultsCount(counts.all);
                setForeignOffersCount(counts.foreign);
                setLocalOffersCount(counts.local);
            });
    }

    const contextFilterActionSheet = useContext(FilterActionSheetContext);
    contextFilterActionSheet.setSelectedOfferType = updateSelectedOfferType;
    contextFilterActionSheet.setSelectedTitle = updateSelectedTitle;
    contextFilterActionSheet.setSelectedCity = updateSelectedCityObject;
    contextFilterActionSheet.setSelectedProvince = updateSelectedProvinceObject;
    contextFilterActionSheet.setSelectedCountry = updateSelectedCountryObject;
    contextFilterActionSheet.setSelectedCompany = updateSelectedCompanyObject;

    const compareWith = (o1: OffersRegionSelectable, o2: OffersRegionSelectable) => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };

    useEffect(() => {
        if (props.filters) {
            refreshFilters(props.filters, false);
        }
    }, [props.filters]);

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={(data) => props.onDismiss()}
            initialBreakpoint={0.75}
            breakpoints={[0, 0.75, 0.99]}
        >
            <div className="modal-wrap filters">
                <img src={CloseImage} className="close" onClick={() => props.onDismiss() } />
                <Form.Container onSubmit={handleSubmit(handleSave)}>
                    <IonGrid>
                        <IonRow className="header-row">
                            <IonCol size="11"  className="ion-justify-content-center header">
                                {t("offersTab.filtersActionSheet.title")}
                            </IonCol>
                        </IonRow>
                        <IonRow className="clear-row">
                            <IonCol size="12">
                                {activeFilters > 0 &&
                                    <div className="clear-button" onClick={() => refreshFilters([])}>
                                        <span>{t("offersTab.filtersActionSheet.clearButton")}</span>
                                        <StyledButtonBadge
                                            className="default-position">{activeFilters}</StyledButtonBadge>
                                    </div>
                                }
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <SearchInput placeholder={t("offersTab.filtersActionSheet.keyWords")} initValue={selectedTitle?.toString()} onChange={event => {
                                    updateSelectedTitle(event.target.value)
                                }}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <StyledField>
                                <label>{t("offersTab.filtersActionSheet.company")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedCompanyObject}
                                                 compareWith={compareWith}
                                                 name="company"
                                                 interfaceOptions={
                                                     {
                                                         header: t("offersTab.filtersActionSheet.company")
                                                     }
                                                 }
                                                 justify="end"
                                                 disabled={companies && companies?.length == 0}
                                                 onIonChange={e => {
                                                     updateSelectedCompanyObject(e.detail.value);
                                                 }}>
                                    {companies?.map((value: OffersRegionSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>
                            </StyledField>
                        </IonRow>
                        <IonRow>
                            <StyledField>
                                <label>{t("offersTab.filtersActionSheet.country")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedCountryObject}
                                                 compareWith={compareWith}
                                                 name="country"
                                                 interfaceOptions={
                                                     {
                                                         header: t("offersTab.filtersActionSheet.country")
                                                     }
                                                 }
                                                 justify="end"
                                                 disabled={countries && countries?.length == 0}
                                                 onIonChange={e => {
                                                     updateSelectedCountryObject(e.detail.value);
                                                     fetchProvinces(e.detail.value);
                                                 }}>
                                    {countries?.map((value: OffersRegionSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>
                            </StyledField>
                        </IonRow>
                        <IonRow>
                            <StyledField>
                                <label>{t("offersTab.filtersActionSheet.province")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedProvinceObject}
                                                 compareWith={compareWith}
                                                 name="province"
                                                 interfaceOptions={
                                                     {
                                                         header: t("offersTab.filtersActionSheet.province")
                                                     }
                                                 }
                                                 justify="end"
                                                 disabled={!provinces || (provinces && provinces?.length == 0)}
                                                 onIonChange={e => {
                                                     updateSelectedProvinceObject(e.detail.value);
                                                     fetchCities(e.detail.value);
                                                 }}>
                                    {provinces?.map((value: OffersRegionSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>
                            </StyledField>
                        </IonRow>
                        <IonRow>
                            <StyledField>
                                <label>{t("offersTab.filtersActionSheet.city")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedCityObject}
                                                 compareWith={compareWith}
                                                 name="city"
                                                 interfaceOptions={
                                                     {
                                                         header: t("offersTab.filtersActionSheet.city")
                                                     }
                                                 }
                                                 justify="end"
                                                 disabled={!cities || (cities && cities?.length == 0)}
                                                 onIonChange={e => {
                                                     updateSelectedCityObject(e.detail.value);
                                                 }}>
                                    {cities?.map((value: OffersRegionSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>
                            </StyledField>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <StyledButton onClick={() => handleSave()}>{t("offersTab.filtersActionSheet.showResults")} {resultsCount !== null ? `(${resultsCount})` : ''}</StyledButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </Form.Container>
            </div>
        </StyledIonModal>
    ) : (<span></span>);
};

export default FilterActionSheet;
