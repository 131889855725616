import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {StyledActionIonModal, StyledActionSheet, StyledIonModal} from '../../../../components/modal/modal.style';
import {StyledDisclaimer, StyledWalletGrid} from '../../panes/wallet.style';
import { MonthWallet, WorkingHour } from '../../panes/walletPane.component';
import moment from 'moment';
import CloseImage from '../../../../assets/images/e-remove.svg';
import { StyledWalletHourlyDetailsActionSheetContainer } from './walletHourlyDetailsActionSheet.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../../components/pane/pane.style';
import { useSwipeable } from 'react-swipeable';
import {minutesToHoursMinutes} from "../../../../utils/tools/time";
import { groupWalletByRate } from '../../../../utils/tools/wallet';
import ModalService from '../../../../services/modal.service';

type WalletHourlyDetailsActionSheetProps = {
    isVisible: boolean;
    wallet?: MonthWallet;
    title?: string;
    onDismiss: () => void;
    date?: Date|undefined;
}

const WalletHourlyDetailsActionSheet: React.FC<WalletHourlyDetailsActionSheetProps> = (props: WalletHourlyDetailsActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const [hiding, startHiding] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (isVisible !== props.isVisible) {
            if (props.isVisible) {
                ModalService.modalOpened(props.onDismiss);
                startHiding(false);
                changeVisibility(props.isVisible);
            } else {
                ModalService.modalDismissed();
                startHiding(true);
                setTimeout(() => changeVisibility(props.isVisible), 275);
            }
        }
    }, [isVisible, props.isVisible]);

	const [currentDate, setCurrentDate] = useState<Date|undefined>();
    const [workingHours, setWorkingHours] = useState<WorkingHour[]>([]);
    useEffect(() => {
        if (!props.wallet) {
            return;
        }

		setCurrentDate(props.date);

		let newWorkingHours: WorkingHour[] = [];

        for (let employer of props.wallet.employers) {
            newWorkingHours = [...newWorkingHours, ...employer.workingHours];
        }

        setWorkingHours(groupWalletByRate(newWorkingHours));

    }, [props.wallet]);

    const getTotalHours = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + curr.quantity;
        }, 0);

        return sum;
    };

    const getTotal = (): number => {
        const sum = workingHours.reduce((prev: number, curr: WorkingHour) => {
            return prev + (curr.quantity * curr.rate);
        }, 0);

        return sum;
    };

    const config = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
      };

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            props.onDismiss();
        },
        ...config,
    });

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
        >
                <StyledWalletHourlyDetailsActionSheetContainer className={hiding ? 'hide': 't-p-50'} onClick={(e) => e.stopPropagation()}>
                    <img src={CloseImage} className="close" onClick={(e) =>{ props.onDismiss(); e.stopPropagation()} } />
                    <StyledWalletGrid>
                        <div className="title">{props.title || t("reportsTab.wallet.detailsView")}</div>
						{currentDate !== undefined && <div className="subtitle">{moment(currentDate).format('yyyy-MM-DD')}</div>}
                        <IonGrid>
                            <IonRow>
                                <IonCol className="aligment header" size="5.5">
                                {t("reportsTab.wallet.rate")}
                                </IonCol>
                                <IonCol className="aligment end  header" size="3">
                                {t("reportsTab.wallet.workingHoursSum")}
                                </IonCol>
                                <IonCol className="aligment end header" size="3.5">
                                {t("reportsTab.wallet.toPay")}<span className="required">*</span>
                                </IonCol>
                            </IonRow>
                    </IonGrid>

                    <IonGrid className="content">

                            { workingHours && workingHours.length > 0 &&
                                workingHours.map((workingHour, key) =>
                                <IonRow key={key}>
                                    <IonCol className="aligment row" size="5.5">
                                        {(workingHour.rate * 1).toFixed(2)}
                                    </IonCol>
                                    <IonCol className="aligment end row value" size="3">
                                        {minutesToHoursMinutes(workingHour.quantity * 60)}
                                    </IonCol>
                                    <IonCol className="aligment end row value" size="3.5">
                                        {(workingHour.quantity * workingHour.rate).toFixed(2)}
                                    </IonCol>
                                </IonRow>
                                )
                            }
                        </IonGrid>

                        <IonGrid>

                            { workingHours && workingHours.length > 0 &&
                                <IonRow className="footer">
                                    <IonCol className="aligment footer black" size="5.5">
                                    {t("reportsTab.wallet.sum")}
                                    </IonCol>
                                    <IonCol className="aligment end footer black" size="3">
                                        {minutesToHoursMinutes(getTotalHours() * 60)}
                                    </IonCol>
                                    <IonCol className="aligment end footer black" size="3.5">
                                        {getTotal().toFixed(2)}
                                    </IonCol>
                                </IonRow>
                            }
                        </IonGrid>
                    </StyledWalletGrid>
                    <StyledDisclaimer>*{t("reportsTab.wallet.toPayDisclaimer")}</StyledDisclaimer>
                </StyledWalletHourlyDetailsActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default WalletHourlyDetailsActionSheet;
