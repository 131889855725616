import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledWiteButton } from '../../../../../../components/button/button.style';
import CircleImage, { ImageTypes } from '../../../../../../components/circleImage/circleImage.component';
import {CameraResultType, CameraSource} from "@capacitor/camera";
import {Preferences} from "@capacitor/preferences";
import {Camera} from "@capacitor/camera";
import {changeBase46ToBlob} from '../../../../../../services/file.service';
import {uploadPhoto} from "../../../../../../services/profile.service";
import {updateStorage} from "../../../../../../utils/tools/storage";
import {useLocation} from "react-router-dom";
import {MainPageContext} from "../../../../mainPage.component";
import {
    StyledActionIonModal,
    StyledActionSheet,
    StyledActionSheetContainer,
    StyledIonModal
} from '../../../../../../components/modal/modal.style';
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";

type AvatarActionSheetProps = {
    isVisible: boolean;
    onDismiss: () => void;
}

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

const AvatarActionSheet: React.FC<AvatarActionSheetProps> = (props: AvatarActionSheetProps) => {
	const context = useContext(MainPageContext);

    const [isVisible, changeVisibility] = useState(false);
    const {t} = useTranslation();

	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ toast, setToast ] = useState<any>(toastInit);
	const [ avatarUrl, setAvatarUrl] = useState<string | null>();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    useEffect(() => {
        changeVisibility(props.isVisible);
    });

    const loadGalleryPhoto = async () => {
        let hasPermissions = await Camera.checkPermissions();
        if (hasPermissions.photos === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Photos,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64
            });

            if (image.base64String) {
                let blob = changeBase46ToBlob(image.base64String);
                sendPhoto(blob);
            }
        }
    };

    const takeSystemPhoto = async () => {
        let hasPermissions = await Camera.checkPermissions();
        if (hasPermissions.camera === 'denied') {
            setShowPermissionAlert(true);
        } else {
            const image = await Camera.getPhoto({
                source: CameraSource.Camera,
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64
            });

            if (image.base64String) {
                let blob = changeBase46ToBlob(image.base64String);
                sendPhoto(blob);
            }
        }
    };

    const sendPhoto = async(blob: Blob) => {
    	const response = await uploadPhoto(blob, true)
			.then(response => {
					if (response.data.photos.thumbnail) {
						updateStorage('avatar_url', response.data.photos.thumbnail);
						context.setAvatarUrl(response.data.photos.thumbnail);
					}
					return true;
				})
				.catch(error => {
					setToast(t("common.serverErrorMsg"));
					setShowToast(true);
					return false;
				});

    	if (response) {
			props.onDismiss();
		}
	}

	const getAvatarUrl = async () => {
		let avatarUrl = await Preferences.get({'key': 'avatar_url'});
		setAvatarUrl(avatarUrl?.value);
	};

	useEffect(() => {
		getAvatarUrl();
	}, []);

    return isVisible ? (
            <>
                <StyledIonModal
                    isOpen={props.isVisible}
                    onDidDismiss={() => props.onDismiss()}
                    initialBreakpoint={0.4}
                    breakpoints={[0, 0.4, 0.99]}
                >
                <StyledActionSheetContainer className="t-p-50" onClick={(e) => e.stopPropagation()}>
					{
						avatarUrl ?
							<div className="avatar"><img src={avatarUrl} /></div> :
							<CircleImage className="image" image={ImageTypes.CAMERA}></CircleImage>
					}
                    <StyledButton onClick={(e) => { takeSystemPhoto(); e.stopPropagation(); }}>{t("profileTab.avatarActionSheet.takePhoto")}</StyledButton>
                    <StyledWiteButton onClick={(e) => { loadGalleryPhoto(); e.stopPropagation(); }}>{t("profileTab.avatarActionSheet.addFromGallery")}</StyledWiteButton>
                </StyledActionSheetContainer>
            </StyledIonModal>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
                </>
    ) : (<span></span>);
};

export default AvatarActionSheet;
