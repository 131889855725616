import styled from 'styled-components';

export const StyledContent = styled.div`

  width: 100%;
  height: 100%;

  .desc {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
`;