import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../components/button/button.style';
import Pane from '../../components/pane/pane.component';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import {Preferences} from "@capacitor/preferences";
import { StyledContainer } from "../mainPage/tabs/profile/panes/personalData.style";
import { StyledDataTable } from '../../components/content/content.style';
import { ifNull } from "../../utils/tools/numbers";
import StaticPane from "../../components/pane/static-pane.component";

interface IPersonalDataStorage {
	phone: any;
	pesel: any;
	number: any;
	isEmployed: any;
	coordinator: any;
	coordinatorPhone: any;
};

const TeamMemberPage: React.FC = () => {

    const history = useHistory();
    const { t } = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	const [storageData, setStorageData] = useState<IPersonalDataStorage>();

	const getPersonalData = async () => {
		let phone = await Preferences.get({'key': 'phone'});
		let pesel = await Preferences.get({'key': 'pesel'});
		let number = await Preferences.get({'key': 'employee_number'});
		let isEmployed = await Preferences.get({'key': 'is_employed'});
		let coordinator = await Preferences.get({'key': 'coordinator'});
		let coordinatorPhone = await Preferences.get({'key': 'coordinator_phone'});

		let personalDataStorage:IPersonalDataStorage = {
			phone: ifNull(phone?.value) || '',
			pesel: ifNull(pesel?.value) || '',
			number: ifNull(number?.value) || '',
			isEmployed: isEmployed?.value,
			coordinator: ifNull(coordinator?.value) || '',
			coordinatorPhone: ifNull(coordinatorPhone?.value) || ''
		};

		setStorageData(personalDataStorage);
	};

	useEffect(() => {
		getPersonalData();
		updateHeight();
	}, []);

    const updateHeight = () => {
        if (header.current ?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current ?.clientHeight);
        }
    };

	const header = useRef<HTMLIonHeaderElement>(null);

	return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("becameTeamMember.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40}>
					<StyledContainer>
						<StyledDataTable>
							<div className="data-row">
								<div className="data-title">{t("becameTeamMember.phone")}</div>
								<div className="data-value">{storageData?.phone ? storageData?.phone.indexOf(' ') >= 0 && storageData?.phone.indexOf('+') < 0 ? '+' + storageData?.phone.replace(/\s+/g, '') : storageData.phone : storageData?.phone}</div>
							</div>
							<div className="data-row">
								<div className="data-title">{t("becameTeamMember.pesel")}</div>
								<div className="data-value">{storageData?.pesel}</div>
							</div>
							<div className="data-row">
								<div className="data-title">{t("becameTeamMember.rcp")}</div>
								<div className="data-value">{storageData?.number}</div>
							</div>
						</StyledDataTable>
						<StyledDataTable>
							<div className="data-row">
								<div className="data-title">{t("becameTeamMember.coordinator")}</div>
								<div className="data-value">{storageData?.coordinator}</div>
							</div>
							<div className="data-row">
								<div className="data-title">{t("becameTeamMember.coordinator_phone")}</div>
								<div className="data-value">{storageData?.coordinatorPhone || '--'}</div>
							</div>
						</StyledDataTable>
					</StyledContainer>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default TeamMemberPage;
