import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import RidePreviewPane from "@app/travelAllowance/ride/panes/ridePreviewPane.component";

import ArrowLeftImage from "@assets/images/arrow-left.svg";

interface RouterProps {
    rideId: string;
}

interface RidePreviewPageProps extends RouteComponentProps<RouterProps> {
}

const RidePreviewPage: React.FC<RidePreviewPageProps> = ({match}: RidePreviewPageProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {params: {rideId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        log('page_visit', {
            page: 'Przejazdy -> Podgląd'
        });

        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("ridePreviewPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <RidePreviewPane topEdge={topEdge} rideId={parseInt(rideId)}/>
            </StyledIonContent>
        </IonPage>
    );
};

export default RidePreviewPage;