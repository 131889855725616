import styled from 'styled-components';

export const StyledContent = styled.div`

  width: 100%;
  height: 100%;
  padding-top: 40px;
`;

export const StyledField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 16px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;

    &.required:after {
      content: '*';
      color: red;
    }
  }
`;