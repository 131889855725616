import React, {useState} from 'react';
import {StyledAttachementContainer} from './attachement.style';
import ClockImage from '../../../../assets/images/time-clock.svg';
import MenuImage from '../../../../assets/images/menu-5.svg';
import {ContractAttachmentFolder} from "../../../../models/contractAttachmentFolder";
import {ContractAttachmentFolderFile} from "../../../../models/contractAttachmentFolderFile";
import {API_ENDPOINT} from "../../../../variables/variables";
import moment from "moment";
import {ContractAttachmentFolderStatus} from "../../../../enums/contractAttachmentFolderStatus";
import {removePhoto, renamePhoto} from "../../../../services/contract.service";
import AttachementsActionSheet from '../attachementActionSheet/attachementActionSheet.component';
import { setConfiguration } from '../../../../services/addEditValuePage.service';
import { useHistory } from 'react-router-dom';
import { Links } from '../../../links';
import { useTranslation } from 'react-i18next';
import {getExtension} from "../../../../services/file.service";
import {IonIcon} from "@ionic/react";
import {documentTextOutline} from "ionicons/icons";
import TruncateText from "../../../../components/truncateText/truncateText.component";

type AttachmentProps = {
    contractId: bigint;
    folder?: ContractAttachmentFolder;
    file?: ContractAttachmentFolderFile;
    onRemove: () => void;
    onUpdate: () => void;
}

const Attachment: React.FC<AttachmentProps> = (props: AttachmentProps) => {
    const [showActionSheet, setShowActionSheet] = useState(false);
    const history = useHistory();
    const {t} = useTranslation();

    const remove = async () => {
        setShowActionSheet(false);

        if (props.contractId && props.folder?.id && props.file?.id) {
            await removePhoto(props.contractId, props.folder?.id, props.file?.id).then(response => {
                props.onRemove();
            });
        }
    }

    const changeName = () => {
        setShowActionSheet(false);
        setConfiguration({
            title: t("contractPage.attachements.actionSheet.changeName"),
            defaultValue: props.file?.original_name,
            handleSave: async (value) => {
                if (props.contractId && props.folder?.id && props.file?.id) {
                    let extension = getExtension(props.file?.original_name);
                    await renamePhoto(props.contractId, props.folder?.id, props.file?.id, value, extension).then(response => {
                        props.onUpdate();
                        history.goBack();
                    });
                }
            },
            handleDismiss: () => history.goBack()
        });
        history.push(Links.addEditValue);
    }

    return (
        <StyledAttachementContainer>

            <span className="preview">
                {
                    props.file?.original_name && getExtension(props.file?.original_name) != 'pdf' &&
                    <img src={API_ENDPOINT + 'file/uploads/' + props.file?.path}/>
                }
                {
                    props.file?.original_name && getExtension(props.file?.original_name) == 'pdf' &&
                    <IonIcon src={documentTextOutline} style={{"height": "80px", "fontSize": "40px", "color": '#888A98'}} />
                }
            </span>

            <span className="name">
                <TruncateText width={120}>{props.file?.original_name}</TruncateText>
            </span>
            <span className="date">
                <img src={ClockImage}/> {moment(props.file?.created_at).format('DD.MM.YYYY')}
            </span>
            {
                (!props.folder?.expiration_date && !props.folder?.qualification_date) &&
                <span className="menu" onClick={() => setShowActionSheet(true)}>
                    <img src={MenuImage}/>
                </span>
            }
            
            <AttachementsActionSheet onRemove={remove} file={props.file} onChangeName={changeName} onDismiss={() => setShowActionSheet(false)} isVisible={showActionSheet}></AttachementsActionSheet>

        </StyledAttachementContainer>
    );
};

export default Attachment;