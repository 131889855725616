import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Modal from '../../components/modal/modal.component';
import {StyledFooter} from '../../components/pane/pane.style';
import Form from '../../components/form'
import {validateEmail} from "../../services/validation.service";
import {StyledTextarea} from "../../components/form/input/textarea.style";
import {IonToast, isPlatform, getPlatforms} from "@ionic/react";
import {appVersion, supportRequest} from "../../services/auth.service";
import {Device, DeviceInfo} from "@capacitor/device";
import {App, AppInfo} from "@capacitor/app";
import CloseImage from "../../assets/images/e-remove.svg";
import {StyledModalContent} from "../../components/modal/modal.style";
import {disableSwipeToClose} from "../../utils/tools/modals";

type SupportRequestModalProps = {
    isOpen: boolean;
    userEmail?: string|null|undefined;
    onClose: () => void;
};

export interface ISupportRequestModalFormData {
    email: string;
    content: string;
}

const SupportRequestModal: React.FC<SupportRequestModalProps> = (props: SupportRequestModalProps) => {

    const {t} = useTranslation();

    const [deviceInfo, updateDeviceInfo] = useState<DeviceInfo>();
    const [appInfo, updateAppInfo] = useState<AppInfo>();
    const [apiVersion, updateApiVersion] = useState<string>();

    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<string>('');

    const {register, handleSubmit, formState: {isValid}, errors} = useForm({
        mode: 'onChange'
    });

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        if (isPlatform('android') || isPlatform('ios')) {
            Device.getInfo().then((info) => updateDeviceInfo(info));
            App.getInfo().then((info) => updateAppInfo(info));
        }
    }, []);

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
        Device.getInfo().then((info) => updateDeviceInfo(info));
    }

    const getAppVersion = async () => {
        await appVersion().then(response => {
            updateApiVersion(response.data.version);
        });
    };

    useEffect(() => {
        getAppVersion();
    }, []);

    const onSubmit = async (data: ISupportRequestModalFormData) => {

        const response = await supportRequest(props.userEmail ? props.userEmail : data.email,
			data.content,
			deviceInfo?.model,
            (isPlatform('mobileweb') ? 'mobileweb,' : '') + (deviceInfo?.operatingSystem || getPlatforms().join(',')),
            appInfo?.version || apiVersion)
            .then(response => {
                setToast(t("supportRequest.successToast"));
                setShowToast(true);
                return true;
            })
            .catch(error => {
                setToast(t("common.serverErrorMsg"));
                setShowToast(true);
                return false;
            });

        if (response) {
            handleClose();
        }
    };

    let invalidEmailError = t("login.invalidEmail");

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleClose() } />
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message={toast}
                        duration={6000}
                    />
                    <h1 className="title">{t('supportRequest.title')}</h1>
                        <>
                            {!props.userEmail &&
                                <>
                                    <Form.Input name="email"
                                                ref={register({
                                                    required: true,
                                                    validate: value =>
                                                        validateEmail(value) || invalidEmailError
                                                })}
                                                placeholder={t("supportRequest.email")}
                                                className={errors.email && 'hasErrors'}
                                                type="email"
                                    />
                                    {errors.email && errors.email.message && <p className="errorMessage">{errors.email.message}</p>}
                                </>
                            }
                            <StyledTextarea name="content"
                                            ref={register({
                                                required: true
                                            })}
                                            placeholder={t("supportRequest.content")}
                                            className={'full-width ' + (errors.email ? 'hasErrors' : '')}  />
                            {errors.content && errors.content.message && <p className="errorMessage">{errors.content.message}</p>}
                        </>
                        <StyledFooter className="modal">
                            <Form.Button type="submit"
                                         disabled={!isValid}>{t('supportRequest.submit')}</Form.Button>
                        </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default SupportRequestModal;
