import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import RideListPane from "@app/travelAllowance/ride/panes/rideListPane.component";

import ArrowLeftImage from "@assets/images/arrow-left.svg";

const RideListPage: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        log('page_visit', {
            page: 'Przejazdy -> Lista'
        });

        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideListPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <RideListPane topEdge={topEdge}/>
            </StyledIonContent>
        </IonPage>
    );
};

export default RideListPage;