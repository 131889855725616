import styled from 'styled-components';

export const StyledOption = styled.div`

  background: #FFFFFF;
  border: 1px solid #EDEDF3;

  &.selected {
    background: rgba(0, 133, 255, 0.1);
    border: 1px solid #0085FF;
  }

  box-sizing: border-box;
  border-radius: 8px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #000000;
  margin-bottom: 8px;
`;