import styled, {css} from 'styled-components';

const SharedMenuStyle = css`
  .profile-avatar {
    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      min-height: 36px;
      border-radius: 28px;
    }
  }
`;

export const MenuContainer = styled.div`
  ${SharedMenuStyle}
`;