import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import { StyledModalContent } from '../../../components/modal/modal.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../components/pane/pane.style';
import Form from '../../../components/form';
import { IonContent } from '@ionic/react';
import {StyledInput} from '../../../components/form/input/input.style';
import { useForm } from 'react-hook-form';
import { StyledButton } from '../../../components/button/button.style';
import {StyledContent} from "./commentsModal.style";

type ComponentNumberModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (selected: string) => void;
};

const ManualModal: React.FC<ComponentNumberModalProps> = (props: ComponentNumberModalProps) => {

    const {t} = useTranslation();

    const { register, getValues, setValue, handleSubmit } = useForm({
        mode: 'all'
    });

    const [showComments, updateShowComments] = useState<boolean>(false);
    const fieldName = 'componentNumber';

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowComments(props.isOpen);
    };

    const handleSave = () => {
        props.onSubmit(getValues(fieldName))
    }

    return (
        <Modal
            isOpen={showComments}
            disableClose={true}
            initialBreakpoint={0.5}
            breakpoints={[0, 0.5]}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm"  onSubmit={handleSubmit(handleSave)}>
                <StyledModalContent className="t-p-25">
                    <StyledContent className="content">
                        <div className="label">{t('reportPage.manualModal.title')}</div>
                        <StyledInput name={fieldName} ref={register()}></StyledInput>
                        <StyledButton onClick={() => handleSave()}>{t('reportPage.manualModal.addButton')}</StyledButton>
                    </StyledContent>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default ManualModal;