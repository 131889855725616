import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import axios from "axios";

import Pane from "@components/pane/pane.component";
import Accordion from "@components/accordion/accordion";
import RideRouteListRow from "@app/travelAllowance/rideRoute/components/rideRouteListRow.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";
import {StyledEmptyContainer} from "@components/content/content.style";
import CircleImage, {ImageTypes} from "@components/circleImage/circleImage.component";

import {
    StyledTravelAllowanceContent,
    StyledButton,
} from '@app/travelAllowance/travelAllowance.style';

import {RideRoute} from "@models/travelAllowance/rideRoute";

import {getRideRoutes, removeRideRoute} from "@services/travelAllowance/rideRoute.service";

import {Links} from "@app/links";

import {ReactComponent as AddIcon} from "@assets/images/travelAllowance/add.svg";

type RideRouteListPaneProps = {
    topEdge?: number;
}

const RideRouteListPane: React.FC<RideRouteListPaneProps> = ({topEdge}: RideRouteListPaneProps) => {
    const cancelToken = axios.CancelToken.source();

    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [rideRoutes, setRideRoutes] = useState<RideRoute[]>([]);

    useEffect(() => {
        setLoading(true);

        fetchRideRoutes()
            .then(() => {
                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
            });

        return () => {
            cancelToken.cancel();
        };
    }, [history.location.pathname]);

    const fetchRideRoutes = async () => {
        const rideRoutesData = await getRideRoutes(cancelToken);

        setRideRoutes(rideRoutesData);
    }

    const handleRemove = async (rideRouteId: number) => {
        setLoading(true);

        await removeRideRoute(rideRouteId);
        await fetchRideRoutes();

        setLoading(false);
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                <StyledTravelAllowanceContent>
                    <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.rideRoute.yourRoutes')}>
                        <div className="travel-division">
                            <section className="ride-route-box">
                                {
                                    loading && <MyDataSkeleton></MyDataSkeleton>
                                }
                                {
                                    !loading && rideRoutes.length > 0 && rideRoutes.map((rideRoute) => {
                                        return <RideRouteListRow key={rideRoute.id}
                                                                 rideRoute={rideRoute}
                                                                 handleRemove={handleRemove}/>
                                    })
                                }
                                {
                                    !loading && rideRoutes.length === 0 &&
                                    <StyledEmptyContainer style={{marginTop: "20px"}}>
                                        <CircleImage image={ImageTypes.T} color="grey"/>
                                        <h3>{t('travelAllowance.rideRoute.empty')}</h3>
                                    </StyledEmptyContainer>
                                }
                            </section>
                        </div>
                    </Accordion>
                    <StyledButton onClick={() => history.push(Links.main + Links.travelAllowance.rideRoute.add)}>
                        <div className="btn center">
                            <AddIcon/>
                            <span style={{marginLeft: "4px"}}>{t('travelAllowance.rideRoute.add')}</span>
                        </div>
                    </StyledButton>
                </StyledTravelAllowanceContent>
            }
        </Pane>
    );
};

export default RideRouteListPane;