import React, { useEffect, useRef, useState } from 'react';
import { IonHeader, IonPage } from '@ionic/react';
import { StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import { useTranslation } from 'react-i18next';
import NotificationsPane from './panes/notificationsPane.component';

export enum NotificationsTabOptions {
    NOTIFICATIONS = 0,
    TIMETABLE = 1
}

const NotificationsTab: React.FC = () => {
    const { t } = useTranslation();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        updateHeight();
    },[]);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("notificationTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <NotificationsPane topEdge={topEdge}></NotificationsPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default NotificationsTab;
