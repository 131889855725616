import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {Contract as ContractModel} from "../../../models/contract";
import Regulation from "../components/regulations/regulation.component";
import {getContractFileBase64, getContractRegulationsPdf, getPayrollRegulationsPdf} from "../../../services/contract.service";
import {blobToBase64, changeBase46ToBlob, downloadFile} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
// import {FileOpener} from "@ionic-native/file-opener";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {ContractFile} from "../../../models/contractFile";
import {isPlatform} from "@ionic/react";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert} from "@ionic/react";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";
import PayrollRegulation from "../components/regulations/payrollRegulation.component";

type RegulationsPaneProps = {
    topEdge?: number;
    contract?: ContractModel;
}

const RegulationsPane: React.FC<RegulationsPaneProps> = (props: RegulationsPaneProps) => {
    const {t} = useTranslation();
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const openRegulation = async () => {
        if (props.contract?.id) {
            let fileData = await getContractRegulationsPdf(props.contract?.id)
                .then(response => {
                    return response.data;
                })

            let base64 = null;
            await blobToBase64(fileData).then(value => base64 = value);
            if (base64) {
                const info = await Device.getInfo();
                let deniedPermissions = false;
                if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                    let hasPermissions = await Filesystem.checkPermissions();
                    deniedPermissions = hasPermissions.publicStorage === 'denied';
                }

                if (deniedPermissions) {
                    setShowPermissionAlert(true);
                } else {
                    if (isPlatform('desktop')) {
                        downloadFile(fileData, 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - regulamin.pdf');
                    } else {
                        let base64 = null;
                        await blobToBase64(fileData).then(value => base64 = value);
                        if (base64) {
                            try {
                                const result = await Filesystem.writeFile({
                                    path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - regulamin.pdf',
                                    data: base64,
                                    directory: FilesystemDirectory.Documents,
                                    recursive: true
                                })

                                FileOpener.open({
                                    'filePath': result.uri,
                                    'contentType': 'application/pdf'
                                });
                            } catch (e) {
                            }
                        }
                    }
                }
            }
        }
    };

    const openFile = async (file: ContractFile) => {
        if (props.contract?.id && file.mime_type) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getContractFileBase64(props.contract?.id, file.id)
                    .then(response => {
                        return response.data.data;
                    })

                if (isPlatform('desktop')) {
                    let blob: Blob = await changeBase46ToBlob(fileData.data);
                    downloadFile(blob, 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + '/' + file.original_name);
                } else {
                    let base64 = null;
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + '/' + file.original_name,
                                data: fileData,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': file.mime_type
                            });
                        } catch (e) {
                        }
                    }
                }
            }
        }
    };

    const openPayrollRegulation = async () => {
        if (props.contract?.id) {
            let fileData = await getPayrollRegulationsPdf(props.contract?.id)
                .then(response => {
                    return response.data;
                })

            let base64 = null;
            await blobToBase64(fileData).then(value => base64 = value);
            if (base64) {
                const info = await Device.getInfo();
                let deniedPermissions = false;
                if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                    let hasPermissions = await Filesystem.checkPermissions();
                    deniedPermissions = hasPermissions.publicStorage === 'denied';
                }

                if (deniedPermissions) {
                    setShowPermissionAlert(true);
                } else {
                    try {
                        const result = await Filesystem.writeFile({
                            path: 'ExactPeople/' + props.contract?.number.replace(/\//g, '_') + ' - regulamin wynagrodzen.pdf',
                            data: base64,
                            directory: FilesystemDirectory.Documents,
                            recursive: true
                        })

                        FileOpener.open({
                            'filePath': result.uri,
                            'contentType': 'application/pdf'
                        });
                    } catch (e) {}
                }
            }
        }
    };

    return (
        <>
            {
                (props.contract?.has_regulations || (props.contract?.worker_status_files && props.contract?.worker_status_files.length > 0) || props.contract?.aas_ap) &&
                <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
                    { props.contract?.has_regulations && !props.contract?.aas_ap &&
                        <Regulation contract={props.contract}
                            onClick={() => openRegulation()} />
                    }
                    {
                        props.contract.worker_status_files.map((file: ContractFile, key) => {
                            if (file.mime_type) {
                                return <Regulation contract={props.contract}
                                                   file={file}
                                                   onClick={() => openFile(file)} />
                            }
                        })
                    }
                    {/*{ props.contract?.aas_ap &&*/}
                    {/*    <PayrollRegulation contract={props.contract}*/}
                    {/*                onClick={() => openPayrollRegulation()} />*/}
                    {/*}*/}
                </Pane>
            }
            {
                !props.contract?.has_regulations && props.contract?.worker_status_files.length == 0 &&
                <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.ATTACHEMENT} color="grey"/>
                        <h2>{t("contractPage.attachements.empty")}</h2>
                    </StyledEmptyContainer>
                </StaticPane>
            }
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </>
    );
};

export default RegulationsPane;
