import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonAlert,
    IonButtons,
    IonHeader,
    IonPage,
    isPlatform,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {
    StyledEmptyContainer,
    StyledIonContentCameraPreview,
    StyledIonTitle,
    StyledIonToolbar
} from '../../components/content/content.style';
import {
    StyledButton,
    StyledHeaderButton,
    StyledHeaderButtonImage,
    StyledSocialButton
} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import WarningImage from '../../assets/images/t-warning.svg';
import CameraPreviewImage from '../../assets/images/takePhoto-small.png';
import {useTranslation} from 'react-i18next';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner"
import {StyledContent} from '../takePhotoPage/takePhotoPage.style';
import {getAuditReportWorkerConfiguration} from '../../services/qrCodePage.service';
import CircleImage, {ImageTypes} from "../../components/circleImage/circleImage.component";
import PaginatedListModal from "./modals/paginatedListModal.component";
import {AuditReportFormModel} from "../../models/auditReport";
import {loadWorkers} from "./helpers";

const AuditFindWorker: React.FC = () => {

    const {t} = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    const configuration = getAuditReportWorkerConfiguration();

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [showError, setShowError] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [showListModal, updateShowListModal] = useState<boolean>(false);

    useEffect(() => {
        updateHeight();
    })

    const startScan = async () => {
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

        if (result.hasContent) {
            if (configuration?.handleScan) {
                let error = await configuration?.handleScan(result.content);
                if (error === false) {
                    stopScan()
                    setShowError(true);
                }
            }
        }
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    useIonViewWillEnter( () => {
        if (isPlatform('ios') || isPlatform('android')) {
            start();
        }
    });

    const start = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
        setShowError(false);
    }

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    useIonViewWillLeave(() => {
        if (isPlatform('ios') || isPlatform('android')) {
            stopScan();
        }
    });

    if (!configuration) {
        history.goBack();
    }

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContentCameraPreview>
                <img className="background" src={CameraPreviewImage}/>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t(configuration?.title ?? '')}</StyledIonTitle>
                    </StyledIonToolbar>
                    <div className="sub-title-light">{t(configuration?.subtitle ?? '')}</div>
                </IonHeader>
                <StyledContent>
                    <div className="target">
                        {
                            showError &&
                            <StyledEmptyContainer className="preview">
                                <CircleImage onClick={async (e) => {
                                    start();
                                }} image={ImageTypes.QR} color="red" />
                            </StyledEmptyContainer>
                        }
                    </div>
                    <div className={'action-buttons empty'}>
                        {showError && <StyledSocialButton className="red"><img src={WarningImage} />{t(configuration?.errorText || '')}</StyledSocialButton>}
                        {configuration?.manualButtonText &&
                            <StyledButton onClick={() => updateShowListModal(true)}>{t(configuration?.manualButtonText)}</StyledButton>}
                    </div>
                </StyledContent>
            </StyledIonContentCameraPreview>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />

            {showListModal && configuration && <PaginatedListModal search={true} report={configuration.report} isOpen={showListModal} onFetch={(report:AuditReportFormModel, page:number, searchText?: string) => {
                let data = loadWorkers(report, page, searchText);

                return data;
            }} onSubmit={(selected) => {
                if (configuration?.handleScan) {
                    configuration?.handleListSelected(selected);
                }

                updateShowListModal(false);
            }} onClose={() => {
                updateShowListModal(false);
            }} />
            }
        </IonPage>
    );
};

export default AuditFindWorker;
