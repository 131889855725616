import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "../../../components/modal/modal.component";
import {StyledModalContent} from "../../../components/modal/modal.style";
import TaskItem, {Task, TaskStatus} from "../components/taskItem/taskItem.component";
import {SimplifiedCategory} from "../components/calendar/calendar.component";
import {StyledDataTable} from "../../../components/content/content.style";
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import moment from "moment";
import PinImage from "../../../assets/images/pin-grey.svg";
import {StyledCategory} from "../../mainPage/tabs/notifications/notificationsTab.style";
import PreviewTaskItemModal from "./previewTaskItemModal.component";
import {IonAlert} from "@ionic/react";
import StaticPane from "../../../components/pane/static-pane.component";
import {approveTask, rejectTask} from "../../../services/timetable.service";
import {removeAbsence} from "../../../services/absence.service";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type PreviewTaskItemsModalProps = {
    isOpen: boolean;
	currentDate: Date;
	selectedDay: number;
	categories: SimplifiedCategory[];
    onClose: () => void;
    refresh: () => void;
};

const PreviewTaskItemsModal: React.FC<PreviewTaskItemsModalProps> = (props: PreviewTaskItemsModalProps) => {

    const {t} = useTranslation();

    const [showRemarksList, updateShowRemarksList] = useState<boolean>(false);
	const [showApproveAlert, setShowApproveAlert] = useState(false);
	const [showDismissAlert, setShowDismissAlert] = useState(false);
	const [showPreviewTaskItemModal, updatePreviewTaskItemModal] = useState<boolean>(false);
	const [showRemoveAlert, setShowRemoveAlert] = useState(false);
	const [currentTask, updateCurrentTask] = useState<Task | undefined>();

	const handleClose = () => {
        props.onClose();
    };

	const refresh = () => {
		props.refresh();
	}

    useEffect(() => {
    	handleOpen();
    });

    const handleOpen = () => {
        updateShowRemarksList(props.isOpen);
    };

	const approve = async (task: Task) => {
		//setLoading(true);

		const response = await approveTask(task.id as number)
			.then(response => {
				refresh();
				updatePreviewTaskItemModal(false);
				handleClose();
			})
			.catch(error => {
				// todo what we show??
			});
		//setLoading(false);
	};

	const dismiss = async (task: Task) => {
		//setLoading(true);

		const response = await rejectTask(task.id as number)
			.then(response => {
				refresh();
				updatePreviewTaskItemModal(false);
				handleClose();
			})
			.catch(error => {
				// todo what we show??
			});
		//setLoading(false);
	};

	const onApprove = (task: Task) => {
		if (task) {
			updateCurrentTask(task);
			setShowApproveAlert(true);
		}
	};

	const onDismiss = (task?: Task) => {
		if (task) {
			updateCurrentTask(task);
			setShowDismissAlert(true);
		}
	}

	const handleTaskItemClick = (task: Task) => {
		if (task.type == 'PROPOSAL') {
			updateCurrentTask(task);
			updatePreviewTaskItemModal(true);
		}
	}

	const onApproveAlertDismiss = () => {
		setShowApproveAlert(false);

		if (!showPreviewTaskItemModal) {
			updateCurrentTask(undefined);
		}
	}

	const onDismissAlertDismiss = () => {
		setShowDismissAlert(false);

		if (!showPreviewTaskItemModal) {
			updateCurrentTask(undefined);
		}
	}

	const onRemoveAlertDismiss = () => {
		setShowRemoveAlert(false);
	}

	const onRemove = (task: Task) => {
		updateCurrentTask(task);
		setShowRemoveAlert(true);
	}

	const remove = async(task: Task) => {
		if (task.id) {
			const response = await removeAbsence(task.id)
				.then(response => {
					refresh();
					updatePreviewTaskItemModal(false);
					handleClose();
				});
		}

		return false;
	}

    return (
        <Modal
            isOpen={showRemarksList}
			canDismiss={disableSwipeToClose}
			maxHeight={'75%'}
            onDidDismiss={() => handleClose()}>

			<IonAlert
				isOpen={showApproveAlert}
				onDidDismiss={() => onApproveAlertDismiss()}
				header={t('notificationTab.timetablePane.alert.approve.title')}
				message={t('notificationTab.timetablePane.alert.approve.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowApproveAlert(false);

							if (!showPreviewTaskItemModal) {
								updateCurrentTask(undefined);
							}
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								approve(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>
			<IonAlert
				isOpen={showDismissAlert}
				onDidDismiss={() => onDismissAlertDismiss()}
				header={t('notificationTab.timetablePane.alert.dismiss.title')}
				message={currentTask && currentTask.status == TaskStatus.ACCEPTED ? t('notificationTab.timetablePane.alert.dismissAccepted.message') : t('notificationTab.timetablePane.alert.dismiss.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowDismissAlert(false);

							if (!showPreviewTaskItemModal) {
								updateCurrentTask(undefined);
							}
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								dismiss(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>
			<IonAlert
				isOpen={showRemoveAlert}
				onDidDismiss={() => onRemoveAlertDismiss()}
				header={t('profileTab.absence.alert.remove.title')}
				message={t('profileTab.absence.alert.remove.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowRemoveAlert(false);
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								remove(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>

			{
				props.categories && <StyledModalContent>
					<h6 className="description">{t('notificationTab.timetablePane.preview.tasksForDay', {day: moment(props.currentDate).format('DD.MM.YYYY')})}</h6>

					<div className="content full-height">
						<StyledDataTable>
						{
							props.categories.map((category, categoryKey) =>
								category.startDate.getDate() == props.selectedDay &&
									<StyledCategory key={categoryKey}>
										<span className="category-name">{ category.title }</span>
										{
											category.place && <span className="category-place"><img src={PinImage}/>{ category.place }</span>
										}

										{
											category.tasks.map((task, key) =>
												<div key={task.id} onClick={(e) => handleTaskItemClick(task)}>
													<TaskItem key={key} task={task} onApprove={() => onApprove(task)} onDismiss={() => onDismiss(task)} onRemove={(task) => onRemove(task)} />
												</div>
											)
										}
									</StyledCategory>
							)
						}
						</StyledDataTable>
					</div>
				</StyledModalContent>
			}

			<PreviewTaskItemModal
				isOpen={showPreviewTaskItemModal}
				onClose={() => updatePreviewTaskItemModal(false)}
				onApprove={onApprove}
				onDismiss={onDismiss}
				taskItem={currentTask}>
			</PreviewTaskItemModal>
        </Modal>
    );
};

export default PreviewTaskItemsModal;
