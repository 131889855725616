import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import AvailabilityPane from "./panes/availabilityPane.component";

const AvailabilityPage: React.FC= () => {
	const { t } = useTranslation();
	const header = useRef<HTMLIonHeaderElement>(null);
	const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

	useEffect(() => {
		updateHeight();
	},[]);

	const updateHeight = () => {
		if (header.current ?.clientHeight == 0) {
			setTimeout(updateHeight);
		} else {
			updateTopEdge(header.current?.clientHeight);
		}
	};

	return (
		<IonPage>
			<StyledIonContent>
				<IonHeader ref={header} className="ion-no-border">
					<StyledIonToolbar>
						<StyledIonTitle className="left">{t("profileTab.optionAvailability")}</StyledIonTitle>
					</StyledIonToolbar>
				</IonHeader>
				<AvailabilityPane topEdge={topEdge} />
			</StyledIonContent>
		</IonPage>
	);
};

export default AvailabilityPage;
