import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    IonAlert,
    IonBadge,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonSkeletonText,
    useIonViewWillEnter
} from "@ionic/react";
import Pane from "../../../components/pane/pane.component";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import {log} from "../../../services/firebaseAnalytics.service";
import {WorkerWarehouseTransfer, WorkerWarehouseTransferConfirmationStatus} from "../../../models/worker";
import {getWorkerWarehouse, getWorkerWarehouseConfirmation} from "../../../services/worker.service";
import {StyledWarehouseItem} from "../workerWarehousePage.style";
import moment, {ISO_8601} from "moment/moment";
import {StyledButton, StyledLightRedButton} from "../../../components/button/button.style";
import ConfirmationModal from "../modals/confirmationModal.component";
import Info from "../../../components/info/info.component";

type PaneProps = {
    topEdge?: number;
}

const WorkerWarehousePane: React.FC<PaneProps> = (props: PaneProps) => {
    const {t} = useTranslation();

    const [warehouse, setWarehouse] = useState<WorkerWarehouseTransfer[] | undefined>(undefined);
    const [selectedTransfer, setSelectedTransfer] = useState<WorkerWarehouseTransfer | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);

    const fetchWarehouse = async (force: boolean = false) => {
        if (warehouse === undefined || force) {
            setLoading(true);
            setWarehouse([]);
            const response = await getWorkerWarehouse()
                .then(response => {
                    setWarehouse(response.data);
                });

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWarehouse();
    }, [])

    useIonViewWillEnter(() => {
        fetchWarehouse();
    });

	useEffect(() => {
		log('page_visit', {
			page: 'Wyposażenie BHP'
		});
	}, []);

    const confirm = async (transfer: WorkerWarehouseTransfer) => {
        await getWorkerWarehouseConfirmation(transfer, WorkerWarehouseTransferConfirmationStatus.CONFIRMED).then((response) => {
            fetchWarehouse(true);
        });
    }
    const reject = async (transfer: WorkerWarehouseTransfer, comment: string) => {
        await getWorkerWarehouseConfirmation(transfer, WorkerWarehouseTransferConfirmationStatus.REJECTED, comment).then((response) => {
            fetchWarehouse(true);
        });
    }

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            <Info>{t("workerWarehousePage.top_info")}</Info>
            {
                !loading && warehouse && warehouse.map((transfer: WorkerWarehouseTransfer, i) =>
                    <StyledWarehouseItem key={i}>
                        <div className="content">
                            {transfer.confirmation_status &&
                                <div className="status">
                                    {transfer.confirmation_status === WorkerWarehouseTransferConfirmationStatus.PENDING &&
                                        <IonBadge color="warning">{t('workerWarehousePage.confirmation_status.pending')}</IonBadge>
                                    }

                                    {transfer.confirmation_status === WorkerWarehouseTransferConfirmationStatus.CONFIRMED &&
                                        <IonBadge color="success">{t('workerWarehousePage.confirmation_status.confirmed')}</IonBadge>
                                    }

                                    {transfer.confirmation_status === WorkerWarehouseTransferConfirmationStatus.CONFIRMED_AUTOMATICALLY &&
                                        <IonBadge color="success">{t('workerWarehousePage.confirmation_status.confirmed_automatically')}</IonBadge>
                                    }

                                    {transfer.confirmation_status === WorkerWarehouseTransferConfirmationStatus.REJECTED &&
                                        <IonBadge color="danger">{t('workerWarehousePage.confirmation_status.rejected')}</IonBadge>
                                    }
                                </div>
                            }

                            <div className="title">
                                {transfer.warehouse_item.name}
                            </div>
                            <IonRow>
                                <IonCol size="6" className="description">
                                    { moment(transfer.created_at, ISO_8601).format('DD.MM.YYYY HH:mm:ss') }
                                </IonCol>
                                <IonCol size="6" className="description quantity">
                                    { t('workerWarehousePage.item.quantity') + ': ' + transfer.quantity }
                                </IonCol>
                            </IonRow>

                            {transfer.can_confirm && transfer.confirmation_status && transfer.confirmation_status === WorkerWarehouseTransferConfirmationStatus.PENDING &&
                                <IonRow>
                                    <IonCol size="6">
                                        <StyledButton onClick={() => {
                                            setSelectedTransfer(transfer);
                                            setShowConfirmationAlert(true);
                                        }}>
                                            {t('workerWarehousePage.confirm')}
                                        </StyledButton>
                                    </IonCol>
                                    <IonCol size="6">
                                        <StyledLightRedButton onClick={() => {
                                            setSelectedTransfer(transfer);
                                            setShowConfirmationModal(true);
                                        }}>
                                            {t('workerWarehousePage.reject')}
                                        </StyledLightRedButton>
                                    </IonCol>
                                </IonRow>
                            }
                        </div>
                    </StyledWarehouseItem>
                )
            }

            {
                !loading && warehouse && warehouse.length === 0 &&
                <StyledEmptyContainer>
                    <CircleImage image={ImageTypes.NOTE} color="grey"/>
                    <h3>{t('workerWarehousePage.empty')}</h3>
                </StyledEmptyContainer>
            }

            {
                loading &&
                <IonList>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonList>
            }

            {selectedTransfer && <ConfirmationModal isOpen={showConfirmationModal} onClose={() => {
                setSelectedTransfer(undefined);
                setShowConfirmationModal(false);
            }} onSuccess={async (comment) => {
                await reject(selectedTransfer, comment)
                setSelectedTransfer(undefined);
                setShowConfirmationModal(false);
            }} transfer={selectedTransfer} /> }

            {selectedTransfer && <IonAlert
                isOpen={showConfirmationAlert}
                onDidDismiss={() => setShowConfirmationAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowConfirmationAlert(false)
                            setSelectedTransfer(undefined);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            await confirm(selectedTransfer);
                            setShowConfirmationAlert(false)
                            setSelectedTransfer(undefined);
                        }
                    }
                ]}
            /> }
        </Pane>
    );
};

export default WorkerWarehousePane;
