import {appApi} from "./api.service";

let endpointPrefix = 'worker/payout-billings';

const getBillings = async () => {
    return appApi.get(endpointPrefix);
}

const getBillingPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download', {
        responseType: "blob"
    });
}

export {
    getBillings,
    getBillingPdf
};
