import styled from 'styled-components';

export const StyledPinRow = styled.div`
  width:  100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 0 24px;
`;

export const StyledRow = styled.div`
  width:  100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 43px 0 24px;
`;