import React, {useContext, useEffect, useState} from "react";
import Modal from "../../components/modal/modal.component";
import Form from "../../components/form";
import {StyledModalContent} from "../../components/modal/modal.style";
import {StyledFooter} from "../../components/pane/pane.style";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ExactPhoneInput from "../../components/form/input/phoneInput.component";
import {updateInlineFieldValue} from "../../services/profile.service";
import {logout, useAuth} from "../../services/auth.service";
import {Links} from "../../app/links";
import { useHistory } from 'react-router-dom';
import {StyledWiteButton} from "../../components/button/button.style";
import LogoffImage from "../../assets/images/log-out.svg";
import { updateStorage } from "../../utils/tools/storage";
import {disableSwipeToClose} from "../../utils/tools/modals";

type TeamMemberPhoneModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (phone: string) => void;
};

const TeamMemberPhoneModal: React.FC<TeamMemberPhoneModalProps> = (props: TeamMemberPhoneModalProps) => {

    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();

    const [show, updateShow] = useState<boolean>(false);
    const [phoneNumber, updatePhoneNumber] = useState<string>('48 ');
    const [missingPhone, setMissingPhone] = useState(!!phoneNumber);


    const {handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const { isValid } = formState;

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    const onSubmit = async () => {
        await updateInlineFieldValue('phone', phoneNumber)
        props.onSubmit(phoneNumber);
        props.onClose();
        const phoneCodeParts = (phoneNumber || '').split(' ');
        if (phoneCodeParts.length > 1 ){
            updateStorage('phone_code', phoneCodeParts[0]);
            updateStorage('phone', phoneCodeParts[1]);
        }

    };

    const logoutClick = async () => {
        await logout().then(() => {
            auth.signOut();
            history.replace(Links.login)
            window.top.location.reload();
        });
    }

    return (
        <Modal
            isOpen={show}
            canDismiss={disableSwipeToClose}
            disableClose={true}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <h1 className="title">{t("teamMemberPhoneModal.title")}</h1>
                    <div className="content">
                        <p>{t("teamMemberPhoneModal.subtitle")}</p>
                        <ExactPhoneInput
                            phoneNumer={phoneNumber}
                            phoneNumerChanged={(phoneNumber) => {
                                updatePhoneNumber(phoneNumber);
                            }}
                            errorChanged={setMissingPhone}
                        ></ExactPhoneInput>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <Form.Button type="submit" disabled={!isValid || missingPhone}>{t("teamMemberPhoneModal.submit")}</Form.Button>
                        <StyledWiteButton onClick={e => logoutClick()}>{t("settingsTab.logoff")} <img src={LogoffImage}/></StyledWiteButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    )
}

export default TeamMemberPhoneModal;