import React, {useContext, useEffect, useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {IonButtons, IonHeader, IonPage, IonAlert} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage,} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import {getContractFolderFiles, getContractFolders, removeContractFolder} from '../../services/contract.service';
import AttachementsPane from './panes/attachementsPane.component';
import {ContractStatus} from '../../enums/contractStatus';
import {ContractAttachmentFolder} from "../../models/contractAttachmentFolder";
import {ContractAttachmentFolderFile} from "../../models/contractAttachmentFolderFile";
import {useTranslation} from "react-i18next";
import {FoldersContext} from "../contractPage/panes/attachementsFolderPane.component";
import ClockImage from "../../assets/images/time-clock.svg";
import moment from "moment";
import RefreshGreyImage from "../../assets/images/refresh-01.svg";

interface RouterProps {
    contractId?: string | undefined;
    folderId?: string | undefined;
}

interface FolderProps extends RouteComponentProps<RouterProps> {
}

const FolderPage: React.FC<FolderProps> = ({match}) => {
        const history = useHistory();
        const context = useContext(FoldersContext);
        const [status, setStatus] = useState<ContractStatus>();
        const [shownFolder, updateShownFolder] = useState<ContractAttachmentFolder | undefined>();
        const [files, updateFiles] = useState<ContractAttachmentFolderFile[]>([]);
        const [showAlert, setShowAlert] = useState(false);
        const [changed, setChanged] = useState(false);

        const {params: {contractId, folderId}} = match;

        const fetchFolder = async () => {
            if (contractId && folderId) {
                await getContractFolderFiles(contractId, folderId)
                    .then(response => {
                        updateShownFolder(response.data.folder);
                        updateFiles(response.data.folder.files);
                    })
                    .catch(error => {
                        // todo what we show??
                    });
            }
        };

        useEffect(() => {
            fetchFolder();
        }, []);

        const backWithContextReplace = async (withoutBack: boolean = false) => {
            if (contractId) {
                const res = await getContractFolders(contractId).then(response => {
                    return response.data;
                });

                context.setFolders(res);
                if (!withoutBack) {
                    history.goBack();
                }
            }
        }

        const remove = async () => {
            if (contractId && folderId && files.length === 0) {
                setShowAlert(false);
                await removeContractFolder(contractId, folderId);

                backWithContextReplace();
            }
        }

        const handleBack = async () => {
            if (contractId && folderId && files.length === 0) {
                setShowAlert(true);
            } else {
                if (changed) {
                    backWithContextReplace();
                } else {
                    history.goBack();
                }
            }
        };

        document.addEventListener('ionBackButton', (ev: Event) => {
            if (changed) {
                backWithContextReplace(true);
            }
        });

        let [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

        useEffect(() => {
            updateHeight();
        });

        const updateHeight = () => {
            if (header.current?.clientHeight == 0) {
                setTimeout(updateHeight);
            } else {
                updateTopEdge(header.current?.clientHeight);
            }
        };

        const header = useRef<HTMLIonHeaderElement>(null);

        const {t} = useTranslation();

        return (
            <IonPage>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t('folderPage.alert.emptyRemoveTitle')}
                    message={t('folderPage.alert.emptyRemoveMessage')}
                    buttons={[
                        {
                            text: t('common.alertCancel'),
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowAlert(false)
                            }
                        },
                        {
                            text: t('common.alertConfirm'),
                            handler: () => {
                                remove();
                            }
                        }
                    ]}
                />

                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <IonButtons slot="start">
                                <StyledHeaderButton onClick={() => handleBack()}>
                                    <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>{shownFolder?.name}</StyledIonTitle>
                        </StyledIonToolbar>
                        { shownFolder &&
                            <div className="sub-title">
                                <small>
                                    {shownFolder?.expiration_date && shownFolder?.qualification_date && <img src={ClockImage}/>}
                                    {(!shownFolder?.expiration_date || !shownFolder?.qualification_date) && <img src={RefreshGreyImage}/>}
                                    {shownFolder?.qualification_date &&
                                    <span>{t('contractPage.attachements.documentValidTill')} <span
                                        className="validity">{moment(shownFolder?.qualification_date).format('DD.MM.YYYY')}</span></span>}
                                    {!shownFolder?.expiration_date && !shownFolder?.qualification_date && files && files.length > 0 &&
                                    <span>{t('contractPage.attachements.documentInVerification')}</span>}
                                    {!shownFolder?.expiration_date && !shownFolder?.qualification_date && files && files.length === 0 &&
                                    <span>{t('contractPage.attachements.documentEmpty')}</span>}
                                    {shownFolder?.expiration_date && !shownFolder?.qualification_date &&
                                    <span>{t('contractPage.attachements.documentVerified')}</span>}
                                </small>
                            </div>
                        }
                    </IonHeader>
                    {contractId && <AttachementsPane topEdge={topEdge} contractId={BigInt(contractId)} status={status} folder={shownFolder} onFilesUpdate={paneFiles => {
                        updateFiles(paneFiles);
                        setChanged(true);
                    }}/>}
                </StyledIonContent>
            </IonPage>
        );
    }
;

export default FolderPage;
