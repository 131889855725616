import styled from 'styled-components';

export const StyledInconsitencyModuleListContent = styled.div`
  
  .details-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    &__month {
      color: #000000;
      font-size: 18px;
      font-weight: 500;
    }

    &__hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #DEE0ED;
      margin: 1em 0;
      padding: 0;
      margin-top: 8px;
      width: 100%;
    }
  }

  .inconcistency-detail {
    background-color: #FFDEDE;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    padding: 11px 0 13px 12px;

    div {
      margin: 4px 0;
    }

    &__fs12 {
      font-size: 12px;
    }

    &__fs14 {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export const StyledInconsitencyItemDetailsContent = styled.div`
  .details-header {
    background-color: #FFDEDE;
    border-radius: 20px 20px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: absolute;
    top: 0;
    left: 0;

    &__fs12 {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__fs14 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .details-content {
    margin-top: 100px;
  }

  .textarea {
    all: unset;
    width: 100%;
    background-color: #F4F4F9;
    border-radius: 4px;
    border: none;
    padding: 8px 0;
    height: 84px;
  }

  .next-action {
    all: unset;
    margin-top: 16px;
    width: 100%;
    color: #0085FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #DBEEFF;
    --background: #DBEEFF;
    font-size: 12px;
    padding: 14px 0;
  }
  
  .save {
    all: unset;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #0085FF;
    --background: #0085FF;
    font-size: 12px;
    padding: 14px 0;
  }

  .jqBDDq {
    --background: #d9d9d9;
    border-radius: 8px;
  }
`;

export const StyledInconsitencySummaryContent = styled.div`

  .details-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;

    &__month {
      color: #000000;
      font-size: 18px;
      font-weight: 700;
    }

    &__hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #DEE0ED;
      margin: 1em 0;
      padding: 0;
      margin-top: 8px;
      width: 100%;
    }
  }

  .summary-label {
    font-size: 12px;
    margin-bottom: 6px;
  }

  .summary-description {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .summary-ready {
    all: unset;
    background-color: #00C918;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    padding: 14px 0;
  }

  .hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #DEE0ED;
    margin: 1em 0;
    padding: 0;
    margin: 16px 0;
    width: 100%;
  }

  .summary-close {
    all: unset;
    background-color: #0085FF;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    padding: 14px 0;
  }
`;

