import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .name {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    padding-top: 33px;
  }

  .number {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    padding-top: 6px;
  }
`;