import { CreateAnimation, createAnimation, IonCol, IonGrid, IonRow } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {StyledActionIonModal, StyledActionSheet, StyledIonModal} from '../../../../components/modal/modal.style';
import { StyledWalletGrid } from '../../panes/wallet.style';
import {EmployerCut, EmployerWallet, MonthWallet, WorkingHour} from '../../panes/walletPane.component';
import CloseImage from '../../../../assets/images/e-remove.svg';
import { StyledWalletCutsActionSheetContainer } from './walletCutsActionSheet.style';
import { StyledPaneBar, StylePaneGrabber } from '../../../../components/pane/pane.style';
import { useSwipeable } from 'react-swipeable';
import ModalService from '../../../../services/modal.service';

type WalletCutsActionSheetProps = {
    isVisible: boolean;
    wallet?: MonthWallet;
    onDismiss: () => void;
}

const WalletCutsActionSheet: React.FC<WalletCutsActionSheetProps> = (props: WalletCutsActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const [hiding, startHiding] = useState(false);
    const [showDetails, updateShowDetails] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (isVisible !== props.isVisible) {
            if (props.isVisible) {
                ModalService.modalOpened(props.onDismiss);
                startHiding(false);
                changeVisibility(props.isVisible);
            } else {
                ModalService.modalDismissed();
                startHiding(true);
                setTimeout(() => changeVisibility(props.isVisible), 275);
            }
        }
    }, [isVisible, props.isVisible]);

    const config = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
      };

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            props.onDismiss();
        },
        ...config,
    });

    const getMonthCutsTotal = (monthWallet: MonthWallet): number => {
        const sum = monthWallet.cuts.reduce((prev: number, curr: EmployerCut) => {
            return prev + (curr.value * 1);
        }, 0);

        return sum;
    };

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.65, 0.99]}
        >
                <StyledWalletCutsActionSheetContainer className={hiding ? 'hide': 't-p-50'} onClick={(e) => e.stopPropagation()}>
                    <img src={CloseImage} className="close" onClick={(e) =>{ props.onDismiss(); e.stopPropagation()} } />
                    <StyledWalletGrid>
                        <div className="title">{t("reportsTab.wallet.detailsView")}</div>
                        <IonGrid>
                            <IonRow>
                                <IonCol className="aligment header" size="5.5">
                                {t("reportsTab.wallet.workingPlace")}
                                </IonCol>
                                <IonCol className="aligment end  header" size="3">
                                {t("reportsTab.wallet.comment")}
                                </IonCol>
                                <IonCol className="aligment end header" size="3.5">
                                {t("reportsTab.wallet.amount")}
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="content">
                            { props.wallet && props.wallet.cuts && props.wallet.cuts.length > 0 &&
                                props.wallet.cuts.map((cut, key) =>
                                <IonRow key={key}>
                                    <IonCol className="aligment row" size="5.5">
                                        {cut.name}
                                    </IonCol>
                                    <IonCol className="aligment end row value long-text" size="3">
                                        {cut.comment}
                                    </IonCol>
                                    <IonCol className="aligment end row value" size="3.5">
                                        {cut.value}
                                    </IonCol>
                                </IonRow>
                                )
                            }

							{ props.wallet && props.wallet.cuts.length == 0 &&
								<IonRow>
									<IonCol className="aligment row" size="12">
										{t("reportsTab.wallet.noCuts")}
									</IonCol>
								</IonRow>
							}

						</IonGrid>

						<IonGrid>

							{ props.wallet && props.wallet.cuts.length > 0 &&
								<IonRow className="footer">
									<IonCol className="aligment footer" size="5.5">
									{t("reportsTab.wallet.cutsSum")}
									</IonCol>
									<IonCol className="aligment end footer" size="3">

									</IonCol>
									<IonCol className="aligment end footer" size="3.5">
                                        {(Math.floor(getMonthCutsTotal(props.wallet) * 100)/100).toFixed(2)}
									</IonCol>
								</IonRow>
							}
						</IonGrid>
                    </StyledWalletGrid>
                </StyledWalletCutsActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default WalletCutsActionSheet;
