import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector';
import {API_ENDPOINT} from "./variables/variables";
import {Device} from "@capacitor/device";

import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend'; // have a own http fallback

const resourcesToBackend = require('i18next-resources-to-backend')

// const getLang = async () => {
//     let lang = await Device.getLanguageCode();
//     return lang.value.toString().slice(0, 2);
// };
//
// const deviceLangDetector: CustomDetector = {
//     name: 'DeviceLangDetector',
//
//     lookup(options: DetectorOptions) {
//         let lang = getLang();
//
//         // await lang.then((v) => {
//         //     return v;
//         // })
//
//         return 'en';
//     },
// };

const languageDetector = new LanguageDetector();
// languageDetector.addDetector(deviceLangDetector);

i18n
    // Enables the i18next backend
    .use(Backend)
    // Enable automatic language detection
    .use(languageDetector)
    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        // Standard language used
        supportedLngs: ['en', 'pl', 'de', 'sk', 'tr', 'ru', 'cs', 'nl', 'hu', 'ro', 'zh', 'pt', 'es', 'uk', 'fr'],
        fallbackLng: 'en',
        debug: false,
        //Detects and caches a cookie from the language provided
        detection: {
            order: ['localStorage', 'queryString', 'cookie', 'DeviceLangDetector', 'navigator'],
            lookupLocalStorage: '_cap_i18nextLng',
            cache: ['cookie', 'localStorage', 'queryString']
        },
        interpolation: {
            escapeValue: false
        },
        backend: {
            backends: [
                HttpApi, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
                resourcesToBackend((language:string, namespace:string, callback:any) => {
                    import(`../public/locales/${language}/${namespace}.json`)
                        .then(({ default: resources }) => {
                            callback(null, resources)
                        })
                        .catch((error) => {
                            callback(error, null)
                        })
                })
            ],
            backendOptions: [{
                loadPath: API_ENDPOINT + 'api/v2/translations-exact-people/{{lng}}',
                crossDomain: true
            }]
        }
    })
