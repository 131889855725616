import React, {useEffect, useRef, useState} from "react";
import {Proposal} from "../../../../../models/proposal";
import Modal from "../../../../../components/modal/modal.component";
import {StyledIonModal, StyledModalContent} from "../../../../../components/modal/modal.style";
import {StyledTextarea} from "../../../../../components/form/input/textarea.style";
import {StyledFooter} from "../../../../../components/pane/pane.style";
import {StyledButton, StyledLightRedButton, StyledRedButton} from "../../../../../components/button/button.style";
import {useTranslation} from "react-i18next";
import CloseImage from "../../../../../assets/images/e-remove.svg";
import {StyledContent} from "../../../../reportPage/choosePersonPage.style";

type RemoveModalProps = {
    isOpen: boolean;
    proposal?: Proposal | null;
    onClose: () => void;
    onRemove: (comment: string) => void;
};

const RemoveInvitationModal: React.FC<RemoveModalProps> = (props: RemoveModalProps) => {

    const {t} = useTranslation();
    const [show, updateShow] = useState<boolean>(false);
    const commentRef = useRef<HTMLTextAreaElement>(null);
    const [comment, updateComment] = useState<string>('');

    const handleClose = () => {
        updateComment('');
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    const handleSave = async () => {
        props.onRemove(comment);
    }

    const handleCommentChange = (event: any) => {
        updateComment(event.target.value);
    };

    return (
        <StyledIonModal
            isOpen={show}
            onDidDismiss={() => {handleClose()}}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
        >
            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose() } />
                <h1 className="title">{t("planningTab.removeInvitation.comment")}</h1>
                <StyledContent className="content">
                    <StyledTextarea className="full-width" ref={commentRef} onChange={handleCommentChange}></StyledTextarea>
                </StyledContent>
            </StyledModalContent>
            <StyledFooter className="modal footer no-shadow">
                <StyledRedButton
                    onClick={() => handleSave()}
                    disabled={!comment}>{t("planningTab.removeInvitation.save")}</StyledRedButton>
            </StyledFooter>
        </StyledIonModal>
    );
};

export default RemoveInvitationModal;