import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {IonItem} from "@ionic/react";
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import LinkLabel from "../../components/form/input/label.component";
import {StyledIonLabel} from "../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../components/form/input/checkbox.style";
import {StyledModalContent} from "../../components/modal/modal.style";
import {useForm} from "react-hook-form";
import {Contract as ContractModel, Contract} from "../../models/contract";
import {getContract, getContractTisax, getForeignTermsSignContract} from "../../services/contract.service";
import {disableSwipeToClose} from "../../utils/tools/modals";

type ForeignTermsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSign: (rulesAccepted: boolean) => void;
};

const ForeignTermsSignModal: React.FC<ForeignTermsModalProps> = (props: ForeignTermsModalProps) => {

    const {t} = useTranslation();

    const [contract, updateContract] = useState<Contract>();
    const [termsChecked, updateTermsChecked] = useState(false);
    const [showTerms, updateShowTerms] = useState<boolean>(false);

    useEffect(() => {
        handleOpen();
    }, []);

    const handleOpen = async () => {
        let contactTmp = await getForeignTermsSignContract() as Contract;
        if (contactTmp) {
            await getContract(contactTmp.id)
                .then(response => {
                    let contract = response.data.contract as Contract;
                    contract.foreign_terms_content = response.data.foreign_terms_content;
                    updateContract(contract);
                });
        }
    };

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const onSubmit = async () => {
        props.onSign(termsChecked);
    };

    return (
        <>
            <Modal isOpen={showTerms} onDidDismiss={() => updateShowTerms(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.foreignTermsModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: contract?.foreign_terms_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={props.isOpen}
                maxHeight={'95%'}
                canDismiss={disableSwipeToClose}
                disableClose={true}
                onDidDismiss={() => props.onClose()}>

                <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                    <StyledModalContent>
                        <h1 className="title">{t("contractPage.approvals.title")}</h1>

                        <div className="content">

                            <LinkLabel urlString={t("contractPage.approvals.checkboxes.foreignTermsAcceptedLink")} url={'#'} onClick={() => updateShowTerms(true)}>
                                <IonItem lines="none">
                                    <StyledIonLabel className="ion-text-wrap">{t('contractPage.approvals.checkboxes.foreignTermsAccepted')}</StyledIonLabel>
                                    <StyledIonCheckbox name="foreignTermsAccepted" slot="start" onIonChange={(e) => {
                                        updateTermsChecked(e.detail.checked);
                                    }}/>
                                </IonItem>
                            </LinkLabel>
                        </div>

                        <StyledFooter className="no-shadow">
                            <Form.Button type="submit" disabled={!termsChecked}>{t("contractPage.approvals.acceptButton")}</Form.Button>
                        </StyledFooter>
                    </StyledModalContent>
                </Form.Container>
            </Modal>
        </>
    );
};

export default ForeignTermsSignModal;