export enum RidePaneType {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export enum RideStatus {
    ACCEPTED = 'ACCEPTED',
    QUARANTINE = 'QUARANTINE',
    REJECTED = 'REJECTED',
    WAITING = 'WAITING',
}