import styled from 'styled-components';
import {StyledModalContent} from "../../components/modal/modal.style";

export const StyledContent = styled(StyledModalContent)`

  width: 100%;
  // height: 100%;

  // align-items: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .desc {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    align-self: flex-start;
    color: #000000;
  }

  .separator {
    
    display: flex;
    align-items: center;
    position: relative;
    margin: 27px 0;

    .box {
      background: #FFFFFF;
      border: 1px solid #F6F6F8;
      box-sizing: border-box;
      border-radius: 39px;
      padding: 3px 8px;
      font-size: 11px;
      line-height: 140%;
      color: #888A98;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      width: 200vw;
      border: 1px solid #F6F6F8;
      position: absolute;
      left: -50vw;
    }
  }
`;

export const StyledSelectedWorkerExtended = styled.div`

  .user-details {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
  .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 16px;

    &.no-margin {
        margin-top: 0;
    }

    span {
        float: right;
        color: #888A98;
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-avatar {
    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      min-height: 36px;
      border-radius: 28px;
    }
  }
  
  .select {
    position: relative;

    input {
      font-weight: 600;
      color: #000000;
    }

    img.down {
      position: absolute;
      width: 10px;
      right: 15px;
      top: 37%;
    }
  }

`;