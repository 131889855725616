import React, { PropsWithChildren, useEffect, useState } from 'react';
import { StyledIonModal } from './modal.style';
import CloseImage from '../../assets/images/e-remove.svg';
import ModalService from '../../services/modal.service';

type ModalPropsType = {
    isOpen: boolean,
    backdropDismiss?: boolean,
    keyboardClose?: boolean,
    disableClose?: boolean,
    maxHeight?: string,
    initialBreakpoint?: number,
    breakpoints?: any,
    onDidDismiss: (data?: any) => void
    canDismiss?: boolean | ((data?: any, role?: string) => Promise<boolean>);
};

const Modal: React.FC<PropsWithChildren<ModalPropsType>> = (props) => {
    const [isOpen, changeVisibility] = useState<boolean>(false);

    useEffect(() => {
        if (props.isOpen !== isOpen) {

            if (props.isOpen) {
                ModalService.modalOpened(props.onDidDismiss);
            } else {
                ModalService.modalDismissed();
            }

            changeVisibility(props.isOpen)
        }
    }, [isOpen, props.isOpen]);

    return (
        <StyledIonModal
            isOpen={isOpen}
            keyboardClose={props.keyboardClose ?? true}
            backdropDismiss={props.backdropDismiss ?? true}
            maxHeight={props.maxHeight}
            presentingElement={undefined}
            onDidDismiss={(data) => props.onDidDismiss(data)}
            initialBreakpoint={props.initialBreakpoint ?? 0.99}
            breakpoints={props.breakpoints ?? [0, 0.99]}
            canDismiss={props.canDismiss ?? true}
        >
            <div className="modal-wrap">
                {!props.disableClose && <img src={CloseImage} className="close" onClick={() => {props.onDidDismiss();} } />}
                {props.children}
            </div>
        </StyledIonModal>
    );
};

export default Modal;