import {appApi} from "./api.service";

let endpointPrefix = 'user/availabilities';

const getAvailabilities = async () => {
    return appApi.get(endpointPrefix);
}

const sendAvailabilities = async (data: object) => {
	return appApi.post(endpointPrefix + '/update', data);
}

export {
	getAvailabilities,
	sendAvailabilities
};
