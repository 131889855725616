import {travelAllowanceApi} from "@services/api.service";

import {RideReport} from "@models/travelAllowance/rideReport";

import {getWorkerId} from "@utils/tools/worker";

const getRideReports = async (): Promise<RideReport[]> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-reports/worker/${workerId}`);

    return response.data;
}

const getRideReport = async (rideReportId: number): Promise<RideReport> => {
    const workerId = await getWorkerId();
    const response: any = await travelAllowanceApi.get(`ride-reports/${rideReportId}/worker/${workerId}`);

    return response.data;
}

const rejectRideReport = async (rideReportId: number, reason: string) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.patch(`ride-reports/${rideReportId}/worker/${workerId}/reject`, {
        'rejectionReason': reason
    });
}

const sendToAssecoRideReport = async (rideReportId: number) => {
    const workerId = await getWorkerId();

    return travelAllowanceApi.post(`ride-reports/${rideReportId}/worker/${workerId}/send-to-asseco`);
}

export {
    getRideReports,
    getRideReport,
    rejectRideReport,
    sendToAssecoRideReport,
};
