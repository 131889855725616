export enum FORM_FIELDS {
    EMAIL = 'email',
    PASSWORD = 'password',
    ACCEPT_TERMS = 'terms',
    ACCEPT_POLICY = 'policy',
    ACCEPT_MAIL = 'mail',
    ACCEPT_PHONE = 'phone',
    ALREADY_WORKER = 'already_worker',
}

export type FormFieldsType = {
    [FORM_FIELDS.EMAIL]: string;
    [FORM_FIELDS.PASSWORD]: string;
    [FORM_FIELDS.ACCEPT_TERMS]: boolean;
    [FORM_FIELDS.ALREADY_WORKER]: boolean;
    [FORM_FIELDS.ACCEPT_POLICY]: boolean;
    [FORM_FIELDS.ACCEPT_PHONE]: boolean;
    [FORM_FIELDS.ACCEPT_MAIL]: boolean;
}
