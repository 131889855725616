import styled from 'styled-components';

export const StyledFilesContainer = styled.div`
    
   overflow: auto;
   padding-bottom: 30px;

  .label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 20px;
  }
`;

export const StyledFileContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  padding: 18px;
  margin-top: 8px;
`;

export const StyledFileContent = styled.div`
  .title {
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin: 0px 4px;
    padding-bottom: 10px;
  }
  
  span.preview {
    padding: 5px;
    height: 300px;

    img {
      max-height: 300px;
    }
  }
`;