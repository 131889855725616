import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton} from '../../../components/button/button.style';
import {StyledTextarea} from '../../../components/form/input/textarea.style';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {StyledOption} from '../../../components/options/options.style';
import {RemarkType} from "../../../enums/remarkType";
import {Contract, ContractAppendix} from "../../../models/contract";
import {StyledModalContent} from "../../../components/modal/modal.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type AppendixRemarksModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onRemarks: (description: string) => void;
    appendix: ContractAppendix | undefined;
};

const AppendixRemarksModal: React.FC<AppendixRemarksModalProps> = (props: AppendixRemarksModalProps) => {

    const {t} = useTranslation();
    const [showRegulations, updateShowRegulations] = useState<boolean>(false);

    let focused = false;
    const manualRemarkRef = useRef<HTMLTextAreaElement>(null);

    const [manualRemark, updateManualRemark] = useState<string>('');
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    const handleSaveRemarks = () => {
        props.onRemarks(manualRemark)
    };

    useEffect(() => {
        handleFocus();
        handleOpen();
    });

    const handleOpen = () => {
        updateShowAddRemarks(props.isOpen);
    };

    const handleFocus = () => {
        if (!focused) {
            focused = true;
            manualRemarkRef.current?.focus();
        }
    };

    const handleManualChange = (event: any) => {
        updateManualRemark(event.target.value);
    };

    return (
        <>
            <Modal isOpen={showRegulations} onDidDismiss={() => updateShowRegulations(false)} canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.regulationModalTitle")}</h1>
                    <div className="content full-height">
                        <div dangerouslySetInnerHTML={{__html: props.appendix?.regulations_content || ''}}/>
                    </div>
                </StyledModalContent>
            </Modal>

            <Modal
                isOpen={showAddRemarks}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleCloseRemarks()}
            >
                <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleCloseRemarks() } />
                    <h1 className="title">
                        {t("contractPage.remarks.titleWriteRemark")}
                        {/*<a onClick={() => updateShowRegulations(true)}>{t('contractPage.remarks.titleRegulations')}</a>*/}
                    </h1>
                    <div className="content">
                        <StyledTextarea className="full-width" ref={manualRemarkRef} onChange={handleManualChange}></StyledTextarea>
                    </div>
                    <StyledFooter className="modal">
                        <StyledButton
                            onClick={() => handleSaveRemarks()}
                            disabled={!manualRemark}>{t("contractPage.content.sendButton")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Modal>
        </>

    )
};

export default AppendixRemarksModal;