import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledButton} from '../../../components/button/button.style';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {IonItem} from "@ionic/react";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {StyledModalContent} from "../../../components/modal/modal.style";
import LinkLabel from "../../../components/form/input/label.component";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type FbLoginModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
};

const FbLoginModal: React.FC<FbLoginModalProps> = (props: FbLoginModalProps) => {

    const {t} = useTranslation();
    const [showFbLogin, updateShowFbLogin] = useState<boolean>(false);

    const [allChecked, setAllChecked] = useState(false);

    const [termsChecked, setTermsChecked] = useState(false);
    // const [policyChecked, setPolicyChecked] = useState(false);

    const [emailMarketingChecked, setEmailMarketingChecked] = useState(false);
    const [phoneMarketingChecked, setPhoneMarketingChecked] = useState(false);
    const [emailMarketingShowDesc, setEmailMarketingShowDesc] = useState(false);
    const [phoneMarketingShowDesc, setPhoneMarketingShowDesc] = useState(false);

    const handleClose = () => {
        props.onClose();
    };

    const handleSave = () => {
        props.onSubmit({
            terms: termsChecked,
            // policy: policyChecked,
            mail: emailMarketingChecked,
            phone: phoneMarketingChecked,
        })
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowFbLogin(props.isOpen);
    };

    return (
        <Modal
            canDismiss={disableSwipeToClose}
            isOpen={showFbLogin}
            onDidDismiss={() => handleClose()}>
            <StyledModalContent>
                <h1 className="title center">{t("login.fbModal.title")}</h1>
                <div className="content">
                    <IonItem lines="none">
                        <StyledIonLabel>{t("createAccount.acceptAll")}</StyledIonLabel>
                        <StyledIonCheckbox slot="start" checked={allChecked}
                                           onIonChange={e => {
                                               setAllChecked(e.detail.checked)
                                               // setPolicyChecked(e.detail.checked)
                                               setTermsChecked(e.detail.checked)
                                               setEmailMarketingChecked(e.detail.checked)
                                               setPhoneMarketingChecked(e.detail.checked)
                                           }}/>
                    </IonItem>
                    <LinkLabel urlString={t("createAccount.acceptTermsPolicyURL")} url="https://exactsystems.pl/gdpr-file/EP-polityka_prywatnosci.htm" external={true}>
                        <LinkLabel urlString={t("createAccount.acceptTermsURL")} url="https://exactsystems.pl/gdpr-file/Warunki-Uzytkowania-Aplikacji-Exact-People.htm" external={true}>
                            <IonItem lines="none">
                                <StyledIonLabel className="required">{t("createAccount.acceptTerms")}</StyledIonLabel>
                                <StyledIonCheckbox slot="start" checked={termsChecked}
                                                   onIonChange={e => setTermsChecked(e.detail.checked)}/>
                            </IonItem>
                        </LinkLabel>
                    </LinkLabel>

                    {/*<LinkLabel urlString={t("createAccount.acceptPolicyURL")} url="https://exactsystems.pl/gdpr-file/Regulamin-Uslugi-Ofert-Zatrudnienia.htm" external={true}>*/}
                    {/*    <IonItem lines="none">*/}
                    {/*        <StyledIonLabel>{t("createAccount.acceptPolicy")}</StyledIonLabel>*/}
                    {/*        <StyledIonCheckbox slot="start" checked={policyChecked}*/}
                    {/*                           onIonChange={e => setPolicyChecked(e.detail.checked)}/>*/}
                    {/*    </IonItem>*/}
                    {/*</LinkLabel>*/}

                    <IonItem lines="none">
                        <StyledIonLabel>{t("createAccount.acceptMailMarketing")}</StyledIonLabel>
                        <StyledIonCheckbox slot="start" checked={emailMarketingChecked}
                                           onIonChange={e => setEmailMarketingChecked(e.detail.checked)}/>
                    </IonItem>

                    <>
                        { !emailMarketingShowDesc &&
                            <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                        }
                        { emailMarketingShowDesc &&
                            <LinkLabel urlString={t("createAccount.acceptMailMarketingDescURL")}
                                       url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc1")}</p>
                                <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc2")}</p>
                            </LinkLabel>
                        }
                        { emailMarketingShowDesc &&
                            <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                        }
                    </>

                    <IonItem lines="none">
                        <StyledIonLabel>{t("createAccount.acceptPhoneMarketing")}</StyledIonLabel>
                        <StyledIonCheckbox slot="start" checked={phoneMarketingChecked}
                                           onIonChange={e => setPhoneMarketingChecked(e.detail.checked)}/>
                    </IonItem>

                    <>
                        { !phoneMarketingShowDesc &&
                            <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                        }

                        { phoneMarketingShowDesc &&
                            <LinkLabel urlString={t("createAccount.acceptPhoneMarketingDescURL")}
                                       url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc1")}</p>
                                <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc2")}</p>
                            </LinkLabel>
                        }

                        { phoneMarketingShowDesc &&
                            <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                        }
                    </>

                </div>


                <StyledFooter className="modal">
                    <StyledButton
                        onClick={() => handleSave()}
                        disabled={!termsChecked}>{t("login.fbModal.submit")}</StyledButton>
                </StyledFooter>
            </StyledModalContent>
        </Modal>
    );
};

export default FbLoginModal;
