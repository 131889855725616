import styled, {css} from 'styled-components';

const SharedContainerStyle = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  overflow: scroll;

  .profile-avatar {
    position: relative;
    display: inline-block;

    .add {
      position: absolute;
      bottom: 17px;
      right: -6px;
    }

    .avatar {
      width: 56px;
      min-width: 56px;
      height: 56px;
      min-height: 56px;
      border-radius: 28px;
      margin-bottom: 16px;
    }
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const StyledContainer = styled.div`
  ${SharedContainerStyle}
`;

export const ModalStyledContainer = styled.div`
  ${SharedContainerStyle}
  
  padding: 20px;
`;

export const StyledMyDataField = styled.div`
    ion-row {
        border-bottom: 1px solid #F6F6F8;
        padding-bottom: 15px;
      
        &.to-verify {
          border-bottom: none;
          padding-bottom: 5px;
        }
    }

    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        padding-top: 1px;
        padding-bottom: 12px;
    }

    .action {
        font-size: 14px;
        line-height: 140%;
        color: #0085FF;
        display: flex;
        width: 100%;
        align-items: center;
        margin: 0;
        
        &.edit {
            align-items: flex-end;  
        }
        
        &.cancel {
            align-items: flex-end;
            margin-bottom: 15px;
        }

        &.disabled {
            align-items: flex-end;
            color: #888A98;
        }
    }
    
    .visible, &.visible {
      display: block;
      /*height: 95px;*/
    }

    .hidden, &.hidden {
      display: none;
      /*height: 0;*/
    }
`;