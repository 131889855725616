import {
    IonAlert,
    IonButton,
    IonCol, IonLoading,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast,
    useIonViewWillEnter
} from "@ionic/react";
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledIonSelect } from "../../../components/form/input/select.style";
import { StyledTextarea } from "../../../components/form/input/textarea.style";
import StaticPane from "../../../components/pane/static-pane.component";
import { StyledAnswerButton } from "../../auditReport/components/form/step2.styles";
import { StyledAccordion } from "../../reportPage/reportPage.style";
import Accordion from "../components/form/accordion";
import { StyledInconsitencyItemDetailsContent } from "./inconcistencyModule.style";
import {
    getButtonType,
    getInconsistencyRecord,
    getInconsistencyRecordFormData,
    getInconsistencyUsers,
    postInconsistencyRecord,
    validateInconsistencyRecord
} from "../../../services/audit.service";
import {
    AuditReportFormModel,
    FormQuestionAnswer,
    InconsistencyRecord,
    InconsistencyRecordAction, InconsistencyRecordFormModel,
    NokDirectory
} from "../../../models/auditReport";
import {AuditIncompatibilityActionType, AuditIncompatibilityStatus} from "../../../enums/audit";
import {StyledButton, StyledCircleButton, StyledLightBlueButton} from "../../../components/button/button.style";
import DateInput from "../../../components/form/input/date.component";
import {StyledInput} from "../../../components/form/input/input.style";
import PaginatedListModal from "../../auditReport/modals/paginatedListModal.component";
import {AuditReportContext} from "../../auditReport/auditReport.component";
import {DataSelectable} from "../../../models/common";
import TrashRedImage from "../../../assets/images/trash-can-red.svg";
import {Links} from "../../links";
import PageLeavingGuard from "../../../components/page-leaving/pageLeavingGuard.component";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);


type InconsistencyModuleDetailsPaneProps = {
    topEdge: number | undefined;
    id: string;
}

export interface RemoveActionState {
    showAlert: boolean;
    action?: InconsistencyRecordAction;
    index?: number;
}

const InconsistencyModuleEditPane: React.FC<InconsistencyModuleDetailsPaneProps> = (props: InconsistencyModuleDetailsPaneProps) => {
    const { t } = useTranslation();

    const history = useHistory();

    const [record, setRecord] = useState<InconsistencyRecord|undefined>(undefined);
    const [recordFormModel, setRecordFormModel] = useState<InconsistencyRecordFormModel>({
        answer: undefined, actionTerm: moment().format('YYYY-MM-DD'), actions: [], nokDictionaryItem: undefined, user: undefined, comment: undefined
    });
    const [nokDirectory, setNokDirectory] = useState<NokDirectory|undefined>(undefined);
    const [answers, setAnswers] = useState<FormQuestionAnswer[]|undefined>(undefined);
    const [showUsersModal, updateShowUsersModal] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<any>(false);
    const [removeActionState, updateRemoveActionState] = useState({ showAlert: false, action: undefined, index: undefined } as RemoveActionState);
    const [showToast, setShowToast] = useState(false);
    const [toast, setToast] = useState<any>('');
    const [formSaved, setFormSaved] = useState(false);
    const [showLoading, updateShowLoading] = useState(false);

    const actionTypes = [
        AuditIncompatibilityActionType.IMMEDIATE,
        AuditIncompatibilityActionType.PERFECTING,
        AuditIncompatibilityActionType.CORRECTIVE,
    ];

    useEffect(() => {
        loadRecord();
    }, []);

    useEffect(() => {
        if (record) {
            setRecordFormModel({
                ...recordFormModel,
                nokDictionaryItem: record.audit_report_question.nok_dictionary_item,
                user: record.user,
                comment: record.comment
            });
        }
    }, [record]);

    useEffect(() => {
        if (answers && record) {
            let answer = answers.find((a) => record?.audit_report_question.answer === a.value)

            if (answer) {
                setRecordFormModel({
                    ...recordFormModel,
                    answer: answer
                });
            }
        }
    }, [answers]);

    const loadRecord = async () => {
        const response = await getInconsistencyRecord(props.id)
            .then(response => {
                setRecord(response.data);
            })
            .catch(reason => {
                history.goBack();
            });

        await getInconsistencyRecordFormData(props.id)
            .then(response => {
                setNokDirectory(response.data.nok_dictionary)
                setAnswers(response.data.answers)
            });
    }

    const loadUsers = async (page: number, searchText?: string) => {
        let data:DataSelectable[] = await getInconsistencyUsers(props.id, page, searchText).then((response) => {
            return response.data as DataSelectable[];
        });

        return data;
    }

    function addNextAction() {
        const actions = [ ...(recordFormModel.actions || []) ];
        if (record) {
            actions.push({type: undefined, comment: record.audit_report_question.nok_comment})
            setRecordFormModel({
                ...recordFormModel,
                actions: actions
            });
        }
    }

    function selectActionType(action: InconsistencyRecordAction, value: string) {
        const index = recordFormModel.actions?.indexOf(action)
        if (index !== undefined && index !== -1) {
            const actions = [ ...(recordFormModel.actions || []) ];
            actions[index].type = value;
            setRecordFormModel({
                ...recordFormModel,
                actions: actions
            })
        }
    }

    function updateActionComment(action: InconsistencyRecordAction, value: string) {
        const index = recordFormModel.actions?.indexOf(action)
        if (index !== undefined && index !== -1) {
            const actions = [ ...(recordFormModel.actions || []) ];
            actions[index].comment = value;
            setRecordFormModel({
                ...recordFormModel,
                actions: actions
            })
        }
    }

    const availableActions = (action: InconsistencyRecordAction) => {
        return actionTypes.filter((type) => {
            return type === action.type || (!action.type && recordFormModel.actions.filter(value => value.type === type).length === 0);
        })
    }

    const removeAction = (action: InconsistencyRecordAction) => {
        const index = recordFormModel.actions?.indexOf(action)
        if (index !== undefined && index !== -1) {
            const actions = [ ...(recordFormModel.actions || []) ];
            actions.splice(index, 1)
            setRecordFormModel({
                ...recordFormModel,
                actions: actions
            })
        }
    }

    function isNOKselected() {
        return answers && answers.some((answer) => answer.id === recordFormModel.answer?.id && answer.value === 'NOK')
    }

    function getStrokeColor() {
        const isSelected = answers && answers.some((answer) => answer.id === recordFormModel?.answer?.id)
        if (isSelected) {
            return '#ffffff'
        } else {
            return '#0085FF'
        }
    }

    function isAnswerSelected(answer:FormQuestionAnswer) {
        const isSelected = recordFormModel.answer?.id === answer.id;
        if (isSelected) {
            return `active`
        } else {
            return ''
        }
    }

    const setAnswer = (answer: FormQuestionAnswer) => {
        setRecordFormModel({
            ...recordFormModel,
            answer: answer
        });
    }

    const setJustification = (justification: DataSelectable) => {
        setRecordFormModel({
            ...recordFormModel,
            nokDictionaryItem: justification
        });
    }

    function getButtonLabel(answer: FormQuestionAnswer, stroke:string) {
        if (answer.value === 'OK') {
            return <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><path fill="none" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M416 128L192 384l-96-96"/></svg>
        } else if (answer.value === 'NOK') {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
        } else {
            return <span style={{ color: stroke}}>{answer.label}</span>
        }
    }

    const onSubmit = async () => {
        setFormSubmitted(true);
        updateShowLoading(true);
        if (!validateInconsistencyRecord(recordFormModel, isNOKselected())) {
            setToast(t("reportPage.validation.commonError"));
            setShowToast(true);

            const errorElement = document.querySelector(
                `.hasErrors`
            )

            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            await onSave();
        }

        updateShowLoading(false);
    }

    const onSave = async () => {
        await postInconsistencyRecord(props.id, recordFormModel).then((response) => {
            setFormSaved(true);
            history.replace(Links.main + Links.inconsistencyModule);
        }).catch(reason => {
            setToast(t("common.serverErrorMsg"));
            setShowToast(true);
        })
    }

    const contextAuditReport = useContext(AuditReportContext);
    contextAuditReport.setFormSubmitted = setFormSubmitted;
    contextAuditReport.getFormSubmitted = () => {
        return formSubmitted;
    };

    const auditContext = useContext(AuditReportContext);

    return (
        <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            <StyledInconsitencyItemDetailsContent>
                {record &&
                    <IonRow className="details-header">
                        <div className="details-header__fs12">{moment(record.audit_report_question.audit_report.date).format('DD.MM.YYYY')}</div>
                        <div className="details-header__fs14">{record.audit_report_question.question}</div>
                        <div className="details-header__fs12">{record.audit_report_question.audit_report.place_of_service?.name}</div>
                    </IonRow>}

                <div className="details-content">
                    {record &&
                        <Accordion isOpen={true} allowToggle={false} title={record?.audit_report_question.question}>
                            <div className="aaa">
                                { record.audit_report_question.question }
                                <StyledAccordion>
                                    <div className="answer-buttons">
                                        { answers && answers.map( (answer) => {
                                            return <StyledAnswerButton className={ `${getButtonType(answer.value)} ${isAnswerSelected(answer)}`  } onClick={ () => setAnswer(answer)}>
                                                { getButtonLabel(answer, getStrokeColor()) }
                                            </StyledAnswerButton>
                                        })}
                                    </div>
                                    {
                                        <div className="mb-20">
                                            { isNOKselected() &&
                                                <>
                                                    <IonRow>
                                                        <IonCol size="12" className="label mt-8 required">
                                                            {t('inconsistency.form.justification')}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="12" className="select-full-width">
                                                            <IonSelect
                                                                interface="action-sheet"
                                                                className={auditContext.getFormSubmitted() && isNOKselected() && !recordFormModel.nokDictionaryItem ? 'hasErrors' : ''}
                                                                onIonChange={ (e) => setJustification(e.detail.value)}
                                                                placeholder={t('common.selectPlaceholder')}
                                                                okText={t('common.selectOk')}
                                                                cancelText={t('common.selectCancel')}
                                                                value={recordFormModel.nokDictionaryItem}
                                                                compareWith={(o1: DataSelectable, o2: DataSelectable) => {
                                                                    return o1 && o2 ? o1.id === o2.id : o1 === o2;
                                                                }}
                                                                interfaceOptions={
                                                                    {
                                                                        header: t('common.select')
                                                                    }
                                                                }
                                                            >
                                                                { nokDirectory && nokDirectory.audit_nok_dictionary_items.map( (answer) => {
                                                                    return <IonSelectOption value={answer}>{answer.name}</IonSelectOption>
                                                                })}
                                                            </IonSelect>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                            }
                                            {
                                                recordFormModel.actions.map( (action: InconsistencyRecordAction, index:number) => {
                                                    return <>
                                                        <IonRow>
                                                            <IonCol size="12" className="label mt-8 required">
                                                                {t('inconsistency.form.action.title')}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="11" className="select">
                                                                <StyledIonSelect
                                                                    interface="action-sheet"
                                                                    className={auditContext.getFormSubmitted() && isNOKselected() && !action.type ? 'hasErrors' : ''}
                                                                    placeholder={t('common.selectPlaceholder')}
                                                                    onIonChange={e => {selectActionType(action, e.detail.value)}}
                                                                    okText={t('common.selectOk')}
                                                                    cancelText={t('common.selectCancel')}
                                                                    interfaceOptions={
                                                                        {
                                                                            header: t('inconsistency.form.selectAction'),
                                                                        }
                                                                    }
                                                                    value={action.type}
                                                                >
                                                                    { availableActions(action).map( (type) => {
                                                                        return <IonSelectOption value={type}>{t('inconsistency.form.actionType.' + type.toLowerCase())}</IonSelectOption>
                                                                    })}
                                                                </StyledIonSelect>
                                                            </IonCol>

                                                            <IonCol size="1">
                                                                <StyledCircleButton className="white" onClick={() => updateRemoveActionState({showAlert: true, action: action, index: index})}><img src={TrashRedImage} /></StyledCircleButton>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="12" className="label mt-8 required">
                                                                {t('inconsistency.form.action.comment')}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <StyledTextarea
                                                                    onKeyPress={event => {
                                                                        if (event.key == 'Enter') {
                                                                            const input = event.target as HTMLInputElement;
                                                                            input.blur();
                                                                        }
                                                                    }}
                                                                    className={auditContext.getFormSubmitted() && !action.comment ? 'full-width hasErrors' : 'full-width'}
                                                                    onChange={(e) => {
                                                                        updateActionComment(action, e.target.value)
                                                                    }}
                                                                    value={action.comment}
                                                                    placeholder={t('auditReport.form.enter')} />
                                                            </IonCol>
                                                        </IonRow>
                                                    </>})
                                            }
                                        </div>
                                    }
                                    <hr className="hr" />

                                    {recordFormModel.actions.length < 3 && <IonRow>
                                        <StyledLightBlueButton onClick={() => addNextAction()}>
                                            {t('inconsistency.form.addNextAction')}
                                        </StyledLightBlueButton>
                                    </IonRow>}


                                    <IonRow className="mt-24">
                                        <IonCol size="12" className="label required">
                                            {t('inconsistency.form.user')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" onClick={() => {
                                            updateShowUsersModal(true)
                                        }}>
                                            <StyledInput readOnly={true} className={auditContext.getFormSubmitted() && (!recordFormModel.user) ? 'hasErrors' : ''} value={recordFormModel.user ? recordFormModel.user.name : ''} placeholder={t("common.select")}/>
                                            {/*<img className="down" src={ArrowDownImage} />*/}
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol size="12" className="label required">
                                            {t('inconsistency.form.term')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="date-field">
                                            <DateInput
                                                presentation="date"
                                                format="DD.MM.YYYY"
                                                value={recordFormModel.actionTerm}
                                                onChange={(value) => {
                                                    setRecordFormModel({
                                                        ...recordFormModel,
                                                        actionTerm: moment(value).format('YYYY-MM-DD')
                                                    });
                                                }}
                                                cancelText={t("common.dateCancel")}
                                                doneText={t("common.dateDone")}
                                                placeholder={t("common.select")}
                                                min={moment().format('YYYY-MM-DD')}
                                                className={auditContext.getFormSubmitted() && !recordFormModel.actionTerm ? 'hasErrors' : ''}
                                            />
                                        </IonCol>

                                    </IonRow>

                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('inconsistency.form.comment.label')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <StyledTextarea
                                                onKeyPress={event => {
                                                    if (event.key == 'Enter') {
                                                        const input = event.target as HTMLInputElement;
                                                        input.blur();
                                                    }
                                                }}
                                                className="full-width"
                                                onChange={(e) => {
                                                    setRecordFormModel({
                                                        ...recordFormModel,
                                                        comment: e.target.value
                                                    })
                                                }}
                                                value={recordFormModel.comment}
                                                placeholder={t('inconsistency.form.comment')}/>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol size="12" className="label mt-8 required">
                                            {t('inconsistency.form.status.label')}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12" className="select-full-width">
                                            <IonSelect
                                                interface="action-sheet"
                                                className={auditContext.getFormSubmitted() && !recordFormModel.status ? 'hasErrors' : ''}
                                                onIonChange={ (e) => {
                                                    setRecordFormModel({
                                                        ...recordFormModel,
                                                        status: e.detail.value,
                                                    });
                                                }}
                                                placeholder={t('common.selectPlaceholder')}
                                                okText={t('common.selectOk')}
                                                cancelText={t('common.selectCancel')}
                                                value={recordFormModel.status}
                                                interfaceOptions={
                                                    {
                                                        header: t('common.select')
                                                    }
                                                }
                                            >
                                                <IonSelectOption value={AuditIncompatibilityStatus.PENDING}>{t('inconsistency.form.status.pending')}</IonSelectOption>
                                                <IonSelectOption value={AuditIncompatibilityStatus.RESOLVED}>{t('inconsistency.form.status.resolved')}</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                    </IonRow>
                                </StyledAccordion>
                            </div>

                            <IonRow>
                                <StyledButton onClick={onSubmit}>
                                    {t('inconsistency.form.save')}
                                </StyledButton>
                            </IonRow>
                        </Accordion>
                    }
                </div>
            </StyledInconsitencyItemDetailsContent>

            {showUsersModal && <PaginatedListModal search={true} report={{} as AuditReportFormModel} isOpen={showUsersModal} onFetchGeneric={(page:number, searchText?: string) => {
                let data = loadUsers(page, searchText);

                return data;
            }} onSubmit={(selected) => {
                setRecordFormModel({
                    ...recordFormModel,
                    user: selected,
                });

                updateShowUsersModal(false);
            }} onClose={() => {
                updateShowUsersModal(false);
            }} />
            }

            <IonAlert
                isOpen={removeActionState.showAlert}
                onDidDismiss={() => updateRemoveActionState({showAlert: false, action: undefined, index: undefined})}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            updateRemoveActionState({showAlert: false, action: undefined, index: undefined})
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            if (removeActionState.action !== undefined) {
                                removeAction(removeActionState.action);
                            }
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {
                    setShowToast(false);
                    setToast('');
                }}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonLoading onDidDismiss={() => updateShowLoading(false)} isOpen={showLoading}/>

            <PageLeavingGuard navigate={path => history.goBack()} shouldBlockNavigation={location => {
                if (formSaved) {
                    return false;
                }

                return true;
            }} />
        </StaticPane>
    );
};

export default InconsistencyModuleEditPane;
