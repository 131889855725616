import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from "../../../components/modal/modal.component";
import {StyledModalContent} from '../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../utils/tools/modals";

type TimetableGuideModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const TimetableGuideModal: React.FC<TimetableGuideModalProps> = (props: TimetableGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
    };

    return (
        <Modal
            isOpen={showGuide}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title">{t("notificationTab.timetablePane.guide.title")}</h1>
                    <div className="content full-height">

                        <h6><strong>{t("notificationTab.timetablePane.guide.timetable.title")}</strong></h6>
						<p>{t("notificationTab.timetablePane.guide.timetable.p1")}</p>
						<p>{t("notificationTab.timetablePane.guide.timetable.p2")}</p>
						<p>{t("notificationTab.timetablePane.guide.timetable.p3")}</p>
						<p>{t("notificationTab.timetablePane.guide.timetable.p4")}</p>

						<h6><strong>{t("notificationTab.timetablePane.guide.calendar.title")}</strong></h6>
						<p>{t("notificationTab.timetablePane.guide.calendar.p1")}</p>
						<p>{t("notificationTab.timetablePane.guide.calendar.p2")}</p>
						<p>{t("notificationTab.timetablePane.guide.calendar.p3")}</p>
						<p>{t("notificationTab.timetablePane.guide.calendar.p4")}</p>

						<h6><strong>{t("notificationTab.timetablePane.guide.acceptRejectInvite.title")}</strong></h6>
						<p>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p1")}</p>
						<ul>
							<li>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p11")}</li>
							<li>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p12")}</li>
							<li>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p13")}</li>
						</ul>
						<p>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p2")}</p>
						<p>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p3")}</p>
						<p>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p4")}</p>
						<p>{t("notificationTab.timetablePane.guide.acceptRejectInvite.p5")}</p>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default TimetableGuideModal;
