import {IonCol, IonGrid, IonItem, IonRow} from "@ionic/react";
import {StyledInput} from "../../../../components/form/input/input.style";
import {StyledIonLabel} from "../../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../../components/form/input/checkbox.style";
import ArrowDownImage from "../../../../assets/images/down-arrow.svg";
import {StyledTextarea} from "../../../../components/form/input/textarea.style";
import {StyledSelectedWorkerExtended} from "../../choosePersonPage.style";
import React, {useEffect, useState} from "react";
import {ReportRowMeasurement} from "../../reportPage.component";
import {useTranslation} from "react-i18next";
import {DataSelectable} from "./reportRowForm.component";
import ListModal from "../../modals/listModal.component";
import {getMeasuringEquipmentsItems} from "../../../../services/report.service";
import moment from "moment/moment";
import {StyledComponentFormContainer} from "./reportRowForm.style";
import {RowMeasurementContainer} from "./reportRowMeasurement.style";

type MeasurementFormProps = {
    reportPreview: boolean;
    specificationId: string;
    serviceDate: string;
    measurement: ReportRowMeasurement;
    onChange: (measurement: ReportRowMeasurement) => void;
};

const ReportRowMeasurementFormComponent: React.FC<MeasurementFormProps> = (props: MeasurementFormProps) => {

    const {t} = useTranslation();
    const [showMeasurementItemsModal, setShowMeasurementItemsModal] = useState<boolean>(false);
    const [measurement, updateMeasurement] = useState<ReportRowMeasurement>(props.measurement);
    const [measurementItems, setMeasurementItems] = useState<DataSelectable[]>([]);

    const updateSelectedMeasurementItem = (measurement: ReportRowMeasurement, item: DataSelectable) => {
        measurement.measuring_equipment_order_item = item;
        setShowMeasurementItemsModal(false);
    }

    const fetchItems = async () => {
        if (props.specificationId && props.serviceDate && props.measurement && props.measurement.measuring_equipment_item_category) {
            await getMeasuringEquipmentsItems(props.specificationId, moment(props.serviceDate).format('DD-MM-YYYY'), props.measurement.measuring_equipment_item_category?.id)
                .then(response => {
                    setMeasurementItems(response.data);
                });
        }
    }

    useEffect(() => {
        fetchItems();
    }, []);

    useEffect(() => {
        props.onChange(measurement);
    }, [measurement]);

    return <RowMeasurementContainer>
        <IonRow>
            <IonCol className="title">{t('report.measurements.category')} {measurement?.hide}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol className="select">
                <StyledInput disabled={true} value={props.measurement.measuring_equipment_item_category?.name}/>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="12">
                <IonItem lines="none">
                    <StyledIonLabel
                        className="ion-text-wrap">{t('report.measurements.hide')}</StyledIonLabel>
                    <StyledIonCheckbox name="hide" slot="start" checked={measurement?.hide} disabled={props.reportPreview}
                                       onIonChange={(e) => {
                                           updateMeasurement({...measurement, hide: e.detail.checked, measuring_equipment_order_item: undefined, missing: false, description: ''});
                                       }}/>
                </IonItem>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol className="title">{t('report.measurements.multiplier')}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <StyledInput disabled={true} value={props.measurement.multiplier}/>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol className="title">{t('report.measurements.item')} {measurement?.hide}</IonCol>
        </IonRow>
        <IonRow>
            <IonCol className="select" onClick={async () => {
                if (!props.reportPreview && !measurement?.hide && !measurement.missing) {
                    setShowMeasurementItemsModal(true);
                }
            }}>
                <StyledInput disabled={true}
                             value={!measurement.hide && !measurement.missing ? measurement.measuring_equipment_order_item?.name : ''}/>
                <img className="down" src={ArrowDownImage}/>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol size="12">
                <IonItem lines="none">
                    <StyledIonLabel
                        className="ion-text-wrap">{t('report.measurements.missing_item')}
                    </StyledIonLabel>
                    <StyledIonCheckbox name="missing" slot="start" checked={measurement?.missing}
                                       disabled={props.reportPreview || measurement?.hide}
                                       onIonChange={(e) => {
                                           updateMeasurement({...measurement, missing: e.detail.checked, measuring_equipment_order_item: undefined, description: ''});
                                       }}/>
                </IonItem>
            </IonCol>
        </IonRow>
        {measurement?.missing && <>
            <IonRow>
                <IonCol size="12" className="title">
                    {t('report.measurements.missing_item_description')}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <StyledTextarea disabled={props.reportPreview || measurement.hide}
                                    onKeyPress={event => {
                                        if (event.key == 'Enter' && (!props.reportPreview || measurement.hide)) {
                                            const input = event.target as HTMLInputElement;
                                            input.blur();
                                        }
                                    }}
                                    className="full-width"
                                    onChange={(e) => {
                                        updateMeasurement({...measurement, description: e.target.value});
                                    }}
                                    value={measurement.description}
                                    placeholder={t('report.measurements.missing_item_description_manual')}/>
                </IonCol>
            </IonRow>
        </>}

        <ListModal
            list={measurementItems}
            isOpen={showMeasurementItemsModal}
            emptyState={true}
            onClose={() => setShowMeasurementItemsModal(false)}
            selected={measurement?.measuring_equipment_order_item?.id}
            onSubmit={(selected) => {
                updateMeasurement({...measurement, measuring_equipment_order_item: selected});
                setShowMeasurementItemsModal(false);
            }}
        />
    </RowMeasurementContainer>
}
export default ReportRowMeasurementFormComponent;