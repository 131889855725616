export enum FORM_FIELDS {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    DATE_OF_BIRTH = 'dateOfBirth',
    EMAIL = 'email',
    PHONE = 'phone',
    PESEL = 'pesel',
    BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
    STREET = 'street',
    HOUSE_NUMBER = 'houseNumber',
    APARTMENT_NUMBER = 'apartmentNumber',
    POST_OFFICE = 'postOffice',
    ZIP_CODE = 'zipCode',
    CITY_TEXT = 'cityText',
    CITY = 'city',
    TAX_OFFICE = 'taxOffice',
    NATIONALITY = 'nationality',
}

export type FormFieldsType = {
    [FORM_FIELDS.FIRST_NAME]: string;
    [FORM_FIELDS.LAST_NAME]: string;
    [FORM_FIELDS.DATE_OF_BIRTH]: Date;
    [FORM_FIELDS.EMAIL]: string;
    [FORM_FIELDS.PHONE]: string;
    [FORM_FIELDS.PESEL]: string;
    [FORM_FIELDS.BANK_ACCOUNT_NUMBER]: string;
    [FORM_FIELDS.STREET]: string;
    [FORM_FIELDS.HOUSE_NUMBER]: string;
    [FORM_FIELDS.APARTMENT_NUMBER]: string;
    [FORM_FIELDS.POST_OFFICE]: string;
    [FORM_FIELDS.ZIP_CODE]: string;
    [FORM_FIELDS.CITY_TEXT]: string;
    [FORM_FIELDS.CITY]: string;
    [FORM_FIELDS.TAX_OFFICE]: string;
    [FORM_FIELDS.NATIONALITY]: string;
}
