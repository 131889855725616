import { IonContent } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonContent = styled.div`
  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    padding: 0 24px;
  }

  .working-hours {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 140%;
    color: #000000;

    img {
      margin-right: 18px;
    }
  }

  .accepted {
    width: 100%;
    height: 92px;

    background: rgba(0, 132, 255, 0.1);
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.408px;

    color: #0084FF;
  }

  .additional-margin {
    margin-top: 28px;
  }

  .note {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #000000;

    margin-bottom: 16px;
  }
  
  .hide {
    display: none;
  }
`;
