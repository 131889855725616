import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {IonItem} from '@ionic/react';
import {FORM_FIELDS} from './signWithPassword.types';
import Modal from '../../components/modal/modal.component';
import Info from '../../components/info/info.component';
import {StyledIonLabel} from '../../components/form/input/label.style';
import {StyledIonCheckbox} from '../../components/form/input/checkbox.style';
import {StyledFooter} from '../../components/pane/pane.style';
import Form from '../../components/form'
import {StyledPinRow, StyledRow} from './signWithPassword.style';
import {StyledField, StyledInput} from "../../components/form/input/input.style";
import {disableSwipeToClose} from "../../utils/tools/modals";

type SignWithPasswordModalProps = {
    configuration: ISignWithPasswordModalConfigration;
    isOpen: boolean;
    onClose: () => void;
    onSave?: (code: string, password: string) => void;
    formSubmitted?: boolean;
};

export interface ISignWithPasswordModalConfigration {
    title: string;
    info?: string;
    buttonText: string;
    approvementText?: string;
    beforeCodeText?: string;
    afterCodeText?: string;
};

export interface ISignModalWithPasswordFormData {
    pin1: string;
    pin2: string;
    pin3: string;
    pin4: string;
    password: string;
};

const SignWithPasswordModal: React.FC<SignWithPasswordModalProps> = (props: SignWithPasswordModalProps) => {

    const {t} = useTranslation();

    const [showSign, updateShowSign] = useState<boolean>(false);
    const [accepted, updateAccepted] = useState<boolean>(false);

    const {register, handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });
    const handleOpen = () => {
        updateShowSign(props.isOpen);
    };
    const onSubmit = (data: ISignModalWithPasswordFormData) => {
        const onSave = props.onSave;
        if (onSave) {
            let code = data.pin1 + data.pin2 + data.pin3 + data.pin4;
            onSave(code, data.password);
        }
    };

    const content = useRef<HTMLDivElement>(null);

    const scrollTo = () => {
        content.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        const input = event.target as HTMLInputElement;

        if (input.value?.length > 1) {
            input.value = input.value.substring(0, 1);
            event.preventDefault();
        }

        if (input.value?.length === 1) {
            const form = input.form;
            const index = Array.prototype.indexOf.call(form, event.target);

            if (form?.elements[index + 1] && form?.elements[index + 1].tagName === "INPUT" && 
                (form?.elements[index + 1] as HTMLInputElement).name !== 'accept') {
                (form?.elements[index + 1] as HTMLInputElement).focus();
            } else {
                input.blur();
            }

            event.preventDefault();
        }

        if (input.value?.length === 0) {
            const form = input.form;
            const index = Array.prototype.indexOf.call(form, event.target);

            if (form?.elements[index - 1] && form?.elements[index - 1].tagName === "INPUT" &&
                (form?.elements[index - 1] as HTMLInputElement).name !== 'accept') {
                (form?.elements[index - 1] as HTMLInputElement).focus();
            } else {
                input.blur();
            }

            event.preventDefault();
        }
    }

    return (
        <Modal
            isOpen={showSign}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>

                <h1 className="title full">{props.configuration.title}</h1>
                <Info>{props.configuration.info}</Info>

                <Form.Container onSubmit={handleSubmit(onSubmit)}>
                    <StyledPinRow>
                        <Form.PinInput name={FORM_FIELDS.PIN_1} placeholder=" " type="text" pattern="[0-9]*" inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp} onFocus={e => scrollTo()}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_2} placeholder=" " type="text" pattern="[0-9]*" inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_3} placeholder=" " type="text" pattern="[0-9]*" inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                        <Form.PinInput name={FORM_FIELDS.PIN_4} placeholder=" " type="text" pattern="[0-9]*" inputMode="tel"
                                       ref={register({required: true})} maxLength={1} onKeyUp={handleKeyUp}/>
                    </StyledPinRow>

                    <StyledField>
                        <div className="content" ref={content}>
                        <label className='required'>{t('signModal.password')}</label>
                        <StyledInput
                            className='bolded no-margin hide-spin-button'
                            name={FORM_FIELDS.PASSWORD}
                            ref={register({
                                required: true,
                            })}
                            type="password"
                            onFocus={e => scrollTo()}
                        ></StyledInput>
                        </div>
                    </StyledField>

                    <StyledFooter className="modal">
                        <Form.Button type="submit"
                                     disabled={!formState.isValid || props.formSubmitted || (!!props.configuration.approvementText && !accepted)}>{props.configuration.buttonText}</Form.Button>
                    </StyledFooter>
                </Form.Container>
        </Modal>
    );
};

export default SignWithPasswordModal;