import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {StyledModalContent} from "../../components/modal/modal.style";
import {Preferences} from "@capacitor/preferences";
import VectorImage from "../../assets/images/checkMarkGreen.svg";
import {StyledDataTable} from "../../components/content/content.style";
import {ILanguage} from "../../app/mainPage/tabs/language/languageTab.component";

type LanguageModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const LanguageSwitcherModal: React.FC<LanguageModalProps> = (props: LanguageModalProps) => {

    const { t, i18n } = useTranslation();
    let supportedLngs = ['en', 'pl', 'de', 'sk', 'tr', 'ru', 'cs', 'nl', 'hu', 'ro', 'zh', 'pt', 'es', 'uk', 'fr'];

    const getLanguages = () => supportedLngs.map((lang: string) => ({
        name: t(`languageTab.${lang}`),
        selected: i18n.language == lang,
        lang
    }));

    const [languages, updateLanguage] = useState(getLanguages());

    const changeLanguage = async (lang: string) => {
        i18n.changeLanguage(lang, () => updateLanguage(getLanguages));
        document.documentElement.lang = lang;
        await Preferences.set({'key': 'i18nextLng', 'value': lang});
        props.onClose();
    };

    return (
        <Modal
            isOpen={props.isOpen}
            maxHeight={'25%'}
            onDidDismiss={() => props.onClose()}>

            <StyledModalContent>
                <div className="content full-height">
                    <StyledDataTable className="no-margin">
                        { languages.map((data:ILanguage, i:any) => <div key={i} className="data-row" onClick={() => changeLanguage(data.lang)}>
                            <div className="data-icon-title">{data.name}</div>
                            <div className="data-action">
                                {data.selected && <img src={VectorImage} />}
                            </div>
                        </div>)
                        }
                    </StyledDataTable>
                </div>
            </StyledModalContent>
        </Modal>
    );
};

export default LanguageSwitcherModal;