import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {IonAlert, useIonViewWillLeave} from '@ionic/react';
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import {Toast} from "@capacitor/toast";
import moment, {Moment} from "moment";
import {Order} from "../reportPage.component";

type QrScannerModalProps = {
    isOpen: boolean;
    order?: Order;
    onClose: () => void;
    onSubmit: (value: string) => void;
};

const SimpleScannerModal: React.FC<QrScannerModalProps> = (props: QrScannerModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [scanStartAt, setScanStartAt] = useState<Moment|undefined>();
    const [scanIntervalAt, setScanIntervalAt] = useState<Moment|undefined>();

    const handleClose = () => {
        props.onClose();
        stopScan();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
        if (props.isOpen) {
            setScanStartAt(moment());
            setScanIntervalAt(moment());
            scanLabel();
        }
    };

    const onSubmit = async (value: string) => {
        props.onSubmit(value);
    };

    let scanTimeout: NodeJS.Timeout | undefined;
    useEffect(() => {
        checkScanInterval();
        if (scanTimeout) clearInterval(scanTimeout);

        if (scanStartAt) {
            scanTimeout = setInterval(checkScanInterval, 1000);
        }

        return () => {
            if (scanTimeout) clearInterval(scanTimeout);
        };
    }, [scanIntervalAt])

    const checkScanInterval = () => {
        let now = moment();
        if (scanStartAt && now.diff(scanStartAt, 's') > (props.order?.ep2_scanner_timeout ? parseInt(props.order.ep2_scanner_timeout) : 15)) {
            stopScan();
        }
    }

    useIonViewWillLeave(() => {
        stopScan();
    });

    const startScan = async () => {
        let scanStarted:any = undefined;
        let scanned:string|null = null;

        document.body.classList.add('scanner-active');
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        BarcodeScanner.startScanning({
        }, (value) => {
            let now = moment();
            if (scanStarted === undefined) {
                scanStarted = now;
                setScanStartAt(now)
            }

            if (value.hasContent && value.content) {
                onSubmit(value.content);
                stopScan();
            }

            setScanIntervalAt(moment())

            if (now.diff(scanStarted, 's') > (props.order?.ep2_scanner_timeout ? parseInt(props.order.ep2_scanner_timeout) : 15)) {
                stopScan();
            }
        });

        setTimeout(() => {
            BarcodeScanner.enableTorch();
        }, 1000);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
        BarcodeScanner.disableTorch();

        document.body.classList.remove('scanner-active');

        setScanStartAt(undefined)
        setScanIntervalAt(undefined)
        props.onClose();
        if (scanTimeout) clearInterval(scanTimeout);
    };

    const scanLabel = async () => {
        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.scanningLabel'),
            duration: 'short',
            position: 'center',
        });

        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.swipeDownToClose'),
            duration: 'short',
            position: 'center',
        });

        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }

        startScan();
    }

    return (
        <Modal
            isOpen={showModal}
            disableClose={false}
            onDidDismiss={() => handleClose()}>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default SimpleScannerModal;