import React from 'react';
import ArrowRightImage from '../../../../assets/images/arrow-right.svg';
import {Contract as ContractModel} from "../../../../models/contract";
import {useTranslation} from "react-i18next";
import {StyledRegulationContainer, StyledRegulationContent} from "./regulation.style";
import {ContractFile} from "../../../../models/contractFile";
import TruncateText from "../../../../components/truncateText/truncateText.component";

type RegulationProps = {
    onClick: () => void;
    contract?: ContractModel;
    file?: ContractFile;
}

const Regulation: React.FC<RegulationProps> = (props: RegulationProps) => {
    const {t} = useTranslation();

    return (
        <StyledRegulationContainer onClick={() => props.onClick()}>
            <StyledRegulationContent>
                <div className="title">
                    <TruncateText width={250}>{props.file ? props.file.original_name : t("contractPage.regulationPdf")}</TruncateText>
                </div>
            </StyledRegulationContent>
            <img className="open" src={ArrowRightImage}/>
        </StyledRegulationContainer>
    );
};

export default Regulation;