import styled from 'styled-components';
import { StyledActionSheetContainer } from '../../../../components/modal/modal.style';

export const StyledAvailabilityExActionSheetContainer = styled(StyledActionSheetContainer)`
  padding: 0;
  padding-bottom: 32px;
  align-items: start;

  .grabber {
    flex-shrink: 0;
  }

  .availability-content {
    padding: 0 24px;
    flex-shrink: 1;
    overflow: auto;
    width: 100%;
  }

  form {
    width: 100%;
  }

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 0;
    padding-left: 5px;
  }

  .label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    padding-left: 5px;
  }

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .time-range {
    transition: height 300ms;
    overflow: hidden;

    &.visible {
      display: block;
      height: 95px;
    }

    &.hidden {
      display: block;
      height: 0;
    }
  }

  .reason-wrapper {
    padding: 0 5px;
    
    .reason {
      margin-top: 16px;
      width: 100%;
      height: 212px;
    }
  }

`;
