import React from 'react';
import {IonCol, IonGrid, IonRow, IonSkeletonText} from "@ionic/react";
import {StyledInvitation} from "../planningTab.style";
import {StyledTaskDate} from "../../../../timetablePage/components/taskItem/taskItem.style";

const InvitationSkeleton: React.FC = () => {
    return (
        <StyledInvitation>
            <IonGrid>
                <IonRow>
                    <IonCol size="12" class="invitation-title">
                        <IonSkeletonText animated style={{ width: (Math.random()>=0.5) ? '70%' : '50%' }} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="5">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="5">
                                    <StyledTaskDate>
                                        <span className="day"><IonSkeletonText animated style={{ width: '50%' }} /></span>
                                        <span className="month"><IonSkeletonText animated style={{ width: '50%' }} /></span>
                                    </StyledTaskDate>
                                </IonCol>
                                <IonCol size="0.5">
                                    <span className="separator">-</span>
                                </IonCol>
                                <IonCol size="5">
                                    <StyledTaskDate>
                                        <span className="day"><IonSkeletonText animated style={{ width: '50%' }} /></span>
                                        <span className="month"><IonSkeletonText animated style={{ width: '50%' }} /></span>
                                    </StyledTaskDate>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                    <IonCol size="7" class="content">
                        <div className="place">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                        <div className="contractor">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="status-title border">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="members">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="status-title">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <div className="members">
                            <IonSkeletonText animated style={{ width: '50%' }} />
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </StyledInvitation>
    );
};

export default InvitationSkeleton;
