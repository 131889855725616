import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {
    AccordionGroupCustomEvent,
    IonAccordion,
    IonAccordionGroup,
    IonButtons,
    IonHeader,
    IonItem,
    IonLabel
} from "@ionic/react";
import RecruitmentImage from '../../../assets/images/recruitment.svg';
import ExactPeopleImg from "../../../assets/images/logo/xpeople.png";
import {
    RecruitmentPathResult, RecruitmentPathResults,
    RecruitmentPathTestResult,
    RecruitmentPathVideo
} from "../../../models/recruitmentPathData";
import ReactPlayer from "react-player";
import {StyledFooter} from "../../../components/pane/pane.style";
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from "../../../components/button/button.style";
import {StyledContent} from "./test3Modal.style"
import {fi} from "date-fns/locale";
import {finishPath, historyInsert, updatePathResult, updatePathResults} from "../../../services/recruitment.service";
import {RecruitmentPathTestType} from "../../../enums/recruitmentPathTestType";
import BackButtonImage from "../../../assets/images/recruitment/back.svg";
import {logout, useAuth} from "../../../services/auth.service";
import {Links} from "../../links";
import {useHistory} from "react-router-dom";
import {StyledIonTitle, StyledIonToolbar} from "../../../components/content/content.style";
import {StyledIonRecruitmentToolbar} from "../panes/recruitmentPathPane.style";
import StaticPane from "../../../components/pane/static-pane.component";
import {Pan} from "hammerjs";
import Pane from "../../../components/pane/pane.component";
import {App, BackButtonListener, BackButtonListenerEvent} from '@capacitor/app';
import {Preferences} from "@capacitor/preferences";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type Test3ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    tests: RecruitmentPathVideo[];
    results: [];
    hash: string;
    recruitmentPathCompleted: boolean;
};

const Test3Modal: React.FC<Test3ModalProps> = (props: Test3ModalProps) => {

    const testType = RecruitmentPathTestType.TEST_3;

    const {t} = useTranslation();
    const history = useHistory();

    const [showModal, updateShowModal] = useState<boolean>(false);
    const [currentTest, updateCurrentTest] = useState<RecruitmentPathVideo|null>(null);
    const [currentTestNumber, updateCurrentTestNumber] = useState<number>(0);
    const [finishedTests, updateFinishedTests] = useState<number[]>(Object.values(props.results));
    const [showSummary, updateShowSummary] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const auth = useAuth();

    const header = useRef<HTMLIonHeaderElement>(null);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowModal(props.isOpen);
        updateCurrentTest(props.tests[currentTestNumber]);
    };

    const exitRecruitment = () => {
        props.onClose();
        updateCurrentTestNumber(0);
    }

    const insertHistory = async(data: {}, hash:string) => {
        await historyInsert(data, hash).then(response => {
        })
            .catch(error => {
                // todo what we show??
            });
    }

    const onPlay = () => {
        //
    }

    const onEnded = (id:number) => {
        let finished = [...finishedTests, id];
        updateFinishedTests(finished);
        sendResult({type: testType, result: finished});
        insertHistory({result: true, id: id, type: RecruitmentPathTestType.TEST_3}, props.hash);
    }

    const testPassed = (id:number) => {
        let result:boolean = false;

        if (finishedTests.indexOf(id) != -1) {
            result = true;
        }

        return result;
    }

    const finishRecruitmentPath = async () => {
        await Preferences.set({
            'key': 'recruitment_path_completed',
            'value': '1',
        });

        finishPath(props.hash);
    }

    useEffect(() => {
        if (allTestsPassed()) {
            closeAccordion();
            finishRecruitmentPath();
        }
    }, [finishedTests]);

    const allTestsPassed = () => {
        return props.tests.length > 0 && props.tests.length == finishedTests.length;
    }

    const goToSummary = () => {
        updateShowSummary(true);
    }

    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    const closeAccordion = () => {
        if (!accordionGroup.current) {
            return;
        }
        const nativeEl = accordionGroup.current;
        nativeEl.value = undefined;
    };

    const getActiveAccordion = () => {
        if (finishedTests.length == 0) {
            return '0';
        }

        for (const [key, video] of Object.entries(props.tests)) {
            if (finishedTests.indexOf(video.id) == -1) {
                return key;
            }
        }

        return '0';
    };

    const sendResult = async (result: RecruitmentPathResults) => {
        setLoading(true);
        await updatePathResults(result, props?.hash)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                // todo what we show??
            });

        setLoading(false);
    }

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            maxHeight={'80%'}
            onDidDismiss={() => handleClose()}>
            <IonHeader ref={header} className="ion-no-border">
                <StyledIonRecruitmentToolbar className="white">
                    <IonButtons slot="start">
                        <StyledHeaderButton onClick={() => handleClose()}>
                            <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                        </StyledHeaderButton>
                    </IonButtons>
                    <StyledIonTitle>
                        <div className="logo">
                            <img src={ExactPeopleImg} />
                        </div>
                    </StyledIonTitle>>
                </StyledIonRecruitmentToolbar>
            </IonHeader>
            <StyledContent>
                { showSummary == false && <>
                    <div className="content full-height">
                        <h1 className="blue">{t("recruitment.recruitmentPath.test3.video.title")}</h1>
                        <Pane marginTop={15} paddingBottom={187}>
                        {
                            props.tests.length > 0 && currentTest && <IonAccordionGroup ref={accordionGroup} value={getActiveAccordion()}>
                                {props.tests.map((testEntry: RecruitmentPathVideo, key) => {
                                    return <IonAccordion value={key as unknown as string} className="accordion">
                                        <IonItem slot="header" className={testPassed(testEntry.id) == true ? "item item-watched" : "item item-to-watch"}>
                                            <IonLabel>
                                                <span className="number">{key + 1}</span>
                                                <span className="title">{testEntry.short_header}</span>
                                            </IonLabel>
                                        </IonItem>
                                        <div className="ion-padding item-content" slot="content">
                                            <ReactPlayer url={"https://vimeo.com/" + testEntry.url}
                                                         controls={true} width="100%" height="200px"
                                                         onPlay={onPlay}
                                                         onEnded={() => onEnded(testEntry.id)}
                                                         light={true}
                                            />
                                        </div>
                                    </IonAccordion>
                                })}
                            </IonAccordionGroup>
                        }
                        </Pane>
                    </div>

                    <StyledFooter className="modal no-shadow">
                        <StyledButton
                            onClick={() => goToSummary()} disabled={!allTestsPassed() || props.recruitmentPathCompleted}>{t("recruitment.recruitmentPath.test3.video.summary")}</StyledButton>
                    </StyledFooter></>
                }

                {
                    showSummary == true && <div>
                        <h1 className="title full">{t("recruitment.recruitmentPath.test3.summary.title")}</h1>
                        <div className="content full-height" dangerouslySetInnerHTML={{__html: t("recruitment.recruitmentPath.test3.summary.content") || ''}}/>

                        <StyledFooter className="modal no-shadow">
                            <StyledButton
                                onClick={() => history.replace(Links.main + Links.profile)}>{t("recruitment.recruitmentPath.test3.summary.finish")}</StyledButton>
                        </StyledFooter>
                    </div>
                }
            </StyledContent>
        </Modal>
    );
};

export default Test3Modal;