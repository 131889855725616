import styled from 'styled-components';

export const StyledWorkerActionSheetContainer = styled.div`
  padding: 40px 40px 49px 40px;

  .image {
    margin-bottom: 20px;
  }

  @keyframes show {
    from {
      bottom: -200%;
    }
    to {
      bottom: 0;
    }
  }
`;