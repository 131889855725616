import {AuditReportPenaltyType} from "../../enums/audit";
import {ProfileType} from "../../enums/profileType";
import {AuditReportFormModel, AuditReportPenalty} from "../../models/auditReport";
import {DataSelectable} from "../../models/common";
import {
    getPenaltyProfiles,
    getPlaceOfServiceCompanyAdditionalEmails,
    getPlaceOfServicesProfiles,
    getPlaceOfServicesProjects,
    getWorkers
} from "../../services/audit.service";
import moment from "moment";

export const loadPlaceOfServicesProfiles = async (report: AuditReportFormModel, page: number, searchText?: string) => {
    if (report.placeOfService) {
        let data:DataSelectable[] = await getPlaceOfServicesProfiles(report.placeOfService.id.toString(), page, searchText).then((response) => {
            return response.data as DataSelectable[];
        });

        return data;
    } else {
        return [];
    }
}

export const loadWorkers = async (report: AuditReportFormModel, page: number, searchText?: string) => {
    if (report.project && report.date) {
        let data:DataSelectable[] = await getWorkers(report.project.id.toString(), moment(report.date).format('DD-MM-YYYY'), page, searchText).then((response) => {
            return response.data as DataSelectable[];
        });

        return data;
    } else {
        return [];
    }
}

export const loadPlaceOfServicesProjects = async (report: AuditReportFormModel, page: number, searchText?: string) => {
    if (report.placeOfService) {
        let data:DataSelectable[] = await getPlaceOfServicesProjects(report.placeOfService.id.toString(), page, searchText).then((response) => {
            return response.data as DataSelectable[];
        });

        return data;
    } else {
        return [];
    }
}

export const loadPlaceOfServiceCompanyAdditionalEmails = async (report: AuditReportFormModel, page: number, searchText?: string) => {
    if (report.placeOfService) {
        return await getPlaceOfServiceCompanyAdditionalEmails(report.placeOfService.id.toString(), page, searchText).then((response) => {
            return response.data as DataSelectable[];
        });
    } else {
        return [];
    }
}

export const loadPenaltyWorkerProfiles = async (report:AuditReportFormModel, page:number, searchText?: string) => {
    return new Promise<DataSelectable[]>( (resolve, reject) => {
        const workers = report.workers || [];
        const filterToWorker = workers.filter( (worker) => {
            return report.userPenalties?.findIndex( penalty => penalty.type === AuditReportPenaltyType.WORKER && penalty.person?.id === worker.id) === -1;
        })
        resolve(filterToWorker || [])
    });
}

export const loadPenaltyCoordinatorProfiles = async (report:AuditReportFormModel, page:number, searchText?: string) => {
    const result = await getPenaltyProfiles(ProfileType.COORDINATOR, page, searchText);
    return result.data;
}

export const loadPenaltyTeamLeaderProfiles = async (report:AuditReportFormModel, page:number, searchText?: string) => {
    const result = await getPenaltyProfiles(ProfileType.TEAM_LEADER, page, searchText);
    return result.data;
}

export function getPenalties(type:AuditReportPenaltyType, report:AuditReportFormModel) {
    return report.userPenalties?.filter((penalty) => penalty.type === type) || [];
}

export function getPenaltiesItems(type:AuditReportPenaltyType, report:AuditReportFormModel) {
    let penalties:AuditReportPenalty[] = [];
    report.userPenalties?.filter((penalty) => penalty.type === type).forEach((val) => {
        val.penalties.forEach((penalty) => {
            penalties.push(penalty);
        })
    })

    return penalties;
}