import styled from 'styled-components';
import { StyledActionSheetContainer } from '../../../../components/modal/modal.style';

export const StyledAttachementsActionSheetContainer = styled(StyledActionSheetContainer)`

  text-align: center;
  
  span.name {
    margin-top: 28px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
  }

  span.date {
    margin-bottom: 28px
  }
`;
