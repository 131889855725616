import styled from "styled-components";


export const StyledContent = styled.div`
    margin-top: 20px;
  
    h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #000000;
        padding: 0 24px;
    
        &.blue {
            color: #0F1664;
        }
    }

    .box {
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        margin-top: 50px;

        .back-button {
          width: 40px;
          margin-top: 5px;
        }

      .push {
        text-align: center;
        background-color: #ffc409;
      }

      .logo {
        text-align: center;
        margin-bottom: 50px;

        img {
          width: 100%;
          height: 44px;
        }
      }
    }

    .logo {
      text-align: center;
    
      img {
        width: 100%;
        height: 44px;
      }
    }

  .title {
    text-align: left;

    &.full {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0 !important;
    }
  }
  
  .justify {
    text-align: justify;
  }
  
  .content {
    margin-top: 20px;
  }
`