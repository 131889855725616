import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {
    App
} from "@capacitor/app";
import {Links} from "../app/links";

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (data: any) => {
            if (data.url.indexOf('exactpeople.com/hash/') != -1) {
                let hash: string = data.url.split('/').pop();
                history.push(Links.recruitment + '/' + hash);
            } else {
                const slug = data.url.split(':/').pop();
                if (slug) {
                    history.push(slug);
                }
            }
        });
    }, []);

    return null;
};

export default AppUrlListener;