import React from 'react';

type FormProps = {
    children: React.ReactChild | React.ReactChild[];
    className?: string;
    onSubmit?: () => void;
}
const Form: React.FC<FormProps> = ({ onSubmit, children, className }) => {
    return (
        <form className={className} onSubmit={onSubmit}>
            {children}
        </form>
    )
}

export default Form;