import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import {StyledAvailabilityOption, StyledDetailViewContainer, StyledNormalViewContainer} from './availability.style';
import SunImage from '../../../assets/images/sun.svg';
import MoonImage from '../../../assets/images/night.svg';
import FogImage from '../../../assets/images/sun-fog-29.svg';
import Form from '../../../components/form';
import AddImage from '../../../assets/images/e-add.svg';
import PenImage from '../../../assets/images/pen-01.svg';
import {StyledCircleButton, StyledWiteButton} from '../../../components/button/button.style';
import {Links} from '../../links';
import useNavigation from '../../../services/navigation.service';
import {AvailabilitiesType} from "../../../enums/availabilitiesType";
import {getAvailabilities, sendAvailabilities} from "../../../services/availability.service";
import {useLocation} from "react-router-dom";
import {IonAlert, IonSkeletonText, IonToast, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import Info from "../../../components/info/info.component";
import {getAvailabilitiesMemo, setConfiguration, setAvailabilities} from '../../../services/editDailyAvailabilityPage.service';
import {log} from "../../../services/firebaseAnalytics.service";
import AvailabilityGuideModal from "../modals/availabilityGuideModal.component";

type AvailabilityPaneProps = {
    topEdge?: number;
}

enum AvailabilityOption {
    NORMAL,
    DETAIL
}

type NormalOptions = {
    hours: string;
    icon: any;
}

interface AvailabilityDay {
	day: number;
	start_time: string;
	end_time: string;
}

interface LocationState {
	toastInit: string;
	showToastInit: boolean;
}

let savedAvailabilities: any[] = [];

const AvailabilityPane: React.FC<AvailabilityPaneProps> = (props: AvailabilityPaneProps) => {
    const dayParts: NormalOptions[] = [
        { icon: FogImage, hours: '06:00 - 14:00' },
        { icon: SunImage, hours: '14:00 - 22:00' },
        { icon: MoonImage, hours: '22:00 - 06:00' }
    ];

	const weekDays = [
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday'
	];

    const normalViewSaveMessage = 'profileTab.availability.normalViewSave';
    const detailViewSaveMessage = 'profileTab.availability.detailViewSave';
    const normalViewAvailabilities = Array(dayParts.length).fill(new Array()).map(() => Array(weekDays.length).fill(false));
    const detailViewAvailabilities = Array(weekDays.length).fill('');

    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [ option, updateOption ] = useState<AvailabilityOption>(parseInt(navigation.getParam<string>('optionAvailability')) || AvailabilityOption.NORMAL);
    const [ selectedNormalAvailability, updateSelectedNormalAvailability ] = useState<boolean[][]>(Array(dayParts.length).fill(new Array()).map(() => Array(weekDays.length).fill(false)));
    const [ detailAvailability, updateDetailAvailability ] = useState<string[]>([...Array(weekDays.length).fill('')]);
	const [ availabilitiesType, setAvailabilitiesType ] = useState<string>('');
	const [ switchAvailabilitiesTypeMessage, setSwitchAvailabilitiesTypeMessage ] = useState<string>('');

	const location = useLocation<LocationState>();
	const { toastInit, showToastInit } = location.state || {toastInit: "", showToastInit: false};
	const [ showToast, setShowToast ] = useState(showToastInit);
	const [ addException, setAddException ] = useState(false);
	const [ toast, setToast ] = useState<any>(toastInit);
	const [ showAlert, setShowAlert ] = useState(false);
	const [ showBlockingAlert, setShowBlockingAlert ] = useState(false);
	const [ blockingAlertResult, setBlockingAlertResult ] = useState<boolean | undefined>(undefined);
	const [ formIsDirty, setFormIsDirty ] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showGuide, updateShowGuide] = useState<boolean>(false);

	const [showSuccessToast, setShowSuccessToast] = useState(false);
	const [successToast, setSuccessToast] = useState<any>('');

	const editAvailability = (day: string, availabilities: string, index: number) => {
		setConfiguration({
			availabilities,
			handleSave: (availabilities: string) => {
				detailAvailability[index]= availabilities;
				updateDetailAvailability(detailAvailability);
				setAvailabilities(detailAvailability);
				history.goBack();
			}
		});
		navigation.setDestinationUrl(Links.editDailyAvailability);
        history.push(Links.editDailyAvailability, { title: day });
    };

    const selectAvailabilityTab = (option: AvailabilityOption) => {
		if (checkBlockingCondition()) {
			navigation.setBlockNavigation(undefined);
			navigation.setParam('optionAvailability', option.toString());
			updateOption(option);
		} else {
			navigation.setDestinationUrl(navigation.getParamUrl('optionAvailability', option.toString()))
		}
    }

	const navigateToExceptions = () => {
		history.push(Links.absences);
	};

	const checkBlockingCondition = (): boolean => {
		const blockNavigationFunction = navigation.getBlockNavigation();

		return typeof blockNavigationFunction === 'undefined' ||
		(typeof blockNavigationFunction === 'function' && !blockNavigationFunction())
	}

    const resolveAvailabilityDayPart = (hour:string) => {
    	if (hour == '06:00') {
    		return 0;
		} else if (hour == '14:00') {
    		return 1;
		} else {
    		return 2;
		}
	}

	const fetchAvailabilities = async () => {
		setLoading(true);
		savedAvailabilities = [];
		const response = await getAvailabilities()
			.then(response => {
				let data = response.data;

				if (data.type == AvailabilitiesType.NORMAL) {
					selectAvailabilityTab(AvailabilityOption.NORMAL);
					setAvailabilitiesType(AvailabilityOption.NORMAL.toString());
					setSwitchAvailabilitiesTypeMessage(detailViewSaveMessage);
					setAvailabilities(undefined);

					let normalAvailabilities = normalViewAvailabilities;
					if (data.availabilities && data.availabilities.length > 0) {
						data.availabilities.forEach((availability: AvailabilityDay) => {
							let day = availability.day;
							let dayPart = resolveAvailabilityDayPart(availability.start_time);
							normalAvailabilities[dayPart][day - 1] = true;
						});

						updateSelectedNormalAvailability(normalAvailabilities);
						savedAvailabilities = [...normalAvailabilities.map((row) => [...row])];
					}
				} else {
					selectAvailabilityTab(AvailabilityOption.DETAIL);
					setAvailabilitiesType(AvailabilityOption.DETAIL.toString());
					setSwitchAvailabilitiesTypeMessage(normalViewSaveMessage);

					let detailAvailabilities = detailViewAvailabilities;
					if (data.availabilities && data.availabilities.length > 0) {
						data.availabilities.forEach((availability: AvailabilityDay) => {
							let day = availability.day;
							let hours = availability.start_time + " - " + availability.end_time;
							detailAvailabilities[day - 1] += detailAvailabilities[day - 1] == '' ? hours : ', ' + hours;
						});

						updateDetailAvailability(detailAvailabilities);
						setAvailabilities(detailViewAvailabilities);
						savedAvailabilities = [...detailViewAvailabilities];
					}
				}
			})
			.catch(error => {
				// todo what we show??
			});
		setLoading(false);
	};

	const updateAvailabilities = async () => {

		setLoading(true);

		let data : { [id: string]: any; } = {};
		let availabilities = new Array();

		if (option == AvailabilityOption.NORMAL) {
			if (selectedNormalAvailability.length > 0) {
				selectedNormalAvailability.forEach((days, index) => {
					let hours = dayParts[index].hours.split(" - ");

					days.forEach((day, index) => {
						if (day == true) {
							let el : { [id: string]: any; } = {};
							el['day'] = index + 1;
							el['startTime'] = hours[0];
							el['endTime'] = hours[1];
							availabilities.push(el);
						}
					});
				});
			}

			data['type'] = AvailabilitiesType.NORMAL;
		} else {
			if (detailAvailability.length > 0) {
				detailAvailability.forEach((availability, index) => {
					let dailyHours = availability.split(", ");
					dailyHours.forEach((hours) => {
						let time = hours.split(" - ");

						if (time.length > 1) {
							let el : { [id: string]: any; } = {};
							el['day'] = index + 1;
							el['startTime'] = time[0];
							el['endTime'] = time[1];
							availabilities.push(el);
						}
					});
				});
			}

			data['type'] = AvailabilitiesType.DETAIL;
		}

		data['availabilities'] = availabilities;

		const response = await sendAvailabilities(data)
			.then(response => {
				setSuccessToast(t("profileTab.availability.updated"));
				setShowSuccessToast(true);
				if (data['type'] == AvailabilitiesType.NORMAL) {
					setAvailabilitiesType(AvailabilityOption.NORMAL.toString());
					setSwitchAvailabilitiesTypeMessage(detailViewSaveMessage);
					updateDetailAvailability(detailViewAvailabilities);
					savedAvailabilities = [...selectedNormalAvailability.map((row) => [...row])];
					setAvailabilities(undefined);
				} else {
					setAvailabilitiesType(AvailabilityOption.DETAIL.toString());
					setSwitchAvailabilitiesTypeMessage(normalViewSaveMessage);
					updateSelectedNormalAvailability(normalViewAvailabilities);
					savedAvailabilities = [...detailAvailability];
					setAvailabilities(undefined);
				}
				return true;
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
				return false;
			});

		setLoading(false);
	};

	useEffect(() => {
		const urlOpstionString = navigation.getParam<string>('optionAvailability');

		if (!urlOpstionString) {
			selectAvailabilityTab(availabilitiesType === AvailabilityOption.NORMAL.toString() ? AvailabilityOption.NORMAL : AvailabilityOption.DETAIL);
			return;
		}

		const urlOption = parseInt(urlOpstionString) || AvailabilityOption.NORMAL;

		if (urlOption !== option) {
			updateOption(urlOption);
		}
	}, [location]);

	const saveAvailabilities = () => {
		if (availabilitiesType !== option.toString() ) {
			setShowAlert(true);
		} else {
			updateAvailabilities();
		}
	}

	useEffect(() => {
		if (!getAvailabilitiesMemo()) {
			fetchAvailabilities();
		} else {
			updateDetailAvailability(getAvailabilitiesMemo() ?? []);
			setAvailabilitiesType(AvailabilityOption.DETAIL.toString());
		}

		return () => { savedAvailabilities = [] };
	}, []);

	useEffect(() => {
		navigation.setBlockNavigation(() => {
			if (hasChanges() &&
				navigation.getDestinationUrl() !== Links.editDailyAvailability &&
				location.pathname !== Links.editDailyAvailability) {

				setShowBlockingAlert(true);

				return true;
			}

			return false;
		});
	});

	useEffect(() => {
		if (blockingAlertResult === undefined) {
			return;
		}

		setShowBlockingAlert(false);
		setBlockingAlertResult(undefined);

		if (blockingAlertResult === true) {
			if (navigation.getUnblockNavigation() !== undefined) {
				navigation.getUnblockNavigation();
			}

			resetChanges();
			navigation.setBlockNavigation(undefined);
			navigation.setUnblockNavigation(undefined);
			history.push(navigation.getDestinationUrl());
		}
	}, [blockingAlertResult])

	const resetChanges = () => {
		const normalAvailabilities = availabilitiesType === AvailabilityOption.NORMAL.toString();

		updateSelectedNormalAvailability(normalAvailabilities ?

			[...savedAvailabilities.map((row) => [...row])] :
			Array(dayParts.length).fill(new Array()).map(() => Array(weekDays.length).fill(false)));

		updateDetailAvailability(!normalAvailabilities ?
			[...savedAvailabilities] :
			[...Array(weekDays.length).fill('')]);

		setAvailabilities(undefined);
	}

	const hasChanges = (): boolean => {
		if (detailAvailability.findIndex((da) => da) !== -1 &&
			selectedNormalAvailability.findIndex((na) => !na.every((nad) => !nad)) !== -1) {
			return true;
		}

		if (savedAvailabilities.length === 0) {
			return false;
		}

		if (availabilitiesType === AvailabilityOption.NORMAL.toString()) {
			const changes = selectedNormalAvailability.every((val, index) => {
				return val.every((detailVal, detailIndex) => {
					return savedAvailabilities[index][detailIndex] === detailVal;
				});
			});

			return !changes;
		} else {
			const changes = detailAvailability.every((val, index) => {
				return savedAvailabilities[index] === val;
			});

			return !changes;
		}
	}

	useEffect(() => {
		log('page_visit', {
			page: 'Profil -> Dostępność'
		});
	}, []);

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={300}>
			<IonAlert
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header={t('profileTab.availability.alert.leaveChangedAvailabilitiesPageTitle')}
				message={t(switchAvailabilitiesTypeMessage)}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowAlert(false)
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							setShowAlert(false);
							updateAvailabilities();
						}
					}
				]}
			/>

			<IonAlert
				isOpen={showBlockingAlert}
				onDidDismiss={() => setBlockingAlertResult(false)}
				header={t('profileTab.availability.alert.leaveChangedAvailabilitiesPageTitle')}
				message={t('profileTab.availability.alert.resetChangedAvailabilitiesPageMessage')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setBlockingAlertResult(false);
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							setBlockingAlertResult(true);
						}
					}
				]}
			/>

			<span onClick={() => updateShowGuide(true)}><Info>{t("profileTab.availability.info")}</Info></span>
			<StyledAvailabilityOption style={{"marginTop": "10px"}}>
                <div onClick={() => selectAvailabilityTab(AvailabilityOption.NORMAL)}
                     className={option === AvailabilityOption.NORMAL ? 'selected' : ''}>{t('profileTab.availability.normalView')}</div>
                <div onClick={() => selectAvailabilityTab(AvailabilityOption.DETAIL)}
                     className={option === AvailabilityOption.DETAIL ? 'selected' : ''}>{t('profileTab.availability.detailView')}</div>
            </StyledAvailabilityOption>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message={toast}
				duration={4000}
				position="top"
				color="danger"
			/>
			<IonToast
				isOpen={showSuccessToast}
				onDidDismiss={() => setShowSuccessToast(false)}
				message={successToast}
				duration={4000}
				position="top"
				color="medium"
			/>
			{
				!loading && availabilitiesType != option.toString() &&
					<Info className="error">{t(switchAvailabilitiesTypeMessage)}</Info>
			}

			{
                !loading && option === AvailabilityOption.NORMAL &&
                <StyledNormalViewContainer> {
                    dayParts.map((part, idx) =>
                        <div key={idx} className="column">
                            <img src={part.icon} />
                            <div className="hours-interval">
                                {part.hours}
                            </div>
                            {
                                weekDays.map((day, index) =>
                                    <div key={index}
                                            onClick={() => {
                                            selectedNormalAvailability[idx][index] = !selectedNormalAvailability[idx][index];
                                            updateSelectedNormalAvailability([...selectedNormalAvailability]);
											}}
                                            className={`hour ${index === 0 ? 'first': ''} ${index === (weekDays.length - 1) ? 'last': ''} ${selectedNormalAvailability[idx][index] === true ? 'selected' : ''}` }>
                                                {t('profileTab.availability.' + day)}</div>
                                )
                            }
                        </div>
                    )
                }
                </StyledNormalViewContainer>
            }

			{
				loading && option === AvailabilityOption.NORMAL &&
				<StyledNormalViewContainer> {
					dayParts.map((part, idx) =>
						<div key={idx} className="column">
							<img src={part.icon} />
							<div className="hours-interval">
								{part.hours}
							</div>
							{
								weekDays.map((day, index) =>
									<div key={index}
										 onClick={() => {
											 selectedNormalAvailability[idx][index] = !selectedNormalAvailability[idx][index];
											 updateSelectedNormalAvailability([...selectedNormalAvailability]);
										 }}
										 className={`hour ${index === 0 ? 'first': ''} ${index === (weekDays.length - 1) ? 'last': ''}` }>
										<IonSkeletonText className="availability-day-skeleton" animated style={{ width: '70%' }} /></div>
								)
							}
						</div>
					)
				}
				</StyledNormalViewContainer>
			}

			{
				loading && option === AvailabilityOption.DETAIL &&
				<StyledDetailViewContainer>
					{
						weekDays.map((day, index) => {
							return <div key={index} className="option">
								<IonSkeletonText animated style={{ width: (Math.random()>=0.5) ? '55%' : '45%' }} />
								<IonSkeletonText animated style={{ width: '10%' }} />
							</div>
						})
					}
				</StyledDetailViewContainer>
			}

            {
                !loading && option === AvailabilityOption.DETAIL &&
                <StyledDetailViewContainer>
                    {
                        weekDays.map((day, index) => {
                            if (detailAvailability[index]) {
                                return <div key={index} className="option filled">
                                        <div>
                                            {t('profileTab.availability.' + day)}
                                            <div className="details">{detailAvailability[index]}</div>
                                        </div>
                                        <StyledCircleButton onClick={() => editAvailability(t('profileTab.availability.' + day), detailAvailability[index], index)}><img src={PenImage} /></StyledCircleButton>
                                    </div>
                            } else {
                                return <div key={index} className="option">{t('profileTab.availability.' + day)} <StyledCircleButton onClick={() => editAvailability(t('profileTab.availability.' + day), '', index)}><img src={AddImage} /></StyledCircleButton></div>
                            }
                        })
                    }
                </StyledDetailViewContainer>
            }

			{
				!loading &&
				<StyledWiteButton onClick={() => {
					navigateToExceptions();
				}}>
					{t('profileTab.availability.absences')}
				</StyledWiteButton>
			}

			{
				loading &&
				<StyledWiteButton className="greyedout">
					<small style={{width: '50%'}}><IonSkeletonText animated/></small>
				</StyledWiteButton>
			}

			{
				!loading &&
				<Form.Button onClick={() => saveAvailabilities()}>
					{t('profileTab.availability.save')}
				</Form.Button>
			}

			{
				loading &&
				<Form.Button className="greyedout">
					<small style={{width: '50%'}}><IonSkeletonText animated/></small>
				</Form.Button>
			}

			<AvailabilityGuideModal isOpen={showGuide} onClose={() => updateShowGuide(false)} />
		</Pane>
    );
};

export default AvailabilityPane;
