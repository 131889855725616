import {IonAlert, IonRow} from "@ionic/react";
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import StaticPane from "../../../components/pane/static-pane.component";
import Accordion from "../components/form/accordion";
import {StyledInconsitencySummaryContent} from "./inconcistencyModule.style";
import {InconsistencyRecord} from "../../../models/auditReport";
import {getInconsistencyRecord, resolveInconsistencyRecord} from "../../../services/audit.service";
import {Links} from "../../links";
import {StyledButton} from "../../../components/button/button.style";
import {AuditIncompatibilityActionType} from "../../../enums/audit";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);


type InconsistencyModuleDetailsPaneProps = {
    topEdge: number | undefined;
    id: string
}

const InconsistencyModuleDetailsPane: React.FC<InconsistencyModuleDetailsPaneProps> = (props: InconsistencyModuleDetailsPaneProps) => {
    const { t } = useTranslation();

    const history = useHistory();

    const [record, setRecord] = useState<InconsistencyRecord|undefined>(undefined);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        loadRecord();
    }, []);
    //
    // useIonViewWillEnter(() => {
    //     loadRecord();
    // });

    const loadRecord = async () => {
        const response = await getInconsistencyRecord(props.id)
            .then(response => {
                setRecord(response.data);
            })
            .catch(reason => {
                history.goBack();
            });
    }

    const onConfirm = async () => {
        await resolveInconsistencyRecord(props.id).then((response) => {
            history.replace(Links.main + Links.inconsistencyModule);
        })
    }

    return (
        <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            {record &&
                <Accordion isOpen={true} allowToggle={false} title={record?.audit_report_question.question}>
                    <StyledInconsitencySummaryContent>
                        <IonRow className="summary-label">
                            {t('inconsistency.form.question')}
                        </IonRow>
                        <IonRow className="summary-description">
                            {record?.audit_report_question.question}
                        </IonRow>
                        <IonRow className="summary-label">
                            {t('inconsistency.form.justification')}
                        </IonRow>
                        <IonRow className="summary-description">
                            {record?.audit_report_question.nok_dictionary_item.name}
                        </IonRow>
                        {record?.action_immediate && <>
                            <IonRow className="summary-label">
                                {t('inconsistency.form.action.title')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {t('inconsistency.form.actionType.' + AuditIncompatibilityActionType.IMMEDIATE.toLowerCase())}
                            </IonRow>
                            <IonRow className="summary-label">
                                {t('auditReport.form.description')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {record.action_immediate}
                            </IonRow>
                        </>}

                        {record?.action_corrective && <>
                            <IonRow className="summary-label">
                                {t('inconsistency.form.action.title')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {t('inconsistency.form.actionType.' + AuditIncompatibilityActionType.CORRECTIVE.toLowerCase())}
                            </IonRow>
                            <IonRow className="summary-label">
                                {t('auditReport.form.description')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {record.action_corrective}
                            </IonRow>
                        </>}

                        {record?.action_perfecting && <>
                            <IonRow className="summary-label">
                                {t('inconsistency.form.action.title')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {t('inconsistency.form.actionType.' + AuditIncompatibilityActionType.PERFECTING.toLowerCase())}
                            </IonRow>
                            <IonRow className="summary-label">
                                {t('auditReport.form.description')}
                            </IonRow>
                            <IonRow className="summary-description">
                                {record.action_perfecting}
                            </IonRow>
                        </>}
                        <IonRow className="summary-label">
                            {t('inconsistency.form.user')}
                        </IonRow>
                        <IonRow className="summary-description">
                            {record?.user?.name}
                        </IonRow>
                        <IonRow className="summary-label">
                            {t('inconsistency.form.term')}
                        </IonRow>
                        <IonRow className="summary-description">
                            {record?.action_term ? moment(record.action_term).format('DD.MM.YYYY') : ''}
                        </IonRow>
                        <IonRow>
                            <StyledButton onClick={() => setShowAlert(true)}>
                                {t('inconsistency.form.setAsReady')}
                            </StyledButton>
                        </IonRow>
                    </StyledInconsitencySummaryContent>
                </Accordion>
            }
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('common.alertHeader')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false)
                        },
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: async () => {
                            await onConfirm();
                            setShowAlert(false);
                        }
                    }
                ]}
            />
        </StaticPane>
    );
};

export default InconsistencyModuleDetailsPane;
