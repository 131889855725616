export enum NotificationAction {
    URL = 'url',
    COMMUNICATOR = 'action:communicator',
    CALENDAR = 'action:calendar',
    WALLET = 'action:wallet',
    HOURS_REGISTRY = 'action:hoursregistry',
    NEWS = 'action:news',
    JOB_OFFERS = 'action:joboffers',
    CONTRACTS = 'action:contracts',
    CONTRACT = 'action:contract',
    CONTRACT_APPENDIX = 'action:contract-appendix',
    CONTRACT_FILE = 'action:contract-file',
    PROFILE = 'action:profile',
	TIMETABLE = "action:timetable",
	WAREHOUSE = "action:warehouse",
}
