import React, {PropsWithChildren, useEffect, useState} from "react";
import {IonCol, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";

import {StyledAccordion} from "@app/travelAllowance/travelAllowance.style";

import {ReactComponent as ArrowDownIcon} from "@assets/images/travelAllowance/arrow-down.svg"
import {ReactComponent as ArrowLeftIcon} from "@assets/images/travelAllowance/arrow-left.svg"

export type AccordionProps = {
    title: string,
    isOpen: boolean,
    allowToggle: boolean,
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = (props: PropsWithChildren<AccordionProps>) => {
    const {t} = useTranslation();

    const [isOpen, setOpen] = useState<boolean>(props.isOpen);
    const [title, setTitle] = useState<string>('');

    const toggleAccordion = () => {
        setOpen(prevState => !prevState);
    }

    useEffect(() => {
        setTitle(props.title)
    }, [props.title])

    return <>
        <StyledAccordion>
            <IonRow className="row">
                <IonCol style={{padding: 0, margin: 0}} className="title">{t(title)}</IonCol>
                {props.allowToggle &&
                    <IonCol className="toggle" onClick={toggleAccordion}>
                        {isOpen
                            ? (
                                <StyledAccordion>
                                    <div className="arrow">
                                        <ArrowDownIcon/>
                                    </div>
                                </StyledAccordion>
                            )
                            : (
                                <StyledAccordion>
                                    <div className="arrow">
                                        <ArrowLeftIcon/>
                                    </div>
                                </StyledAccordion>
                            )
                        }
                    </IonCol>}
            </IonRow>
            <IonRow>
                <hr/>
            </IonRow>
            <IonRow className={`content ${isOpen ? 'open' : 'hidden'}`}>
                <IonCol className="title">{props.children}</IonCol>
            </IonRow>
        </StyledAccordion>
    </>
}

export default Accordion;