import {IonCol, IonGrid, IonRow, IonSkeletonText} from '@ionic/react';
import React from 'react';
import CalendarItemImage from "../../../../assets/images/calendar-item.svg";

type TaskItemSkeletonProps = {
	size: string,
	offset: string
}

const CalendarItemSkeleton: React.FC<TaskItemSkeletonProps> = (props: TaskItemSkeletonProps) => {

    return (
		<IonCol size={props.size ?? "1"} offset={props.offset ?? "0"}>
			<IonSkeletonText animated style={{height: '30px'}} />
		</IonCol>
    );
};

export default CalendarItemSkeleton;
