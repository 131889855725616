import styled from 'styled-components';

export const StyledTaskItemContainer = styled.div`
  border-bottom: 1px solid #F6F6F8;
  padding: 25px 0;
  width: 100%;

  ion-grid {
    padding: 0;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
  }

  .description {
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;
    margin: 0;
    margin-top: 6px;
  }

  .separator {
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .content-button {
  	overflow: hidden;
  }
`;

export const StyledTaskDate = styled.div`
  width: 100%;
  height: 56px;

  background: #FFFFFF;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .day {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #0085FF;
  }

  .month {
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
    text-transform: capitalize;
  }

  .time {
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    color: #000000;
    text-transform: capitalize;
  }
`;

export const StyledActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  button {
    margin-top: 0;

    &.dismiss {
      margin-top: 8px;
    }
  }
`;
