import {appApi} from "./api.service";
import {Preferences} from "@capacitor/preferences";

let endpointPrefix = 'mobile-menu';

const getMenu = async () => {
    const profileId = await Preferences.get({'key': 'profile_id'});
    const response = await appApi.get(endpointPrefix + '?profileId=' + (profileId.value ? profileId.value : ''));

    if (response.data) {
        const hasTravelAllowanceMenuItem = response.data.some(isTravelAllowanceMenuItem);

        if (hasTravelAllowanceMenuItem) {
            await Preferences.set({'key': 'travel_allowance_enabled', 'value': '1'});
        }
    }

    return response;
}

const isTravelAllowanceMenuItem = (item: any) => {
    const references = [
        'app.mobile_menu.cars',
        'app.mobile_menu.ride_routes',
        'app.mobile_menu.rides'
    ];

    return references.includes(item.reference);
};

export {
    getMenu
};
