import React, {useEffect, useState} from 'react';
import {StyledCalendarContainer} from './calendar.style';
import CalendarItemImage from '../../../../assets/images/calendar-item.svg';
import {IonCol, IonGrid, IonRow, useIonViewWillEnter} from '@ionic/react';
import {getActivities, getActivitiesByDate} from "../../../../services/timetable.service";
import moment from "moment";
import {Task, TaskStatus} from "../taskItem/taskItem.component";
import PreviewTaskItemsModal from "../../modals/previewTaskItemsModal.component";
import {StyledIonDatetime} from "../../../../components/form/input/date.style";
import {getI18n, useTranslation} from "react-i18next";
import DateInput from "../../../../components/form/input/date.component";
import {validateName} from "../../../../services/validation.service";

type CalendarProps = {
    startDay: number,
    taskList: Date[];
}

export type SimplifiedCategory = {
	id: number;
	place?: string;
	title: string;
	startDate: Date;
	tasks: Task[];
}

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
	const {t} = useTranslation();
	const i18 = getI18n();
	moment.locale(i18.language);

	const months = [
		t('months.january'),
		t('months.february'),
		t('months.march'),
		t('months.april'),
		t('months.may'),
		t('months.june'),
		t('months.july'),
		t('months.august'),
		t('months.september'),
		t('months.october'),
		t('months.november'),
		t('months.december')
	];

    const [currentDate, updateCurrentDate] = useState<Date>(new Date());
    const [days, updateDays] = useState(new Array(moment(currentDate, 'YYYY-MM-DD').endOf('month').toDate().getDate()).fill(0));
	const [loading, setLoading] = useState<boolean>(false);
	const [showPreviewTaskItemsModal, updatePreviewTaskItemsModal] = useState<boolean>(false);
	const [selectedDay, updateSelectedDay] = useState<number>(0);
	const [categories, updateCategories] = useState<SimplifiedCategory[]>([]);

	const getDaysOffset = (): number => {
        const firstDayOfMonth: number = moment(currentDate, 'YYYY-MM-DD').startOf('month').isoWeekday();

        if (props.startDay <= firstDayOfMonth) {
        	return (firstDayOfMonth - props.startDay) * 12 / 7;
        } else {
			return (firstDayOfMonth - props.startDay + 7) * 12 / 7;
        }
    };

	const hasTask = (day: number): boolean => {
		return categories.findIndex((category) => {
			return category.startDate.getDate() === day &&
				category.startDate.getMonth() === currentDate.getMonth() &&
				category.startDate.getFullYear() === currentDate.getFullYear();
		}) !== -1;
	};

    const selectDate = (day: number) => {
		if(!hasTask(day)) {
            return;
        }

		updateSelectedDay(day);
        updatePreviewTaskItemsModal(true);
    };

    const prevMonth = () => {
    	let date: any = moment(currentDate, 'YYYY-MM-DD').subtract(1, 'months');
    	updateCurrentDate(date.toDate());
		updateDays(new Array(moment(date, 'YYYY-MM-DD').endOf('month').toDate().getDate()).fill(0));

		fetchActivities(date.startOf('month').toDate(), date.endOf('month').toDate());
	}

	const currentMonth = () => {
		let date: any = moment(currentDate, 'YYYY-MM-DD');

		fetchActivities(
			date.startOf('month').toDate(), date.endOf('month').toDate()
		);
	}

	const nextMonth = () => {
    	let date: any = moment(currentDate, 'YYYY-MM-DD').add(1, 'months');
    	updateCurrentDate(date.toDate());
		updateDays(new Array(moment(date, 'YYYY-MM-DD').endOf('month').toDate().getDate()).fill(0));

		fetchActivities(date.startOf('month').toDate(), date.endOf('month').toDate());
	}

	const fetchActivities = async (startDate: Date, endDate: Date) => {
    	setLoading(true);
    	const response = await getActivitiesByDate(startDate, endDate)
			.then(response => {

				let data = response.data;

				let categories: SimplifiedCategory[] = [];
				let tasksDates: Date[] = [];

				if (data.length > 0) {
					data.forEach((activity: any) => {
						if (activity.type == 'ABSENCE') {
							let category: SimplifiedCategory = {
								id: activity.id,
								title: t('notificationTab.timetablePane.absence'),
								startDate: moment(activity.start_date).toDate(),
								tasks: []
							};

							let task: Task = {
								id: activity.id,
								startDate: moment(activity.start_date).toDate(),
								endDate: moment(activity.end_date).toDate(),
								allDay: activity.attributes.all_day,
								description: activity.attributes.reason,
								place: '',
								status: TaskStatus.ABSENCE,
								type: activity.type
							};

							category.tasks.push(task);
							categories.push(category);
							tasksDates.push(moment(activity.start_date).toDate());
						} else if (activity.type == 'PROPOSAL') {
							let category: SimplifiedCategory = {
								id: activity.id,
								place: activity.attributes.place_of_service,
								title: activity.attributes.job_position ? activity.attributes.job_position : t('notificationTab.timetablePane.workProposal'),
								startDate: moment(activity.start_date).toDate(),
								tasks: []
							};

							let task: Task = {
								id: activity.id,
								startDate: moment(activity.start_date).toDate(),
								endDate: moment(activity.end_date).toDate(),
								allDay: false,
								description: activity.attributes.description,
								place: activity.attributes.place_of_service,
								jobPosition: activity.attributes.job_position,
								status: TaskStatus[activity.attributes.status] as unknown as number,
								acceptEnabled: activity.attributes.accept_enabled,
								type: activity.type,
								coordinator: activity.attributes.coordinator
							};

							category.tasks.push(task);
							categories.push(category);
							tasksDates.push(moment(activity.start_date).toDate());
						}
					});

					updateCategories(categories);
				}

				//updateDaysArray();
			})
			.catch(error => {
				// todo what we show??
			});
		setLoading(false);
	};

    const updateDaysArray = () => {
    	updateDays(new Array(moment(currentDate, 'YYYY-MM-DD').endOf('month').toDate().getDate()).fill(0));
    }

	useEffect(() => {
		let date: any = moment(currentDate, 'YYYY-MM-DD');

		fetchActivities(
			date.startOf('month').toDate(), date.endOf('month').toDate()
		);
	}, []);

	useIonViewWillEnter(() => {
		let date: any = moment(currentDate, 'YYYY-MM-DD');

		fetchActivities(
			date.startOf('month').toDate(), date.endOf('month').toDate()
		);
	});

    return days.length > 0 ? (
		<StyledCalendarContainer>
			<IonGrid>
				<IonRow style={{"padding": "5px 0"}}>
					<IonCol onClick={(e) => prevMonth()} style={{"fontSize": "26px", "textAlign": "left"}}>&lang;</IonCol>
					<IonCol>
						<DateInput
							format="mm yyyy"
							presentation="date"
							onChange={(value) => {
								updateCurrentDate(moment(value, moment.ISO_8601).toDate())
							}}
							className="timetable"
							doneText={t('common.dateDone')}
							cancelText={t('common.dateCancel')}
							placeholder={t('common.select')}
							value={currentDate.toISOString()}
							min={moment().subtract(1, 'year').format('YYYY-MM-DD')}
							max={moment().add(1, 'year').format('YYYY-MM-DD')}
							// monthNames={months}
						/>
					</IonCol>
					<IonCol onClick={(e) => nextMonth()} style={{"fontSize": "26px", "textAlign": "right"}}>&rang;</IonCol>
				</IonRow>

                <IonRow>
                {
                    days.map((day, key) =>
                        <IonCol key={key} size="12/7" offset={key === 0 ? `${getDaysOffset()}` : "0"} onClick={() => selectDate(key + 1)}>
                            <div className="calendar-item">
                                <div className={ 'day ' + (moment(currentDate).format('YYYY-MM-DD') === moment(new Date().setDate(key + 1)).format('YYYY-MM-DD') ? 'current' : '') }>{key + 1}</div>
								<div className="task-indicator">
                                    { hasTask(key + 1) &&
                                        <div className="indicator"></div>
                                    }
                                </div>
                                <img src={CalendarItemImage}/>
                            </div>
                        </IonCol>
                    )
                }
                </IonRow>
            </IonGrid>

			<PreviewTaskItemsModal
				isOpen={showPreviewTaskItemsModal}
				onClose={() => updatePreviewTaskItemsModal(false)} // jak po zaakceptowaniu / odrzuceniu ma zostac odswiezony modal podgladu, trzeba zamknac i ponownie go otworzyc
				currentDate={currentDate}
				selectedDay={selectedDay}
				categories={categories}
				refresh={currentMonth}
			>
			</PreviewTaskItemsModal>
        </StyledCalendarContainer>
    ) : (<span></span>);
};

export default Calendar;
