import {Location} from 'history';
import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import {IonAlert} from "@ionic/react";
import {useTranslation} from "react-i18next";

interface Props {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
}

const PageLeavingGuard = ({
                              when,
                              navigate,
                              shouldBlockNavigation,
                          }: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };
    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    const {t} = useTranslation();

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation}/>
            <IonAlert
                isOpen={modalVisible}
                onDidDismiss={() => closeModal()}
                header={t('reportPage.backAlert.title')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {

                        }
                    },
                    {
                        text: t('reportPage.backAlert.discard'),
                        handler: async () => {
                            closeModal()
                            handleConfirmNavigationClick();
                        }
                    }
                ]}
            />
        </>
    );
};
export default PageLeavingGuard;