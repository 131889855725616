import React from 'react';
import {StyledAttachementContainer} from './attachement.style';
import {IonIcon, IonSkeletonText} from "@ionic/react";
import {imageOutline} from "ionicons/icons";


const AttachmentSkeleton: React.FC = () => {

    return (
        <StyledAttachementContainer>

            <span className="preview">
                <IonIcon src={imageOutline} style={{"height": "80px", "fontSize": "40px", "color": '#888A98'}} />
            </span>

            <span className="name" style={{ width: '80%' }}>
                <IonSkeletonText animated />
            </span>
            <span className="date" style={{ width: '50%' }}>
                <IonSkeletonText animated />
            </span>
        </StyledAttachementContainer>
    );
};

export default AttachmentSkeleton;