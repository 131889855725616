import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {IonIcon, IonItem, useIonViewDidEnter} from '@ionic/react';
import Modal from '../../../../../components/modal/modal.component';
import Form from '../../../../../components/form';
import { StyledModalContent } from '../../../../../components/modal/modal.style';
import { StyledIonLabel } from '../../../../../components/form/input/label.style';
import { StyledIonCheckbox } from '../../../../../components/form/input/checkbox.style';
import LinkLabel from "../../../../../components/form/input/label.component";
import {Preferences} from "@capacitor/preferences";
import {checkboxOutline} from "ionicons/icons";
import {getProfile, updateAcceptanceFieldValue} from "../../../../../services/profile.service";
import {StyledFooter} from "../../../../../components/pane/pane.style";
import {Links} from "../../../../links";
import { useHistory } from 'react-router-dom';
import {useAuth} from "../../../../../services/auth.service";
import {logout} from "../../../../../services/auth.service";
import LogoffImage from "../../../../../assets/images/log-out.svg";
import {StyledWiteButton} from "../../../../../components/button/button.style";
import {disableSwipeToClose} from "../../../../../utils/tools/modals";

type AppApprovalsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    withLogout?: boolean;
};

interface IApprovalsManager {
    setJobOfferContactChecked: Function;
}

const approvals: IApprovalsManager = {
    setJobOfferContactChecked: () => {

    },
};

export const ApprovalsContext = React.createContext<IApprovalsManager>(approvals);

const AppApprovalsModal: React.FC<AppApprovalsModalProps> = (props: AppApprovalsModalProps) => {

    const {t} = useTranslation();
    const history = useHistory();
    const auth = useAuth();

    const [showApprovals, updateShowApprovals] = useState<boolean>(false);

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const [termsInit, setTermsInit] = useState<any>(null);
    const [termsChecked, setTermsChecked] = useState<any>(null);
    const [jobOfferContactChecked, setJobOfferContactChecked] = useState<any>(null);
    const [emailMarketingChecked, setEmailMarketingChecked] = useState<any>(null);
    const [phoneMarketingChecked, setPhoneMarketingChecked] = useState<any>(null);
    const [emailMarketingShowDesc, setEmailMarketingShowDesc] = useState(false);
    const [phoneMarketingShowDesc, setPhoneMarketingShowDesc] = useState(false);
    const [jobOfferContactShowDesc, setJobOfferContactShowDesc] = useState(false);

    const approvals = useContext(ApprovalsContext);
    approvals.setJobOfferContactChecked = setJobOfferContactChecked

    const handleClose = () => {
        props.onClose();
        setTermsInit(null);
        setTermsChecked(null);
        setJobOfferContactChecked(null);
        setEmailMarketingChecked(null);
        setPhoneMarketingChecked(null);
        setEmailMarketingShowDesc(false);
        setPhoneMarketingShowDesc(false);
        setJobOfferContactShowDesc(false);
    };

    useEffect(() => {
        handleOpen();
    });

    useEffect(() => {
        loadSelected();
    }, [termsInit, termsChecked, jobOfferContactChecked, emailMarketingChecked, phoneMarketingChecked]);

    const handleOpen = () => {
        updateShowApprovals(props.isOpen);
    };

    const loadSelected = async () => {
        if (termsInit == null && termsChecked == null && jobOfferContactChecked == null && emailMarketingChecked == null && phoneMarketingChecked == null) {
            await getProfile(true)
                .then(response => {
                    let data = response.data;
                    setTermsInit(data.ep2_terms_acceptance);
                    setTermsChecked(data.ep2_terms_acceptance);
                    setJobOfferContactChecked(data.ep2_job_offer_contact_acceptance);
                    setEmailMarketingChecked(data.ep2_email_acceptance);
                    setPhoneMarketingChecked(data.ep2_phone_acceptance);
                });
        }
    };

    const snakeToCamel = (str: string) =>
        str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );

    const onChangeSave = async (field: string, value: boolean) => {
        if (value !== undefined) {
            await updateAcceptanceFieldValue(field.replace(/([_])/g, '-') +'-acceptance', snakeToCamel(field)+'Acceptance', value)
            await Preferences.set({'key': field+'_acceptance', 'value': value ? '1' : '0'});
        }
    };

    const onSubmit = async () => {
        await onChangeSave('job_offer_contact', jobOfferContactChecked);
        await onChangeSave('email', emailMarketingChecked);
        await onChangeSave('phone', phoneMarketingChecked);
        if (!termsInit) {
            await onChangeSave('terms', termsChecked);
        }

        if (props.onSubmit != undefined) {
            props.onSubmit();
        }

        props.onClose();
    };

    const logoutClick = async () => {
        await logout().then(() => {
            auth.signOut();
            history.replace(Links.login)
            window.top.location.reload();
        });
    }

    return (
        <Modal
            isOpen={showApprovals}
            canDismiss={disableSwipeToClose}
            disableClose={!termsInit}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <h1 className="title">{t("settingsTab.approvalsModal.title")}</h1>
                    
                        <div className="content">
                            <LinkLabel urlString={t("createAccount.acceptTermsPolicyURL")} url="https://exactsystems.pl/gdpr-file/EP-polityka_prywatnosci.htm" external={true}>
                                <LinkLabel urlString={t("createAccount.acceptTermsURL")} url="https://exactsystems.pl/gdpr-file/Warunki-Uzytkowania-Aplikacji-Exact-People.htm" external={true}>
                                    <IonItem lines="none">
                                        { termsInit &&
                                            <IonIcon src={checkboxOutline} style={{"paddingRight": "17px", "fontSize": "24px", "color": "#bdbdc2"}} />
                                        }
                                        <StyledIonLabel className="required">{t("createAccount.acceptTerms")}</StyledIonLabel>
                                        { !termsInit &&
                                            <StyledIonCheckbox slot="start" checked={termsChecked}
                                                           onIonChange={e => {
                                                               setTermsChecked(e.detail.checked)
                                                           }}/>
                                        }
                                    </IonItem>
                                </LinkLabel>
                            </LinkLabel>

                            <IonItem lines="none">
                                <StyledIonLabel>{t("createAccount.acceptJobOfferContact")}</StyledIonLabel>
                                <StyledIonCheckbox slot="start" checked={jobOfferContactChecked}
                                                   onIonChange={e => {
                                                       setJobOfferContactChecked(e.detail.checked)
                                                   }}/>
                            </IonItem>

                            <>
                                { !jobOfferContactShowDesc &&
                                <a className="showLessMore" onClick={ e => setJobOfferContactShowDesc(true)}>{t("createAccount.showMore")}</a>
                                }

                                { jobOfferContactShowDesc &&
                                <LinkLabel urlString={t("createAccount.acceptJobOfferContactDescURL")}
                                           url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                    <p className="termsDesc">{t("createAccount.acceptJobOfferContactDesc1")}</p>
                                    <p className="termsDesc">{t("createAccount.acceptJobOfferContactDesc2")}</p>
                                </LinkLabel>
                                }

                                { jobOfferContactShowDesc &&
                                <a className="showLessMore" onClick={ e => setJobOfferContactShowDesc(false)}>{t("createAccount.showLess")}</a>
                                }
                            </>

                            <IonItem lines="none">
                                <StyledIonLabel>{t("createAccount.acceptMailMarketing")}</StyledIonLabel>
                                <StyledIonCheckbox slot="start" checked={emailMarketingChecked}
                                                   onIonChange={e => {
                                                       setEmailMarketingChecked(e.detail.checked)
                                                   }}/>
                            </IonItem>

                            <>
                                { !emailMarketingShowDesc &&
                                    <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                                }
                                { emailMarketingShowDesc &&
                                    <LinkLabel urlString={t("createAccount.acceptMailMarketingDescURL")}
                                               url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                        <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc1")}</p>
                                        <p className="termsDesc">{t("createAccount.acceptMailMarketingDesc2")}</p>
                                    </LinkLabel>
                                }
                                { emailMarketingShowDesc &&
                                    <a className="showLessMore" onClick={ e => setEmailMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                                }
                            </>

                            <IonItem lines="none">
                                <StyledIonLabel>{t("createAccount.acceptPhoneMarketing")}</StyledIonLabel>
                                <StyledIonCheckbox slot="start" checked={phoneMarketingChecked}
                                                   onIonChange={e => {
                                                       setPhoneMarketingChecked(e.detail.checked)
                                                   }}/>
                            </IonItem>

                            <>
                                { !phoneMarketingShowDesc &&
                                    <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(true)}>{t("createAccount.showMore")}</a>
                                }

                                { phoneMarketingShowDesc &&
                                    <LinkLabel urlString={t("createAccount.acceptPhoneMarketingDescURL")}
                                               url="https://exactsystems.pl/grupa-kapitalowa/polska" external={true}>
                                        <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc1")}</p>
                                        <p className="termsDesc">{t("createAccount.acceptPhoneMarketingDesc2")}</p>
                                    </LinkLabel>
                                }

                                { phoneMarketingShowDesc &&
                                    <a className="showLessMore" onClick={ e => setPhoneMarketingShowDesc(false)}>{t("createAccount.showLess")}</a>
                                }
                            </>
                        </div>
                        <StyledFooter className="modal footer no-shadow">
                            <Form.Button type="submit" disabled={!termsChecked}>{t("settingsTab.approvalsModal.acceptButton")}</Form.Button>
                            {props.withLogout && <StyledWiteButton onClick={e => logoutClick()}>{t("settingsTab.logoff")} <img src={LogoffImage}/></StyledWiteButton>}
                        </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default AppApprovalsModal;