import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    IonButtons,
    IonHeader,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '../../components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from '../../components/button/button.style';
import ArrowLeftImage from '../../assets/images/arrow-left.svg';
import CheckImage from '../../assets/images/check.svg';
import {ClearableInput} from '../../components/form/input/input.style';
import {useForm} from 'react-hook-form';
import {StyledContent} from './addEditPhonePage.style';
import {getConfiguration} from '../../services/addEditValuePage.service';
import {Links} from '../links';
import Form from '../../components/form';
import StaticPane from '../../components/pane/static-pane.component';
import {validatePhoneNumber} from "../../services/validation.service";
import {useTranslation} from "react-i18next";

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
import {PhoneNumberPrefix} from "../../enums/phoneNumberPrefix";
import ExactPhoneInput from '../../components/form/input/phoneInput.component';

const AddEditPhonePage: React.FC = () => {

    const {t} = useTranslation();
    const phoneNumberName = "phoneNumber";
    const countryNumberName = "countryNumber";
    const history = useHistory();
    const [error, setError] = useState<string>();
    const [submitted, setSubmitted] = useState(false);
    const [err, setErr] = useState('');
    const [startSaving, setStartSaving] = useState(false);

    if (!getConfiguration()) {
        history.goBack();
    }

    const handleBack = () => {
        const handleDismiss = getConfiguration()?.handleDismiss;

        if (typeof handleDismiss === 'function') {
            handleDismiss();
        } else {
            getConfiguration()?.url ? history.replace(getConfiguration()?.url || Links.login, history.location.state) : history.goBack();
        }
    };

    const handleSave = async () => {
        if (err || errors.field) {
            setStartSaving(true);
            return;
        }

        const handleSave = getConfiguration()?.handleSave;
        if (typeof handleSave === 'function') {
            setSubmitted(true);
            if (!submitted) {
                let response: any = await handleSave(phoneNumber);
                if (response !== undefined) {
                    setSubmitted(false);
                    setError(response);
                }
            }
        } else {
            setSubmitted(false);
            getConfiguration()?.url ? history.replace(getConfiguration()?.url || Links.login, history.location.state) : history.goBack();
        }
    };

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const {register, getValues, setValue, handleSubmit, watch, errors, formState} = useForm({
        mode: 'onChange'
    });

    let invalidPhoneError = t("profileTab.personalData.validation.invalidPhone");
    let invalidPhoneErrorPl = t("profileTab.personalData.validation.pl.invalidPhone");
    const [phoneNumber, updatePhoneNumber] = useState(getConfiguration()?.defaultValue);

    useEffect(() => {
        setValue(phoneNumberName, getConfiguration()?.defaultValue);
    }, []);

    useEffect(() => {
        updateHeight();
    });

    useIonViewDidEnter(() => {
        document.getElementsByName(phoneNumberName)[0].focus();
    });

    const updateHeight = () => {
        if (header.current?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handlePhoneErr = (error: boolean, code: string) => {
        if (error) {
            if (code != '48') {
                setErr(invalidPhoneError);
            } else {
                setErr(invalidPhoneErrorPl);
            }
        } else {
            setErr('');
        }
    }

    const header = useRef<HTMLIonHeaderElement>(null);

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{getConfiguration()?.title}</StyledIonTitle>
                        <IonButtons slot="end">
                            <StyledHeaderButton onClick={() => handleSave()}>
                                <StyledHeaderButtonImage src={CheckImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} hideGrabber={true}>
                    <Form.Container onSubmit={handleSubmit(handleSave)}>
                        <StyledContent>
                            <ExactPhoneInput
                                name={phoneNumberName}
                                phoneNumer={phoneNumber || '48 '}
                                phoneNumerChanged={(phoneNumber) => {
                                    updatePhoneNumber(phoneNumber)
                                }}
                                errorChanged={handlePhoneErr}
                            ></ExactPhoneInput>
                            {startSaving && err && <p className="errorMessage">{err}</p>}
                        </StyledContent>
                    </Form.Container>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default AddEditPhonePage;
