import styled from 'styled-components';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - 202px) 202px;

  grid-template-areas: 
    "desc"
    "actions";

  .desc {
    grid-area: desc;
    overflow-y: auto;
    max-height: 100%;
    padding-top: 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: justify;
    color: #888A98;

    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-top: 16px;
      margin-bottom: 12px;
    }
  }

  .actions {
    grid-area: actions;
    text-align: center;

    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-top: 15px;
    }

    .info {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        color: #0084FF;
      }

      font-weight: 500;
      font-size: 12px;
      line-height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: -0.408px;
      letter-spacing: -0.408px;
      color: #3C3C43;
      flex-direction: column;
      width: 55%;
      margin: auto;
      height: 100%;

      color: #3C3C43;
    }

    .separator {
      margin-top: 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 100%;
      position: relative;
      color: #949494;

      span {
        background: #ffffff;
        padding: 0 35px;
        z-index: 0;
      }

      :before {
        content: ' ';
        display: block;
        width: calc(100% - 24px);
        border-bottom: 1px solid #F4F4F9;
        position: absolute;
      }
    }
  }
`;