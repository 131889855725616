import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import {ContractStatus} from '../../../enums/contractStatus';
import {
    StyledButton,
    StyledButtonBadge,
    StyledLightBlueButton,
    StyledWiteButton
} from '../../../components/button/button.style';
import {ContractAppendix} from '../../../models/contract'
import {IonAlert, IonCol, IonGrid, IonRow, IonToast, isPlatform} from '@ionic/react';
import SignModal, {ISignModalConfigration} from '../../../modals/sign/sign.component';
import {
    getContracts
} from '../../../services/contract.service';
import {ContractComment} from "../../../models/contractComment";
import {ContractsContext} from "./contractsPane.component";
import {Directory, Filesystem, FilesystemDirectory} from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import {blobToBase64, downloadFile} from "../../../services/file.service";
// import { FileOpener } from '@ionic-native/file-opener';
import RemarksListModal from "../modals/remarksListModal.component";
import {ContractCommentsStatus} from "../../../enums/contractCommentsStatus";
import {useHistory} from "react-router-dom";
import {Links} from "../../links";
import {hasPhone} from "../../../services/teamMember.service";
import TeamMemberPhoneModal from "../../../modals/teamMemberPhone/teamMemberPhone.component";
import {
    getAppendix,
    getAppendixPdf, getAppendixQualifiedSignatureFilePdf,
    rejectAppendix,
    saveAppendixComments,
    signAppendix,
    signAppendixVerify
} from "../../../services/contractAppendix.service";
import {ContractAppendixPageContext} from "../contractAppendixPage.component";
import AppendixRemarksModal from "../modals/appendixRemarksModal.component";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";

enum DownloadFileType {
    CONTRACT,
    QUALIFIED_SIGNATURE
}

type ContentPaneProps = {
    topEdge?: number;
    status?: ContractStatus;
    commentsStatus?: ContractCommentsStatus | null | undefined;
    appendix?: ContractAppendix;
}

const ContentAppendixPane: React.FC<ContentPaneProps> = (props: ContentPaneProps) => {
    const context = useContext(ContractsContext);
    const {t} = useTranslation();
    const history = useHistory();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showConversation, updateShowConversation] = useState<boolean>(false);

    const [status, setStatus] = useState('');
    const [commentsStatus, setCommentsStatus] = useState<ContractCommentsStatus | null>();
    const [contractAppendix, setContractAppendix] = useState<ContractAppendix>();
    const [hasNewComment, setHasNewComment] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const contextContractAppendixPage = useContext(ContractAppendixPageContext);

    useEffect(() => {
        if (props.status !== undefined && !status) {
            setStatus(props.status);
        }

        if (props.commentsStatus !== undefined && !commentsStatus) {
            setCommentsStatus(props.commentsStatus);
        }

        if (props.appendix !== undefined && !contractAppendix) {
            setContractAppendix(props.appendix);
        }

        hasNewCommentFn();
    }, [contractAppendix, status, props.appendix, props.status]);

    useEffect(() => {
        updateContractAppendix();
    }, []);

    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);

    const saveRemarks = async (description: string, update: boolean = false) => {
        await saveAppendixComments(contractAppendix?.id, description)
            .then(response => {
                updateShowAddRemarks(false);
                const comment = {
                    is_worker_author: true,
                    comment: description
                } as ContractComment;

                contractAppendix?.sign_comments.unshift(comment);

                setHasNewComment(true);

                if (!update) {
                    updateShowAddRemarks(false);
                    setCommentsStatus(ContractCommentsStatus.COMMENTED_BY_WORKER);
                    contextContractAppendixPage.setCommentsStatus(ContractCommentsStatus.COMMENTED_BY_WORKER);
                    updateList();
                    updateContractAppendix();
                } else {
                    updateShowConversation(false);
                }
            });
    };

    const [showSignModal, updateShowSignModal] = useState<boolean>(false);
    const [showPhoneModal, updateShowPhoneModal] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [signModalConfiguration, updateSignModalConfiguration] = useState<ISignModalConfigration>();

    const preSign = async () => {
        let hasPhoneNumber = null;
        await hasPhone().then(value => {
            hasPhoneNumber = value;
        });

        if (hasPhoneNumber === false) {
            updateShowPhoneModal(true);
        } else {
            await sign();
        }
    }

    const sign = async () => {
        await signAppendix(contractAppendix?.id)
            .then(response => {
                updateSignModalConfiguration({
                    title: t("contractAppendixPage.sign.title"),
                    info: t("contractAppendixPage.sign.info"),
                    buttonText: t("contractAppendixPage.sign.signButton")
                });

                updateShowSignModal(true);
            });
    };

    const signSave = async (code: string) => {
        await signAppendixVerify(contractAppendix?.id, code)
            .then(response => {
                updateShowSignModal(false);
                if (status === ContractStatus.WAITING_FOR_WORKER_SIGN) {
                    setStatus(ContractStatus.SIGNED);
                    contextContractAppendixPage.setStatus(ContractStatus.SIGNED);
                } else if (status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT) {
                    setStatus(ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT);
                    contextContractAppendixPage.setStatus(ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT);
                } else if (status === ContractStatus.SEND_BY_KO) {
                    setStatus(ContractStatus.WAITING_FOR_KO_SIGN);
                    contextContractAppendixPage.setStatus(ContractStatus.WAITING_FOR_KO_SIGN);
                } else {
                    setStatus(ContractStatus.SIGNED_BY_WORKER);
                    contextContractAppendixPage.setStatus(ContractStatus.SIGNED_BY_WORKER);
                }
                updateContractAppendix();
                updateList();

                history.replace(Links.main + Links.contracts);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    setToast(t("common.invalidVerifyCode"));
                    setShowToast(true);
                }
            });
    };

    const reject = async () => {
        if (contractAppendix?.id) {
            await rejectAppendix(contractAppendix?.id)
                .then(response => {
                    setShowAlert(false);
                    setStatus(ContractStatus.REJECTED_BY_WORKER);
                    contextContractAppendixPage.setStatus(ContractStatus.REJECTED_BY_WORKER);
                    updateContractAppendix();
                    updateList();
                });
        }
    };

    const downloadPdf = async (type: DownloadFileType) => {
        if (contractAppendix?.id) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = null;
                switch (type) {
                    case DownloadFileType.CONTRACT:
                        fileData = await getAppendixPdf(contractAppendix.id)
                            .then(response => response.data);
                        break;
                    case DownloadFileType.QUALIFIED_SIGNATURE:
                        fileData = await getAppendixQualifiedSignatureFilePdf(contractAppendix.id)
                            .then(response => response.data);
                        break;
                    default:
                        throw new Error("Unsupported download file type");
                }

                let base64 = null;
                if (fileData) {
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/' + contractAppendix.number.replace(/\//g, '_') + '.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            await FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch(e) {

                        }
                    }
                }
            }
        }
    };

    const updateList = async () => {
        await getContracts()
            .then(response => {
                context.setContracts(response.data);
            })
    }

    const updateContractAppendix = async () => {
        if (props.appendix?.id) {
            await getAppendix(props.appendix?.id)
                .then(response => {
                    const appendix = response.data.appendix as ContractAppendix;
                    appendix.content = response.data.appendix_content;
                    appendix.regulations_content = response.data.regulations_content;
                    setContractAppendix(appendix);
                    setStatus(appendix.sign_status);
                    setCommentsStatus(appendix.comments_status);
                    contextContractAppendixPage.setStatus(appendix.sign_status);
                    contextContractAppendixPage.setCommentsStatus(appendix.comments_status);
                })
        }
    }

    const hasNewCommentFn = () => {
        const count = contractAppendix?.sign_comments.length && contractAppendix?.sign_comments.filter((comment: ContractComment) => {
            return !comment.status;
        }).length;

        if (count && count > 0) {
            setHasNewComment(true);
        } else {
            setHasNewComment(false);
        }
    }

    return (
        <div>
            <Pane topEdge={props.topEdge} marginTop={40}
                  paddingBottom={(status !== ContractStatus.WAITING_FOR_KO_SIGN) ? 160 : 0}>
                <h1>{contractAppendix?.number}</h1>
                <div dangerouslySetInnerHTML={{__html: contractAppendix?.content || ''}}/>
            </Pane>
            {(status !== ContractStatus.WAITING_FOR_KO_SIGN && (contractAppendix?.is_active && status !== ContractStatus.REJECTED_BY_WORKER) || (!contractAppendix?.is_active && status === ContractStatus.SIGNED)) &&
                <StyledFooter>
                    <IonGrid>
                        <IonRow>
                            {
                                (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO) && (status === ContractStatus.SIGNED_BY_KO || status === ContractStatus.WAITING_FOR_WORKER_SIGN) && contractAppendix?.is_active &&
                                <IonCol size="12">
                                    <StyledButton className="no-margin-top" onClick={() => preSign()}>{t("contractPage.content.signButton")}</StyledButton>
                                </IonCol>
                            }
                            {
                                ((status === ContractStatus.SIGNED_BY_KO || status === ContractStatus.WAITING_FOR_WORKER_SIGN) && (!commentsStatus || (commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && !hasNewComment) || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO)) && contractAppendix?.is_active &&
                                <IonCol size="6">
                                    <StyledWiteButton className="no-margin-top" onClick={() => updateShowAddRemarks(true)}>{t("contractPage.content.addRemarksButton")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && hasNewComment &&
                                <IonCol size="12">
                                    <StyledWiteButton className="no-margin-top" onClick={() => updateShowConversation(true)}>{t("contractAppendixPage.content.remarksButton")}
                                        <StyledButtonBadge className="default-position">{contractAppendix?.sign_comments.length}</StyledButtonBadge>
                                    </StyledWiteButton>
                                </IonCol>
                            }
                            {
                                ((status === ContractStatus.SIGNED_BY_KO || status === ContractStatus.WAITING_FOR_WORKER_SIGN) && (!commentsStatus || commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO || commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO)) && contractAppendix?.is_active &&
                                <IonCol size="6">
                                    <StyledWiteButton className="red no-margin-top" onClick={() => setShowAlert(true)}>{t("contractPage.content.reject")}</StyledWiteButton>
                                </IonCol>
                            }
                            {
                                (status === ContractStatus.SIGNED || status === ContractStatus.ENDED) &&
                                <>
                                    <IonCol size="12">
                                        <StyledLightBlueButton className="no-margin-top" onClick={() => downloadPdf(DownloadFileType.CONTRACT)}>{t("contractAppendixPage.content.downloadPdf")}</StyledLightBlueButton>
                                    </IonCol>
                                    {contractAppendix?.is_qualified_signature_file &&
                                        <IonCol size="12">
                                            <StyledLightBlueButton className="no-margin-top" onClick={() => downloadPdf(DownloadFileType.QUALIFIED_SIGNATURE)}>{t("contractAppendixPage.content.downloadQualifiedSignaturePdf")}</StyledLightBlueButton>
                                        </IonCol>
                                    }
                                </>
                            }
                        </IonRow>
                    </IonGrid>
                </StyledFooter>
            }
            <AppendixRemarksModal
                appendix={contractAppendix}
                isOpen={showAddRemarks}
                onRemarks={(description: string) => saveRemarks(description)}
                onClose={() => updateShowAddRemarks(false)}>
            </AppendixRemarksModal>
            {signModalConfiguration && <SignModal configuration={signModalConfiguration}
                                                  isOpen={showSignModal}
                                                  onClose={() => updateShowSignModal(false)}
                                                  onSave={(code: string) => signSave(code)}>

            </SignModal>}
            <RemarksListModal
                isOpen={showConversation}
                onClose={() => updateShowConversation(false)}
                comments={contractAppendix?.sign_comments}
                appendix={true}>
            </RemarksListModal>
            <TeamMemberPhoneModal isOpen={showPhoneModal} onClose={() => updateShowPhoneModal(false)} onSubmit={async (phone) => {
                updateShowPhoneModal(false)
                await Preferences.set({'key': 'phone', 'value': phone});
                await sign();
            }}></TeamMemberPhoneModal>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('contractAppendixPage.rejectAlertTitle')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false)
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            reject();
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </div>
    );
};

export default ContentAppendixPane;