import { IonModal } from '@ionic/react';
import styled from 'styled-components';

export const StyledRoleActionSheet = styled.div`
  z-index: 20002;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  position: fixed;
  background-color: rgba(0,0, 0, 0.5)
`;

export const StyledRoleActionSheetContainer = styled.div`
  padding: 40px 40px 49px 40px;

  .image {
    margin-bottom: 20px;
  }

  @keyframes show {
    from {
      bottom: -200%;
    }
    to {
      bottom: 0;
    }
  }
`;

export const StyledRoleIonModal = styled(IonModal)`
  --min-width: 100%;
  --width: 100%;
  --max-width: 100%;
  --height: 100%;
  
  .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-ios {
    background-color: transparent;
  }
`;
