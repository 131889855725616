import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import ArrowLeftImage from "@assets/images/arrow-left.svg";
import RideRouteAddEditPane from "@app/travelAllowance/rideRoute/panes/rideRouteAddEditPane.component";
import {RideRoutePaneType} from "@enums/travelAllowance/rideRoute";

const RideRouteAddPage: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    useEffect(() => {
        log('page_visit', {
            page: 'Trasy -> Dodaj'
        });

        updateHeight();
    }, []);

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideRouteAddPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <RideRouteAddEditPane type={RideRoutePaneType.ADD} topEdge={topEdge}/>
            </StyledIonContent>
        </IonPage>
    );
};

export default RideRouteAddPage;