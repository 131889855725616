import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledField} from "../../../../components/form/input/input.style";
import {StyledContent} from "./../recruitmentPath.style";
import {StyledButton} from "../../../../components/button/button.style";
import {useTranslation} from "react-i18next";
import Timer from "./../timer/timer.component";
import {RecruitmentPathTestType} from "../../../../enums/recruitmentPathTestType";
import {RecruitmentPathTest} from "../../../../models/recruitmentPathData";
import {StyledGameContent} from "./../game.style";
import TestsCounter from "./../testCounter/testsCounter.component";
import {StyledFooter} from "../../../../components/pane/pane.style";

type RecruitmentPathGameProps = {
    currentTest: RecruitmentPathTest;
    currentTestNumber: number;
    updateResults: (id: number, result: boolean) => void;
    totalTests: number;
    duration: number;
};


const Test2Game: React.FC<RecruitmentPathGameProps> = (props: RecruitmentPathGameProps) => {

    const {t} = useTranslation();

    const [imagesLoaded, updateImagesLoaded] = useState<number>(0);

    const onResultClick = (id: number, result: boolean) => {
        props.updateResults(id, result);
    }

    const onCounterDurationElapsed = () => {
        props.updateResults(props.currentTest.id, false);
    }

    return (
        <StyledGameContent>
            <div className="game2">
                <div className="answers">
                    {
                        props.currentTest.answers.map((test, key) =>
                            <div className="wrapper-image" onClick={() => onResultClick(props.currentTest.id, test.valid_answer)}>
                                <div className="image">
                                    <img src={test.base64} className={test.valid_answer == true ? "valid" : "" } />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <StyledFooter className="modal no-shadow">
                <TestsCounter currentTest={props.currentTestNumber} totalTests={props.totalTests}
                              onDurationElapsed={onCounterDurationElapsed} duration={props.duration}></TestsCounter>
            </StyledFooter>
        </StyledGameContent>
    )
};

export default Test2Game;