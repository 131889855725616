import React, {useEffect, useRef, useState} from 'react';
import {IonButtons, IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps, useHistory} from "react-router-dom";

import {log} from "@services/firebaseAnalytics.service";
import {getRide} from "@services/travelAllowance/ride.service";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import {StyledHeaderButton, StyledHeaderButtonImage} from "@components/button/button.style";

import RideAddEditPane from "@app/travelAllowance/ride/panes/rideAddEditPane.component";
import Pane from "@components/pane/pane.component";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";

import ArrowLeftImage from "@assets/images/arrow-left.svg";

import {Ride, RideChange} from "@models/travelAllowance/ride";

import {RidePaneType} from "@enums/travelAllowance/ride";

interface RouterProps {
    rideId: string;
}

interface RideEditPageProps extends RouteComponentProps<RouterProps> {
}

const RideEditPage: React.FC<RideEditPageProps> = ({match}: RideEditPageProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {params: {rideId}} = match;

    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [ride, setRide] = useState<Ride>();
    const [rideChanges, setRideChanges] = useState<RideChange>();

    useEffect(() => {
        log('page_visit', {
            page: 'Przejazdy -> Edycja'
        });

        updateHeight();
    }, []);

    useEffect(() => {
        setLoading(true);

        fetchRide()
            .then(() => {
                setLoading(false)
            });
    }, []);

    const fetchRide = async () => {
        if (rideId !== undefined) {
            const rideData = await getRide(parseInt(rideId));

            setRide(rideData);

            if (rideData.rideChanges) {
                const preparedRideChanges = prepareRideChangesTranslations(rideData.rideChanges.changes);
                setRideChanges(preparedRideChanges);
            }
        }
    }

    const prepareRideChangesTranslations = (rideChanges: Record<string, any>): Record<string, string> => {
        const results: Record<string, string> = {};

        Object.keys(rideChanges).forEach(key => {
            if (key === 'id') {
                return;
            }

            const value = rideChanges[key];

            if ('new' in value && 'old' in value) {
                results[key] = `travelAllowance.ride.changed.${key}`;
            }
        });

        return results;
    };

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const handleBack = () => {
        history.goBack();
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleBack()}>
                                <StyledHeaderButtonImage src={ArrowLeftImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("rideEditPage.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                {
                    loading &&
                    <Pane topEdge={topEdge}>
                        <MyDataSkeleton/>
                    </Pane>
                }
                {
                    !loading && <RideAddEditPane type={RidePaneType.EDIT} topEdge={topEdge} rideDefaultValues={ride} rideChanges={rideChanges}/>
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default RideEditPage;