import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { close } from 'ionicons/icons';
import React from 'react';
import { Filter } from '../filterActionSheet/filterActionSheet.component';
import { StyledFilterChipsContainer } from './filterChips.style';

type FilterChipsProps = {
    filters: Filter[];
    onFilterClear: (filter: Filter) => void;
};

const FilterChips: React.FC<FilterChipsProps> = (props: FilterChipsProps) => {

    return <StyledFilterChipsContainer>
        {
            props.filters.map((filter, key) =>
            <IonChip key={key}>
                <IonLabel>{filter.description}</IonLabel>
                <IonIcon icon={close} onClick={() => props.onFilterClear(filter) } />
            </IonChip>
            )
        }
    </StyledFilterChipsContainer>
};

export default FilterChips;
