import React from 'react';
import {
    StyledAttachementFolderContainer,
    StyledAttachementFolderContent
} from './attachementFolder.style';
import {IonSkeletonText} from "@ionic/react";


const AttachmentFolderSkeleton: React.FC = () => {

    return (
        <StyledAttachementFolderContainer>
            <StyledAttachementFolderContent>
                <div className="title"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                <small><IonSkeletonText animated style={{ width: '60%' }} /></small>
                <small><IonSkeletonText animated style={{ width: '40%' }} /></small>
            </StyledAttachementFolderContent>
        </StyledAttachementFolderContainer>
    );
};

export default AttachmentFolderSkeleton;