import { IonLabel } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonLabel = styled(IonLabel)`   
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px;
    text-overflow: unset !important;
    white-space: unset !important;
    
    &.required:before {
        content:"* ";
        color: red;
    }
`;
