import React, {useEffect, useState} from 'react';
import {StyledIonModal} from '../../components/modal/modal.style';
import {StyledFooter} from '../../components/pane/pane.style';
import Form from "../../components/form";
import CircleImage, {ImageTypes} from '../../components/circleImage/circleImage.component';
import {StyledContent} from './status.style';
import {useTranslation} from "react-i18next";
import {StyledWiteButton} from "../../components/button/button.style";
import LogoffImage from "../../assets/images/log-out.svg";
import {App as AppPlugin} from "@capacitor/app";
import {Capacitor} from "@capacitor/core";

export enum StatusModalType {
    SUCCESS,
    ERROR
}

type StatusModalProps = {
    isOpen: boolean;
    message: string;
    description?: string;
    buttonText?: string;
    maxHeight?: string;
    image?: ImageTypes;
    status: StatusModalType;
    onClose: () => void;
    onRefresh?: () => void;
    allowCloseApp?: boolean
};

const StatusModal: React.FC<StatusModalProps> = (props: StatusModalProps) => {
    const onRefresh = props.onRefresh;

    const {t} = useTranslation();
    const [show, setShow] = useState<boolean>(false);

    const closeApp = async () => {
        await AppPlugin.exitApp();
    }

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    return (
        <StyledIonModal
            isOpen={show}
            canDismiss={true}
            onDidDismiss={() => props.onClose()}
            maxHeight={props.maxHeight}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}>
            <StyledContent>
                <CircleImage image={props.image !== undefined ? props.image : ImageTypes.CONNECT}
                             color={props.status === StatusModalType.ERROR ? 'red' : undefined}
                >
                </CircleImage>
                <span className="message">{props.message}</span>
                {props.description && <p className="description">{props.description}</p>}
            </StyledContent>

            <StyledFooter className="no-shadow">
                {props.buttonText &&
                    <Form.Button type="submit" onClick={() => props.onClose()}>{props.buttonText}</Form.Button>
                }
                {onRefresh &&
                    <Form.Button type="submit" onClick={() => onRefresh()}>{t("status.modal.refresh")}</Form.Button>
                }
                {props.allowCloseApp && Capacitor.isNativePlatform() &&
                    <StyledWiteButton onClick={e => closeApp()}>{t("status.modal.closeApp")}
                        <img src={LogoffImage}/>
                    </StyledWiteButton>
                }
            </StyledFooter>
        </StyledIonModal>
    );
};

export default StatusModal;
