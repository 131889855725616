import styled from 'styled-components';

export const StyledInfoContainer = styled.div`
  width: 100%;
  background: rgba(0, 133, 255, 0.08);
  border-radius: 12px;
  padding: 13px;
  display: flex;
  color: #0085FF;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;

  &.error {
    background: rgba(244, 48, 48, 0.12);
    color: #F43030;
    font-weight: bold;
  }
  
  &.transparent {
    background: #fff;
    color: #000000;
    
    img {
        visibility: hidden;
    }
  }
  
  &.stacked {
    margin-top: 10px;
  }
`;

export const StyledInfoImage = styled.img`
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 10px;
`;
