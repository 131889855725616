import React, {useEffect, useState} from 'react';
import {IonAccordion, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledButton, StyledGrayButton} from "../../../components/button/button.style";
import {RecruitmentPathData, RecruitmentPathFaqEntryData} from "../../../models/recruitmentPathData";
import {useTranslation} from "react-i18next";

type AccordionItemProps = {
    id: string;
    faqEntry: RecruitmentPathFaqEntryData
    updateShowTest: (type: boolean) => void;
    recruitmentPathData: RecruitmentPathData;
    openContactForm: () => void;
    testPassed: (data: any) => boolean;
    updateShowAdaptiveTest: (type: boolean) => void;
};


const AccordionItem: React.FC<AccordionItemProps> = (props: AccordionItemProps) => {

    const {t} = useTranslation();

    return (
        <IonAccordion value={props.faqEntry.type == 'other' ? props.id : props.faqEntry.type}>
            <IonItem slot="header" className="recruitment-faq-item">
                <IonLabel style={{"width": "10%"}}>
                    <span className="number">{props.id + 1}</span>
                    <span className="title">{props.faqEntry.title}</span>
                </IonLabel>
            </IonItem>
            <div className="ion-padding recruitment-faq-item-content justify" slot="content">
                {props.faqEntry.content}

                {props.faqEntry.icon_path &&
                    <div className="icon">
                        <img src={props.faqEntry.icon_path} />
                    </div>
                }

                {props.faqEntry.showActionButton &&
                    <div>
                        <StyledButton onClick={() => props.updateShowTest(true)} disabled={props.testPassed(props.recruitmentPathData?.results)}>
                            {props.testPassed(props.recruitmentPathData?.results) ? t('recruitment.recruitmentPath.test.passed') : t('recruitment.recruitmentPath.test.start')}
                        </StyledButton>
                    </div>
                }

                {props.faqEntry.isAdaptiveTestSection &&
                    <>
                        {
                            !props.recruitmentPathData.recruitment_path_completed && props.recruitmentPathData?.faq.showAdaptiveTest &&
                                <StyledButton onClick={() => props.updateShowAdaptiveTest(true)}>
                                    {t('recruitment.recruitmentPath.test.start')}
                                </StyledButton>
                        }

                        {
                            props.recruitmentPathData.recruitment_path_completed &&
                                <StyledButton onClick={() => props.updateShowAdaptiveTest(true)} className="disabled">
                                    {t('recruitment.recruitmentPath.adaptiveTest.passed')}
                                </StyledButton>
                        }
                    </>
                }

                {props.recruitmentPathData?.contract_form_url &&
                    props.recruitmentPathData?.contract_form_completed == false &&
                    props.faqEntry.showContractFormActionButton == true &&
                    <div>
                        <StyledButton onClick={() => props.openContactForm()}>
                            {t('recruitment.recruitmentPath.test.start')}
                        </StyledButton>
                    </div>
                }

            </div>
        </IonAccordion>
    )
};

export default AccordionItem;