const snakeToCamel = (value: any) => {
	if (value != null && value !== "null" && value !== "undefined") {
		return value.split("_")
			.reduce(
				(res:string, word:string, i:number) =>
					i === 0
						? word.toLowerCase()
						: `${res}${word.charAt(0).toUpperCase()}${word
							.substr(1)
							.toLowerCase()}`,
				""
			);
	}

	return "";
};

// Encoding UTF8 ⇢ base64
export function b64EncodeUnicode(str: string) {
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
		return String.fromCharCode(parseInt(p1, 16))
	}))
}

// Decoding base64 ⇢ UTF8
export function b64DecodeUnicode(str: string) {
	return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
	}).join(''))
}

export { snakeToCamel };
