import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledFooter} from '../../../components/pane/pane.style';
import Form from '../../../components/form'
import {useForm} from 'react-hook-form';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {StyledButton} from "../../../components/button/button.style";
import {Contract} from "../../../models/contract";
import {StyledOption} from "../../../components/options/options.style";
import {
    ContractExitInterviewAnswer,
    ContractExitInterviewQuestion
} from "../../../models/contractExitInterview";
import {StyledTextarea} from "../../../components/form/input/textarea.style";
import {exitInterviewCoordinator, exitInterviewDepartment} from "../../../services/contract.service";
import {DataSelectable} from "../../reportPage/components/report/reportRowForm.component";
import {StyledIonSelect} from "../../../components/form/input/select.style";
import {IonSelectOption} from "@ionic/react";
import {StyledField} from "../../../components/form/input/input.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type ExitInterviewModalProps = {
    isOpen: boolean;
    contract?: Contract,
    questions?: ContractExitInterviewQuestion[],
    onClose: () => void;
    onSave: (selectedAnswers: ContractExitInterviewQuestion[], department?: string, coordinator?: string) => void;
};

const ExitInterviewModal: React.FC<ExitInterviewModalProps> = (props: ExitInterviewModalProps) => {

    const {t} = useTranslation();

    const [showExitInterview, updateShowExitInterview] = useState<boolean>(false);
    const [selectedAnswers, updateSelectedAnswers] = useState<ContractExitInterviewQuestion[]>();
    const [lastSelect, updateLastSelect] = useState<string>('');
    const [isValid, updateIsValid] = useState<boolean>(false);
    const [departments, updateDepartments] = useState<DataSelectable[]>();
    const [selectedDepartment, updateSelectedDepartment] = useState<DataSelectable>();
    const [coordinators, updateCoordinators] = useState<DataSelectable[]>();
    const [selectedCoordinator, updateSelectedCoordinator] = useState<DataSelectable>();

    const {handleSubmit} = useForm({
        mode: 'onChange'
    });

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    useEffect(() => {
        loadDepartments();
        loadCoordinators();
    }, []);

    const loadDepartments = async () => {
        await exitInterviewDepartment().then(response => {
            updateDepartments(response.data);
        });
    }

    const loadCoordinators = async () => {
        await exitInterviewCoordinator().then(response => {
            updateCoordinators(response.data);
        });
    }

    const handleOpen = () => {
        updateShowExitInterview(props.isOpen);
    };

    const onSubmit = () => {
        if (selectedAnswers) {
            props.onSave(selectedAnswers, selectedDepartment?.id, selectedCoordinator?.id);
            updateSelectedAnswers(props.questions);
        }
    };

    const handleSelectAnswers = (openAnswer: string, question: ContractExitInterviewQuestion, selected: ContractExitInterviewAnswer) => {
        let answers = question.answers;
        if (selectedAnswers) {
            var result = props.questions;
        } else {
            var result = props.questions;
        }
        var valid = true;
        if (result) {
            result.forEach((question2, index) => {
                if (question2.id == question.id) {
                    answers.forEach((answer, index) => {
                        if (answer.answer == selected.answer) {
                            question.answers[index].selected = true;
                            question.answers[index].openAnswer = openAnswer;
                        } else {
                            question.answers[index].selected = false;
                            question.answers[index].openAnswer = '';
                        }
                    });
                }
            });
            var count = 0;
            var countOk = 0;
            result.forEach((question2, index) => {
                let answers = question2.answers;
                count = count + 1;
                answers.forEach((answer, index) => {
                    if ((answer.selected && !answer.require_open_answer) || (answer.selected && answer.require_open_answer && answer.openAnswer != '')) {
                        countOk = countOk + 1;
                    }
                });
            });
            if (count == countOk) {
                updateIsValid(true);
            }else{
                updateIsValid(false);
            }
        }
        updateSelectedAnswers(result);
        updateLastSelect(question.question + selected.answer);
    };


    return (
        <Modal
            isOpen={showExitInterview}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.exitInterview.title")}</h1>
                    <div className="content">
                        {departments && departments.length > 0 &&
                            <StyledField>
                                <label className="required">{t("contractPage.exitInterview.placeOfService")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedDepartment}
                                                 justify="end"
                                                 compareWith={(o1: DataSelectable, o2: DataSelectable) => {
                                                     return o1 && o2 ? o1.id === o2.id : o1 === o2;
                                                 }}
                                                 name="placeOfService"
                                                 interfaceOptions={
                                                     {
                                                         header: t("contractPage.exitInterview.placeOfService")
                                                     }
                                                 }
                                                 onIonChange={e => {
                                                     updateSelectedDepartment(e.detail.value);
                                                 }}>
                                    {departments?.map((value: DataSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>

                                <hr/>
                            </StyledField>
                        }
                        {coordinators && coordinators.length > 0 &&
                            <StyledField>
                                <label className="required">{t("contractPage.exitInterview.coordinator")}</label>
                                <StyledIonSelect className="bolded" okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 value={selectedCoordinator}
                                                 justify="end"
                                                 compareWith={(o1: DataSelectable, o2: DataSelectable) => {
                                                     return o1 && o2 ? o1.id === o2.id : o1 === o2;
                                                 }}
                                                 name="coordinator"
                                                 interfaceOptions={
                                                     {
                                                         header: t("contractPage.exitInterview.coordinator")
                                                     }
                                                 }
                                                 onIonChange={e => {
                                                     updateSelectedCoordinator(e.detail.value);
                                                 }}>
                                    {coordinators?.map((value: DataSelectable, key) =>
                                        <IonSelectOption key={key}
                                                         value={value}>{value.name}</IonSelectOption>)}
                                </StyledIonSelect>

                                <hr/>
                            </StyledField>
                        }
                        {
                            props.questions && props.questions.map((question, index) => {
                                return <>
                                    <h6>{question.question}</h6>
                                    {
                                        question.answers && question.answers.map((answer, index2) => {
                                            return <>
                                                <StyledOption key={index2}
                                                              className={selectedAnswers && selectedAnswers[index].answers[index2]?.selected ? 'selected' : ''}
                                                              onClick={() => handleSelectAnswers('', question, answer)}>{answer.answer}</StyledOption>

                                                {selectedAnswers && selectedAnswers[index].answers[index2]?.selected && selectedAnswers[index].answers[index2]?.require_open_answer &&
                                                    <StyledTextarea className="full-width margin-bottom"
                                                                    placeholder={t('contractPage.exitInterview.openAnswerPlaceholder')}
                                                                    onChange={(event) => handleSelectAnswers(event.target.value, question, answer)}></StyledTextarea>
                                                }
                                            </>
                                        })
                                    }
                                </>
                            })
                        }
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <StyledButton type="submit"
                                      disabled={!isValid || (departments && departments.length > 0 && !selectedDepartment) || (coordinators && coordinators.length > 0 && !selectedCoordinator)}>{t("contractPage.exitInterview.button")}</StyledButton>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
        </Modal>
    );
};

export default ExitInterviewModal;