import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import useNavigation from '../../../services/navigation.service';
import {StyledAvailabilityOption} from '../../availabilityPage/panes/availability.style';
import Calendar from '../components/calendar/calendar.component';
import TaskItem, {Task, TaskStatus} from '../components/taskItem/taskItem.component';
import {StyledCategory} from '../../mainPage/tabs/notifications/notificationsTab.style';
import PinImage from '../../../assets/images/pin-grey.svg';
import StaticPane from "../../../components/pane/static-pane.component";
import {approveTask, getActivities, rejectTask} from "../../../services/timetable.service";
import TaskItemSkeleton from "../components/taskItem/taskItemSkeleton.component";
import {StyledDetailViewContainer} from "../../absencePage/absencePage.style";
import CalendarItemSkeleton from "../components/taskItem/calendarItemSkeleton.component";
import {StyledCalendarContainer} from "../components/calendar/calendar.style";
import {IonAlert, IonGrid, IonRow, useIonViewWillEnter} from "@ionic/react";
import PreviewTaskItemModal from "../modals/previewTaskItemModal.component";
import moment from "moment";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import {log} from "../../../services/firebaseAnalytics.service";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import AvailabilityExActionSheet, {AvailabilityExActionSheetContext} from "../../availabilityPage/components/availabilityExActionSheet/availabilityExActionSheet.component";
import {editAbsence, removeAbsence} from "../../../services/absence.service";
import Info from "../../../components/info/info.component";
import TimetableGuideModal from "../modals/timetableGuideModal.component";
import AvailabilityGuideModal from "../../availabilityPage/modals/availabilityGuideModal.component";
//import {log} from "../../../../../services/firebaseAnalytics.service";

type TimetablePaneProps = {
    topEdge?: number;
}

enum TimetableOption {
    SIMPLIFIED,
    MONTH
}

type SimplifiedCategory = {
	id: number;
    place?: string;
    title: string;
    tasks: Task[];
}

const TimetablePane: React.FC<TimetablePaneProps> = (props: TimetablePaneProps) => {
	const context = useContext(AvailabilityExActionSheetContext);

    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [ option, updateOption ] = useState<TimetableOption>(parseInt(navigation.getParam<string>('optionTimetable')) || TimetableOption.SIMPLIFIED);
    const selectTimetableTab = (option: TimetableOption) => {
        navigation.setParam('optionTimetable', option.toString());
        updateOption(option);
    }

    const [categories, updateCategories] = useState<SimplifiedCategory[]>([]);
    const [tasks, updateTasks] = useState<Date[]>([]);
	const [currentTask, updateCurrentTask] = useState<Task | undefined>();
	const [showApproveAlert, setShowApproveAlert] = useState(false);
	const [showDismissAlert, setShowDismissAlert] = useState(false);
	const [showPreviewTaskItemModal, updatePreviewTaskItemModal] = useState<boolean>(false);
	const [ addException, setAddException ] = useState(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toast, setToast] = useState<string>('');
	const [showRemoveAlert, setShowRemoveAlert] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showGuide, updateShowGuide] = useState<boolean>(false);

    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    const oneHour = new Date();
    oneHour.setHours(new Date().getHours() + 1);

	const fetchActivities = async (notification: number) => {
		setLoading(true);
		const response = await getActivities()
			.then(response => {
				let data = response.data;

				if (data.length > 0) {

					let categories: SimplifiedCategory[] = [];
					let taskToOpen: Task|undefined = undefined;
					let tasksDates: Date[] = [];

					data.forEach((activity: any) => {
						if (activity.type == 'ABSENCE') {
							let category: SimplifiedCategory = {
								id: activity.id,
								title: t('notificationTab.timetablePane.absence'),
								tasks: []
							};

							let task: Task = {
								id: activity.id,
								startDate: moment(activity.start_date).toDate(),
								endDate: moment(activity.end_date).toDate(),
								allDay: activity.attributes.all_day,
								description: activity.attributes.reason,
								place: '',
								status: TaskStatus.ABSENCE,
								type: activity.type
							};

							category.tasks.push(task);
							categories.push(category);
							tasksDates.push(moment(activity.start_date).toDate());
						} else if (activity.type == 'PROPOSAL') {
							let category: SimplifiedCategory = {
								id: activity.id,
								title: activity.attributes.job_position ? activity.attributes.job_position : t('notificationTab.timetablePane.workProposal'),
								place: activity.attributes.place_of_service,
								tasks: []
							};

							let task: Task = {
								id: activity.id,
								startDate: moment(activity.start_date).toDate(),
								endDate: moment(activity.end_date).toDate(),
								allDay: false,
								description: activity.attributes.description,
								place: activity.attributes.place_of_service,
								jobPosition: activity.attributes.job_position,
								status: TaskStatus[activity.attributes.status] as unknown as number,
								acceptEnabled: activity.attributes.accept_enabled,
								type: activity.type,
								coordinator: activity.attributes.coordinator
							};

							category.tasks.push(task);
							categories.push(category);
							tasksDates.push(moment(activity.start_date).toDate());

							if (notification && notification == activity.id) {
								taskToOpen = task;
							}
						}
					});

					updateCategories(categories);
					updateTasks(tasksDates);

					if (taskToOpen) {
						updateCurrentTask(taskToOpen);
						updatePreviewTaskItemModal(true);
						navigation.setParam('notification', "0");
					}
				}

			})
			.catch(error => {
				// todo what we show??
			});
		setLoading(false);
	};

	useEffect(() => {
		let activityId: number = navigation.getParam('notification') ? navigation.getParam('notification') : 0;
		fetchActivities(activityId);
	}, []);

	useIonViewWillEnter(() => {
		fetchActivities(0);
	});

	const approve = async (task: Task) => {
		//setLoading(true);

		const response = await approveTask(task.id as number)
			.then(response => {
				updatePreviewTaskItemModal(false);

				fetchActivities(0);
			})
			.catch(error => {
				// todo what we show??
			});
		//setLoading(false);
	};

	const dismiss = async (task: Task) => {
		//setLoading(true);

		const response = await rejectTask(task.id as number)
			.then(response => {
				updatePreviewTaskItemModal(false);

				fetchActivities(0);
			})
			.catch(error => {
				// todo what we show??
			});
		//setLoading(false);
	};

	const onApprove = (task: Task) => {
		if (task) {
			updateCurrentTask(task);
			setShowApproveAlert(true);
		}
	};

	const onDismiss = (task?: Task) => {
		if (task) {
			updateCurrentTask(task);
			setShowDismissAlert(true);
		}
	}

	const handleTaskItemClick = (task: Task) => {
		if (task.type == 'PROPOSAL') {
			updateCurrentTask(task);
			updatePreviewTaskItemModal(true);
		}
	}

	const onApproveAlertDismiss = () => {
		setShowApproveAlert(false);

		if (!showPreviewTaskItemModal) {
			updateCurrentTask(undefined);
		}
	}

	const onDismissAlertDismiss = () => {
		setShowDismissAlert(false);

		if (!showPreviewTaskItemModal) {
			updateCurrentTask(undefined);
		}
	}

	const [edit, updateEdit] = useState<number>(-1);

	const fillException = (task: Task | undefined = undefined) => {
		updateCurrentTask(task);
		//updateEdit(absences.indexOf(task as Task));
		setAddException(true);
	};

	const handleExceptionChange = async (task: Task) => {
		if (task.id) {
			const response = await editAbsence(task.id, task.startDate, task?.endDate || task.startDate, task.description)
				.then(response => {
					fetchActivities(0);
					setAddException(false);
					return true;
				})
				.catch(error => {
					setToast(t("common.serverErrorMsg"));
					setShowToast(true);
					context.setErrors(error.response.data.errors);
					return false;
				});

			return response;
		}
		setAddException(false);

		return false;
	};

	const onRemoveAlertDismiss = () => {
		setShowRemoveAlert(false);
	}

	const onRemove = (task: Task) => {
		updateCurrentTask(task);
		setShowRemoveAlert(true);
	}

	const remove = async(task: Task) => {
		if (task.id) {
			const response = await removeAbsence(task.id)
				.then(response => {
					fetchActivities(0);
				});
		}

		return false;
	}

	useEffect(() => {
		log('page_visit', {
			page: 'Powiadomienia -> Harmonogram'
		});
	}, []);

	return (
        <StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
			<IonAlert
				isOpen={showApproveAlert}
				onDidDismiss={() => onApproveAlertDismiss()}
				header={t('notificationTab.timetablePane.alert.approve.title')}
				message={t('notificationTab.timetablePane.alert.approve.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowApproveAlert(false);

							if (!showPreviewTaskItemModal) {
								updateCurrentTask(undefined);
							}
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								approve(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>
			<IonAlert
				isOpen={showDismissAlert}
				onDidDismiss={() => onDismissAlertDismiss()}
				header={t('notificationTab.timetablePane.alert.dismiss.title')}
				message={currentTask && currentTask.status == TaskStatus.ACCEPTED ? t('notificationTab.timetablePane.alert.dismissAccepted.message') : t('notificationTab.timetablePane.alert.dismiss.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowDismissAlert(false);

							if (!showPreviewTaskItemModal) {
								updateCurrentTask(undefined);
							}
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								dismiss(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>
			<IonAlert
				isOpen={showRemoveAlert}
				onDidDismiss={() => onRemoveAlertDismiss()}
				header={t('profileTab.absence.alert.remove.title')}
				message={t('profileTab.absence.alert.remove.message')}
				buttons={[
					{
						text: t('common.alertCancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							setShowRemoveAlert(false);
						}
					},
					{
						text: t('common.alertConfirm'),
						handler: () => {
							if (currentTask) {
								remove(currentTask);
							} else {
								//
							}
						}
					}
				]}
			/>

			{
				!loading && categories && categories.length > 0 &&
				<>
					<span
						onClick={() => updateShowGuide(true)}><Info>{t("notificationTab.timetablePane.info")}</Info></span>
					<StyledAvailabilityOption style={{"marginTop": "10px"}}>
						<div onClick={() => selectTimetableTab(TimetableOption.SIMPLIFIED)}
							 className={option === TimetableOption.SIMPLIFIED ? 'selected' : ''}>{t('notificationTab.timetablePane.simplified')}</div>
						<div onClick={() => selectTimetableTab(TimetableOption.MONTH)}
							 className={option === TimetableOption.MONTH ? 'selected' : ''}>{t('notificationTab.timetablePane.month')}</div>
					</StyledAvailabilityOption>
				</>
			}

			{
				!loading && option === TimetableOption.SIMPLIFIED &&
				categories.map((category, categoryKey) =>
					<StyledCategory key={categoryKey}>
						<span className="category-name">{ category.title }</span>
						{
							category.place && <span className="category-place"><img src={PinImage}/>{ category.place }</span>
						}

						{
							category.tasks.map((task, key) =>
								<div key={task.id} onClick={(e) => handleTaskItemClick(task)}>
									<TaskItem key={key} task={task} onApprove={() => onApprove(task)} onDismiss={() => onDismiss(task)} onEdit={(task) => fillException(task)} onRemove={(task) => onRemove(task)} />
								</div>
							)
						}
					</StyledCategory>
				)
			}

			{
				loading && option === TimetableOption.SIMPLIFIED && <StyledDetailViewContainer>
					<TaskItemSkeleton />
					<TaskItemSkeleton />
					<TaskItemSkeleton />
					<TaskItemSkeleton />
					<TaskItemSkeleton />
				</StyledDetailViewContainer>
			}

			{
				!loading && option === TimetableOption.MONTH && <Calendar startDay={1} taskList={tasks} />
			}

			{
				loading && option === TimetableOption.MONTH && <StyledCalendarContainer>
					<IonGrid>
						<IonRow>
							<CalendarItemSkeleton size="8" offset="4" />
						</IonRow>
						<IonRow>
							<CalendarItemSkeleton size="12" offset="0" />
						</IonRow>
						<IonRow>
							<CalendarItemSkeleton size="12" offset="0" />
						</IonRow>
						<IonRow>
							<CalendarItemSkeleton size="12" offset="0" />
						</IonRow>
						<IonRow>
							<CalendarItemSkeleton size="8" offset="0" />
						</IonRow>
					</IonGrid>
				</StyledCalendarContainer>
			}

			{
				!loading && categories && categories.length === 0 &&
				<StyledEmptyContainer>
					<CircleImage image={ImageTypes.NOTE} color="grey"/>
					<h3>{t('notificationTab.emptyTimetable')}</h3>
				</StyledEmptyContainer>
			}

			<PreviewTaskItemModal
				isOpen={showPreviewTaskItemModal}
				onClose={() => updatePreviewTaskItemModal(false)}
				onApprove={onApprove}
				onDismiss={onDismiss}
				taskItem={currentTask}>
			</PreviewTaskItemModal>

			<AvailabilityExActionSheet
				isVisible={addException}
				task={currentTask}
				onSave={(task: Task) => handleExceptionChange(task)}
				onDismiss={() => { setAddException(false); updateCurrentTask(undefined) }}
			/>

			<TimetableGuideModal isOpen={showGuide} onClose={() => updateShowGuide(false)} />
		</StaticPane>
    );
};

export default TimetablePane;
