import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {checkLoanAvailability, getLoans, addLoan} from "../../../services/loan.service";
import {IonList, IonToast, IonItem, useIonViewWillEnter, useIonViewDidEnter} from "@ionic/react";
import StaticPane from "../../../components/pane/static-pane.component";
import LoanSkeleton from "../../../components/loan/loanSkeleton.component";
import {StyledField} from "./newLoanModal.style";
import {StyledInput} from "../../../components/form/input/input.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import Form from "../../../components/form";
import {useForm} from "react-hook-form";
import {Links} from "../../links";
import {useHistory} from "react-router-dom";
import {StyledIonLabel} from "../../../components/form/input/label.style";
import {StyledIonCheckbox} from "../../../components/form/input/checkbox.style";
import {snakeToCamel} from "../../../utils/tools/string";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type NewLoanModalModalProps = {
    minAmount: number;
    maxAmount: number;
    currency: string;
    bankAccountNumber: any;
    isOpen: boolean;
    onClose: () => void;
    updateLoans: () => void;
    updateLoansAndShowApprovals: () => void;
    availabilityMessage: string;
};


interface NewLoanManager {
    setMaxAmount: Function;
    setAvailabilityMessage: Function;
}

const contextNewLoan: NewLoanManager = {
    setMaxAmount: () => {

    },
    setAvailabilityMessage: () => {

    }
};

export const NewLoanContext = React.createContext<NewLoanManager>(contextNewLoan);

const NewLoanModal: React.FC<NewLoanModalModalProps> = (props: NewLoanModalModalProps) => {

    const {t} = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [showNew, updateShowNew] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [availabilityMessage, setAvailabilityMessage] = useState<number>(0);
    const [currency, setCurrency] = useState<string>('');
    const [requestedAmount, setRequestedAmount] = useState<number>(0);
    const [bankAccountConfirmed, updateBankAccountConfirmed] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');

    const handleClose = () => {
        updateBankAccountConfirmed(false);
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowNew(props.isOpen);
    };

    const {register, errors, setValue, handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const { isValid, isDirty } = formState;

    const onSubmit = async (data: any) => {
        await addLoan(data.amount)
            .then(response => {
                if (response.data?.result == 'success') {
                    props.updateLoansAndShowApprovals();
                    handleClose();
                } else if (response.data?.result == 'not_available') {
                    setToast(t('loanPage.newLoan.errors.' + snakeToCamel(response.data.message)));
                    setShowToast(true);
                } else {
                    setToast(t("common.serverErrorMsg"));
                    setShowToast(true);
                }
            }).catch(error => {
                // todo what we show??
            });
    }

    let invalidRequestedAmountError = t("loanPage.newLoan.form.invalidRequestedAmount");

    const validateRequestedAmount = (requestedAmount: number): boolean => {
        return requestedAmount > 0 && requestedAmount >= parseFloat(props.minAmount as unknown as string) && requestedAmount <= parseFloat(props.maxAmount as unknown as string)
    }

    return (
        <NewLoanContext.Provider value={contextNewLoan}>
            <Modal
                isOpen={showNew}
                canDismiss={disableSwipeToClose}
                onDidDismiss={() => handleClose()}>
                    <StyledModalContent>
                        <h1 className="title full">{t("loanPage.newLoan.title")}</h1>

                        {props.maxAmount > 0 &&
                            <div className="content">
                                <p>{t("loanPage.newLoan.amount")}: {props.maxAmount} {props.currency}</p>

                                <Form.Container onSubmit={handleSubmit(onSubmit)}>
                                    {/*<small>{t("loanPage.newLoan.desc")}</small>*/}
                                    <StyledField>
                                        <label className='required'>{t('loanPage.newLoan.form.requestedAmount')}</label>
                                        <StyledInput
                                            className={errors['amount'] ? 'bolded no-margin hasErrors hide-spin-button' : 'bolded no-margin hide-spin-button'}
                                            name="amount"
                                            ref={register({
                                                required: true,
                                                validate: value =>
                                                    validateRequestedAmount(value) || invalidRequestedAmountError
                                            })}
                                            type="number"
                                            step="0.01"
                                            onChange={e => {
                                                setRequestedAmount(e.target.value as unknown as number)
                                            }}
                                        ></StyledInput>
                                        {
                                            errors.amount &&
                                            errors.amount.message &&
                                            <p className="errorMessage">{requestedAmount < props.minAmount ? t('loanPage.newLoan.form.RequestedAmountLowerThanMin') : requestedAmount > props.maxAmount ? t('loanPage.newLoan.form.RequestedAmountGreaterThanMax') : errors.amount.message}</p>
                                        }
                                    </StyledField>

                                    <StyledField></StyledField>

                                    <StyledField>
                                        <label>{t("loanPage.newLoan.bankAccountNumber")}: {props.bankAccountNumber}</label>
                                        <IonItem lines="none">
                                        <StyledIonLabel className="ion-text-wrap">{t('loanPage.approvals.checkboxes.confirmAccountNumber')}</StyledIonLabel>
                                        <StyledIonCheckbox name="bankAccountConfirmed" slot="start" onIonChange={(e) => {
                                            updateBankAccountConfirmed(e.detail.checked);
                                        }}/>
                                        </IonItem>
                                    </StyledField>

                                    <StyledField className="bank-account-number-invalid">
                                        <label>{t("loanPage.newLoan.bankAccountNumberInfo")}</label>
                                    </StyledField>

                                    <StyledFooter className="modal">
                                        <Form.Button type="submit"
                                                     disabled={!isValid || !bankAccountConfirmed}>{t("loanPage.newLoan.form.next")}</Form.Button>
                                    </StyledFooter>
                                </Form.Container>
                            </div>
                        }

                        {
                            props.maxAmount == 0 &&
                            <div className="content">
                                <p>{t("loanPage.newLoan." + (props.availabilityMessage ? 'errors.' + snakeToCamel(props.availabilityMessage) : 'loanNotAvailable'))}</p>
                            </div>
                        }
                    </StyledModalContent>
            </Modal>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
        </NewLoanContext.Provider>
    );
};

export default NewLoanModal;