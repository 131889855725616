import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const basicTextStyle = css`
  text-align: center;
  font-size: 12px;
  line-height: 15px;
`
export const StyledText = styled.div`
  ${basicTextStyle};
  
  &.highlighted {
    color: red;
    font-weight: bold;
  }
`;

export const StyledTextLink = styled(Link)`
  ${basicTextStyle};

  color: #0085FF;
  
  &.highlighted {
    color: red;
    font-weight: bold;
  }
`

export const StyledTextHref = styled.a`
  ${basicTextStyle};

  color: #0085FF;
  cursor: pointer;
`

