import React from 'react';
import {
    StyledLoanButton,
    StyledLoanContainer,
    StyledLoanProps
} from './loan.style';
import ClockImage from '../../assets/images/time-clock.svg';
import WarningImage from '../../assets/images/warning-red.svg';
import CheckMarkImage from '../../assets/images/checkMarkGreen.svg';
import {Loan as LoanModel} from '../../models/loan';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {LoanStatus} from "../../enums/loanStatus";
import {StyledButtonBadge} from '../button/button.style';
import {Links} from "../../app/links";
import { useHistory } from 'react-router-dom';

const Loan: React.FC<StyledLoanProps> = (props: StyledLoanProps) => {
    const {t} = useTranslation();
    let loan: LoanModel = props.loan;
    const history = useHistory();

    function handleClick() {
        if (props.onClick) {
            props.onClick()
        }
    }

    return (
        <StyledLoanContainer onClick={handleClick}>
            <small><img
                src={ClockImage}/><span>{moment(loan.date).format('DD.MM.YYYY')}</span></small>
            <h1>{t('loanPage.loanNumber')}: {loan.number}</h1>
            <h1>{t('loanPage.loanAmount')}: {loan.amount} {loan.currency}</h1>
            <StyledLoanButton
                loan={props.loan}
                status={props.status}
                onClick={props.onClick}>

                {
                    (props.status === LoanStatus.UNSIGNED) &&
                    <>
                        <img src={WarningImage} />
                        <span>{t("loanPage.list.unsigned")}</span>
                    </>
                }

                {
                    (props.status === LoanStatus.EXPIRED) &&
                    <>
                        <img src={WarningImage} />
                        <span>{t("loanPage.list.expired")}</span>
                    </>
                }

                {
                    (props.status === LoanStatus.REJECTED) &&
                    <>
                        <img src={WarningImage} />
                        <span>{t("loanPage.list.rejected")}</span>
                    </>
                }

                {
                    (props.status === LoanStatus.SIGNED) &&
                    <>
                        <img src={CheckMarkImage} />
                        <span>{t("loanPage.list.signed")}</span>
                    </>
                }
            </StyledLoanButton>
        </StyledLoanContainer>
    );
};

export default Loan;
