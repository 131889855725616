import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Order, Report, ReportRow, ReportRowComponent} from '../reportPage.component';
import CloseImage from "../../../assets/images/e-remove.svg";
import {StyledIonModal, StyledModalContent} from "../../../components/modal/modal.style";
import {StyledContent} from "./commentsModal.style";
import ReportRowComponentForm from "../components/report/reportRowComponentForm.component";
import {Profile} from "../../../models/profile";
import {StyledButton, StyledWiteButton} from "../../../components/button/button.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import {validateRowComponent} from "../../../services/report.service";

type FilesModalProps = {
    isOpen: boolean;
    orderId?: string,
    reportId?: string,
    profile?: Profile,
    specificationId?: string,
    row: ReportRow;
    component: ReportRowComponent;
    report: Report;
    order?: Order;
    reportPreview: boolean;
    onClose: () => void;
    onSubmit: (component: ReportRowComponent) => void;
};

const ReportRowComponentEditModal: React.FC<FilesModalProps> = (props: FilesModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [component, setComponent] = useState<ReportRowComponent>(props.component);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    const handleSubmit = () => {
        props.onSubmit(component);
    };

    return (
        <StyledIonModal
            isOpen={showModal}
            onDidDismiss={() => {handleClose()}}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
        >
            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose() } />
                <StyledContent className="content full-height">
                    <ReportRowComponentForm
                        key={'component_modal_' + component.id}
                        orderId={props.orderId}
                        specificationId={props.specificationId}
                        reportId={props.reportId}
                        profile={props.profile}
                        row={props.row}
                        component={props.component}
                        modalEdit={true}
                        report={props.report}
                        order={props.order}
                        reportPreview={props.reportPreview}
                        onChange={(c) => {
                            setComponent({
                                ...component,
                                serialNumber: c.serialNumber,
                                quantity: c.quantity,
                                deliveryNote: c.deliveryNote,
                                levNumber: c.levNumber,
                                componentNumberText: c.componentNumberText,
                                files: c.files,
                                defectGroups: c.defectGroups,
                                comment: c.comment,
                                batchNumber: c.batchNumber,
                            })
                        }}
                        onRemove={(component) => {}}
                        onSubmit={() => {}}
                        onAdd={() => {}}
                    />
                    <StyledFooter className="modal footer no-shadow">
                        {props.order && <StyledButton type="submit" disabled={!validateRowComponent(component, props.order, props.specificationId == '0')} onClick={() => {
                            props.onSubmit(component)
                        }}>{t("reportPage.components.edit.confirm")}</StyledButton>}
                    </StyledFooter>
                </StyledContent>
            </StyledModalContent>
        </StyledIonModal>
    );
};

export default ReportRowComponentEditModal;