import React, { PropsWithChildren } from 'react';
import {IonButton, IonContent, IonImg} from "@ionic/react";
import {StyledPhotoPreviewModal} from "@components/modal/photoPreviewModal.style";
import {useTranslation} from "react-i18next";

type PhotoPreviewModalProps = {
    isOpen: boolean,
    photo: string,
    onClose: () => void,
};

const Modal: React.FC<PropsWithChildren<PhotoPreviewModalProps>> = ({isOpen, photo, onClose}) => {
    const {t} = useTranslation();

    const handleClose = () => {
        onClose();
    };

    return (
        <StyledPhotoPreviewModal isOpen={isOpen} onDidDismiss={handleClose}>
            <IonContent>
                <IonImg src={`data:image/jpeg;base64,${photo}`} />
                <IonButton onClick={handleClose} expand="full">{t('photoPreviewModal.close')}</IonButton>
            </IonContent>
        </StyledPhotoPreviewModal>
    );
};

export default Modal;