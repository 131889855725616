import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {Contract} from "../../../models/contract";
import Form from '../../../components/form';
import {useForm} from "react-hook-form";
import {StyledInput} from "../../../components/form/input/input.style";
import {StyledField} from "../../teamMemberPage/teamMemberPage.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import {documentsStatementGenerate} from "../../../services/contract.service";
import {StyledButton, StyledLightBlueButton, StyledWiteButton} from "../../../components/button/button.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";


type DocumentStatementGenerateModalProps = {
    isOpen: boolean;
    contract?: Contract,
    onClose: () => void;
    onSubmit: () => void;
};

const DocumentStatementGenerateModal: React.FC<DocumentStatementGenerateModalProps> = (props: DocumentStatementGenerateModalProps) => {

    const {t} = useTranslation();

    const [show, updateShow] = useState<boolean>(false);

    const [schoolName, setSchoolName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [schoolYear, setSchoolYear] = useState<string>('');
    const [schoolPeriod, setSchoolPeriod] = useState<string>('');
    const [documentsStatementGeneratedText, updateDocumentsStatementGeneratedText] = useState<string>('');

    const {register, errors, setValue, handleSubmit, formState} = useForm({
        mode: 'onChange'
    });

    const { isValid, isDirty } = formState;

    const onSubmit = async (data: any) => {
        if (props.contract) {
            await documentsStatementGenerate(props.contract.id, schoolName, city, schoolYear, schoolPeriod)
                .then(response => {
                    updateDocumentsStatementGeneratedText(response.data.html);
                })
        }
    }

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    return (
        <Modal
            isOpen={show}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleClose()}>
                <StyledModalContent>
                    <h1 className="title">{t("contractPage.documentsStatement.title")}</h1>
                    <div className="content">
                        {!documentsStatementGeneratedText &&
                            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                                <small>{t("contractPage.documentsStatement.desc")}</small>
                                <StyledField>
                                    <label
                                        className='required'>{t('contractPage.documentsStatement.form.schoolName')}</label>
                                    <StyledInput
                                        className={errors['schoolName'] ? 'bolded no-margin hasErrors' : 'bolded no-margin'}
                                        name="schoolName"
                                        ref={register({
                                            required: true
                                        })}
                                        type="text"
                                        onChange={e => {
                                            setSchoolName(e.target.value)
                                        }}
                                    ></StyledInput>
                                </StyledField>

                                <StyledField>
                                    <label className='required'>{t('contractPage.documentsStatement.form.city')}</label>
                                    <StyledInput
                                        className={errors['city'] ? 'bolded no-margin hasErrors' : 'bolded no-margin'}
                                        name="city"
                                        ref={register({
                                            required: true
                                        })}
                                        type="text"
                                        onChange={e => {
                                            setCity(e.target.value)
                                        }}
                                    ></StyledInput>
                                </StyledField>

                                <StyledField>
                                    <label
                                        className='required'>{t('contractPage.documentsStatement.form.schoolYear')}</label>
                                    <StyledInput
                                        className={errors['schoolYear'] ? 'bolded no-margin hasErrors gray-placeholder' : 'bolded no-margin gray-placeholder'}
                                        name="schoolYear"
                                        ref={register({
                                            required: true
                                        })}
                                        type="text"
                                        placeholder={t('contractPage.documentsStatement.form.schoolYearPlaceholder')}
                                        onChange={e => {
                                            setSchoolYear(e.target.value)
                                        }}
                                    ></StyledInput>
                                </StyledField>

                                <StyledField>
                                    <label
                                        className='required'>{t('contractPage.documentsStatement.form.schoolPeriod')}</label>
                                    <StyledInput
                                        className={errors['schoolPeriod'] ? 'bolded no-margin hasErrors' : 'bolded no-margin'}
                                        name="schoolPeriod"
                                        ref={register({
                                            required: true
                                        })}
                                        type="text"
                                        onChange={e => {
                                            setSchoolPeriod(e.target.value)
                                        }}
                                    ></StyledInput>
                                </StyledField>

                                <StyledFooter className="modal">
                                    <Form.Button type="submit"
                                                 disabled={!isDirty && isValid}>{t("contractPage.documentsStatement.generateSubmit")}</Form.Button>
                                </StyledFooter>
                            </Form.Container>
                        }

                        {
                            documentsStatementGeneratedText &&
                                <>
                                    <div dangerouslySetInnerHTML={{__html: documentsStatementGeneratedText || ''}}/>
                                    <StyledFooter className="modal">
                                        <StyledWiteButton onClick={() => updateDocumentsStatementGeneratedText('')}>{t("contractPage.documentsStatement.backSubmit")}</StyledWiteButton>
                                        <StyledButton onClick={() => props.onSubmit()}>{t("contractPage.documentsStatement.signSubmit")}</StyledButton>
                                    </StyledFooter>
                                </>
                        }
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default DocumentStatementGenerateModal;