import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Pane from '../../../components/pane/pane.component';
import {useHistory} from "react-router-dom";
import useNavigation from "../../../services/navigation.service";
import {
    AccordionGroupCustomEvent,
    IonAccordion,
    IonAccordionGroup, IonButtons, IonHeader,
    IonItem,
    IonLabel,
    IonList,
    useIonViewWillEnter
} from '@ionic/react';
import Info from "../../../components/info/info.component";
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from "../../../components/button/button.style";
import {Links} from "../../links";
import {
    getContactFormUrlByPath,
    getTestPathByHash,
    historyInsert,
    refreshPath
} from "../../../services/recruitment.service";
import {
    RecruitmentPathData,
    RecruitmentPathFaqData,
    RecruitmentPathFaqEntryData, RecruitmentPathTest, RecruitmentPathTestResult
} from "../../../models/recruitmentPathData";
import RecruitmentPathSkeleton from "../../../components/recruitment/recruitmentPathSkeleton.component";
import RecruitmentPathTestModal from "../modals/recruitmentPathTestModal.component";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import {StyledEmptyContainer, StyledIonTitle, StyledIonToolbar} from "../../../components/content/content.style";
import {StyledContent, StyledIonRecruitmentToolbar} from "./recruitmentPathPane.style";
import {RecruitmentPathTestType} from "../../../enums/recruitmentPathTestType";
import Test3 from "../components/test3/test3.component";
import {StyledFooter} from "../../../components/pane/pane.style";
import RecruitmentImage from "../../../assets/images/recruitment.svg";
import BackButtonImage from "../../../assets/images/recruitment/back.svg";
import ExactPeopleImg from "../../../assets/images/logo/xpeople.png";
import {Browser} from "@capacitor/browser";
import {logout, useAuth} from "../../../services/auth.service";
import AccordionItem from "../components/accordionItem.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {Capacitor} from "@capacitor/core";
import {App as AppPlugin} from "@capacitor/app";
import {Toast} from "@capacitor/toast";
import Test3Modal from "../modals/test3Modal.component";
import AdaptiveTestInfoModal from "../modals/adaptiveTestInfoModal.component";
import {RecruitmentPathTestVersion} from "../../../enums/recruitmentPathTestVersion";

type RecruitmentPathPaneProps = {
    topEdge?: number;
    hash: string;
}

const RecruitmentPathPane: React.FC<RecruitmentPathPaneProps> = (props: RecruitmentPathPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');

    const [recruitmentPathData, setRecruitmentPathData] = useState<RecruitmentPathData|null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showInfo, updateShowInfo] = useState<boolean>(true);
    const [showTest, updateShowTest] = useState<boolean>(false);
    const [showAdaptiveTestIntro, updateShowAdaptiveTestIntro] = useState<boolean>(false);
    const [showAdaptiveTest, updateShowAdaptiveTest] = useState<boolean>(false);
    const [showContactForm, updateShowContactForm] = useState<boolean>(false);
    const auth = useAuth();
    const header = useRef<HTMLIonHeaderElement>(null);
    const accordionRecruitment = useRef<null | HTMLIonAccordionGroupElement>(null);

    useEffect(() => {
        insertHistory({action_code: 'LOGIN'}, props.hash);
    }, [])

    const insertHistory = async(data: {}, hash:string) => {
        await historyInsert(data, hash).then(response => {
        })
        .catch(error => {
            // todo what we show??
        });
    }

    useEffect(() => {
        getPath(props.hash, true);
    }, []);

    useIonViewWillEnter(() => {
        getPath(props.hash, true);
    });

    const getPath = async (hash:string, forceAccordion:boolean = true) => {
        setLoading(true);
        await getTestPathByHash(hash)
            .then(response => {
                if (response.data.id) {
                    let recruitmentPathData = response.data as RecruitmentPathData;
                    setRecruitmentPathData(recruitmentPathData);
                    console.log(response.data);

                    if (recruitmentPathData?.contract_form_url) {
                        goToAccordion();
                    } else if (recruitmentPathData.faq.showAdaptiveTest && recruitmentPathData?.details[RecruitmentPathTestType.TEST_3]) {
                        // showAdaptiveTestIntro == true && recruitmentPathData?.details[RecruitmentPathTestType.TEST_3] && !recruitmentPathData?.contract_form_url &&
                        if (forceAccordion) {
                            goToAccordion();
                        } else {
                            updateShowInfo(false);
                            updateShowAdaptiveTestIntro(true);
                        }
                    }
                } else {
                    setRecruitmentPathData(null);
                }
            })
            .catch(error => {
                // todo what we show??
            });

        setLoading(false);
    }

    const faqEntriesGroupChange = (ev: AccordionGroupCustomEvent) => {
        const selectedValue = ev.detail.value;
    };

    const testPassed = (results: any) => {
        let failed: number = 0;

        if (results.length == 0) {
            return false;
        }

        if (!results[RecruitmentPathTestType.TEST_1] || results[RecruitmentPathTestType.TEST_1].result == false) {
            failed++;
        }

        if (!results[RecruitmentPathTestType.TEST_2] || results[RecruitmentPathTestType.TEST_2].result == false) {
            failed++;
        }

        return failed == 0;
    }

    const goToStart = () => {
        updateShowTest(false);
        updateShowInfo(false);
        updateShowContactForm(false);
        getPath(props.hash, true);
    }

    const goToAccordion = () => {
        updateShowInfo(false);
        updateShowTest(false);
        updateShowContactForm(false);
    }

    const openContactForm = async () => {
        let formUrl = recruitmentPathData?.contract_form_url ? recruitmentPathData?.contract_form_url : null;

        if (formUrl) {
            Toast.show({
                text: t('recruitment.recruitmentPath.externalLink'),
                duration: 'short',
                position: 'bottom',
            })

            Browser.open({ url: formUrl });
            Browser.addListener('browserFinished', () => {
                getPath(props.hash);
            });
        }
    };

    const logoutClick = async () => {
        await logout().then(() => {
            auth.signOut();
            history.replace(Links.login)
            window.location.reload();
        });
    }

    const goBack = async () => {
        if (recruitmentPathData?.faq.showAdaptiveTest) {
            if (showAdaptiveTestIntro == true) {
                updateShowAdaptiveTestIntro(false);
            } else {
                goToProfile();
            }
        } else {
            goToProfile();
        }
    }

    const getActiveItem = () => {
        if (recruitmentPathData?.recruitment_path_flow_version == RecruitmentPathTestVersion.VER_2) {
            if (recruitmentPathData?.contract_form_url &&
                recruitmentPathData.tests_passed &&
                !recruitmentPathData?.contract_form_completed) {
                return 'form';
            }

            if (!recruitmentPathData.contract_form_url && // recruitmentPathData?.recruitment_path_completed &&
                recruitmentPathData.tests_passed) {
                return 'contact';
            }

            if (recruitmentPathData?.faq.showAdaptiveTest == true) {
                return 'adaptive_tests';
            }

            return 'tests';
        } else {
            if (recruitmentPathData?.contract_form_url) { // recruitmentPathData?.contract_form_completed == false
                return 'form';
            }

            if (recruitmentPathData?.recruitment_path_completed) {
                return 'contact';
            }

            if (recruitmentPathData?.faq.showAdaptiveTest == true) {
                return 'adaptive_tests';
            }

            return 'tests';
        }
    }

    const openAdaptiveTest = () => {
        updateShowAdaptiveTestIntro(false);
        updateShowAdaptiveTest(true);
    }

    const closeAdaptiveTestIntro = () => {
        updateShowAdaptiveTestIntro(false);
    }

    const goToProfile = () => {
        history.replace(Links.main + Links.profile);
    }

    return (
        <>
            {loading &&
                <IonList>
                    <RecruitmentPathSkeleton />
                </IonList>
            }

            {!loading && recruitmentPathData?.id && showInfo == true && <>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar className="white">
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => goToProfile()}>
                                <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                    </StyledIonToolbar>
                </IonHeader>
                <Pane topEdge={props.topEdge} marginTop={55} paddingBottom={97}>
                    <StyledContent className="info">
                        <div className="content full-height">
                            <div className="logo">
                                <img src={ExactPeopleImg} />
                            </div>

                            <h1 className="title full">{t("recruitment.recruitmentPath.intro.info.title")}</h1>
                            <div className="content justify" dangerouslySetInnerHTML={{__html: t("recruitment.recruitmentPath.intro.info.content") || ''}}></div>
                        </div>
                    </StyledContent>
                    <StyledFooter className="modal no-shadow">
                        <StyledButton
                            onClick={() => goToAccordion()}>{t("recruitment.recruitmentPath.intro.info.continue")}</StyledButton>
                    </StyledFooter>
                </Pane></>
            }

            {!loading && !showInfo && recruitmentPathData?.faq.entries.length > 0 &&
                <>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonRecruitmentToolbar className="white">
                            <IonButtons slot="start">
                                <StyledHeaderButton onClick={() => goBack()}>
                                    <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                            <StyledIonTitle>
                                <div className="logo">
                                    <img src={ExactPeopleImg} />
                                </div>
                            </StyledIonTitle>
                        </StyledIonRecruitmentToolbar>
                    </IonHeader>
                    <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                        <StyledContent className="accordion">
                            <div className="content full-height">
                                {
                                    <>
                                        <h1 className="group-title">{t('recruitment.recruitmentPath.recruitmentProcess')}</h1>
                                        <IonAccordionGroup ref={accordionRecruitment} onIonChange={faqEntriesGroupChange} value={getActiveItem()}>
                                            {recruitmentPathData?.faq.entries.map((faqEntry: RecruitmentPathFaqEntryData, key:string) => {
                                                return <AccordionItem id={key} faqEntry={faqEntry}
                                                            updateShowTest={updateShowTest} recruitmentPathData={recruitmentPathData}
                                                            openContactForm={openContactForm} testPassed={testPassed} updateShowAdaptiveTest={updateShowAdaptiveTest} />
                                            })}
                                        </IonAccordionGroup></>
                                }

                                <Test3Modal isOpen={showAdaptiveTest} tests={recruitmentPathData?.details[RecruitmentPathTestType.TEST_3]}
                                            hash={props.hash} results={recruitmentPathData && recruitmentPathData.results && recruitmentPathData.results[RecruitmentPathTestType.TEST_3] ? recruitmentPathData.results[RecruitmentPathTestType.TEST_3] : []}
                                            recruitmentPathCompleted={recruitmentPathData?.recruitment_path_completed ? recruitmentPathData.recruitment_path_completed : false} onClose={() => updateShowAdaptiveTest(false)}
                                />
                            </div>
                        </StyledContent>
                    </StaticPane>
                </>
            }

            {!loading && (!recruitmentPathData?.id || recruitmentPathData?.faq.entries.length == 0) &&
                <>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar className="white">
                            <IonButtons slot="start">
                                <StyledHeaderButton onClick={() => goToProfile()}>
                                    <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                                </StyledHeaderButton>
                            </IonButtons>
                        </StyledIonToolbar>
                    </IonHeader>
                    <Pane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                        <StyledEmptyContainer>
                            <CircleImage image={ImageTypes.ATTACHEMENT} color="grey"/>
                            <h3>{t('recruitment.recruitmentPath.pathNotFound')}</h3>
                        </StyledEmptyContainer>
                    </Pane>
                </>
            }

            <RecruitmentPathTestModal isOpen={showTest} tests={recruitmentPathData?.details} durations={recruitmentPathData?.times}
                                      hash={recruitmentPathData?.hashCode} results={recruitmentPathData && recruitmentPathData.results ? recruitmentPathData.results : []}
                                      version={recruitmentPathData?.recruitment_path_flow_version} series={recruitmentPathData?.series}
                                      onClose={() => goToStart()} />
            
            <AdaptiveTestInfoModal isOpen={showAdaptiveTestIntro} onClose={closeAdaptiveTestIntro} openAdaptiveTest={openAdaptiveTest} />
        </>
    );
};

export default RecruitmentPathPane;