import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../utils/tools/modals";

type BillingsGuideModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const BillingsGuideModal: React.FC<BillingsGuideModalProps> = (props: BillingsGuideModalProps) => {

    const {t} = useTranslation();

    const [showGuide, updateShowGuide] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowGuide(props.isOpen);
    };

    return (
        <Modal
            isOpen={showGuide}
            onDidDismiss={() => handleClose()}
            canDismiss={disableSwipeToClose}>
                <StyledModalContent>
                    <h1 className="title full">{t("billingPage.billingsGuide.title")}</h1>
                    <div className="content full-height">
                        <ol>
                            <li>{t("billingPage.billingsGuide.p1")}</li>
                            <li>{t("billingPage.billingsGuide.p2")}</li>
                            <li>{t("billingPage.billingsGuide.p3")}</li>
                            <li>{t("billingPage.billingsGuide.p4")}</li>
                        </ol>
                    </div>
                </StyledModalContent>
        </Modal>
    );
};

export default BillingsGuideModal;