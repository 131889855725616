import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "../../../../../components/modal/modal.component";
import {Notification} from "../../../../../models/notification";
import {StyledButton, StyledLightBlueButton, StyledWiteButton} from "../../../../../components/button/button.style";
import {StyledFooter} from "../../../../../components/pane/pane.style";
import {StyledModalContent} from "../../../../../components/modal/modal.style";
import {disableSwipeToClose} from "../../../../../utils/tools/modals";

type PreviewModalProps = {
    isOpen: boolean;
    notification?: Notification | null;
    onClose: () => void;
    onAction: () => void;
    buttonText?: string
};

const PreviewModal: React.FC<PreviewModalProps> = (props: PreviewModalProps) => {

    const {t} = useTranslation();

    const [showRemarksList, updateShowRemarksList] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowRemarksList(props.isOpen);
    };

    return (
        <Modal
            isOpen={showRemarksList}
            canDismiss={disableSwipeToClose}
            maxHeight={'75%'}
            onDidDismiss={() => handleClose()}>
            <StyledModalContent className="notification">
                <h1 className="title">{props.notification?.notification.subject}</h1>
				<div className="date">
					{ props.notification?.notification.sent_date}
				</div>
                <div className={props.notification?.notification.redirect_to && props.buttonText ? "content" : "content full-height" }>
                    <div dangerouslySetInnerHTML={{__html: props.notification?.notification.body || ''}}/>
                </div>
                { props.notification?.notification.redirect_to && props.buttonText &&
                    <StyledFooter className="modal footer no-shadow">
                        <StyledButton
                            onClick={event => props.onAction()}>{props.buttonText}</StyledButton>
                    </StyledFooter>
                }
            </StyledModalContent>
        </Modal>
    );
};

export default PreviewModal;
