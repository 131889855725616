import React from 'react';
import {IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText} from "@ionic/react";
import ArrowRightImage from "../../../assets/images/arrow-right.svg";
import {StyledField} from "../../../components/form/input/input.style";
import {StyledMyDataField} from "../panes/content.style";

const MyDataSkeleton: React.FC = () => {

    return (
        <StyledMyDataField>
            <IonGrid>
                <IonRow>
                    <IonCol size="12">
                        <StyledField>
                            <div className="title"><IonSkeletonText animated style={{ width: '50%' }} /></div>
                            <div className="title"><IonSkeletonText animated style={{ width: '80%' }} /></div>
                        </StyledField>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </StyledMyDataField>
    )
};

export default MyDataSkeleton;