import React, { useEffect, useRef, useState } from 'react';
import { IonButtons, IonHeader, IonPage } from '@ionic/react';
import { StyledDataTable, StyledIonContent, StyledIonTitle, StyledIonToolbar } from '../../../../components/content/content.style';
import { StyledHeaderButton, StyledHeaderButtonImage } from '../../../../components/button/button.style';
import { useHistory } from 'react-router-dom';
import ArrowLeftImage from '../../../../assets/images/arrow-left.svg';
import VectorImage from '../../../../assets/images/checkMarkGreen.svg';
import { useTranslation } from 'react-i18next';
import StaticPane from '../../../../components/pane/static-pane.component';
import {Preferences} from "@capacitor/preferences";
import {updateLang} from "../../../../services/profile.service";

export interface ILanguage {
    name: string;
    selected: boolean,
    lang: string
};

// TODO: possible merge languageTab with appModeTab into select optionTab ??
const LanguageTab: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    let supportedLngs = ['en', 'pl', 'de', 'sk', 'tr', 'ru', 'cs', 'nl', 'hu', 'ro', 'zh', 'pt', 'es', 'uk', 'fr'];

    const getLanguages = () => supportedLngs.map((lang: string) => ({
        name: t(`languageTab.${lang}`),
        selected: i18n.language == lang,
        lang
    }));

    const [languages, updateLanguage] = useState(getLanguages());

    useEffect(() => {
        updateHeight();
    });

    const changeLanguage = async (lang: string) => {
        i18n.changeLanguage(lang, () => updateLanguage(getLanguages));
        document.documentElement.lang = lang;
        await Preferences.set({'key': 'i18nextLng', 'value': lang});
        updateLang(lang)
    };

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <IonButtons slot="start">
                            <StyledHeaderButton>
                                <StyledHeaderButtonImage src={ArrowLeftImage}
                                                         onClick={() => history.goBack()}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>{t("languageTab.title")}</StyledIonTitle>
                    </StyledIonToolbar>
                </IonHeader>
                <StaticPane topEdge={topEdge} marginTop={40} paddingBottom={105}>
                    <StyledDataTable className="no-margin">
                    { languages.map((data:ILanguage, i:any) => <div key={i} className="data-row" onClick={() => changeLanguage(data.lang)}>
                                                        <div className="data-icon-title">{data.name}</div>
                                                        <div className="data-action">
                                                            {data.selected && <img src={VectorImage} />}
                                                        </div>
                                                    </div>)
                    }
                    </StyledDataTable>
                </StaticPane>
            </StyledIonContent>
        </IonPage>
    );
};

export default LanguageTab;
