import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import { StyledButton, StyledCircleButton, StyledWiteButton } from '../../../../components/button/button.style';
import { StyledActionButtons, StyledTaskDate, StyledTaskItemContainer } from './taskItem.style';
import PenImage from "../../../../assets/images/pen-01.svg";
import TrashImage from '../../../../assets/images/trash-can.svg';
import moment from 'moment';

export enum TaskStatus {
    PENDING,
    ACCEPTED,
    DISMISSED,
    ABSENCE,
	PROPOSED,
	ABSENCE_REPORTED,
	ABSENCE_REPORTED_AFTER_DEADLINE,
	ABSENCE_UNREPORTED
}

export type Task = {
	id?: number;
    startDate: Date;
    endDate?: Date;
    allDay: boolean;
    title?: string;
    description: string;
    status: TaskStatus;
    place: string;
    jobPosition?: string;
    acceptEnabled?: boolean;
    type?: string;
    coordinator?: string;
};

type TaskItemProps = {
    task: Task,
    onApprove?: (task: Task) => void,
    onDismiss?: (task: Task) => void,
    onEdit?: (task: Task) => void,
	onRemove?: (task: Task) => void,
};

const TaskItem: React.FC<TaskItemProps> = (props: TaskItemProps) => {
    const { t } = useTranslation();
    const i18 = getI18n();
    moment.locale(i18.language);

    const showTwoDates = (): boolean => {
        if (!props.task.endDate) {
            return false;
        }

        if (props.task.startDate.getFullYear() === props.task.endDate.getFullYear() &&
            props.task.startDate.getMonth() === props.task.endDate.getMonth() &&
            props.task.startDate.getDay() === props.task.endDate.getDay()) {

            return false;
        }

        return true;
    };

    const getTime = (date: Date): string => {
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

    return (
        <StyledTaskItemContainer>
            <IonGrid>
                <IonRow>
                    <IonCol size="5">
                        <IonGrid>
                            <IonRow>
                                <IonCol size={showTwoDates() ? '5.5' : '12'}>
                                    <StyledTaskDate>
                                        <span className="day">{props.task.startDate.getDate()}</span>
                                        <span className="month">{  moment(props.task.startDate).format('MMM') }</span>
										{
											showTwoDates() &&
											<span className="time">{moment(props.task.startDate).format('HH:mm')}</span>
										}
                                    </StyledTaskDate>
                                </IonCol>
                                {showTwoDates() &&
                                <IonCol size="1">
                                    <span className="separator">-</span>
                                </IonCol>}
                                {showTwoDates() &&
                                <IonCol size="5.5">
                                    <StyledTaskDate>
                                        <span className="day">{(props.task.endDate??  new Date()).getDate()}</span>
                                        <span className="month">{  moment(props.task.endDate).format('MMM') }</span>
                                        {
											showTwoDates() &&
											<span className="time">{moment(props.task.endDate).format('HH:mm')}</span>
										}
                                    </StyledTaskDate>
                                </IonCol>}
                            </IonRow>
                        </IonGrid>
                    </IonCol>
                    <IonCol size={props.task.status === TaskStatus.ABSENCE ? '4' : '7'} class="content">
						{
							!showTwoDates() && <div>
								<h3 className="title">{`${getTime(props.task.startDate)} - ${getTime(props.task.endDate ?? new Date())}`}</h3>
								{
									props.task.status !== TaskStatus.ABSENCE && <h3 className="description"></h3>
								}
							</div>
						}

						{
                            props.task.status === TaskStatus.PROPOSED &&
                            <StyledActionButtons>
								{
									props.task.acceptEnabled &&
									<StyledButton className="accept"
												  onClick={(e) => {e.stopPropagation(); props.onApprove && props.onApprove(props.task)}}>
										{t("notificationTab.taskItem.accept")}
									</StyledButton>
								}

								<StyledWiteButton className="dismiss"
												  onClick={(e) => {e.stopPropagation(); props.onDismiss && props.onDismiss(props.task)}}>
	                                { t("notificationTab.taskItem.dismiss") }
                                </StyledWiteButton>
							</StyledActionButtons>
                        }
                        {
                            props.task.status === TaskStatus.ACCEPTED && !props.task.title && props.task.allDay &&
                            <h3 className="title">{t("notificationTab.taskItem.allDay")}</h3>
                        }
                        {
                            props.task.status === TaskStatus.ACCEPTED && !props.task.title && !props.task.allDay && props.task.endDate &&
							<div>
								<h3 className="title">{t("notificationTab.taskItem.accepted")}</h3>
								<h3 className="description">
									{
										moment(props.task.startDate).format('DD.MM') == moment(props.task.endDate).format('DD.MM') &&
											`${moment(props.task.startDate).format('HH:mm')} - ${moment(props.task.endDate).format('HH:mm')}`
									}

									{
										moment(props.task.startDate).format('DD.MM') != moment(props.task.endDate).format('DD.MM') &&
										`${moment(props.task.startDate).format('DD.MM HH:mm')} - ${moment(props.task.endDate).format('DD.MM HH:mm')}`
									}
								</h3>
							</div>
                        }
                        {
                            props.task.status === TaskStatus.ACCEPTED && props.task.title &&
                            <h3 className="title">{props.task.title}</h3>
                        }
                        {
                            props.task.status === TaskStatus.ABSENCE && showTwoDates() &&
                            <h3 className="title">{t("notificationTab.taskItem.absence")}</h3>
                        }
                        {
                            //props.task.status === TaskStatus.ABSENCE && !showTwoDates() &&
                            //<h3 className="title">{`${getTime(props.task.startDate)} - ${getTime(props.task.endDate ?? new Date())}`}</h3>
                        }
                        {
                            (props.task.status === TaskStatus.ACCEPTED || props.task.status === TaskStatus.ABSENCE) &&
                            <h3 className="description">{props.task.description}</h3>
                        }
						{
							(props.task.status === TaskStatus.ABSENCE_REPORTED || props.task.status === TaskStatus.ABSENCE_REPORTED_AFTER_DEADLINE || props.task.status === TaskStatus.ABSENCE_UNREPORTED) &&
							<h3 className="title">{t("notificationTab.taskItem.dismissed")}</h3>
						}
                    </IonCol>

					{
						props.task.status === TaskStatus.ABSENCE && (props.task.startDate > moment().toDate()) && props.onEdit &&
							<IonCol size="1.2" className="content content-button">
								<StyledCircleButton className="edit-button" onClick={() => props.onEdit && props.onEdit(props.task) }><img src={PenImage} /></StyledCircleButton>
							</IonCol>
					}

					{
						props.task.status === TaskStatus.ABSENCE && (props.task.startDate > moment().toDate()) && props.onRemove &&
							<IonCol size="1.2" className="content content-button">
								<StyledCircleButton className="delete" onClick={() => props.onRemove && props.onRemove(props.task) }><img src={TrashImage} /></StyledCircleButton>
							</IonCol>
					}

                </IonRow>
            </IonGrid>
        </StyledTaskItemContainer>
    );
};

export default TaskItem;
