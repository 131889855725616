import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Pane from '../../../components/pane/pane.component';
import { StyledIonContent } from './registry.style';
import moment from 'moment';
import ClockImage from '../../../assets/images/clock-blue.svg';
import {StyledButton, StyledLightRedButton, StyledWiteButton} from '../../../components/button/button.style';
import { ContractComment } from '../../../models/contractComment';
import ConversationModal from '../../../modals/conversation/conversation.component';
import ReportRemarksModal from '../modals/remarksModal.component';
import {IonCol, IonSkeletonText, IonToast, useIonViewWillEnter} from "@ionic/react";
import {getWalletData} from "../../../services/worker.service";
import {MonthWallet} from "../../walletPage/panes/walletPane.component";
import {getRegistryData, acceptRegistryData, sendRegistryDataComment} from "../../../services/worker.service";
import {isEmployed} from "../../../services/auth.service";
import {StyledEmptyContainer} from "../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../components/circleImage/circleImage.component";
import StaticPane from "../../../components/pane/static-pane.component";
import {RemarkAuthorType} from "../../../enums/remarkAuthorType";
import {minutesToHoursMinutes} from "../../../utils/tools/time";
import {log} from "../../../services/firebaseAnalytics.service";

type RegistryPaneProps = {
    topEdge?: number;
}

type Registry = {
    date: Date;
    workingHoursCount: number;
    workingHoursReadable: string;
    status: RegistryStatus;
    comments?: ContractComment[];
    actionsAvailable: boolean;
    commentActionsAvailable: boolean;
    enableActionsAt: Date;
    disableActionsAt: Date;
}

enum RegistryStatus {
	NEW = 'NEW',
	REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
	AUTO_ACCEPTED = 'AUTO_ACCEPTED',
	UPDATED = 'UPDATED',
}

const RegistryPane: React.FC<RegistryPaneProps> = (props: RegistryPaneProps) => {
    const { t } = useTranslation();
    const [showAddRemarks, updateShowAddRemarks] = useState<boolean>(false);
    const [showConversation, updateShowConversation] = useState<boolean>(false);
    const [showNote, updateShowNote] = useState<boolean>(false);

    const history = useHistory();

    const [registry, setRegistry] = useState<Registry>({
        date: moment().subtract(1, 'month').toDate(),
        status: RegistryStatus.NEW,
        workingHoursCount: 0,
		workingHoursReadable: '0h',
		actionsAvailable: false,
		commentActionsAvailable: false,
		enableActionsAt: new Date(),
		disableActionsAt: new Date()
    });

	const [loading, setLoading] = useState<boolean>(false);
	const [employed, setEmployed] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState<any>('');

    const handleRemarks = () => {
        if (registry && registry.comments && registry.comments.length > 0) {
            updateShowConversation(true);
        } else {
            updateShowAddRemarks(true);
        }
    }

	const isEmployee = async() => {
		const response = await isEmployed();
		let result:boolean = response == '1' ? true : false;
		return result;
	}

	const fetchRegistryData = async () => {
		let isEmployed:boolean = await isEmployee();
		setEmployed(isEmployed);

		if (isEmployed == true) {
			setLoading(true);
			await getRegistryData()
				.then(response => {
					let data = response.data;

					if (data && data.hours) {
						let comments = undefined;
						if (data.comments.length > 0) {
							comments = Array();

							data.comments.forEach((comment:any) => {
								comments.push({
									is_worker_author: comment.user_type == RemarkAuthorType.WORKER,
									status: '',
									status_changed_at: new Date(),
									comment: comment.content,
									description: ''
								});
							});
						}

						setRegistry({
							date: moment().subtract(1, 'month').toDate(),
							workingHoursCount: data.hours,
							workingHoursReadable: minutesToHoursMinutes(data.hours * 60),
							status: data.status.toUpperCase(),
							comments: comments?.reverse(),
							actionsAvailable: data.actions_available,
							commentActionsAvailable: data.comment_actions_available,
							enableActionsAt: new Date(data.enable_actions_at),
							disableActionsAt: new Date(data.disable_actions_at)
						});

						updateShowNote(true);
					}
				});
			setLoading(false);
		}
	};

    const sendAcceptRegistryData = async () => {
    	await acceptRegistryData()
			.then(response => {
				let data = response.data;

				if (data.result && data.result == 'not_available') {
					setToast(t("reportsTab.registry.actionNotAvailable"));
					setShowToast(true);
				} else {
					fetchRegistryData();
				}
			})
			.catch(error => {
				setToast(t("common.serverErrorMsg"));
				setShowToast(true);
			})
    	;
	};

    const addRegistryDataComment = async (comment: string) => {
    	await sendRegistryDataComment(comment)
			.then(response => {
				let data = response.data;
				updateShowAddRemarks(false);
				//updateShowConversation(false);
				fetchRegistryData();
			})
			.catch(error => {
				if (error.response && error.response.status === 400) {
					setToast(t("reportsTab.registry.valueShouldNotBeBlank"));
					setShowToast(true);
				} else {
					setToast(t("common.serverErrorMsg"));
					setShowToast(true);
				}
			})
		;
	}

	useEffect(() => {
		fetchRegistryData();
	}, []);

	useIonViewWillEnter(() => {
		fetchRegistryData();
	});

	useEffect(() => {
		log('page_visit', {
			page: 'Raporty -> Rejestr'
		});
	}, []);

    return employed == false ? (
			<StaticPane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
				<StyledEmptyContainer>
					<CircleImage image={ImageTypes.NOTE} color="grey"/>
					<h3>{t('reportsTab.registry.soonAvailableNotWorker')}</h3>
				</StyledEmptyContainer>
			</StaticPane>
    ) : (
		<Pane topEdge={props.topEdge} marginTop={40} paddingBottom={97}>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message={toast}
				duration={6000}
				position="top"
				color="danger"
			/>
			{
				!loading &&
				<StyledIonContent>
					<h1>{t('reportsTab.registry.title')} {moment(registry.date).format('MM/YYYY')}</h1>
					<div className="working-hours">
						<img src={ClockImage}/>
						<span>{registry.workingHoursReadable}</span>
					</div>

					{
						registry && registry.actionsAvailable === true &&
						<StyledButton onClick={() => sendAcceptRegistryData()}>{t('reportsTab.registry.accept')}</StyledButton>
					}

					{
						registry && registry.commentActionsAvailable === true && !registry?.comments?.length &&
						<StyledLightRedButton onClick={() => handleRemarks()}>
							{t('reportsTab.registry.remarks')}
						</StyledLightRedButton>
					}

					{
						registry && registry.commentActionsAvailable === true && registry?.comments?.length &&
						<StyledLightRedButton onClick={() => handleRemarks()}>
							{t('reportsTab.registry.remarks')} ({registry.comments.length})
						</StyledLightRedButton>
					}

					{
						registry && (registry.status === RegistryStatus.ACCEPTED || registry.status === RegistryStatus.AUTO_ACCEPTED) &&
						<div className="accepted">
							<span>{t('reportsTab.registry.accepted')}</span>
						</div>
					}

					<div className={showNote == true ? "note additional-margin" : "hide"}>
						{t('reportsTab.registry.note1', {from: registry.enableActionsAt.getDate(), to: registry.disableActionsAt.getDate()})}
					</div>

					<div className={showNote == true ? "note" : "note additional-margin"}>
						{t('reportsTab.registry.note2')}
					</div>
				</StyledIonContent>
			}

			{
				loading &&
				<StyledIonContent>
					<h1><IonSkeletonText animated style={{width: '100%'}}/></h1>
					<div className="working-hours">
						<span><IonSkeletonText animated style={{width: '200px', height: '60px'}}/></span>
					</div>

					<StyledWiteButton>
						<small style={{width: '50%'}}><IonSkeletonText animated/></small>
					</StyledWiteButton>

					<div className="note additional-margin">
						<IonSkeletonText animated/>
					</div>
				</StyledIonContent>
			}

			<ReportRemarksModal
				isOpen={showAddRemarks}
				onClose={() => updateShowAddRemarks(false)}
				onRemarks={(comment) => addRegistryDataComment(comment)}
			></ReportRemarksModal>

			<ConversationModal
				isOpen={showConversation}
				onClose={() => updateShowConversation(false)}
				onSave={(comment) => addRegistryDataComment(comment)}
				comments={registry?.comments}>
			</ConversationModal>
		</Pane>
	);
};

export default RegistryPane;
