import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {IonHeader, IonPage} from "@ionic/react";
import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from "../../components/content/content.style";
import SubNavigation, {SubNavigationOption} from "../../components/sub-navigation/subNavigation.component";
import useNavigation from "../../services/navigation.service";
import LoansPane from "./panes/loansPane.component";
import LoansHistoryPane from "./panes/loansHistoryPane.component";
import {ContractPageContext} from "../contractPage/contractPage.component";

type LoansDataPaneProps = {
    topEdge?: number;
}

export enum LoansDataPageTabOptions {
    LOANS_INFO = 0,
    LOANS_HISTORY = 1
}

export interface ILoansDataTabState {
    activeTab: LoansDataPageTabOptions;
}

interface LoansPageManager {
    setSelectedTab: Function;
}

const contextLoansPage: LoansPageManager = {
    setSelectedTab: () => {

    }
};

export const LoansPageContext = React.createContext<LoansPageManager>(contextLoansPage);

const LoansPage: React.FC<LoansDataPaneProps> = (props: LoansDataPaneProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const header = useRef<HTMLIonHeaderElement>(null);
    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);

    const options = [
        {
            name: t("loansPage.loansInfo")
        },
        {
            name: t("loansPage.loansHistory")
        }
    ];

    const [selectedOption, updateSelectedOption] = useState<number>(parseInt(navigation.getParam<string>('selectedOption')) || LoansDataPageTabOptions.LOANS_INFO);
    const handleSelect = (option: SubNavigationOption) => {
        const optionIndex = options.indexOf(option);
        if (optionIndex === selectedOption) {
            return;
        }

        navigation.setParam('selectedOption', optionIndex.toString());
        updateSelectedOption(optionIndex);
    };

    const contextLoanPage = useContext(LoansPageContext);
    contextLoanPage.setSelectedTab = updateSelectedOption;

    useEffect(() => {
        updateHeight();
    },[]);

    const updateHeight = () => {
        if (header.current ?.clientHeight == 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    return (
        <IonPage>
            <StyledIonContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonToolbar>
                        <StyledIonTitle className="left">{t("profileTab.optionLoans")}</StyledIonTitle>
                    </StyledIonToolbar>
                    <SubNavigation options={options}
                                   defaultValue={selectedOption || LoansDataPageTabOptions.LOANS_INFO}
                                   onSelect={handleSelect}></SubNavigation>
                </IonHeader>

                {
                    selectedOption === LoansDataPageTabOptions.LOANS_INFO &&
                    <LoansPane topEdge={topEdge} handleNavigation={handleSelect} />
                }
                {
                    selectedOption === LoansDataPageTabOptions.LOANS_HISTORY &&
                    <LoansHistoryPane topEdge={topEdge} />
                }
            </StyledIonContent>
        </IonPage>
    );
};

export default LoansPage;
