import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../../components/modal/modal.component';
import { StyledModalContent } from '../../../../../components/modal/modal.style';
import {disableSwipeToClose} from "../../../../../utils/tools/modals";

type InstructionModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const InstructionModal: React.FC<InstructionModalProps> = (props: InstructionModalProps) => {

    const {t} = useTranslation();

    const [showInstruction, updateShowInstruction] = useState<boolean>(false);

    const handleCloseRemarks = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowInstruction(props.isOpen);
    };

    return (
        <Modal
            isOpen={showInstruction}
            canDismiss={disableSwipeToClose}
            onDidDismiss={() => handleCloseRemarks()}>
            <StyledModalContent>
                <h1 className="title">{t("settingsTab.instructionModal.title")}</h1>
                <div className="content full-height">
                    {t("settingsTab.instructionModal.content")}
                </div>
            </StyledModalContent>
        </Modal>
    );
};

export default InstructionModal;