import {Preferences} from "@capacitor/preferences";

const updateStorage = async (field: string, value: any) => {
	await Preferences.set({
		'key': field,
		'value': value
	});
}

export { updateStorage };
